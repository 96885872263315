import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { ThreeDots } from 'react-loading-icons'
import { dispachAlert } from '../../../utils/dispachers';

function MoveAll({ selected, setSelected, handleLoad }) {
  const [loadedMovie, setLoadedMovie] = useState(undefined)
  const [loadingMovie, setLoadingMovie] = useState(false)
  const [resultOpen, setResultOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [resultError, setResultError] = useState(false)
  const [results, setResults] = useState([])
  const movieIdRef = useRef()
  const handleLoadMovie = () => {
    setLoadingMovie(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/admin/movies/moveAll/getMovie',
        { newMovieId: movieIdRef.current.value },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setLoadedMovie(res.data.newMovie)
        } else {
          dispachAlert('error', res.data.errMsg || 'Error')
        }
        setLoadingMovie(false)
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          dispachAlert('error', 'Forbidden.')
        }
        dispachAlert('error', 'Error.')
        setLoadingMovie(false)
        console.log({ err })
      })
  }
  const handleMoveAll = () => {
    var answer = window.confirm('Confirm moving all subtitles?')
    if (answer) {
      setResultOpen(true)
      setProcessing(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/movies/moveAll/move',
          { newMovieId: movieIdRef.current.value, oldMovieId: selected.id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setResults(res.data.results)
          } else {
            setResultError(res.data.errMsg)
          }
          setProcessing(false)
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            setResultError('Forbidden.')
          } else {
            setResultError('server Error.')
          }
          setProcessing(false)
        })
    }
  }
  useEffect(() => {
    setLoadedMovie(undefined)
    movieIdRef.current.value = ''
  }, [selected])
  const handleCloseDialog = () => {
    setResultOpen(false)
    setLoadedMovie(undefined)
    movieIdRef.current.value = ''
    setResultError(false)
  }
  return (
    <>
      <Dialog open={resultOpen} maxWidth="false">
        <DialogTitle
          sx={{
            textAlign: 'center',
            color: '#21495F',
            fontFamily: 'Bold-Family',
            fontSize: '30px'
          }}
        >
          Move Results
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: '700px',
            minHeight: '450px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          {processing ? (
            <Box sx={{ flex: 1, alignContent: 'center' }}>
              <ThreeDots fill="black" />
            </Box>
          ) : resultError ? (
            <>{resultError}</>
          ) : (
            <>
              <Box sx={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                <Typography sx={{ fontFamily: 'Bold-Family', fontSize: '20px' }}>
                  Total: {results.length}
                </Typography>
                <Typography
                  sx={{ fontFamily: 'Bold-Family', fontSize: '20px', color: 'darkgreen' }}
                >
                  Success: {results.filter((x) => x.success === true).length}
                </Typography>
                <Typography sx={{ fontFamily: 'Bold-Family', fontSize: '20px', color: 'darkred' }}>
                  Failed: {results.filter((x) => x.success === false).length}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                {results.map((result) => (
                  <>
                    <Paper
                      component={Box}
                      sx={{
                        display: 'flex',
                        px: '8px',
                        py: '3px',
                        flex: 1,
                        gap: '2rem',
                        bgcolor: result.success ? '#8fbc8f78' : '#cd5c5c4f'
                      }}
                      elevation={3}
                    >
                      <Typography>
                        ID:{' '}
                        <Link href={result.subLink} target="_blank" sx={{ textDecoration: 'none' }}>
                          {result.id}
                        </Link>
                      </Typography>
                      <Typography>Ratings Moved: {result.ratesMoves}</Typography>
                      <Typography>Error: {result.error || 'N/A'}</Typography>
                    </Paper>
                  </>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ m: '0.25rem' }}>
          <Button variant="contained" color="info" disableRipple onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '25px' }}>
            Move All Subtitles
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <TextField
            fullWidth
            size="small"
            label="Movie ID"
            placeholder="Type movie id to load details..."
            inputRef={movieIdRef}
            disabled={loadingMovie}
          />
          <Button variant="outlined" onClick={handleLoadMovie} disabled={loadingMovie}>
            Load
          </Button>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', gap: '1rem' }}>
          <Box
            sx={{
              width: '100px',
              height: '150px',
              border: '1px solid black',
              borderRadius: '10px',
              overflow: 'hidden',
              display: 'flex'
            }}
          >
            <img
              src={loadedMovie?.poster}
              alt="poster"
              style={{ display: loadedMovie !== undefined ? 'flex' : 'none' }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography>Title: {loadedMovie?.title || 'N/A'}</Typography>
            <Typography>
              <Link sx={{ textDecoration: 'none' }} href={loadedMovie?.sslink} target="_blank">
                SubSource Link
              </Link>
            </Typography>
            <Typography>Subtitles: {loadedMovie?.subCount || 'N/A'}</Typography>
            <Typography>Type: {loadedMovie?.type || 'N/A'}</Typography>
            <Typography>Season: {loadedMovie?.season || 'N/A'}</Typography>
            <Typography>Year: {loadedMovie?.year || 'N/A'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignSelf: 'flex-end', flex: 1, justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleMoveAll} disabled={!loadedMovie}>
              Move All
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MoveAll
