import { Box, Divider, Skeleton, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode'
import NewsCard from './NewsCard'
import './News.css'
function News({ isLoading, news }) {
  const darkmode = useDarkMode()
  if (isLoading) {
    return (
      <Box id={'homepage-news-container'}>
        <Typography
          id={'homepage-news-head-title'}
          variant="h2"
          sx={{ fontWeight: 'bold', mb: '.5rem' }}
          fontFamily={'Medium-Family'}
          className={darkmode.value && 'dark-mode'}
        >
          News & Updates
        </Typography>{' '}
        <Divider
          sx={{
            borderColor: darkmode.value ? 'white' : 'black',
            borderBottomWidth: '2px',
            mb: '1.25rem'
          }}
        />
        <Box id={'homepage-newscards-container'}>
          {[1, 2, 3, 4, 5].map((n) => (
            <Skeleton
              key={n}
              animation="wave"
              variant="rounded"
              sx={{ width: '100%', height: '50px', borderRadius: '10px' }}
            />
          ))}
        </Box>
        <Divider
          sx={{
            mt: '1rem',
            borderColor: darkmode.value ? 'white' : 'black',
            borderBottomWidth: '2px'
          }}
        />
      </Box>
    )
  }
  return (
    <Box id={'homepage-news-container'}>
      <Typography
        id={'homepage-news-head-title'}
        variant="h2"
        sx={{ fontWeight: 'bold', mb: '0.5rem' }}
        fontFamily={'Medium-Family'}
        className={darkmode.value && 'dark-mode'}
      >
        News & Updates
      </Typography>
      <Divider
        sx={{
          borderColor: darkmode.value ? 'white' : 'black',
          borderBottomWidth: '2px',
          mb: '1.25rem'
        }}
      />
      <Box id={'homepage-newscards-container'}>
        {news?.map((n, index) => (
          <NewsCard data={n} index={index} key={index} />
        ))}
      </Box>
      <Divider
        sx={{
          mt: '1rem',
          borderColor: darkmode.value ? 'white' : 'black',
          borderBottomWidth: '2px'
        }}
      />
    </Box>
  )
}

export default News
