import { Box, FormControlLabel, Radio, RadioGroup, ThemeProvider, createTheme } from '@mui/material'
import React, { useState } from 'react'
import ImdbTab from '../Components/AddMovie/ImdbTab'
import TmdbTab from '../Components/AddMovie/TmdbTab'
import MyDramaListTab from '../Components/AddMovie/MyDramaListTab'
import CustomTab from '../Components/AddMovie/CustomTab'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo';

function AddMoviePage() {
  const darkmode = useDarkMode()
  const [tab, setTab] = useState('0')
  set_meta({
    is_landing: false,
    title: 'SubSource: Add a new Movie/TV-Show'
  })
  const tabs = {
    0: <ImdbTab />,
    1: <TmdbTab />,
    2: <MyDramaListTab />,
    3: <CustomTab />
  }
  const darkTheme = createTheme({
    palette: {
      mode: darkmode.value ? 'dark' : 'light'
    }
  })
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: '3rem',
          p: '1rem',
          mx: { xs: '3%', md: '3%', lg: '18%' }
        }}
        className="addMovie-Container"
      >
        <Box sx={{ display: 'flex', alignSelf: { lg: 'center' }, mb: '1.5rem' }}>
          <RadioGroup
            sx={{
              width: '100%',
              backgroundColor: darkmode.value ? 'darkgray' : '#EEE',
              '&.addMovie-Tabs .MuiFormControlLabel-root:has(.Mui-checked)': {
                backgroundColor: darkmode.value ? 'lightgray' : 'white'
              },
              display: 'grid',
              gridTemplateColumns: { xs: 'auto auto', lg: 'auto auto auto auto' }
            }}
            row
            name="tabs"
            className="addMovie-Tabs"
            value={tab}
            onChange={(e) => {
              setTab(e.target.value)
            }}
          >
            <FormControlLabel value="0" control={<Radio />} label="IMDb" />
            <FormControlLabel value="1" control={<Radio />} label="TMDB" />
            <FormControlLabel value="2" control={<Radio />} label="MyDaramList" />
            <FormControlLabel value="3" control={<Radio />} label="Custom" />
          </RadioGroup>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', lg: 'row' }, gap: '2rem' }}
        >
          {tabs[tab]}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default AddMoviePage
