import React, { useEffect, useState } from 'react'
import { Typography, Dialog, DialogContent, DialogActions, Button, Box, Avatar, TextField } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import { useUserContext } from '../../../Contexts/userContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserApi } from '../../../api/userApi';
import { dispachAlert } from '../../../utils/dispachers';
const BoldFont = 'Bold-Family'
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
function DashboardChangeProfileModa({ show, toggleShow }) {
    const [preview, setPreview] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const user = useUserContext();
    const [username, setUsername] = useState(user.username);
    const [avatarDeleted, setAvatarDeleted] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [bioError, setBioError] = useState(false);
    const [bio, setBio] = useState(user.bio);
    const queryClient = useQueryClient();
    const { refetch, isFetching } = useQuery({
        queryKey: ["changeProfile"],
        staleTime: 0,
        gcTime: 0,
        enabled: false,
        queryFn: async () => {
            try {
                if (avatarDeleted || !selectedFile) {
                    const result = await UserApi.editProfile(avatarDeleted, bio, username);
                    if (result) {
                        if (result?.success === true) {
                            queryClient.invalidateQueries({
                                queryKey: ['getUser'],
                            });
                            toggleShow();
                        } else {
                            if (result?.errCode === 1) {
                                setUsernameError("Username Already Exists.");
                                return;
                            }
                            if (result?.errCode === 2) {
                                setUsernameError("Username Invalid.");
                                return;
                            }
                            if (result?.errCode === 3) {
                                setBioError("Bio Invalid.");
                                return;
                            }
                            if (result?.errCode === 4) {
                                dispachAlert('error', "Limit Exceeded.")
                                return;
                            } else {
                                dispachAlert('error', "Error.")
                                return;
                            }
                        }
                    }
                    return;
                } else {
                    const result = await UserApi.editProfileWAvatar(selectedFile, false, bio, username);
                    if (result) {
                        if (result?.success === true) {
                            queryClient.invalidateQueries({
                                queryKey: ['getUser'],
                            });
                            toggleShow();
                        } else {
                            if (result.errCode === 1) {
                                setUsernameError("Username Already Exists.");
                                return;
                            }
                            if (result.errCode === 2) {
                                setUsernameError("Username Invalid.");
                                return;
                            }
                            if (result.errCode === 3) {
                                setBioError("Bio Invalid.");
                                return;
                            }
                            if (result.errCode === 4) {
                                dispachAlert('error', "Limit Exceeded.")
                                return;
                            } else {
                                dispachAlert('error', "Error.")
                                return;
                            }
                        }
                    }
                    return;
                }
            } catch (error) {
                dispachAlert('error', 'Server error.');
            }
        },
    })

    const handleClose = () => {
        setUsername(username);
        setUsernameError();
        setBio(bio);
        setBioError();
        setAvatarDeleted();
        setPreview();
        setSelectedFile();
        toggleShow();
        return;
    }
    const handleSave = () => {
        if (!username) {
            return setUsernameError("Username cannot be empty.")
        }
        setBioError(false);
        setUsernameError(false);
        refetch();
    }
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }
        if (e.target.files[0].size > 1000000) {
            return dispachAlert('error', 'File too big.')
        }
        if (e.target.files[0].name.toLowerCase().endsWith("png") || e.target.files[0].name.toLowerCase().endsWith("jpeg") || e.target.files[0].name.toLowerCase().endsWith("jpg")) {
            setSelectedFile(e.target.files[0])
            console.log(e.target.files[0])
        } else {
            return dispachAlert('error', 'Unsupported Format.')
        }

    }
    const handleDeleteAvatar = () => {
        setAvatarDeleted(true);
    }
    const getImageSrc = () => {
        if (avatarDeleted) {
            return "";
        }
        if (preview) {
            return preview;
        }
        if (user.avatar) {
            return process.env.REACT_APP_API_URL + user.avatar
        }
        return ""
    }
    useEffect(() => {
        var expr = /^[a-zA-Z0-9._]*$/;
        if (!expr.test(username)) {
            setUsernameError("Unallowed Characters...")
        } else {
            setUsernameError(false)
        }
    }, [username])
    return (
        <Dialog open={show} onClose={isFetching ? null : handleClose} aria-labelledby={'Subscene ID'} >
            <Box sx={{ display: "flex", minWidth: "400px", justifyContent: "center", alignContent: "center", paddingTop: "3rem" }}>
                <Typography fontFamily={BoldFont} variant='h4'>Edit Profile</Typography>
            </Box>
            <DialogContent >
                <Box fontFamily={BoldFont} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", p: "1.5rem", paddingTop: "0.5rem" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center", marginTop: "1rem", gap: "1rem" }}>
                        <Avatar sx={{ width: "5rem", height: "5rem" }} children={user.avatar ? "" : <AccountCircleIcon sx={{ width: "5rem", height: "5rem" }} />} src={getImageSrc()} alt={user.username} />
                        <Box>
                            <Button component="label" role='undefined' variant='outlined' color='primary' sx={{ textTransform: "none" }} disableRipple>Upload New Avatar<VisuallyHiddenInput type="file" onChange={onSelectFile} accept="image/png, image/jpeg, image/jpg" /></Button>
                            <Typography sx={{ position: "absolute", mt: "0.5rem", color: "black", fontSize: "0.75rem" }}>Formats Allowed: PNG,JPG,JPEG<br></br>Maximum File Size: 1Mb</Typography>
                        </Box>
                        <Button variant='contained' color='error' sx={{ textTransform: "none" }} disableRipple disabled={!user.avatar || avatarDeleted} onClick={handleDeleteAvatar}>Delete</Button>
                    </Box>
                    <Box>
                        <TextField disabled={user.ucl === 0} sx={{ mt: "3rem" }} fullWidth label={"Username (" + user.ucl + " Changes Left)"} value={username} onChange={(e) => setUsername(e.target.value)} error={Boolean(usernameError)} helperText={usernameError} />
                        <TextField sx={{ mt: "1rem" }} inputProps={{ dir: "auto" }} fullWidth label="Bio" value={bio} multiline onChange={(e) => setBio(e.target.value)} error={Boolean(bioError)} helperText={bioError} />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    color="success"
                    variant='contained'
                    sx={{ fontFamily: BoldFont, px: "1rem", }}
                    disabled={isFetching}
                >
                    Save
                </Button>
                <Button
                    onClick={handleClose}
                    color="error"
                    sx={{ fontFamily: BoldFont, px: "1rem" }}
                    disabled={isFetching}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default DashboardChangeProfileModa