import { Box, Container, Divider, Link, Paper, ToggleButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Grid } from 'react-loading-icons'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import useDarkMode from 'use-dark-mode'
import DataGridUserAllSubs from '../../Components/DataGrid/UserAllSubs'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';

function AllSubsPage() {
  const darkmode = useDarkMode()
  const params = useParams()
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useSearchParams()
  const [data, setData] = useState([])
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(false)
  const [sortBy, setSortBy] = useState('latest')
  // PAGINATION
  const itemsPerPage = 20
  const [pageCount, setPageCount] = useState(0)
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setDataLoading(true)
    setSearchQuery({
      page: parseInt(event.selected || 0) + 1,
      sort: sortBy
    })
  }
  //END PAGINATION
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/userSubs', {
        params: {
          page: searchQuery.get('page') || 1,
          userId: params.id,
          sort: searchQuery.get('sort')
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success && res.data.subs.length > 0) {
          set_meta({
            is_landing: false,
            title: `SubSource: ${ res?.data?.userData?.username }'s Subtitles..`
          })
          var subs = res.data.subs
          if (searchQuery.get('sort') === 'latest') {
            subs = subs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          } else if (searchQuery.get('sort') === 'comments') {
            subs = subs.sort((a, b) => b.comments - a.comments)
          } else if (searchQuery.get('sort') === 'downloads') {
            subs = subs.sort((a, b) => b.downloads - a.downloads)
          } else {
            subs = subs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          }
          setUserData(res.data.userData)
          setData(subs)
          setPageCount(
            Math.ceil(subs[0].total / itemsPerPage) > 250
              ? 250
              : Math.ceil(subs[0].total / itemsPerPage)
          )
          setLoading(false)
          setDataLoading(false)
        } else {
          setUserData(res.data.userData)
          setData([])
          setPageCount(0)
          setLoading(false)
          setDataLoading(false)
        }
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
        console.log(err)
      })
    // eslint-disable-next-line
  }, [searchQuery])
  const handleSortFilterChange = () => {
    setDataLoading(true)
    setSearchQuery({
      page: searchQuery.get('page') || 1,
      sort: window.localStorage.getItem('sortFilter_user') || 'latest'
    })
  }
  return loading ? (
    <>
      <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
          <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
        </Box>
      </Box>
    </>
  ) : (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex', mt: { xs: '2rem' }, flexDirection: 'column' }}>
        <Link
          href={'/u/' + params.id}
          onClick={(e) => {
            if (!e.ctrlKey) {
              e.preventDefault()
              navigate('/u/' + params.id)
            }
          }}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'fit-content',
            textDecoration: 'none',
            color: 'black'
          }}
        >
          <Paper
            sx={{
              width: 'fit-content',
              py: '0.25rem',
              px: '0.25rem',
              my: '0.25rem',
              mr: '0.25rem',
              backgroundColor: 'rgb(255,255,255,0.6)',
              transform: 'scale(0.75)'
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: '0.75rem', color: 'black' }} />
          </Paper>
          <Typography
            fontFamily={'Medium-Family'}
            fontSize={'1rem'}
            sx={{ lineHeight: 'unset', color: darkmode.value ? 'white' : 'initial' }}
          >
            {' '}
            {'Back To Profile'}
          </Typography>
        </Link>
        <Divider
          sx={{ borderBottom: darkmode.value ? '2px solid white' : '2px solid black', mt: '10px' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            mt: '20px'
          }}
        >
          <Typography
            fontSize={'25px'}
            color={darkmode.value ? 'white' : 'initial'}
            fontWeight={'700'}
          >
            Subtitles by {userData?.username}{' '}
            {userData?.collabs > 0
              ? `(${userData?.total || 0} + ${userData.collabs})`
              : `(${userData?.total || 0})`}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Paper>
              <ToggleButton
                disabled={dataLoading}
                selected={sortBy === 'latest'}
                onClick={(e) => {
                  if (sortBy !== 'latest') {
                    setSortBy('latest')
                    window.localStorage.setItem('sortFilter_user', 'latest')
                    handleSortFilterChange()
                  }
                }}
                disableRipple
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textWrap: 'nowrap',
                  '&.Mui-selected': {
                    backgroundColor: '#0080808f',
                    boxShadow:
                      'inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)',
                    '&:hover': { backgroundColor: '#0080808f' }
                  },
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'initial' },
                  py: '0.5rem',
                  px: '0.75rem'
                }}
              >
                Latest
              </ToggleButton>
            </Paper>
            <Paper>
              <ToggleButton
                disabled={dataLoading}
                selected={sortBy === 'comments'}
                onClick={(e) => {
                  if (sortBy !== 'comments') {
                    setSortBy('comments')
                    window.localStorage.setItem('sortFilter_user', 'comments')
                    handleSortFilterChange()
                  }
                }}
                disableRipple
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textWrap: 'nowrap',
                  '&.Mui-selected': {
                    backgroundColor: '#0080808f',
                    boxShadow:
                      'inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)',
                    '&:hover': { backgroundColor: '#0080808f' }
                  },
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'initial' },
                  py: '0.5rem',
                  px: '0.75rem'
                }}
              >
                Most comments
              </ToggleButton>
            </Paper>
            <Paper>
              <ToggleButton
                disabled={dataLoading}
                selected={sortBy === 'downloads'}
                onClick={(e) => {
                  if (sortBy !== 'downloads') {
                    setSortBy('downloads')
                    window.localStorage.setItem('sortFilter_user', 'downloads')
                    handleSortFilterChange()
                  }
                }}
                disableRipple
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textWrap: 'nowrap',
                  '&.Mui-selected': {
                    backgroundColor: '#0080808f',
                    boxShadow:
                      'inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)',
                    '&:hover': { backgroundColor: '#0080808f' }
                  },
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'initial' },
                  py: '0.5rem',
                  px: '0.75rem'
                }}
              >
                Most downloads
              </ToggleButton>
            </Paper>
          </Box>
        </Box>
        <Box sx={{ position: 'relative', mt: '1rem' }}>
          <DataGridUserAllSubs subs={data} dataLoading={dataLoading} />
        </Box>
        {userData.total > 20 ? (
          <ReactPaginate
            containerClassName={
              darkmode.value ? 'allsubs-pagination dark-mode' : 'allsubs-pagination'
            }
            breakLabel="..."
            breakLinkClassName="breaklink"
            nextLabel="»"
            nextClassName="nextpage"
            previousClassName="prevpage"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            forcePage={(searchQuery.get('page') || 1) - 1}
            previousLabel="«"
            renderOnZeroPageCount={null}
            pageClassName="page"
            pageLinkClassName="pagelink"
            activeClassName="active"
          />
        ) : (
          <></>
        )}
      </Box>
    </Container>
  )
}

export default AllSubsPage
