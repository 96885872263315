import { Box, Dialog, FormControl, FormControlLabel, InputBase, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useDarkMode from 'use-dark-mode';
import { UserApi } from '../../api/userApi';
import { useQuery } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';

function RatingReport({ show, toggleShow, ratedFrom, subId }) {
    const darkmode = useDarkMode();
    const [reportButtonText, setReportButtonText] = useState("Report");
    const [reason, setReason] = useState(undefined);
    const [reportText, setReportText] = useState('');
    const [placeholder, setPlaceholder] = useState('Explain Reason.')
    const [buttondisable, setButtondisable] = useState(true);
    const { isFetching: isSubmitting, refetch: submitReport } = useQuery({
        queryKey: ['submitReport'],
        queryFn: async () => {
            const result = await UserApi.report('rate', reason, reportText, null, { ratedFrom, subId });
            if (result.success) {
                setReportButtonText("Success")
            } else {
                setReportButtonText("Error")
            }
        },
        enabled: false,
    })
    const handleReportTextChange = (e) => {
        setReportText(e.target.value);
        if (e.target.value === "" && (reason === "1" || reason === "2")) {
            setButtondisable(false);
        } else if (!reason) {
            setButtondisable(true);
        } else {
            setButtondisable(false);
        }
    }
    const handleReasonChange = (e) => {
        setReason(e.target.value)
        if (e.target.value === "1" || e.target.value === "2") {
            setButtondisable(false);
        }
    }
    useEffect(() => {
        if (reason === "1") {
            setPlaceholder("Optional")
        } else if (reason === "2") {
            if (reportText === "") {
                setButtondisable(true)
            }
            setPlaceholder("Required. Explain Reason.")
        }
    }, [reportText, reason])
    const _toggleShow = () => {
        setReportText("")
        setReportButtonText("Report");
        setReason(undefined);
        toggleShow();
    }

    return (
      <Dialog
        open={show}
        onClose={_toggleShow}
        sx={{ fontFamily: 'Medium-Family', marginBottom: '1rem' }}
      >
        <Paper elevation={4}>
          <Box sx={{ m: '1rem' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.15rem' }}>Report Rate</Typography>
            <FormControl sx={{ width: '400px' }}>
              <RadioGroup
                aria-labelledby="Report-Rate-Options"
                name="Report-Rate-Options"
                value={reason}
                onChange={handleReasonChange}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      size="small"
                      disableRipple
                      sx={{ padding: '0px', py: '13px', paddingLeft: '8px', paddingRight: '8px' }}
                    />
                  }
                  label="Spam or Fake"
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      size="small"
                      disableRipple
                      sx={{ padding: '0px', py: '13px', paddingLeft: '8px', paddingRight: '8px' }}
                    />
                  }
                  label="Other Reason"
                />
                <InputBase
                  id="report"
                  label="report"
                  multiline
                  value={reportText}
                  onChange={handleReportTextChange}
                  fullWidth
                  placeholder={placeholder}
                  minRows={3}
                  sx={{
                    w: '100%',
                    backgroundColor: darkmode.value ? 'dimgray' : 'azure',
                    borderRadius: '5px',
                    marginTop: '1rem',
                    px: '1rem',
                    py: '0.5rem',
                    fontFamily: 'Medium-Family',
                    boxShadow: '1px 1px 4px #7b8e8e'
                  }}
                />
                <LoadingButton
                  loading={isSubmitting}
                  disabled={buttondisable}
                  variant="contained"
                  color={reportButtonText === 'Success' ? 'success' : 'primary'}
                  sx={{ textTransform: 'none', marginTop: '1rem', fontFamily: 'Medium-Family' }}
                  disableRipple
                  onClick={() => reportButtonText === 'Report' && submitReport()}
                >
                  {reportButtonText}
                </LoadingButton>
              </RadioGroup>
            </FormControl>
          </Box>
        </Paper>
      </Dialog>
    )
}

export default RatingReport