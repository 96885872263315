import { Box, Link, Paper, Tooltip, Rating, Typography } from '@mui/material'
import SubtitlesRoundedIcon from '@mui/icons-material/SubtitlesRounded'
import { useNavigate } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import React from 'react'

/**
 *
 * @param rating includes :
 * - good : boolean
 * - subLink : full path of rating
 * - subMovieName : name for the movie/series
 * - date : iso date string
 * - fromId : id of the user who rated this
 * - fromName : name of the user who rated this
 * - fromRating : 0 - 5 rating number
 * @returns
 */
export default function RatingCard({ rating }) {
  const navigate = useNavigate()
  return (
    <Paper
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        // alignItems: { lg: 'center' },
        justifyContent: 'space-between',
        p: { xs: '10px 5px' },
        maxWidth: '100%',
        py: '0.5rem',
        mb: '0.25rem'
      }}
      elevation={5}
    >
      <Box
        sx={{
          display: 'flex',
          width: { lg: '70%  ' },
          textWrap: 'nowrap',
          gap: '.5rem',
          overflow: 'hidden',
          alignItems: 'start',
          textOverflow: 'ellipsis'
        }}
      >
        <Tooltip placement="top" title={`Rated ${rating.good ? 'Good' : 'Bad'}`}>
          <SubtitlesRoundedIcon
            sx={{
              color: rating.good ? 'teal' : '#dd0303',
              fontSize: '1.25rem'
            }}
          />
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            justifyContent: 'space-between',
            overflow: 'hidden',
            flexGrow: { xs: 1, lg: 'initial' }
          }}
        >
          <Link
            href={rating.subLink}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate(rating.subLink)
              }
            }}
            sx={{
              // textWrap: 'nowrap',
              textDecoration: 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontFamily: 'Medium-Family',
              fontSize: '1.05rem',
              display: 'inline-block'
            }}
          >
            {rating.subMovieName}
          </Link>
          {/* language */}
          {rating.language ? (
            <Typography sx={{ fontSize: '14px', opacity: '0.6' }}>{rating.language}</Typography>
          ) : (
            ''
          )}
          {/* date */}
          {rating.date ? (
            <span
              style={{
                fontSize: '0.8rem',
                opacity: '0.65',
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
                fontFamily: 'Medium-Family'
              }}
            >
              • <ReactTimeAgo date={new Date(rating.date)} />
            </span>
          ) : (
            ''
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex',  alignItems: 'end', width: { lg: '30%' } }}>
        <Typography sx={{ justifySelf: 'flex-end', mr: '0.5rem' }}> By</Typography>
        <Link
          href={'/u/' + rating.fromId}
          onClick={(e) => {
            if (!e.ctrlKey) {
              e.preventDefault()
              navigate('/u/' + rating.fromId)
            }
          }}
          sx={{
            justifySelf: 'flex-end',
            mr: '0.5rem',
            textDecoration: 'none',
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'Medium-Family'
          }}
        >
          {rating.fromName}
        </Link>
        <Rating
          value={rating.fromRating}
          max={5}
          precision={0.5}
          readOnly
          sx={{
            display: rating.fromRating !== -1 ? 'flex' : 'none',
            alignSelf: 'center',
            '& .MuiRating-decimal': { marginLeft: '-2px', color: 'teal' }
          }}
          size="small"
        ></Rating>
      </Box>
    </Paper>
  )
}
