import React from 'react'
import { Box, Container, Divider, Link, Typography } from '@mui/material'
import TelegramIconColored from '../icons/telegramColored.svg'
import InstagramColored from '../icons/instagram.svg'
import TwitterColored from '../icons/twitterColor.svg'
import EmailIcon from '../icons/Email_icon.png'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo';
function ContactPage() {
  const darkmode = useDarkMode( false )
  set_meta({
    is_landing: false
  })
  return (
    <Container
      sx={{
        margin: '0 auto',
        mt: '80px',
        maxWidth: '1100px',
        color: darkmode.value ? 'white' : 'initial'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '30px',
          fontWeight: '900',
          color: darkmode.value ? 'white' : '#205e60',
          fontFamily: 'Medium-Family',
          textAlign: 'center'
        }}
      >
        Contact Us
      </Typography>
      <Typography
        sx={{
          fontSize: '17px',
          fontWeight: '400',
          fontStyle: 'italic',
          color: darkmode.value ? 'white' : 'black',
          mt: '55px',
          fontFamily: 'Medium-Family',
          textAlign: 'center'
        }}
      >
        Please use Ticket Page to report any problems or suggestions. You can also contact us via:
      </Typography>
      <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column', mt: '50px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={EmailIcon} width={30} height={30} alt="Email" />
            <Typography
              sx={{
                fontSize: '25px',
                fontWeight: '600',
                color: darkmode.value ? '#4296c5' : '#21495F'
              }}
            >
              Email
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: '400', fontStyle: 'italic' }}>
                Email Address
              </Typography>
              <Link
                href="mailto:support@subsource.net"
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Medium-Family',
                  textDecoration: 'none'
                }}
              >
                support@subsource.net
              </Link>
            </Box>
            <Divider
              sx={{ width: '100%', borderBottom: '1px solid var(--Black, #0000004f)', pt: '8px' }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={TelegramIconColored} width={30} height={30} alt="Telegram" />
            <Typography
              sx={{
                fontSize: '25px',
                fontWeight: '600',
                color: darkmode.value ? '#4296c5' : '#21495F'
              }}
            >
              Telegram
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: '400', fontStyle: 'italic' }}>
                Telegram Channel
              </Typography>
              <Link
                href="https://t.me/SubSource_Net"
                target="_blank"
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Medium-Family',
                  textDecoration: 'none'
                }}
              >
                @SubSource_Net
              </Link>
            </Box>
            <Divider
              sx={{ width: '100%', borderBottom: '1px solid var(--Black, #0000004f)', my: '8px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: '400', fontStyle: 'italic' }}>
                Telegram Chat
              </Typography>
              <Link
                href="https://t.me/SubSourceChat"
                target="_blank"
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Medium-Family',
                  textDecoration: 'none'
                }}
              >
                @SubSourceChat
              </Link>
            </Box>
            <Divider
              sx={{ width: '100%', borderBottom: '1px solid var(--Black, #0000004f)', my: '8px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: '400', fontStyle: 'italic' }}>
                Telegram Bot
              </Typography>
              <Link
                href="https://t.me/SubSource_bot"
                target="_blank"
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Medium-Family',
                  textDecoration: 'none'
                }}
              >
                @SubSource_bot
              </Link>
            </Box>
            <Divider
              sx={{ width: '100%', borderBottom: '1px solid var(--Black, #0000004f)', my: '8px' }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={TwitterColored} width={30} height={30} alt="Twitter" />
            <Typography
              sx={{
                fontSize: '25px',
                fontWeight: '600',
                color: darkmode.value ? '#4296c5' : '#21495F'
              }}
            >
              X (Twitter)
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: '400', fontStyle: 'italic' }}>
                Twitter Page
              </Typography>
              <Link
                href="https://twitter.com/SubSource_Net"
                target="_blank"
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Medium-Family',
                  textDecoration: 'none'
                }}
              >
                @SubSource_Net
              </Link>
            </Box>
            <Divider
              sx={{ width: '100%', borderBottom: '1px solid var(--Black, #0000004f)', pt: '8px' }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={InstagramColored} width={30} height={30} alt="Instagram" />
            <Typography
              sx={{
                fontSize: '25px',
                fontWeight: '600',
                color: darkmode.value ? '#4296c5' : '#21495F'
              }}
            >
              Instagram
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: '400', fontStyle: 'italic' }}>
                Instagram Page
              </Typography>
              <Link
                href="https://instagram.com/subsource_net"
                target="_blank"
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  fontFamily: 'Medium-Family',
                  textDecoration: 'none'
                }}
              >
                @SubSource_Net
              </Link>
            </Box>
            <Divider
              sx={{ width: '100%', borderBottom: '1px solid var(--Black, #0000004f)', pt: '8px' }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default ContactPage
