import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useSmallWidth() {
  const responsiveWidth = 1150
  let location = useLocation()
  const [ windowSize, setWindowSize ] = useState( 0 )
  const onWindowResize = () => {
    setWindowSize(window.outerWidth < window.innerWidth ? window.outerWidth : window.innerWidth)
  }
  useEffect(() => {
    onWindowResize()
  }, [location])
  useEffect(() => {
    onWindowResize()
    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  return windowSize < responsiveWidth
}