import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TelegramIconColored from '../../icons/telegramColored.svg'
import InstagramColored from '../../icons/instagram.svg'
import TwitterColored from '../../icons/twitterColor.svg'
import EmailIcon from '../../icons/Email_icon.png'
import { useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import BadgeInfoDialog from '../User/BadgeInfoDialog'
import Fancybox from '../ui/Fancy';
function Footer() {
  //
  const darkmode = useDarkMode(false)
  const navigate = useNavigate()
  const [isRankingShowing, setIsRankingShowing] = useState(false)
  //
  const LinkItem = (href, text) => (
    <Link
      sx={{
        textDecoration: 'none',
        width: '100%',
        p: '0.25rem',
        color: darkmode.value ? '#ffffff87' : '#00000091',
        '&:hover': {
          textDecoration: 'underline',
          color: darkmode.value ? 'white' : 'black'
        }
      }}
      href={href}
      onClick={(e) => {
        if (!e.ctrlKey) {
          e.preventDefault()
          navigate(href)
        }
        window.scrollTo(0, 0)
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Regular-Family',
          fontSize: { xs: '18px', md: '20px' },
          textAlign: { xs: 'center' }
        }}
      >
        {text}
      </Typography>
    </Link>
  )
  const SocialItem = (title, href, src, alt) => (
    <Tooltip title={title} placement="top">
      <IconButton disableRipple href={href}>
        <img src={src} width={50} alt={alt} />
      </IconButton>
    </Tooltip>
  )
  const toggleRankingSystemDialog = () => {
    setIsRankingShowing(!isRankingShowing)
  }
  //
  const linkItems = [
    LinkItem('/about', 'About'),
    LinkItem('/dmca', 'DMCA'),
    LinkItem('/contactus', 'Contact us'),
    LinkItem('/policy', 'Privacy Policy'),
    LinkItem('/terms', 'Terms & Conditions')
  ]
  const socialItems = [
    SocialItem('@SubSource_Net', 'https://t.me/SubSource_Net', TelegramIconColored, 'Telegram'),
    SocialItem(
      '@SubSource_Net',
      'https://instagram.com/subsource_net',
      InstagramColored,
      'Instagram'
    ),
    SocialItem('@SubSource_Net', 'https://twitter.com/SubSource_Net', TwitterColored, 'Twitter'),
    SocialItem('support@subsource.net', 'mailto:support@subsource.net', EmailIcon, 'Email')
  ]
  return (
    <Box
      component={'footer'}
      sx={{
        width: '100%',
        pt: '7rem',
        pb: '2rem',
        height: 'auto',
        marginTop: 'auto',
        background: darkmode.value
          ? 'none'
          : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 100%)',
        color: 'black',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '30px'
        }}
      >
        <Link
          href={'/'}
          onClick={(e) => {
            e.preventDefault()
            window.scroll(0, 0)
            navigate('/')
          }}
          sx={{ display: 'block' }}
        >
          <img
            src={darkmode.value ? '/icons/subsource-light.png' : '/icons/subsource-dark.png'}
            alt="SubSource"
            height={100}
          />
        </Link>
        <List
          sx={{
            // width: { xs: '100%', md: 'auto' },
            maxWidth: '1200px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: { md: '1rem' },
            p: '0.5rem'
          }}
        >
          {linkItems.map((item, index) => (
            <ListItem key={index} sx={{ width: { xs: '100%', md: 'auto' } }} disablePadding>
              {item}
            </ListItem>
          ))}
        </List>
        <List id="ContactUs" sx={{ display: 'flex', gap: { md: '20px' } }}>
          {socialItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              {item}
            </ListItem>
          ))}
          {/* ranking detail */}
          <ListItem disablePadding>
            <Fancybox
              options={{
                
              }}
            >
              <a data-fancybox="gallery" href="/badges/ranks.jpg">
                <Tooltip title={'Find out more about our ranking system'} placement="top">
                  <IconButton disableRipple>
                    <img alt={'badge description'} src={'/badges/vip.webp'} width={'50px'} />
                  </IconButton>
                </Tooltip>
              </a>
            </Fancybox>
          </ListItem>
        </List>
        <BadgeInfoDialog show={isRankingShowing} toggleShow={toggleRankingSystemDialog} />
        <Box sx={{ mt: '30px' }}>
          <Typography
            textAlign={'center'}
            fontFamily={'Medium-Family'}
            fontSize={'15px'}
            fontWeight={'200'}
            color={darkmode.value ? 'white' : 'black'}
          >
            © 2024 SubSource. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
