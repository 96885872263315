import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import axios from 'axios'
import useDarkMode from 'use-dark-mode'
import { dispachAlert } from '../../../utils/dispachers'
import { rankOptions, subjects } from '../../../data/tickets'

const languages = ['Farsi/Persian', 'Arabic', 'Bengali', 'Others']
const formattedLanguages = {
  'Farsi/Persian': 'farsi_persian',
  Arabic: 'arabic',
  Bengali: 'bengali',
  Others: 'others'
}
function NewTicketModal({ open, onClose }) {
  const darkmode = useDarkMode()
  const [subject, setSubject] = useState(-1)
  const [language, setlanguage] = useState(-1)
  const [rank, setRank] = useState(-1)
  const [submitting, setSubmitting] = useState(false)
  const urlRef = useRef()
  const descriptionRef = useRef()
  const onSubjectChange = (e) => {
    setSubject(e.target.value)
  }
  const onLanguageChange = (e) => {
    setlanguage(e.target.value)
  }
  const onRankChange = (e) => {
    setRank(e.target.value)
  }
  const handleSubmit = () => {
    if (subject < 0) {
      return dispachAlert('error', 'Please select a subject.')
    }
    if ([3, 4, 6, 7].includes(subject) && language < 0) {
      return dispachAlert('error', 'Please select a language.')
    }
    if (descriptionRef.current.value.length == 0) {
      return dispachAlert('error', 'Please explain about your problem.')
    }
    setSubmitting(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/user/mySubsource/tickets/create',
        {
          subject: subjects[subject],
          language: [3, 4, 6, 7].includes(subject)
            ? formattedLanguages[languages[language]]
            : undefined,
          url: urlRef?.current?.value || undefined,
          rank: rank ?? undefined,
          desc: descriptionRef?.current?.value || undefined
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          window.location.reload()
        } else {
          setSubmitting(false)
          dispachAlert('error', 'Error.')
        }
      })
      .catch((err) => {
        setSubmitting(false)
        dispachAlert('error', 'Error.')
      })
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={'New Ticket'}
      PaperProps={{
        sx: { width: { xs: '90vw' }, maxWidth: '600px' }
      }}
    >
      <DialogTitle id={'New Ticket'} sx={{ fontFamily: 'Medium-Family', fontSize: '35px' }}>
        New Ticket
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem'
        }}
      >
        {/* subject */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{ mb: '0.5rem', color: darkmode.value ? 'white' : '#21495f', fontSize: '18px' }}
          >
            Subject/Issue
          </Typography>
          <Select
            value={subject}
            onChange={onSubjectChange}
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
            size="small"
          >
            <MenuItem disabled value={-1}>
              Select Subject/Issue
            </MenuItem>
            {subjects.map((sub, ind) => (
              <MenuItem value={ind}>{sub}</MenuItem>
            ))}
          </Select>
        </Box>
        {/* lang */}
        <Box
          sx={{
            display: [3, 4, 6, 7].includes(subject) ? 'flex' : 'none',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{ mb: '0.5rem', color: darkmode.value ? 'white' : '#21495f', fontSize: '18px' }}
          >
            Language
          </Typography>
          <Select
            value={language}
            onChange={onLanguageChange}
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
            size="small"
          >
            <MenuItem disabled value={-1}>
              Select a Language
            </MenuItem>
            <MenuItem value={0}>Farsi/Persian</MenuItem>
            <MenuItem value={1}>Arabic</MenuItem>
            <MenuItem value={2}>Bengali</MenuItem>
            <MenuItem value={3}>Others</MenuItem>
          </Select>
        </Box>
        {/* url */}
        <Box
          sx={{
            display: [0, 1, 3, 4, 8].includes(subject) ? 'flex' : 'none',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{ mb: '0.5rem', color: darkmode.value ? 'white' : '#21495f', fontSize: '18px' }}
          >
            URL (optional)
          </Typography>
          <TextField size="small" placeholder="Enter URL here." inputRef={urlRef} />
        </Box>
        {/* ranks options */}
        <Box
          sx={{
            display: subject === 7 ? 'flex' : 'none',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{ mb: '0.5rem', color: darkmode.value ? 'white' : '#21495f', fontSize: '18px' }}
          >
            I want to apply for ...
          </Typography>
          <Select
            value={rank}
            onChange={onRankChange}
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
            size="small"
          >
            <MenuItem disabled value={-1}>
              Select an option
            </MenuItem>
            {rankOptions.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {op.title}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {/* description */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{ mb: '0.5rem', color: darkmode.value ? 'white' : '#21495f', fontSize: '18px' }}
          >
            Explain More{' '}
            <span
              style={{
                color: 'darkred',
                display: [0, 3, 5].includes(subject) ? 'contents' : 'none'
              }}
            >
              (in English)
            </span>
          </Typography>
          <TextField
            placeholder="Explain your problem in detail..."
            multiline
            minRows={3}
            maxRows={3}
            inputRef={descriptionRef}
            inputProps={{ dir: 'auto' }}
            InputProps={{ sx: { fontFamily: 'Regular-Family' } }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: '1.5rem', pb: '1rem' }}>
        <Button
          disabled={submitting}
          variant="contained"
          onClick={handleSubmit}
          color="success"
          sx={{ textTransform: 'none' }}
        >
          Submit
        </Button>
        <Button
          disabled={submitting}
          variant="outlined"
          onClick={onClose}
          color="error"
          sx={{ textTransform: 'none' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewTicketModal
