import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode'
import { langs } from '../../data/langs'

function LanguageInput({ lang, setLang, disabled }) {
  const darkmode = useDarkMode(false)
  const onChnage = (e) => {
    setLang(e.target.value)
  }

  return (
    <Box sx={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={{
          marginBottom: '0.5rem',
          fontWeight: 'bold',
          color: darkmode.value ? 'white' : 'initial'
        }}
      >
        Language
      </Typography>
      <Box sx={{ minWidth: 120 }}>
        <FormControl>
          <Select
            disabled={disabled}
            variant="standard"
            labelId="Language"
            id="lang"
            value={lang}
            label="Language"
            onChange={onChnage}
          >
            <MenuItem value="Select Language" disabled>
              Select Language
            </MenuItem>
            {langs.map((lang, index) => (
              <MenuItem key={index} value={lang}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default LanguageInput
