import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Rating from '@mui/material/Rating'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TelegramIconColored from '../../icons/telegramColored.svg'
import InstagramColored from '../../icons/instagram.svg'
import TwitterColored from '../../icons/twitterColor.svg'
import FacebookColored from '../../icons/facebookColored2.svg'
import IconButton from '@mui/material/IconButton'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LogoSolo from '../../icons/logoNew.png'
import ssLogo from '../../imgs/ss.png'
import ForwardToInboxSharpIcon from '@mui/icons-material/ForwardToInboxSharp'
import FlagSharpIcon from '@mui/icons-material/FlagSharp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import '../../css/UserProfile.css'
import { Avatar, Container, Link, Tooltip, Grid, Stack } from '@mui/material'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'

import axios from 'axios'
import moment from 'moment'
import useDarkMode from 'use-dark-mode'
import NotFound from './../NotFound'
import UserReport from '../../Components/Reports/UserReport'
import UserMessage from '../../Components/UserMessage'
import VerticalMovieCard from '../../Components/ui/VerticalMovieCard'
import UserBadgeList from '../../Components/User/BadgeList'
import { useSmallWidth } from '../../hooks/useSmallWidth'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';
function UserProfilePage() {
  //
  const darkmode = useDarkMode()
  const params = useParams()
  const navigate = useNavigate()
  const isSmall = useSmallWidth()
  //
  const [userData, setUserData] = useState(undefined)
  const [notFound, setNotFound] = useState(false)
  const [reportShow, setReportShow] = useState(false)
  const [messageShow, setMessageShow] = useState(false)
  //
  const UserProfile = () => (
    <Box>
      <Typography
        variant="h1"
        sx={{ fontFamily: 'Medium-Family', color: darkmode.value ? 'white' : 'initial' }}
      >
        User Profile
      </Typography>
      <Divider
        variant="fullWidth"
        orientation="horizontal"
        sx={{ borderColor: darkmode.value ? 'white' : 'black' }}
      />
      {/* avatar / username */}
      <Box sx={{ display: 'flex', gap: '0.75rem', mt: '1rem' }}>
        <Paper elevation={3} sx={{ display: 'flex', height: '8rem', padding: '0.5rem' }}>
          <Avatar
            sx={{ width: '8rem', height: '8rem', alignSelf: 'center', borderRadius: '0%' }}
            children={
              userData.avatar ? '' : <AccountCircleIcon sx={{ width: '8rem', height: '8rem' }} />
            }
            key={Date.now()}
            src={userData.avatar ? process.env.REACT_APP_API_URL + userData.avatar : ''}
            alt={userData.username}
          />
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap : {md: '.5rem'},
            flexWrap: 'wrap',
            height: 'max-content',
            alignItems: 'center',
          }}
        >
          <Typography
            fontWeight={'bold'}
            fontSize={'1.5rem'}
            sx={{ color: darkmode.value ? 'white' : 'initial' }}
          >
            {userData.username}
          </Typography>
          {/* user badges */}
          <UserBadgeList badges={userData?.badges} darkmode={darkmode.value} />
        </Box>
      </Box>
      {/* user is banned */}
      {userData.isBanned && (
        <Box>
          <Typography
            sx={{
              fontFamily: 'Helvetiva',
              fontSize: '18px',
              fontWeight: 'bold',
              color: darkmode.value ? '#c30000' : 'darkred'
            }}
          >
            Penalty: This user is <strong>permenantly</strong> banned from SubSource.
          </Typography>
        </Box>
      )}
    </Box>
  )

  const UserBio = () => (
    <Box>
      <Typography
        variant="h1"
        component="h2"
        sx={{
          fontFamily: 'Medium-Family',
          color: darkmode.value ? 'white' : 'initial'
        }}
      >
        Bio
      </Typography>
      <Divider
        variant="fullWidth"
        orientation="horizontal"
        sx={{ borderColor: darkmode.value ? 'white' : 'initial', mb: '0.5rem' }}
      />
      <Paper sx={{ width: 'auto', mt: '1rem', p: '0.5rem' }}>
        <Typography
          sx={{
            fontFamily: 'Regular-Family',
            padding: '0.5rem',
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
            color: darkmode.value ? 'white' : 'initial'
          }}
          dir="auto"
        >
          {userData.bio || 'No bio is set.'}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              window.open('https://t.me/' + userData?.socialMedia?.telegram, '_blank')
            }}
            sx={{
              px: '0.5rem',
              py: '0.5rem',
              display: userData?.socialMedia?.telegram ? 'flex' : 'none'
            }}
            children={
              <img
                src={TelegramIconColored}
                alt="Telegram"
                width={'26px'}
                height={'26px'}
                style={{ pointerEvents: 'none' }}
              />
            }
          />
          <IconButton
            onClick={() => {
              window.open('https://instagram.com/' + userData?.socialMedia?.insta, '_blank')
            }}
            sx={{
              px: '0.5rem',
              py: '0.5rem',
              display: userData?.socialMedia?.insta ? 'flex' : 'none'
            }}
            children={
              <img
                src={InstagramColored}
                alt="Telegram"
                width={'26px'}
                height={'26px'}
                style={{ pointerEvents: 'none' }}
              />
            }
          />
          <IconButton
            onClick={() => {
              window.open('https://twitter.com/' + userData?.socialMedia?.twitter, '_blank')
            }}
            sx={{
              px: '0.5rem',
              py: '0.5rem',
              display: userData?.socialMedia?.twitter ? 'flex' : 'none'
            }}
            children={
              <img
                src={TwitterColored}
                alt="Telegram"
                width={'30px'}
                height={'30px'}
                style={{ pointerEvents: 'none' }}
              />
            }
          />
          <IconButton
            onClick={() => {
              window.open('https://www.facebook.com/' + userData?.socialMedia?.facebook, '_blank')
            }}
            sx={{
              px: '0.5rem',
              py: '0.5rem',
              display: userData?.socialMedia?.facebook ? 'flex' : 'none'
            }}
            children={
              <img
                src={FacebookColored}
                alt="Telegram"
                width={'24px'}
                height={'24px'}
                style={{ pointerEvents: 'none' }}
              />
            }
          />
        </Box>
      </Paper>
    </Box>
  )

  const UserDetail = () => (
    <Box sx={{width: '100%'}}>
      <Typography
        variant="h1"
        component="h2"
        sx={{ fontFamily: 'Medium-Family', color: darkmode.value ? 'white' : 'initial' }}
      >
        Details
      </Typography>
      <Divider
        variant="fullWidth"
        orientation="horizontal"
        sx={{ borderColor: darkmode.value ? 'white' : 'black', mb: '0.5rem' }}
      />
      <Box>
        {/* signup date / signup_scene */}
        <Box sx={{ display: 'flex', width: 'auto' }}>
          {/* signup date */}
          <Paper
            sx={{
              py: '0.25rem',
              display: 'flex',
              my: '0.25rem',
              justifyContent: 'center',
              width: '100%',
              px: '0.5rem',
              backgroundColor: darkmode.value ? 'rgb(255,255,255,0.2)' : 'rgb(255,255,255,0.6)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              textWrap: 'nowrap'
            }}
          >
            <Typography sx={{ fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
              <img
                alt="subsource"
                src={LogoSolo}
                style={{ marginRight: '0.25rem', width: '20px', height: '25px' }}
              />{' '}
              Registered: {moment(userData.signup).format('YYYY/MM/DD')}
            </Typography>
          </Paper>
          {/* signup_scene */}
          <Paper
            sx={{
              p: '0px',
              ml: '5px',
              my: '0.25rem',
              display: userData.signup_scene ? 'flex' : 'none',
              px: '2px',
              backgroundColor: darkmode.value ? 'rgb(255,255,255,0.2)' : 'rgb(255,255,255,0.6)'
            }}
          >
            <IconButton
              onClick={() => {
                setShowSubsceneRegister((p) => !p)
              }}
              sx={{ p: '0px' }}
              children={<ErrorOutlineIcon />}
            ></IconButton>
          </Paper>
        </Box>
        {/*  */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper
            sx={{
              py: showSubsceneRegister ? '0.25rem' : '0px',
              my: showSubsceneRegister ? '0.25rem' : '0px',
              width: '110px',
              display: 'flex',
              justifyContent: 'center',
              transition: '0.5s all ease-in-out',
              height: showSubsceneRegister ? '21px' : '0px',
              px: '0.5rem',
              backgroundColor: '#0080804d',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              textWrap: 'nowrap'
            }}
          >
            <img alt="ss" src={ssLogo} style={{ marginRight: '0.5rem', width: '20px' }} />
            <Typography sx={{ fontSize: '0.9rem' }}>
              {' '}
              {moment(userData.signup_scene).format('YYYY/MM/DD')}
            </Typography>
          </Paper>
        </Box>
        {/*  */}
        <Paper
          sx={{
            py: '0.25rem',
            my: '0.25rem',
            width: 'auto',
            textAlign: 'center',
            px: '0.5rem',
            backgroundColor: darkmode.value ? 'rgb(255,255,255,0.2)' : 'rgb(255,255,255,0.6)',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap'
          }}
        >
          <Typography sx={{ fontSize: '0.9rem' }}>Last Seen: {userData?.lastSeen}</Typography>
        </Paper>
        {/*  */}
        <Link
          sx={{ display: 'contents' }}
          href={'/u/' + params.id + '/ratings_given'}
          onClick={(e) => {
            if (!e.ctrlKey) {
              e.preventDefault()
              navigate('/u/' + params.id + '/ratings_given')
            }
          }}
        >
          <Paper
            sx={{
              cursor: 'pointer',
              py: '0.25rem',
              my: '0.25rem',
              width: 'auto',
              textAlign: 'center',
              px: '0.5rem',
              backgroundColor: darkmode.value ? 'rgb(255,255,255,0.2)' : 'rgb(255,255,255,0.6)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              textWrap: 'nowrap'
            }}
          >
            <Typography sx={{ fontSize: '0.9rem' }}>
              {userData.ratings_given} Ratings Given
            </Typography>
          </Paper>
        </Link>
        {/*  */}
        <Paper
          sx={{
            py: '0.25rem',
            my: '0.25rem',
            width: 'auto',
            textAlign: 'center',
            px: '0.5rem',
            textOverflow: 'ellipsis',
            backgroundColor: darkmode.value ? 'rgb(255,255,255,0.2)' : 'rgb(255,255,255,0.6)',
            overflow: 'hidden',
            textWrap: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer' /*  "&:hover": { backgroundColor: "#00808066" } */
          }}
          onClick={toggleReportShow}
        >
          <FlagSharpIcon sx={{ height: '20px', width: '20px', mr: '0.25rem' }} />
          <Typography sx={{ fontSize: '0.9rem' }}>Report User</Typography>
        </Paper>
        {/*  */}
        <Paper
          sx={{
            py: '0.25rem',
            my: '0.25rem',
            width: 'auto',
            textAlign: 'center',
            px: '0.5rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            backgroundColor: darkmode.value ? 'rgb(255,255,255,0.2)' : 'rgb(255,255,255,0.6)',
            textWrap: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer' /* "&:hover": { backgroundColor: "#00808066" } */
          }}
          onClick={toggleMessageShow}
        >
          <ForwardToInboxSharpIcon sx={{ height: '20px', width: '20px', mr: '0.25rem' }} />
          <Typography sx={{ fontSize: '0.9rem' }}>Private Message</Typography>
        </Paper>
        {/* <Box sx={{ display: "none" }}>
                            <a href="/" class="donate">Donate Your Translator</a>
                            </Box> */}
      </Box>
    </Box>
  )

  const LastSubs = () => (
    <Box sx={{ width: '100%' }}>
      {/* subs/ratings */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link
            href={'/u/' + params.id + '/subtitles'}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/u/' + params.id + '/subtitles')
              }
            }}
            className="button-24 contribSub"
            sx={{
              textDecoration: 'none',
              backgroundColor: darkmode.value ? '#44474a !important' : 'initial'
            }}
          >
            <Typography
              fontFamily={'Regular-Family'}
              fontWeight={'bold'}
              sx={{ color: darkmode.value ? 'white' : 'black', fontSize: '1.05rem' }}
            >
              {userData.subCount} Subtitles{' '}
            </Typography>
            <Tooltip title="Number of Collaborations" placement="top">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AddCircleSharpIcon
                  sx={{
                    color: darkmode.value ? '#868ab3' : '#484c7a',
                    fontSize: '0.9rem',
                    ml: '0.25rem',
                    mt: '1px'
                  }}
                ></AddCircleSharpIcon>
                <Typography
                  fontFamily={'Regular-Family'}
                  fontWeight={'bold'}
                  sx={{ ml: '0.15rem', color: darkmode.value ? '#868ab3' : '#484c7a' }}
                >
                  {userData.contribCount}
                </Typography>
              </Box>
            </Tooltip>
          </Link>
        </Box>
        <Link
          href={'/u/' + params.id + '/ratings'}
          onClick={(e) => {
            if (!e.ctrlKey) {
              e.preventDefault()
              navigate('/u/' + params.id + '/ratings')
            }
          }}
          sx={{
            color: darkmode.value ? 'white' : 'initial',
            textDecoration: 'none',
            fontFamily: 'Medium-Family',
            display: 'contents'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { sm: 'center' },
              gap: '0.5rem'
            }}
          >
            {userData.ratings} Ratings
            <Rating
              value={userData.rate}
              max={5}
              precision={0.5}
              readOnly
              sx={{
                display: userData.rate === -1 ? 'none' : 'flex',
                alignSelf: 'center',
                '& .MuiRating-decimal': { marginLeft: '-2px', color: 'teal' }
              }}
              size="small"
            ></Rating>
          </Box>
        </Link>
      </Box>
      <Grid container spacing={2}>
        {userData?.lastNine.map((trend) => (
          <Grid key={trend.fullLink} item xs={6} sm={4} sx={{ p: '.15rem' }}>
            <Link
              href={trend.fullLink}
              sx={{ display: 'inline-block', width: '100%', textDecoration: 'none' }}
              onClick={(e) => {
                if (!e.ctrlKey) {
                  e.preventDefault()
                  navigate(trend.fullLink)
                }
              }}
            >
              <VerticalMovieCard movie={trend} darkmode={darkmode} />
            </Link>
          </Grid>
        ))}
      </Grid>
      <Button
        href={'/u/' + params.id + '/subtitles'}
        onClick={(e) => {
          if (!e.ctrlKey) {
            e.preventDefault()
            navigate('/u/' + params.id + '/subtitles')
          }
        }}
        variant="text"
        fullWidth
        sx={{
          mt: '0.5rem',
          bgcolor: 'white',
          boxShadow:
            '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
          backgroundColor: '#ffffff42'
        }}
        disableRipple
      >
        view all
      </Button>
    </Box>
  )
  //
  const toggleReportShow = () => {
    setReportShow((p) => !p)
  }
  const toggleMessageShow = () => {
    setMessageShow((p) => !p)
  }
  //
  useEffect(() => {
    setUserData(undefined)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/getProfile',
        { id: params.id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success && res.data.userData) {
          set_meta({
            is_landing: false,
            title: `SubSource: ${ res?.data?.userData?.username }'s Profile.`
          })
          setUserData(res?.data?.userData)
        } else {
          dispachAlert('error', 'Error.')
          window.location.href = '/'
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setNotFound(true)
        }
        console.log(err)
      })
  }, [params.id])

  const [showSubsceneRegister, setShowSubsceneRegister] = useState(false)
  if (notFound) {
    return <NotFound />
  }
  return userData === undefined ? (
    <>
      <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
          <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
        </Box>
      </Box>
    </>
  ) : (
    <Container sx={{mt: '2rem'}}>
      <Box
        sx={{
          margin: '0 auto',
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: { xs: '1rem', lg: '4rem' },
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ width: { lg: '60%' }, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {/* Profile */}
          {UserProfile()}
          {isSmall ? (
            <>
              {/* bio */}
              {UserBio()}
              {/* detail */}
              {UserDetail()}
            </>
          ) : (
            <>
              {/* last subs */}
              {LastSubs()}
            </>
          )}
        </Box>
        <Stack direction={'column'} sx={{width: {lg: '30%'}}}>
          {isSmall ? (
            <>
              {/* last subs */}
              {LastSubs()}
            </>
          ) : (
            <>
              {/* detail */}
              {UserDetail()}
              {/* bio */}
              {UserBio()}
            </>
          )}
        </Stack>
      </Box>
      <UserReport show={reportShow} toggleShow={toggleReportShow} userId={params.id} />
      <UserMessage
        show={messageShow}
        toggleShow={toggleMessageShow}
        username={userData?.username || ''}
        userId={params?.id}
      />
    </Container>
  )
}

export default UserProfilePage
