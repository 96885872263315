import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import Poster from '../ui/Poster';
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 0, maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden' }}
          className="scrollCustom"
        >
          {children}
        </Box>
      )}
    </div>
  )
}
function SearchMovies({
  movies,
  handleClose,
  setSearchText,
  setFoundMovies,
  setSearching,
  searchText
}) {
  const darkmode = useDarkMode(false)
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState(0)
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }
  const handleMovieClick = (e, movie) => {
    console.log('clicked')
    var recentSearches = JSON.parse(window.localStorage.getItem('recent_searches')) || []
    if (recentSearches.length >= 5) {
      if (recentSearches.length > 5) {
        recentSearches = recentSearches.slice(0, 5)
      }
      console.log({ recentSearches })
      console.log({ title: movie.title })
      if (!recentSearches.includes(movie.title)) {
        recentSearches.shift()
        recentSearches.push(movie.title)
        window.localStorage.setItem('recent_searches', JSON.stringify(recentSearches))
      }
    } else {
      recentSearches.push(movie.title)
      window.localStorage.setItem('recent_searches', JSON.stringify(recentSearches))
    }
    if (!e.ctrlKey) {
      setSearchText('')
      setFoundMovies([])
      e.preventDefault()
      handleClose()
      navigate('/subtitles/' + movie.linkName)
    }
  }
  const handleSearchAllClick = (e) => {
    e.preventDefault()
    setSearchText('')
    setFoundMovies([])
    handleClose()
    navigate('/search/' + searchText)
  }
  if (movies.length === 0) {
    const recentSearches = JSON.parse(window.localStorage.getItem('recent_searches')) || []
    if (recentSearches.length > 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            width: '100%',
            padding: '0.5rem',
            fontFamily: 'Medium-Family'
          }}
        >
          <Typography sx={{ display: searchText !== '' && movies.length === 0 ? 'flex' : 'none' }}>
            No Results.
          </Typography>
          Recent Searches:
          {recentSearches.reverse().map((x, index) => (
            <Paper
              key={index}
              onClick={(e) => {
                e.stopPropagation()
                setSearchText(x)
                setSearching(true)
              }}
              sx={{
                flex: 1,
                fontSize: '20px',
                cursor: 'pointer',
                padding: '0.5rem',
                '&:hover': { filter: 'contrast(0.8)' }
              }}
            >
              {x}
            </Paper>
          ))}
        </Box>
      )
    }
  } else {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          sx={{ width: '400px', bgcolor: darkmode.value ? 'rgb(18,18,18)' : 'initial' }}
        >
          <Tab label="All" id="All" sx={{ textTransform: 'none' }} />
          <Tab label="Movies" id="Movie" sx={{ textTransform: 'none' }} />
          <Tab label="TV-Series" id="TVSeries" sx={{ textTransform: 'none' }} />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <List>
            {movies.map((movie) => (
              <ListItem disablePadding divider>
                <Link
                  key={movie.linkName}
                  href={'/subtitles/' + movie.linkName}
                  sx={{
                    flexGrow: 1,
                    textDecoration: 'none',
                    display: 'flex',
                    p: '0.25rem',
                    color: darkmode.value ? 'white' : 'initial',
                    bgcolor: darkmode.value ? 'rgb(18,18,18)' : 'white',
                    '&:hover': { backgroundColor: darkmode.value ? '#2f2f2f' : 'powderblue' }
                  }}
                  onClick={(e) => {
                    handleMovieClick(e, movie)
                  }}
                >
                  <Box sx={{ width: '75px' }}>
                    <Poster src={movie.poster} alt={movie.title} />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      overflow: 'hidden',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Medium-Family',
                        fontWeight: 'bold',
                        marginLeft: '0.5rem',
                        fontSize: movie.title.length < 30 ? '1.1rem' : '0.8rem'
                      }}
                    >
                      {movie.title} ({movie.releaseYear})
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: 'Medium-Family',
                        fontWeight: 'bold',
                        marginLeft: '0.5rem',
                        fontSize: '1rem',
                        mb: '0.25rem'
                      }}
                    >
                      Type:{' '}
                      {movie.type === 'Movie' ? (
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            display: 'contents',
                            fontWeight: 'bold',
                            color: darkmode.value ? 'white' : 'black'
                          }}
                        >
                          Movie
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: '0.8rem',
                            display: 'contents',
                            fontWeight: 'bold',
                            color: 'teal'
                          }}
                        >
                          TV-Series
                        </Typography>
                      )}
                    </Typography>
                    {/* <Typography sx={{ fontFamily: "Medium-Family", fontWeight: "bold", marginLeft: "0.5rem", fontSize: "0.75rem", my: "0rem" }}>Subs Available: {movie.subCount}</Typography> */}
                  </Box>
                </Link>
              </ListItem>
            ))}
          </List>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <List>
            {movies
              .filter((x) => x.type === 'Movie')
              .map((movie) => (
                <ListItem disablePadding divider>
                  <Link
                    key={movie.linkName}
                    href={'/subtitles/' + movie.linkName}
                    sx={{
                      flexGrow: 1,
                      textDecoration: 'none',
                      display: 'flex',
                      p: '0.25rem',
                      color: darkmode.value ? 'white' : 'initial',
                      bgcolor: darkmode.value ? 'rgb(18,18,18)' : 'white',
                      '&:hover': { backgroundColor: darkmode.value ? '#2f2f2f' : 'powderblue' }
                    }}
                    onClick={(e) => {
                      handleMovieClick(e, movie)
                    }}
                  >
                    <Box sx={{ width: '75px' }}>
                      <Poster src={movie.poster} alt={movie.title} />
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Medium-Family',
                          fontWeight: 'bold',
                          marginLeft: '0.5rem',
                          fontSize: movie.title.length < 30 ? '1.1rem' : '0.8rem'
                        }}
                      >
                        {movie.title} ({movie.releaseYear})
                      </Typography>

                      <Typography
                        sx={{
                          fontFamily: 'Medium-Family',
                          fontWeight: 'bold',
                          marginLeft: '0.5rem',
                          fontSize: '1rem',
                          mb: '0.25rem'
                        }}
                      >
                        Type:{' '}
                        {movie.type === 'Movie' ? (
                          <Typography
                            sx={{
                              fontSize: '0.8rem',
                              display: 'contents',
                              fontWeight: 'bold',
                              color: darkmode.value ? 'white' : 'black'
                            }}
                          >
                            Movie
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '0.8rem',
                              display: 'contents',
                              fontWeight: 'bold',
                              color: 'teal'
                            }}
                          >
                            TV-Series
                          </Typography>
                        )}
                      </Typography>
                      {/* <Typography sx={{ fontFamily: "Medium-Family", fontWeight: "bold", marginLeft: "0.5rem", fontSize: "0.75rem", my: "0rem" }}>Subs Available: {movie.subCount}</Typography> */}
                    </Box>
                  </Link>
                </ListItem>
              ))}
          </List>
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <List>
            {movies
              .filter((x) => x.type === 'TVSeries')
              .map((movie) => (
                <ListItem disablePadding divider>
                  <Link
                    key={movie.linkName}
                    href={'/subtitles/' + movie.linkName}
                    sx={{
                      flexGrow: 1,
                      textDecoration: 'none',
                      display: 'flex',
                      p: '0.25rem',
                      color: darkmode.value ? 'white' : 'initial',
                      bgcolor: darkmode.value ? 'rgb(18,18,18)' : 'white',
                      '&:hover': { backgroundColor: darkmode.value ? '#2f2f2f' : 'powderblue' }
                    }}
                    onClick={(e) => {
                      handleMovieClick(e, movie)
                    }}
                  >
                    <Box sx={{ width: '75px' }}>
                      <Poster src={movie.poster} alt={movie.title} />
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Medium-Family',
                          fontWeight: 'bold',
                          marginLeft: '0.5rem',
                          fontSize: movie.title.length < 30 ? '1.1rem' : '0.8rem'
                        }}
                      >
                        {movie.title} ({movie.releaseYear})
                      </Typography>

                      <Typography
                        sx={{
                          fontFamily: 'Medium-Family',
                          fontWeight: 'bold',
                          marginLeft: '0.5rem',
                          fontSize: '1rem',
                          mb: '0.25rem'
                        }}
                      >
                        Type:{' '}
                        {movie.type === 'Movie' ? (
                          <Typography
                            sx={{
                              fontSize: '0.8rem',
                              display: 'contents',
                              fontWeight: 'bold',
                              color: darkmode.value ? 'white' : 'black'
                            }}
                          >
                            Movie
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '0.8rem',
                              display: 'contents',
                              fontWeight: 'bold',
                              color: 'teal'
                            }}
                          >
                            TV-Series
                          </Typography>
                        )}
                      </Typography>
                      {/* <Typography sx={{ fontFamily: "Medium-Family", fontWeight: "bold", marginLeft: "0.5rem", fontSize: "0.75rem", my: "0rem" }}>Subs Available: {movie.subCount}</Typography> */}
                    </Box>
                  </Link>
                </ListItem>
              ))}
          </List>
        </TabPanel>
        <Divider
          flexItem
          sx={{ borderBottom: darkmode.value ? '1px solid white' : '1px solid black' }}
        />
        <Button
          variant="containd"
          fullWidth
          sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
          href={'/search/' + searchText}
          onClick={handleSearchAllClick}
        >
          View all results
        </Button>
      </Box>
    )
  }
}

export default SearchMovies
