import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { dispachAlert } from '../../../utils/dispachers';

function ChangeTitle({ selected, setSelected, handleLoad }) {
  const [title, setTitle] = useState(selected.title)
  const [submitting, setSubmitting] = useState(false)
  const [allSeasons, setAllSeasons] = useState(false)
  useEffect(() => {
    setTitle(selected.title)
  }, [selected])
  const handleSubmit = () => {
    if (title !== '') {
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/movies/updateTitle',
          { movieId: selected.id, newTitle: title, allSeasons: allSeasons },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setSelected((p) => ({ ...p, title: title }))
            dispachAlert('success', 'Success.')
            handleLoad()
          } else {
            dispachAlert('error', 'Error.')
          }
          setSubmitting(false)
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
          }
          setSubmitting(false)
          console.log({ err })
        })
    }
  }
  return (
    <Box>
      <TextField
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Movie Title ..."
        label="Title"
        size="small"
      />
      <FormControlLabel
        sx={{ textWrap: 'nowrap' }}
        control={
          <Checkbox
            value={allSeasons}
            onChange={(e) => setAllSeasons(e.target.value)}
            disabled={selected.type === 'Movie'}
          />
        }
        label="All Seasons"
      />
      <Button
        variant="contained"
        disabled={title === selected.title || submitting || title === ''}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Box>
  )
}

export default ChangeTitle
