/**
 * Dispatches a custom alert event (`SnackError`) to notify the application of an alert.
 *
 * This function creates and dispatches a `CustomEvent` on the `window` object, allowing
 * any component listening for the `SnackError` event to handle and display alerts accordingly.
 * The event carries the alert details, including the severity level and message, as a JSON string
 * in its `detail` property.
 *
 * @param {string} severity - Indicates the alert severity level (e.g., "error", "warning", "info", or "success").
 * @param {string} message - The message content of the alert.
 *
 * @example
 * // Dispatch an error alert
 * dispachAlert("error", "An error occurred while processing the request.");
 *
 * @fires SnackError
 * @event SnackError
 * @property {string} detail - A JSON string with the alert's severity and message properties.
 */
export function dispachAlert(severity, message) {
  window.dispatchEvent(
    new CustomEvent('SnackError', {
      detail: JSON.stringify({ severity, message })
    })
  )
}
