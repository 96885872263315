export const types = [
  'Select Release Type',
  'WEB',
  'BLURAY',
  'HDTV',
  'CAM/HDTS/HDRIP',
  'UNOFFICIAL/LEAKED',
  'DVDRip',
  'Other'
]
