import React, { useState } from 'react'
import { Box, List, ListItem, ToggleButton, Typography } from '@mui/material'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo'
function TermsPage() {
  const darkmode = useDarkMode(false)
  const [language, setLanguage] = useState('en')
  set_meta({
    is_landing: false
  })
  const handleChange = (event) => {
    setLanguage(event.target.value)
  }
  return (
    <Box sx={{ margin: '0 auto', mt: '80px', maxWidth: '1100px' }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: '30px',
          fontWeight: '900',
          color: darkmode.value ? 'white' : '#205e60',
          fontFamily: 'Medium-Family',
          textAlign: 'center'
        }}
      >
        Terms & Conditions
      </Typography>
      <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center', mt: '1rem' }}>
        <ToggleButton
          disableRipple
          onClick={handleChange}
          selected={language === 'en'}
          size="small"
          value="en"
          sx={{ textTransform: 'none', fontFamily: 'Regular-Familyar-Familyar-Family' }}
        >
          English
        </ToggleButton>
        <ToggleButton
          disableRipple
          onClick={handleChange}
          selected={language === 'fa'}
          size="small"
          value="fa"
          sx={{ textTransform: 'none', fontFamily: 'Regular-Familyar-Family' }}
        >
          فارسی
        </ToggleButton>
        <ToggleButton
          disableRipple
          onClick={handleChange}
          selected={language === 'ar'}
          size="small"
          value="ar"
          sx={{ textTransform: 'none', fontFamily: 'Regular-Family' }}
        >
          العربیة
        </ToggleButton>
        <ToggleButton
          disableRipple
          onClick={handleChange}
          selected={language === 'bg'}
          size="small"
          value="bg"
          sx={{ textTransform: 'none', fontFamily: 'Regular-Family' }}
        >
          Bengali
        </ToggleButton>
        <ToggleButton
          disableRipple
          onClick={handleChange}
          selected={language === 'in'}
          size="small"
          value="in"
          sx={{ textTransform: 'none', fontFamily: 'Regular-Family' }}
        >
          Indonesian
        </ToggleButton>
      </Box>

      <Box
        sx={{
          mt: '80px',
          px: '2rem',
          color: darkmode.value ? 'white' : 'initial',
          display: language === 'en' ? 'block' : 'none'
        }}
      >
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 'bold',
            color: darkmode.value ? 'white' : 'initial',
            fontFamily: 'Regular-Family'
          }}
        >
          SubSource Terms & Conditions
        </Typography>

        <List
          sx={{
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: '25px',
            lineHeight: '30px'
          }}
        >
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            All property and intellectual rights of SubSource are reserved.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            The main goal of this website is to provide subtitles for movies and TV shows while
            protecting the rights of their translators.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Politeness and respect are our priorities, and anyone who crosses the line will be
            reprimanded. Additionally, hate speech and racism are not tolerated.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Copying and plagiarizing any subtitle is strictly prohibited, and the user responsible
            for it will be banned.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Any subtitle submitted to this website is the intellectual property of its translator,
            and no third party is allowed to interpolate its content.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Uploaders must publish their subtitles with correct details, such as release names and
            file names. Altering and manipulating release names for personal gain is forbidden.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Translators are not allowed to submit bad ratings or negative comments for rival
            subtitles (Other subs for the same title).
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Spamming of any kind is not allowed, and the responsible user will be reprimanded.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Our policy is to offer an environment where healthy competition can thrive, and any
            action aimed at disrupting this environment is not tolerated.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Using offensive names and avatars is forbidden.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Each user is only allowed to have one account, and users with fake or alternate accounts
            will be punished. Exception: It is allowed if you had more than one account in subscene
            or you need to upload with more than one account in SubSource (Spitful actions with
            alternate/fake accounts are not tolerated.)
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            We do not take responsibility for users sharing their account passwords.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Posting any download links or advertisement in comments are not allowed.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Demonstrating Machine Translated subtitles as real high-quality subtitles is not allowed
            in SubSource. In the case you state "Machine Translated" in both Production Type and
            Caption, you can upload machine/AI translated subs on this website.
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          direction: 'rtl !important',
          mt: '80px',
          px: '2rem',
          color: darkmode.value ? 'white' : 'initial',
          display: language === 'fa' ? 'block' : 'none'
        }}
      >
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 'bold',
            color: darkmode.value ? 'white' : 'initial',
            fontFamily: 'Regular-Family'
          }}
        >
          قوانین و مقررات ساب‌سورس
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            textAlign: 'right',
            listStylePosition: 'outside',
            ml: '25px',
            lineHeight: '30px'
          }}
        >
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            کلیه‌ی حقوق مادی و معنوی وب‌سایت ساب‌سورس متعلق به این مجموعه بوده و محفوظ می‌باشد.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            رسالت اصلی این سایت، ارائه‌ی زیرنویس برای آثار سینمایی و سریالی و حفاظت از حقوق مترجمان
            فیلم و سریال است.{' '}
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            رعایت ادب و احترام خط قرمز این مجموعه بوده و با کاربران خاطی برخورد خواهد شد. همچنین
            نفرت‌پراکنی و نژادپرستی به هیچ عنوان مورد قبول نیست.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            کپی کردن از زیرنویس دیگر مترجمان، اکیداً ممنوع بوده و حساب کاربر خاطی مسدود خواهد شد.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            کلیه‌ی حقوق و کپی‌رایت زیرنویس‌های منتشر شده توسط مترجمان در این وب‌سایت، متعلق به مترجم
            مربوطه بوده و هیچ شخص ثالثی اجازه‌ی دخل و تصرف در آن را نخواهد داشت.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            کاربران موظف هستند زیرنویس‌های خود را با اطلاعات صحیح، اعم از نامِ ریلیز و نام فایل،
            آپلود کنند. دست‌کاری نام ریلیز (Release Names) برای منفعت شخصی ممنوع است.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            مترجم یا مترجمان یک اثر، حق دادن ریت منفی و گذاشتن کامنت منفی روی زیرنویس‌های موازی خود
            را ندارند.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            اسپم در هر شکلی غیرمجاز بوده و منجر به جریمه‌ی کاربر خاطی می‌گردد.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            سیاست سایت حفظ رقابت سالم بین مترجمان می‌باشد. هر عملی که ناقض این سیاست باشد ممنوع است.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            استفاده از اسامی و آواتارهای توهین‌آمیز (به هر نحوی) ممنوع است.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            هر شخص در ساب‌سورس مجاز به داشتن تنها یک حساب است، و با کاربرانی که حساب‌های جعلی بسازند
            برخورد خواهد شد. تبصره: اگر در سابسین بیش از یک اکانت داشتید یا نیاز دارید با بیش از یک
            اکانت در ساب‌سورس به آپلود و فعالیت بپردازید مانعی وجود ندارد (با اعمال نفرت‌پراکنانه،
            اعم از ریت و کامنت منفی بی‌دلیل با حساب‌های جعلی، برخورد خواهد شد.)
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            کاربران از به اشتراک گذاری رمز حساب خود با دیگران پرهیز کنند چرا که عواقب آن بر عهده‌ی
            خود کاربر است.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            قرار دادن هرگونه لینک دانلود فایل و تبلیغات در کامنت‌ها ممنوع است.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            جا زدن ترجمه‌ی ماشینی به‌عنوان زیرنویس اصلی با کیفیت در ساب‌سورس مجاز نیست. اما در صورتی
            که در کپشن قید شود ترجمه‌ی زیرنویس ماشینی است، آپلود آن مانعی ‌ندارد.
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          direction: 'rtl',
          mt: '80px',
          px: '2rem',
          color: darkmode.value ? 'white' : 'initial',
          display: language === 'ar' ? 'block' : 'none'
        }}
      >
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 'bold',
            color: darkmode.value ? 'white' : 'initial',
            fontFamily: 'Regular-Family'
          }}
        >
          الأحكام والشروط
        </Typography>
        <List
          sx={{
            direction: 'rtl',
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: '25px',
            lineHeight: '30px'
          }}
        >
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            جميع حقوق الملكية الفكرية للموقع محفوظة.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            الهدف الرئيسي للموقع هو إتاحة وتوفير ترجمات للأفلام والمسلسلات مع حماية حقوق المترجمين.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            أولويتنا هي الاحترام المتبادل بين أعضاء الموقع الكرام والارتقاء بأسلوب الحوار، وأي تجاوز
            لقوانين الموقع فسيعرّض صاحبه للمعاقبة. أيضًا، ممنوع منعًا باتًا الغلط أو التنمر على أي
            عضو، فإن كان مخطئًا انتقدوه نقدًا بناءً لا هدّامًا ليصحح من نفسه ونرتقي معًا. كما تُمنع
            النقاشات السياسية والدينية فدائمًا ما تنتهي بالسبّ وقبيح الكلام وهو ما نرفضه رفضًا
            قاطعًا.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            أي عضو سيسرق ترجمة وينسبها لنفسه أو ينسخ من ترجمة عضو آخر، فهو يُعرّض حسابه للحظر.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            أي ترجمة تُرفع على الموقع فهي تخص رافعها وتعتبر حقًا فكريًا له، وممنوع منعًا باتًا عن أي
            عضو آخر استخدامها أو تحريفها أو نسبها لنفسه.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            على الرافع التأكد من نشر ترجمته بالتفاصيل الصحيحة؛ فممنوع كتابة أسماء نسخ لا وجود لها،
            أو أن الترجمة رسمية من منصة "كذا" بينما لم تُتح ترجمة رسمية للعمل. وأي تلاعب بأسماء
            النسخ لرفع الترجمة إلى أعلى الصفحة أو غيرها من الأسباب محظور ويُعرض صاحبه للمعاقبة.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            ممنوع على المترجمين تقييم الترجمات المنافسة لهم بالسيئ، أو التقليل من صاحبها، أو التعليق
            له تعليقًا سلبيًا.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            الرسائل المزعجة Spam ممنوعة، وفاعلها يُعرض نفسه للمعاقبة.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            يهدف الموقع إلى توفير بيئة تنتعش فيها المنافسة الشريفة التي بها نرتقي بلغتنا لا ننحدر
            بها، وأي فعل معارض لسياسة الموقع محظور.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            يُحظر اختيار أسماء حسابات وصور لها غير أخلاقية، أو مسيئة لعضو بعينه.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            مسموح لكل عضو بامتلاك حساب واحد فقط، وامتلاك أكثر من حساب أو إنشاء حسابات وهمية Fake
            Accounts لا يُعرض فاعلها إلا للمعاقبة. باستثناء إن سبق وملك أكثر من حساب في Subscene أو
            أراد الرفع على موقع SubSource من أكثر من حساب. وأي عضو سينشئ حسابًا ليقيّم مرفوعاته
            بالجيد ومرفوعات غيره بالسيئ، فلا يلومن إلا نفسه.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            يخلي مديرو ومشرفو ومبرمجو الموقع مسؤوليتهم عن مشاركة أي عضو لكلمة مروره.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            يُمنع في التعليقات نشر أي رابط خارجي أو الإعلان لأي موقع.
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'right',
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            وأخيرًا والأهم، ممنوع منعًا باتًا رفع أي ترجمات آلية (ترجمات غوغل وترجمات الذكاء
            الصناعي) وذكر أنها ترجمتك من مجهودك، والترجمات العامية (لأنها تُضرّ بهدفنا الرئيسي، ألا
            وهو الارتقاء باللغة العربية).
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          mt: '80px',
          px: '2rem',
          color: darkmode.value ? 'white' : 'initial',
          display: language === 'bg' ? 'block' : 'none'
        }}
      >
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 'bold',
            color: darkmode.value ? 'white' : 'initial',
            fontFamily: 'Regular-Family'
          }}
        >
          শর্তাবলী
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: '25px',
            lineHeight: '30px'
          }}
        >
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            সাবসোর্সের সকল মালিকানা এবং মেধাস্বত্ব সংরক্ষিত।{' '}
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            আমাদের মূল লক্ষ্য অনুবাদকদের অধিকার সংরক্ষণের মাধ্যমে মুভি এবং সিরিজের সাবটাইটেল সরবরাহ
            করা।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            ভদ্রতা এবং একে-অপরকে শ্রদ্ধা প্রদর্শনে আমরা অগ্রাধিকার দেবো, অমান্যকারীর বিরুদ্ধে কঠোর
            ব্যবস্থা নেয়া হবে। এছাড়াও, গালাগালি কিংবা বর্ণবাদী কথাবার্তাও সহ্য করা হবে না।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            যেকোনো সাবটাইটেল কপি করা কিংবা অনুবাদকের অনুমতি ব্যতীত সেটার ব্যবহার একদমই নিষিদ্ধ, এ
            কাজের সাথে জড়িত ব্যক্তিকে ব্যান করা হবে।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            এই ওয়েবসাইটে আপলোডকৃত সকল সাবটাইটেল অনুবাদকের ব্যক্তিগত মেধাস্বত্ব, এটাতে কোনো তৃতীয়
            পক্ষের হস্তক্ষেপ একদমই নিষিদ্ধ।{' '}
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            সাবটাইটেল রিলিজের ক্ষেত্রে আপলোডারকে অবশ্যই সঠিক তথ্য প্রদান করতে হবে, যেমন-রিলিজ কিংবা
            ফাইলের নাম। আর ব্যক্তিগত ফায়দা হাসিলের স্বার্থে রিলিজ নেম বারংবার পরিবর্তন কিংবা সেটাতে
            হস্তক্ষেপ সম্পূর্ণরূপে নিষিদ্ধ।{' '}
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            প্রতিদ্বন্দ্বীর সাবটাইটেলে ব্যাড রেটিং কিংবা নেগেটিভ কমেন্ট করা যাবে না। (ক্ল্যাশের
            ক্ষেত্রে)
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            কোনো ধরনের স্প্যামিং করা যাবে না, করলে অপরাধীকে শাস্তি দেওয়া হবে।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            আমাদের মূল লক্ষ্য শান্তিপূর্ণ পরিবেশে কাজ করা, সেটায় কেউ বাধা দিলে কোনোভাবেই বরদাস্ত করা
            হবে না।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            বাজে নাম এবং অবতার (Avatar) ব্যবহার করা যাবে না।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            প্রত্যেক ইউজার একটা মাত্র অ্যাকাউন্ট ব্যবহার করতে পারবে, কোনো ইউজার ফেইক আইডি কিংবা
            অল্টারনেটিভ আইডি ব্যবহার করলে শাস্তি দেওয়া হবে। ব্যতিক্রম: কারো পূর্বে সাবসিনে একাধিক
            আইডি থেকে থাকলে অথবা সাবসোর্সেও একাধিক আইডিতে আপলোডের প্রয়োজনের ক্ষেত্রে এটা করা যাবে।
            (তবে অল্টারনেটিভ/ফেক আইডি দিয়ে হিংসাত্মক কোনো কাজ করা যাবে না।)
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            কোনো ইউজার তার আইডির পাসওয়ার্ড শেয়ার করলে তার জন্য কতৃপক্ষ কোনো দায়ভার নিবে না।{' '}
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            কমেন্ট সেকশনে কোনো ডাউনলোড লিংক কিংবা বিজ্ঞাপন দেওয়া যাবে না।
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            মেশিন ট্রান্সলেটেড সাবটাইটেলকে আসল এবং মানসম্মত দাবি করা যাবে না। আমাদের ওয়েবসাইটে
            মেশিন/এআই ট্রান্সলেটেড সাবটাইটেল আপলোড করা যাবে, তবে সেক্ষেত্রে "মেশিন ট্রান্সলেটেড"
            কথাটা প্রডাকশন টাইপ এবং ক্যাপশন দুই জায়গাতেই উল্লেখ করতে হবে।
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          mt: '80px',
          px: '2rem',
          color: darkmode.value ? 'white' : 'initial',
          display: language === 'in' ? 'block' : 'none'
        }}
      >
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 'bold',
            color: darkmode.value ? 'white' : 'initial',
            fontFamily: 'Regular-Family'
          }}
        >
          Syarat & Ketentuan
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            listStylePosition: 'outside',
            ml: '25px',
            lineHeight: '30px'
          }}
        >
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Semua hak properti dan intelektual SubSource dilindungi undang-undang.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Tujuan utama situs ini adalah menyediakan subtitle untuk film dan acara TV sekaligus
            melindungi hak penerjemahnya.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Kesopanan dan rasa hormat adalah prioritas kami, dan siapa pun yang melanggar batas akan
            ditegur. Selain itu, ujaran kebencian dan rasisme tidak dapat ditoleransi.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Dilarang keras menyalin dan menjiplak subtitle apa pun, dan pelakunya akan dibanned.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Subtitle apa pun yang dikirimkan ke situs web ini adalah kekayaan intelektual milik
            penerjemahnya, dan tidak ada pihak ketiga yang diizinkan untuk mengubah-ubah kontennya.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Pengunggah harus mempublikasikan subtitle mereka dengan rincian yang benar, seperti nama
            rilis dan nama file. Dilarang mengubah dan memanipulasi nama rilis untuk keuntungan
            pribadi.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Penerjemah tidak diperbolehkan memberikan rating buruk atau komentar negatif untuk
            subtitle saingan (Subs lain untuk judul yang sama).
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Spam dalam bentuk apa pun tidak diperbolehkan, dan pelakunya akan ditegur.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Kebijakan kami adalah menciptakan lingkungan di mana persaingan yang sehat dapat
            berkembang, dan tindakan apa pun yang bertujuan mengganggu lingkungan ini tidak akan
            ditoleransi.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Dilarang menggunakan nama dan avatar yang menyinggung.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Setiap pengguna hanya diperbolehkan memiliki satu akun, dan pengguna dengan akun palsu
            atau alternatif akan dihukum. Pengecualian: Diperbolehkan jika Anda memiliki lebih dari
            satu akun di subscene atau Anda perlu mengunggah dengan lebih dari satu akun di
            SubSource (Tindakan jahat dengan akun alternatif/palsu tidak ditoleransi.)
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Kami tidak bertanggung jawab jika pengguna membagikan kata sandi akun mereka.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Dilarang memposting link download atau iklan apa pun di komentar.
          </ListItem>
          <ListItem
            sx={{
              display: 'list-item',
              fontSize: '17px',
              fontFamily: 'Regular-Family',
              fontWeight: '500',
              color: darkmode.value ? '#FFFFFFcc' : '#000000cc'
            }}
          >
            Memamerkan subtitle hasil terjemahan mesin sebagai subtitle berkualitas tinggi tidak
            diperbolehkan di SubSource. Jika Anda mencantumkan "Machine Translated" pada bagian
            Production Type dan Caption, Anda dapat mengunggah subtitle terjemahan mesin/AI di situs
            web ini.
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

export default TermsPage
