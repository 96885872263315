export const subjects = [
  'Technical Problem',
  'Change Poster or Title',
  'Claim Subscene Profile',
  'Plagiarism/Copying',
  'Release Name Issues',
  'Suggestions & Criticisms',
  'Edit or Delete My Subtitles',
  'Apply for a Rank',
  'Other Issues'
]

export const rankOptions = [
  {
    title: 'A translator badge',
    value: 0
  },
  {
    title: 'An uploader badge',
    value: 1
  }
]