import {
  Box,
  Divider,
  Typography,
  Paper,
  Checkbox,
  Button,
  Link,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  ListItemText,
  MenuItem,
  Alert,
  FormGroup,
  IconButton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import FlagIcon from '@mui/icons-material/Flag'
import useDarkMode from 'use-dark-mode'
import MovieReport from '../Reports/MovieReport'
import { langs } from '../../data/langs'
import DataGridMovieSubs from '../DataGrid/MovieSubs'
import Poster from '../ui/Poster'
import { types } from '../../data/types'
import { InfoRounded } from '@mui/icons-material'
import { useSmallWidth } from '../../hooks/useSmallWidth'
const mainFont = 'Medium-Family'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}
function Subtitles({
  backToSerie,
  movieLink,
  typesFilter,
  setTypesFilter,
  totalSubs,
  movie,
  subs,
  langFilter,
  setLangFilter,
  loading,
  setLoading,
  urlFilter,
  reload
}) {
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  const isSmall = useSmallWidth()
  //
  const [open, setOpen] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [reportShow, setReportShow] = useState(false)
  const [showEmpty, setShowEmpty] = useState(false)
  const [alertMessageOpen, setAlertMessageOpen] = useState(false)
  //
  const ReportFlag = () => (
    <Link sx={{ cursor: 'pointer' }} onClick={toggleReportShow}>
      <FlagIcon
        sx={{
          '&:hover': { color: 'red' },
          cursor: 'pointer',
          fontSize: '1.25rem'
        }}
      />
    </Link>
  )
  //
  const toggleAlertMessage = () => setAlertMessageOpen(!alertMessageOpen)
  // save lang filter
  const handleChangeLanguage = (event) => {
    const {
      target: { value }
    } = event
    // On autofill we get a stringified value.
    let temp = typeof value === 'string' ? value.split(',') : value
    setLangFilter(temp)
    window.localStorage.setItem('langs', JSON.stringify(temp))
  }
  // save release_types filter
  const handleChangeType = (event) => {
    const {
      target: { value }
    } = event
    // On autofill we get a stringified value.
    let temp = typeof value === 'string' ? value.split(',') : value
    window.localStorage.setItem('release_types', JSON.stringify(temp))
    setTypesFilter(temp)
  }
  const handleSaveFilters = () => {
    setLoading(true)
    reload()
  }
  const toggleReportShow = () => {
    setReportShow((p) => !p)
  }
  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        setOpen(false) // Close the select menu when scrolling
      }
    }

    if (open) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }

    return () => window.removeEventListener('scroll', handleScroll)
  }, [open])
  useEffect(() => {
    const handleScroll2 = () => {
      if (open2) {
        setOpen2(false) // Close the select menu when scrolling
      }
    }

    if (open2) {
      window.addEventListener('scroll', handleScroll2)
    } else {
      window.removeEventListener('scroll', handleScroll2)
    }

    return () => window.removeEventListener('scroll', handleScroll2)
  }, [open2])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Box sx={{ marginTop: '1rem', mb: '3rem' }}>
      <MovieReport show={reportShow} toggleShow={toggleReportShow} movieId={movie.id} />
      <Box>
        {backToSerie ? (
          <Box
            sx={{
              display: backToSerie ? 'flex' : 'none',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              width: 'fit-content'
            }}
            onClick={() => {
              navigate('/subtitles/' + movieLink)
            }}
          >
            <Paper
              sx={{
                width: 'fit-content',
                py: '0.25rem',
                px: '0.25rem',
                m: '0.25rem',
                backgroundColor: 'rgb(255,255,255,0.6)',
                transform: 'scale(0.75)'
              }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: '0.75rem', color: 'black' }} />
            </Paper>
            <Typography
              fontFamily={mainFont}
              fontWeight={'bold'}
              fontSize={'1rem'}
              sx={{ lineHeight: 'unset', color: darkmode.value ? 'white' : 'black' }}
            >
              {' '}
              {'Back to Season List'}
            </Typography>
          </Box>
        ) : (
          <Typography
            fontFamily={mainFont}
            fontWeight={'bold'}
            fontSize={'1.5rem'}
            sx={{ color: darkmode.value ? 'white' : 'initial' }}
          >
            Subtitles For
          </Typography>
        )}
        <Divider sx={{ borderColor: darkmode.value ? 'white' : 'initial' }} />
        {/* movie/series info */}
        <Box
          width={{ xs: '100%', sm: 'auto' }}
          display={'flex'}
          sx={{ gap: { xs: '.5rem', md: '1rem' }, py: '1rem' }}
        >
          {/* image */}
          <Box>
            <Box sx={{ display: 'inline-block', width: { xs: '150px', lg: '200px' } }}>
              <Poster src={movie.poster} alt="poster" />
            </Box>
          </Box>
          {/* info */}
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {/* name / report */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
              <Typography
                variant={'h1'}
                fontFamily={mainFont}
                fontWeight={'bold'}
                sx={{
                  color: darkmode.value ? 'white' : 'initial'
                }}
              >
                {movie.fullName}
              </Typography>
              {!isSmall ? ReportFlag() : ''}
            </Box>
            {/* yaer / imdb */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row' },
                // justifyContent: 'space-between',
                gap: '1rem'
              }}
            >
              <Typography
                fontSize={'1rem'}
                fontFamily={mainFont}
                fontWeight={'bold'}
                sx={{
                  color: darkmode.value ? 'white' : 'initial'
                }}
              >
                Year: {movie.year}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'end' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {/* imdb */}
                <a
                  href={
                    movie?.imdbLink?.startsWith('tt')
                      ? 'https://www.imdb.com/title/' + movie?.imdbLink
                      : movie?.imdbLink
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={'/icons/imdb.png'}
                    width={'40px'}
                    height={'40px'}
                    alt="imdb"
                    style={{ borderRadius: '50%', overflow: 'hidden' }}
                  />
                </a>
                {/* report */}
                {isSmall ? ReportFlag() : ''}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* filters */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <FormGroup
            sx={{
              alignItems: { lg: 'flex-end' },
              // justifyContent: { lg: 'flex-end' },
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              flexWrap: 'wrap',
              mb: '1rem',
              gap: '1rem',
              flexGrow: { md: 1 }
            }}
          >
            {/* Language */}
            <FormControl
              focused
              sx={{ flexGrow: { xs: 1 }, width: { md: '100%' }, maxWidth: { sm: 250 } }}
            >
              <InputLabel htmlFor="selected-language" shrink>
                Language
              </InputLabel>
              <Select
                open={open}
                onOpen={() => {
                  setShowEmpty(true)
                  setOpen(true)
                }}
                onClose={() => setOpen(false)}
                onBlur={() => setShowEmpty(false)}
                value={langFilter ?? []}
                onChange={handleChangeLanguage}
                inputProps={{
                  name: 'language',
                  id: 'selected-language'
                }}
                size="small"
                multiple
                input={<OutlinedInput label="Language" />}
                renderValue={(selected) => {
                  return selected.length === 0 ? 'All Languages' : selected.join(', ')
                }}
                displayEmpty={showEmpty}
                MenuProps={MenuProps}
              >
                {['Select Language'].concat(langs).map((lang, index) => (
                  <MenuItem key={lang} value={lang} disabled={index === 0}>
                    <Checkbox checked={langFilter?.indexOf(lang) > -1} />
                    <ListItemText primary={lang} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Release Types */}
            <FormControl
              focused
              sx={{
                flexGrow: { xs: 1 },
                width: { md: '100%' },
                maxWidth: { sm: 250 }
              }}
            >
              <InputLabel htmlFor="selected-type" shrink>
                Release Type
              </InputLabel>
              <Select
                open={open2}
                onOpen={() => {
                  setShowEmpty(true)
                  setOpen2(true)
                }}
                onClose={() => setOpen2(false)}
                onBlur={() => setShowEmpty(false)}
                value={typesFilter ?? []}
                onChange={handleChangeType}
                inputProps={{
                  name: 'type',
                  id: 'selected-type'
                }}
                size="small"
                multiple
                input={<OutlinedInput label="Release Type" />}
                renderValue={(selected) => {
                  return selected.length == 0 ? 'All Release Types' : selected.join(', ')
                }}
                displayEmpty={showEmpty}
                MenuProps={MenuProps}
              >
                {types.map((type, index) => (
                  <MenuItem key={type} value={type} disabled={index == 0}>
                    <Checkbox checked={typesFilter?.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* filter */}
            <Box sx={{ display: 'flex', gap: '.5rem' }}>
              <Button
                sx={{ flexGrow: 1 }}
                type="submit"
                onClick={handleSaveFilters}
                variant="contained"
                color="success"
              >
                Filter
              </Button>
              <IconButton type="button" onClick={toggleAlertMessage} aria-label="delete">
                <InfoRounded />
              </IconButton>
            </Box>
            {alertMessageOpen ? (
              <Alert severity="info" sx={{ py: '.12rem' }}>
                Subtitles with unidentified release types cannot be found by Filtering. Try without
                Filter Type, too.
              </Alert>
            ) : (
              ''
            )}
          </FormGroup>
        </Box>
        {/* subs table */}
        <DataGridMovieSubs subs={subs} dataLoading={loading} />
        <Typography
          textAlign={'center'}
          fontSize={'30px'}
          sx={{
            marginTop: '2rem',
            fontFamily: 'Medium-Family',
            color: '#21495F',
            fontWeight: 'Bold',
            display: totalSubs > 0 && subs.length === 0 ? 'content' : 'none'
          }}
        >
          No subtitles were found with selected filters
        </Typography>
        <Typography
          textAlign={'center'}
          fontSize={'30px'}
          sx={{
            marginTop: '2rem',
            fontFamily: 'Medium-Family',
            color: '#21495F',
            fontWeight: 'Bold',
            display: totalSubs === 0 ? 'content' : 'none'
          }}
        >
          No subtitles available
        </Typography>
      </Box>
    </Box>
  )
}

export default Subtitles
