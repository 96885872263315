import { Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { changeSubsceneStatus } from '../../../../api/adminApi';
import { dispachAlert } from '../../../../utils/dispachers';
function SubsceneStatus({ user, isLoading }) {
    const [isSubscene, setIsSubscene] = useState(user.isSubsceneAccount);
    const queryClient = useQueryClient();
    const { isFetching, refetch } = useQuery({
        queryKey: ['adminUpdateSubsceneStatus'],
        queryFn: async () => {
            try {
                const result = await changeSubsceneStatus(user.id, isSubscene);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    });
    useEffect(() => {
        setIsSubscene(user.isSubsceneAccount)
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Box sx={{ display: 'flex', gap: "5rem", ml: "0.25rem" }}>
                <FormControlLabel sx={{ flex: 1 }} control={<Checkbox disabled={isFetching || isLoading} checked={isSubscene} onChange={(e, v) => setIsSubscene(v)} />} label="Is Subscene Account?" />
                <Button variant='outlined' disabled={user.isSubsceneAccount === isSubscene || isFetching || isLoading} onClick={() => { if (!isFetching) refetch() }}><SaveIcon /></Button>
            </Box>
        </Box>
    )
}

export default SubsceneStatus