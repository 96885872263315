import React, { useEffect, useState } from 'react'
import Header from '../Components/Header/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import { Alert, Snackbar } from '@mui/material'

function MainLayout() {
  /**
   * open
   * severity
   * message
   */
  const [alerts, setAlerts] = useState([])

  const handleClose = (index) => {
    setAlerts((prevAlerts) => prevAlerts.filter((i, j) => j !== index))
  }

  useEffect(() => {
    const addListener = (e) => {
      let detail = e?.detail ? JSON.parse(e.detail) : null
      if (detail) {
        // Use a callback function to ensure the latest state
        setAlerts((prevAlerts) => {
          const updatedAlerts = [...prevAlerts, { open: true, ...detail }]
          return updatedAlerts
        })
      }
    }

    window.addEventListener('SnackError', addListener)

    return () => {
      window.removeEventListener('SnackError', addListener)
    }
  }, [])
  return (
    <>
      <Header />
      <div id="bodyContainer">
        <Outlet />
        {alerts.map((item, index) => (
          <Snackbar
            key={`alert-${index}`}
            open={item.open}
            autoHideDuration={6000}
            onClose={() => handleClose(index)}
          >
            <Alert
              onClose={() => handleClose(index)}
              severity={item.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {item.message}
            </Alert>
          </Snackbar>
        ))}
      </div>
      <Footer />
    </>
  )
}

export default MainLayout
