
import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const MainApi = {
    getHome: async function (cancel = false) {
        const response = await api.request({
            url: `/api/getHome`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response?.data || null
    },
    getTrendingUploads: async function (cancel = false) {
        const response = await api.request({
            url: `/api/getTrendingUploads`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response?.data || null
    },
    searchMoviesUpload: async function (query, cancel = false) {
        const response = await api.request({
            url: `/api/searchMovieUpload`,
            method: "POST",
            data: { query },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response?.data || null
    },
    searchMoviesFull: async function (query, cancel = false) {
        const response = await api.request({
            url: `/api/searchMovieFull`,
            method: "POST",
            data: { query },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response?.data || null
    },
}

const cancelApiObject = defineCancelApiObject(MainApi)