import { Box, Button, Link, List, ListItem, Paper, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import SubtitlesRoundedIcon from '@mui/icons-material/SubtitlesRounded'
import React, { useEffect, useState } from 'react'
import { Grid } from 'react-loading-icons'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import ContribIcon from '../../../icons/contribIcon.png'
import '../../../css/Dashboard.css'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../Contexts/userContext'
import useDarkMode from 'use-dark-mode'
import Poster from '../../../Components/ui/Poster'
import RatingCard from '../../../Components/Ratings/Card'
import { dispachAlert } from '../../../utils/dispachers'
const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key]
    var y = b[key]
    return x < y ? 1 : x > y ? -1 : 0
  })
}
function DashboardMySubscene() {
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  const [subs, setSubs] = useState([])
  const [subsLoading, setSubsLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [ratings, setRatings] = useState([])
  const [ratingsLoading, setRatingsLoading] = useState(true)
  const [subCount, setsubCount] = useState(0)
  const [rateCount, setRateCount] = useState(0)
  const [contribs, setContribs] = useState(0)
  const user = useUserContext()
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/user/mySubsource/getSubs', {
        withCredentials: true
      })
      .then((res) => {
        setSubs(res.data.subs)
        setsubCount(res.data.count)
        setContribs(res.data.contribsLength)
        setSubsLoading(false)
        setRatings(sortByKey(res.data.rates, 'date') || [])
        setRateCount(res.data.ratesCount || 0)
        setRatingsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const handleSubDelete = (id) => {
    var answer = window.confirm('Confirm Deleting Sub?')
    if (answer) {
      setDeleting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/user/subs/delete',
          { subId: id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            return window.location.reload()
          } else {
            console.log(res.data.err || 'Error.')
          }
          setDeleting(false)
        })
        .catch((err) => {
          dispachAlert('error', 'Error.')
          console.log({ err })
          setDeleting(false)
        })
    }
  }
  return (
    <Box
      sx={{
        paddingTop: { xs: '1rem', lg: '3rem' },
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        mb: '1rem',
        mx: 'auto',
        maxWidth: '750px'
      }}
    >
      {/* ratings */}
      <Box sx={{ p: '0.5rem' }}>
        {/* title */}
        <Box
          className="button-24 contribSub"
          sx={{
            backgroundColor: darkmode.value ? '#44474a !important' : 'initial',
            width: 'fit-content !important',
            py: '0.5rem !important',
            display: subsLoading || ratingsLoading ? 'none' : 'flex',
            '&:active': {
              boxShadow:
                'inset 0px 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0px 4px 5px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important'
            },
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important'
          }}
        >
          <Link
            sx={{ display: 'inline-block', textDecoration: 'none' }}
            href={'/u/' + user.id + '/ratings'}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/u/' + user.id + '/ratings')
              }
            }}
          >
            <Typography
              fontFamily={'Medium-Family'}
              fontWeight={'bold'}
              sx={{ color: darkmode.value ? 'white' : 'black', fontSize: '1.4rem' }}
            >
              {rateCount} Ratings
            </Typography>
          </Link>
        </Box>
        {/* loading */}
        {ratingsLoading && (
          <>
            <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
                <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
              </Box>
            </Box>
          </>
        )}
        {/* empty */}
        {ratings.length === 0 && (
          <Box sx={{ py: '2rem' }}>
            <Typography sx={{ color: darkmode.value ? 'white' : 'initial' }}>
              No Ratings Found.
            </Typography>
          </Box>
        )}
        {/* items */}
        <List sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem', pt: '1rem' }}>
          {ratings.map((x, index) => (
            <ListItem key={index} disablePadding>
              <RatingCard rating={x} />
            </ListItem>
          ))}
        </List>
      </Box>
      {/* subtitles */}
      <Box sx={{ p: '0.5rem' }}>
        {/* title */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Link
            sx={{ display: 'inline-block', textDecoration: 'none' }}
            href={'/u/' + user.id + '/subtitles'}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/u/' + user.id + '/subtitles')
              }
            }}
          >
            <Box
              className="button-24 contribSub"
              sx={{
                backgroundColor: darkmode.value ? '#44474a !important' : 'inital',
                display: subsLoading || ratingsLoading ? 'none' : 'flex',
                '&:active': {
                  boxShadow:
                    'inset 0px 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0px 4px 5px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important'
                },
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important'
              }}
            >
              <Typography
                fontFamily={'Medium-Family'}
                fontWeight={'bold'}
                sx={{
                  color: darkmode.value ? 'white' : 'black',
                  fontSize: '1.4rem',
                  py: '0.5rem !important'
                }}
              >
                {subCount} Subtitles{' '}
              </Typography>
              <AddCircleSharpIcon
                sx={{
                  fontSize: '1.1rem',
                  ml: '0.25rem',
                  mt: '1px',
                  color: darkmode.value ? '#868ab3' : '#484c7a'
                }}
              ></AddCircleSharpIcon>
              <Typography
                fontFamily={'Medium-Family'}
                fontWeight={'bold'}
                sx={{
                  ml: '0.15rem',
                  fontSize: '1.2rem',
                  color: darkmode.value ? '#868ab3' : '#484c7a'
                }}
              >
                {contribs}
              </Typography>
            </Box>
          </Link>
        </Box>
        {/* loading */}
        {subsLoading && (
          <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
              <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
            </Box>
          </Box>
        )}
        {/* empty */}
        {subs.length === 0 && (
          <Box sx={{ py: '2rem' }}>
            <Typography sx={{ color: darkmode.value ? 'white' : 'initial' }}>
              No Subtitles Found.
            </Typography>
          </Box>
        )}
        {/* items */}
        <List sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem', pt: '1rem' }}>
          {subs.map((x, index) => (
            <ListItem key={index} disablePadding>
              <Paper
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  overflow: 'hidden',
                  alignItems: { md: 'center' },
                  justifyContent: 'space-between',
                  p: '0.25rem',
                  gap: '.5rem',
                  flexDirection: { xs: 'column', md: 'row' }
                }}
                elevation={5}
              >
                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                  <Box sx={{ width: { xs: '120px' }, display: 'flex' }}>
                    <Poster src={x.poster} alt={x.subMovieName} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      flexGrow: 1,
                      p: '0.5rem',
                      textWrap: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <Box sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                      <Tooltip placement="top" title={x.subMovieName}>
                        <Link
                          href={x.fullLink}
                          sx={{
                            fontFamily: 'Medium-Family',
                            fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                            textWrap: 'nowrap',
                            textDecoration: 'none',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {x.subMovieName}
                        </Link>
                      </Tooltip>
                      <Typography
                        sx={{
                          fontFamily: 'Medium-Family',
                          fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                          mt: '0.35rem'
                        }}
                      >
                        {x.subLang}
                      </Typography>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', gap: '1rem', mb: '.5rem' }}>
                        <Button
                          onClick={() => {
                            handleSubDelete(x.id)
                          }}
                          disabled={deleting || !x.userUploaded}
                          color="error"
                          variant="outlined"
                          sx={{
                            px: '1px',
                            py: '1px',
                            textTransform: 'none',
                            width: 'fit-content',
                            borderRadius: '15px',
                            fontSize: '0.75rem',
                            minWidth: '50px',
                            mb: '0.25rem'
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          href={'/edit/' + x.id}
                          onClick={(e) => {
                            if (!e.ctrlKey) {
                              e.preventDefault()
                              navigate('/edit/' + x.id)
                            }
                          }}
                          disabled={deleting || !x.userUploaded}
                          variant="outlined"
                          sx={{
                            px: '1px',
                            py: '1px',
                            textTransform: 'none',
                            width: 'fit-content',
                            borderRadius: '15px',
                            fontSize: '0.75rem',
                            minWidth: '50px',
                            mb: '0.25rem'
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', gap: '1.5rem' }}>
                        <Tooltip placement="top" title="Collaborated">
                          <Box
                            sx={{ display: x?.isContrib ? 'flex' : 'none', alignItems: 'center' }}
                          >
                            <img
                              src={ContribIcon}
                              alt="Contributed"
                              style={{
                                width: '20px',
                                height: '15px',
                                filter: darkmode.value ? 'invert(1)' : 'none'
                              }}
                            />
                          </Box>
                        </Tooltip>
                        <Link
                          href={x?.isContrib ? null : '/ratings/' + x.id}
                          onClick={(e) => {
                            if (!x.isContrib)
                              if (!e.ctrlKey) {
                                e.preventDefault()
                                navigate('/ratings/' + x.id)
                              }
                          }}
                          sx={{
                            fontFamily: 'Medium-Family',
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            maxWidth: '35px',
                            justifyContent: 'center',
                            color: x?.isContrib ? '#b9bcbc' : darkmode.value ? 'white' : 'initial'
                          }}
                        >
                          <SubtitlesRoundedIcon
                            sx={{
                              fontSize: '1rem',
                              color: x?.isContrib ? '#b9bcbc' : 'teal',
                              mr: '0.25rem'
                            }}
                          />
                          {x.ratings}
                        </Link>
                        <Link
                          href={x.fullLink}
                          onClick={(e) => {
                            if (!e.ctrlKey) {
                              e.preventDefault()
                              navigate(x.fullLink)
                            }
                          }}
                          sx={{
                            fontFamily: 'Medium-Family',
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            maxWidth: '35px',
                            justifyContent: 'center'
                          }}
                        >
                          <ChatRoundedIcon
                            sx={{ fontSize: '1rem', color: 'gray', mr: '0.25rem' }}
                          />
                          {x.comments}
                        </Link>
                        <Link
                          sx={{
                            fontFamily: 'Medium-Family',
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            maxWidth: '35px',
                            justifyContent: 'center'
                          }}
                        >
                          <DownloadRoundedIcon
                            sx={{ fontSize: '1rem', color: 'darkred', mt: '1px', mr: '3px' }}
                          />
                          {x.downloads}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default DashboardMySubscene
