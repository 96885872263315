import { Box, Button } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react'
import { deleteOthers, restoreOthers } from '../../../../api/adminApi';
import { dispachAlert } from '../../../../utils/dispachers';

function Rates({ user, isLoading }) {
    const queryClient = useQueryClient();
    const { refetch: fetchDeleteRates, isFetching: isFetchingDelete } = useQuery({
        queryKey: ["adminDeleteAllRates"],
        queryFn: async () => {
            try {
                const result = await deleteOthers(user.id, 'rates');
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const { refetch: fetchRestoreRates, isFetching: isFetchingRestore } = useQuery({
        queryKey: ["adminRestoreAllRates"],
        queryFn: async () => {
            try {

                const result = await restoreOthers(user.id, 'rates');
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const handleClickDelete = () => {
        const answer = window.confirm("Confirm Deleting All Rates?");
        if (answer) {
            fetchDeleteRates();
        }
    }
    const handleClickRestore = () => {
        const answer = window.confirm("Confirm Restore All Rates?");
        if (answer) {
            fetchRestoreRates();
        }
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Button variant='outlined' disableRipple color='error' disabled={isFetchingRestore || isFetchingDelete || isLoading} size='small' sx={{ textTransform: "none" }} onClick={handleClickDelete}>Delete Rates ({user.ratesCount})</Button>
            <Button variant='outlined' disableRipple color='error' disabled={isFetchingRestore || isFetchingDelete || isLoading} size='small' sx={{ textTransform: "none" }} onClick={handleClickRestore}>Restore Rates ({user.ratesCountBanDeleted})</Button>
        </Box>

    )
}

export default Rates