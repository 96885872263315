import {
  Box,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { Grid } from 'react-loading-icons'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled'
import { decode } from 'html-entities'
import { Link, useNavigate } from 'react-router-dom'
import { useSmallWidth } from '../../hooks/useSmallWidth'
import { Search } from '@mui/icons-material'
import useDarkMode from 'use-dark-mode'
import UserBadgeList from '../User/BadgeList'
import { TableVirtuoso } from 'react-virtuoso'

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} id='table-container' ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
}

export default function DataGridMovieSubs({ subs, dataLoading }) {
  const navigate = useNavigate()
  const darkmode = useDarkMode()
  const isSmall = useSmallWidth()
  const [sortByDate, setSortByDate] = useState(window.localStorage.getItem('bydate') === '1')
  const [searchTerm, setSearchTerm] = useState('')

  let timer;
  const handleSearchChange = (event) => {
    clearTimeout( timer );
    timer = setTimeout(() => {
      setSearchTerm(event.target.value.toLowerCase())
    }, 1500)
  }

  const handleChange = (event) => {
    const isChecked = event.target.checked
    window.localStorage.setItem('bydate', isChecked ? '1' : '0')
    setSortByDate(isChecked)
  }

  const columns = [
    {
      field: 'lang',
      headerName: isSmall ? 'Lang' : 'Language',
      width: 100,
      valueGetter: (val, row) => val
    },
    {
      field: 'releaseName',
      headerName: 'Release Name',
      width: isSmall ? 400 : 450,
      valueGetter: (val, row) => val
    },
    {
      field: 'rt',
      headerName: 'Type',
      valueGetter: (val, row) => val || 'N/A',
      width: 135
    },
    {
      field: 'commentary',
      headerName: 'Caption',
      width: isSmall ? 400 : 400,
      center: true,
      valueGetter: (val, row) => decode(val)
    },
    {
      field: 'uploadedBy',
      headerName: 'Owner',
      width: 150,
      center: true,
      valueGetter: (val, row) => val
    },
    {
      field: 'uploaderBadges',
      headerName: 'Rank',
      disableTooltip: true,
      width: 110,
      center: true,
      valueGetter: (val, row) => (
        <UserBadgeList
          badges={val.filter((b) => ['tr1', 'tr2', 'tr3', 'up1', 'up2', 'up3'].includes(b))}
          disablePadding
          smaller
          center
        />
      )
    },
    {
      field: 'hi',
      headerName: 'H.I',
      width: 50,
      valueGetter: (val, row) =>
        val ? <HearingDisabledIcon sx={{ color: 'darkgray', fontSize: '16px' }} /> : ''
    },
    {
      field: 'date',
      headerName: 'Date',
      // width: 200,
      type: 'date',
      valueGetter: (val, row) => new Date(val).toLocaleDateString()
    }
  ]

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.field}
            style={{ width: column.width }}
            sx={{
              color: 'white',
              maxWidth: column.width,
              display: column.field === 'date' ? 'none' : '',
              textAlign: column.center ? 'center' : 'left'
            }}
          >
            {column.headerName}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const groupedData = useCallback(() => {
    const grouped = {}
    // Group the items by language without slicing
    subs
      .sort((a, b) => (a?.lang || 'zzz').localeCompare(b?.lang || 'zzz'))
      .forEach((sub) => {
        const lang = sub.lang || 'zzz' // Default to 'zzz' if lang is missing
        // add the lang of it doesnt exists in our group
        if (!grouped[lang]) grouped[lang] = []
        // if user hasn't searched add the item
        if (!searchTerm) grouped[lang].push(sub)
        // otherwise add the item only if it contains our search term
        else if (JSON.stringify(sub).toString().toLowerCase().includes(searchTerm))
          grouped[lang].push(sub)
      })
    // Sort each language's items by date or releaseName based on sortByDate flag
    Object.keys(grouped).forEach((lang) => {
      grouped[lang].sort((a, b) => {
        if (sortByDate) {
          const dateA = new Date(a.date).getTime()
          const dateB = new Date(b.date).getTime()
          return dateB - dateA // Sort by date descending if sortByDate is true
        } else {
          const releaseNameA = a.releaseName.trim().toLowerCase() // Normalize comparison
          const releaseNameB = b.releaseName.trim().toLowerCase() // Normalize comparison
          return releaseNameB.localeCompare(releaseNameA) // Sort by releaseName if sortByDate is false
        }
      })
      // Mark the last item of each language group
      if (grouped[lang].length > 0) {
        grouped[lang][grouped[lang].length - 1].isLast = true
      }
    })
    console.log('res', grouped)

    return Object.values(grouped).flat()
  }, [subs, searchTerm, sortByDate]) // Include sortByDate as a dependency

  const data = groupedData()

  function rowContent(_index, row) {
    const isOdd = _index % 2 === 0
    const rowClass = isOdd ? 'odd-row' : 'even-row'

    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            className={rowClass}
            key={`${row.lang}-${row.subId}-${column.field}-${_index}`}
            style={{ width: column.width }}
            sx={{
              whiteSpace: 'nowrap',
              display: column.field === 'date' ? 'none' : '',
              border: 'none',
              p: '0',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: column.center ? 'center' : 'left',
              borderTop: 'none',
              height: _index === data.length - 1 ? '45px' : '40px',
              borderBottom: row?.isLast ? '5px solid #6eb5b5' : 'none'
            }}
          >
            <Tooltip
              placement="top"
              title={
                !column?.disableTooltip
                  ? column.valueGetter
                    ? column.valueGetter(row[column.field], row)
                    : row[column.field]
                  : ''
              }
            >
              <Link
                to={row.fullLink}
                style={{
                  justifyContent: column.center ? 'center' : 'flex-start'
                }}
              >
                {column.valueGetter
                  ? column.valueGetter(row[column.field], row)
                  : row[column.field]}
              </Link>
            </Tooltip>
          </TableCell>
        ))}
      </React.Fragment>
    )
  }

  const [scrollDirection, setScrollDirection] = useState(null) // Track scroll direction
  const initialX = useRef(null) // Initialize refs for X and Y
  const initialY = useRef(null)
  // Handle initial touch to determine scroll direction
  const handleTouchStart = (e) => {
    if (!isSmall) return
    const touch = e.touches[0]
    setScrollDirection(null)
    // Store initial touch coordinates
    initialX.current = touch.clientX
    initialY.current = touch.clientY
  }

  // Detect direction on touch move and set scroll constraint
  const handleTouchMove = (e) => {
    if (scrollDirection || !isSmall) return // Only set once
    const touch = e.touches[0]
    const diffX = Math.abs(touch.clientX - initialX.current)
    const diffY = Math.abs(touch.clientY - initialY.current)
    let dir = diffX > diffY ? 'horizontal' : 'vertical'

    let el = document.querySelector('#table-container')
    if (el) {
      el.style.overflowX = dir === 'vertical' ? 'hidden' : 'auto'
      el.style.overflowY = dir === 'horizontal' ? 'hidden' : 'auto'
    }
    setScrollDirection(dir)
  }

  useEffect(() => {
    const resetScrollDirection = () => {
      let el = document.querySelector('#table-wrapper')
      if (el) {
        el.style.overflowX = 'auto'
        el.style.overflowY = 'auto'
      }
      setScrollDirection(null)
    }
    document.addEventListener('touchend', resetScrollDirection)
    return () => {
      document.removeEventListener('touchend', resetScrollDirection)
    }
  }, [])

  return (
    <Paper className="movieSubs">
      <Box
        sx={{
          p: { xs: 1, lg: 1.5 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { sm: '1rem' }
        }}
      >
        <FormControl variant="standard" sx={{ width: '100%', maxWidth: '500px' }}>
          <Input
            id="input-with-icon-adornment"
            placeholder="Search ..."
            onChange={handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControlLabel
          control={<Switch checked={sortByDate} onChange={handleChange} />}
          label="Sort by Date"
        />
      </Box>
      <Box
        sx={{
          height: '800px',
          display: dataLoading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black'
        }}
      >
        <Grid fill={darkmode.value ? 'white' : 'black'} />
      </Box>
      {/* 
        // TODO: Fix these
        one direction scroll at a time
        display: dataLoading ? 'none' : 'initial',
        overflowX: isSmall && scrollDirection === 'vertical' ? 'hidden' : 'auto',
        overflowY: isSmall && scrollDirection === 'horizontal' ? 'hidden' : 'auto'
      */}
      <Box
        sx={{ height: { xs: '95vh', lg: '200vh' } }}
        id="table-wrapper"
        onTouchStart={isSmall ? handleTouchStart : undefined}
        onTouchMove={isSmall ? handleTouchMove : undefined}
      >
        <TableVirtuoso
          data={data}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </Box>
    </Paper>
  )
}
