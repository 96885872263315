export const prioritizedLangs = ['English', 'Farsi/Persian']

export const langs = [
  'English',
  'Farsi/Persian',
  'Abkhazian',
  'Afrikaans',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Asturian',
  'Azerbaijani',
  'Basque',
  'Belarusian',
  'Bengali',
  'Big 5 code',
  'Bosnian',
  'Brazillian Portuguese',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan',
  'Chinese',
  'Chinese (Cantonese)',
  'Chinese (Simplified)',
  'Chinese (Traditional)',
  'Chinese BG code',
  'Chinese Bilingual',
  'Croatian',
  'Czech',
  'Danish',
  'Dari',
  'Dutch',
  'Espranto',
  'Estonian',
  'Extremaduran',
  'Finnish',
  'French',
  'French (Canada)',
  'French (France)',
  'Gaelic',
  'Gaelician',
  'Georgian',
  'German',
  'Greek',
  'Greenlandic',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Igbo',
  'Indonesian',
  'Interlingua',
  'Irish',
  'Italian',
  'Japanese',
  'Kannada',
  'Kazakh',
  'Khmer',
  'Korean',
  'Kurdish',
  'Kyrgyz',
  'Latvian',
  'Lithuanian',
  'Luxembourgish',
  'Macedonian',
  'Malay',
  'Malayalam',
  'Manipuri',
  'Marathi',
  'Mongolian',
  'Montenegrin',
  'Navajo',
  'Nepali',
  'Northen Sami',
  'Norwegian',
  'Occitan',
  'Odia',
  'Pashto',
  'Polish',
  'Portuguese',
  'Pushto',
  'Romanian',
  'Russian',
  'Santli',
  'Serbian',
  'Sindhi',
  'Sinhala',
  'Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sorbian',
  'Spanish',
  'Spanish (Latin America)',
  'Spanish (Spain)',
  'Swahili',
  'Swedish',
  'Syriac',
  'Tagalog',
  'Tamil',
  'Tatar',
  'Telugu',
  'Telugu',
  'Tetum',
  'Thai',
  'Toki Pona',
  'Turkish',
  'Turkmen',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Vietnamese',
  'Welsh'
]

export const formatted_langs = {
  'Farsi/Persian': 'farsi_persian',
  English: 'english',
  Arabic: 'arabic',
  Bengali: 'bengali',
  Albanian: 'albanian',
  'Brazillian Portuguese': 'brazilian_portuguese',
  Portuguese: 'portuguese',
  Croatian: 'croatian',
  Czech: 'czech',
  Danish: 'danish',
  Dutch: 'dutch',
  Chinese: 'chinese',
  'Chinese (Traditional)': 'chinese_traditional',
  'Chinese (Simplified)': 'chinese_simplified',
  'French (France)': 'french_france',
  'French (Canada)': 'french_canada',
  'Spanish (Latin America)': 'spanish_latin_america',
  'Spanish (Spain)': 'spanish_spain',
  Serbian: 'serbian',
  Burmese: 'burmese',
  Finnish: 'finnish',
  Malayalam: 'malayalam',
  Urdu: 'urdu',
  Latvian: 'latvian',
  Lithuanian: 'lithuanian',
  Tagalog: 'tagalog',
  French: 'french',
  German: 'german',
  Greek: 'greek',
  Hebrew: 'hebrew',
  Kurdish: 'kurdish',
  Bosnian: 'bosnian',
  Basque: 'basque',
  Catalan: 'catalan',
  Kannada: 'kannada',
  Greenlandic: 'greenlandic',
  Macedonian: 'macedonian',
  Hindi: 'hindi',
  Estonian: 'estonian',
  Indonesian: 'indonesian',
  Italian: 'italian',
  Japanese: 'japanese',
  Korean: 'korean',
  Malay: 'malay',
  Slovenian: 'slovenian',
  Icelandic: 'icelandic',
  Sinhala: 'Sinhala',
  Norwegian: 'norwegian',
  Polish: 'polish',
  Romanian: 'romanian',
  Russian: 'russian',
  Spanish: 'spanish',
  Turkish: 'turkish',
  Swedish: 'swedish',
  Slovak: 'slovak',
  Telugu: 'telugu',
  Tamil: 'tamil',
  Thai: 'thai',
  Vietnamese: 'vietnamese',
  Hungarian: 'hungarian',
  Ukrainian: 'ukrainian',
  Bulgarian: 'bulgarian',
  'Chinese BG code': 'chinese_bg_code',
  'Big 5 code': 'big_5_code',
  Abkhazian: 'abkhazian',
  Afrikaans: 'afrikaans',
  Amharic: 'amharic',
  Aragonese: 'aragonese',
  Armenian: 'armenian',
  Assamese: 'assamese',
  Asturian: 'asturian',
  Azerbaijani: 'azerbaijani',
  Belarusian: 'belarusian',
  Breton: 'breton',
  'Chinese (Cantonese)': 'chinese_cantonese',
  'Chinese Bilingual': 'chinese_bilingual',
  Dari: 'dari',
  Espranto: 'espranto',
  Extremaduran: 'extremaduran',
  Gaelic: 'gaelic',
  Gaelician: 'gaelician',
  Georgian: 'georgian',
  Igbo: 'igbo',
  Interlingua: 'interlingua',
  Irish: 'irish',
  Kazakh: 'kazakh',
  Khmer: 'khmer',
  Kyrgyz: 'kyrgyz',
  Luxembourgish: 'luxembourgish',
  Manipuri: 'manipuri',
  Marathi: 'marathi',
  Mongolian: 'mongolian',
  Montenegrin: 'montenegrin',
  Navajo: 'navajo',
  Nepali: 'nepali',
  'Northen Sami': 'northen_sami',
  Occitan: 'occitan',
  Odia: 'odia',
  Pushto: 'pushto',
  Pashto: 'pashto',
  Santli: 'santli',
  Sindhi: 'sindhi',
  Sinhalese: 'sinhalese',
  Somali: 'somali',
  Sorbian: 'sorbian',
  Swahili: 'swahili',
  Syriac: 'syriac',
  Tatar: 'tatar',
  Tetum: 'tetum',
  'Toki Pona': 'toki_pona',
  Turkmen: 'turkmen',
  Uzbek: 'uzbek',
  Welsh: 'welsh'
}
