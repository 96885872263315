import { Button, InputBase } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import useDarkMode from 'use-dark-mode';
import { dispachAlert } from '../../utils/dispachers';

function SubtitleCommentField({ addComment, subData, reply, commentText, setCommentText, setReply }) {
    const darkmode = useDarkMode();
    const [submitting, setSubmitting] = useState(false);
    const handleChange = (e) => {
        setCommentText(e.target.value)
    }
    const handleSubmitComment = () => {
        setSubmitting(true)
        if (commentText.trim() !== "") {
            axios.post(process.env.REACT_APP_API_URL + "/api/subComment", { subId: subData.sub.id, text: commentText, reply: reply?.id || null }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    if (res.data.isReply) {
                        window.location.reload();
                    } else {
                        addComment(res.data.comment);
                        setCommentText("");
                        setReply(null)
                        setSubmitting(false)
                    }
                } else {
                    dispachAlert('error', res.data.errMsg || "Error.")
                    setSubmitting(false)
                }
            }).catch(err => {
                if (err.response.status === 429) {
                    dispachAlert('error', 'You can only comment once per minute.')
                }
                setSubmitting(false)
                console.log(err);
            })
        }
    }
    return (
      <>
        <InputBase
          id="comment"
          label="Comment"
          multiline
          value={commentText}
          onChange={handleChange}
          fullWidth
          placeholder="Leave a comment..."
          minRows={3}
          inputProps={{ dir: 'auto', maxLength: 512 }}
          InputProps={{ style: { fontFamily: 'Regular-Family' } }}
          sx={{
            backgroundColor: darkmode.value ? 'dimgray' : 'azure',
            borderRadius: '5px',
            marginTop: '0rem',
            px: '1rem',
            py: '0.5rem',
            fontFamily: 'Regular-Family',
            boxShadow: darkmode.value ? '1px 1px 4px #636363' : '1px 1px 4px #7b8e8e'
          }}
        />
        <Button
          onClick={handleSubmitComment}
          disabled={submitting || !commentText.trim()}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none', marginTop: '1rem', fontFamily: 'Medium-Family' }}
          disableRipple
        >
          Post Comment
        </Button>
      </>
    )
}

export default SubtitleCommentField