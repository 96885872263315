import React from 'react'
import { Box, Typography } from '@mui/material'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo';

function DmcaPage() {
  const darkmode = useDarkMode( false )
  set_meta({
    is_landing: false
  })
  return (
    <Box
      sx={{
        margin: '0 auto',
        mt: '80px',
        maxWidth: '1100px',
        color: darkmode.value ? 'white' : 'initial'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '30px',
          fontWeight: '900',
          color: darkmode.value ? 'white' : '#205e60',
          fontFamily: 'Medium-Family',
          textAlign: 'center'
        }}
      >
        DMCA Policy
      </Typography>
      <Box
        sx={{
          mt: '80px',
          px: '2rem',
          fontFamily: 'Medium-Family',
          lineHeight: '2rem',
          fontSize: '18px'
        }}
      >
        SubSource's Commitment to Copyright:
        <br />
        SubSource is committed to respecting copyright laws, including the DMCA. We don't allow
        copyrighted or illegal content on our platform.
        <br />
        <br />
        Copyright Owner Takedown Procedure:
        <br />
        If you're a copyright owner and believe a subtitle file on SubSource infringes your rights,
        you can request its removal by following these steps:
        <br />
        <br />
        Submit a claim to support@subsource.net.
        <br />
        Ensure your claim is:
        <br />
        In English: Clear and concise communication is essential.
        <br />
        Professional Email: We require claims from company email addresses (not free services like
        Gmail).
        <br />
        Proof of Ownership: Provide documentation verifying your copyright or your authorization to
        act on the copyright holder's behalf.
        <br />
        Copyright Evidence: Show the content is legally copyrighted and yours.
        <br />
        Specific Details: List the infringing subtitle files by name and provide direct links to
        their pages (avoid search queries, categories, or media pages).
        <br />
        Valid Contact: Include a valid email address for communication.
        <br />
        <br />
        Important Note:
        <br />
        We can only remove the subtitle file from our website. We have no control over removing
        torrents or other content from other websites, even if they mention our name.
        <br />
        <br />
        <br />
      </Box>
      <Typography
        sx={{
          fontSize: '30px',
          fontWeight: 'bold',
          color: darkmode.value ? 'white' : 'initial',
          fontFamily: 'Medium-Family',
          textAlign: 'center'
        }}
      >
        Disclaimer
      </Typography>
      <Box
        sx={{
          mt: '80px',
          px: '2rem',
          fontFamily: 'Medium-Family',
          lineHeight: '2rem',
          fontSize: '18px'
        }}
      >
        SubSource lets you directly download legal subtitle files, translated by users for movies,
        from our secure servers. We do not offer any kind of video or audio files. We carefully
        review everything to ensure copyright compliance, and promptly remove any questionable
        files. While we can't guarantee the functionality or safety of downloads, we'll address any
        issues brought to our attention. Be Aware that SubSource content is for personal use only.
        Commercial use is strictly prohibited.
        <br />
        <br />
        Disclaimer of Endorsement:
        <br />
        Just mentioning a specific brand, product, service, or process (by its trademark,
        manufacturer, etc.) on this website doesn't mean we endorse, recommend, or favor it. It's
        for informational purposes only.
        <br />
        <br />
        The SubSource team isn't responsible for any consequences of using this website or its
        content. While you can't freely share these documents, feel free to use and distribute them
        for non-commercial educational or scientific purposes. Remember, copyright laws protect
        commercial use of our content.
        <br />
      </Box>
    </Box>
  )
}

export default DmcaPage
