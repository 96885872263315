import { Box, Link, List, ListItem, Paper, Skeleton, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode'

export function HorizontalMovieCardSkeleton({ posterHeight, posterAspectRatio = '3/4', hideIMDB }) {
  const darkmode = useDarkMode()

  return (
    <Paper
      elevation={4}
      sx={{
        width: '100%',
        display: 'flex',
        gap: { xs: '0.5rem', lg: '1rem' },
        borderRadius: '10px',
        padding: { xs: '5px', md: '15px' }
      }}
    >
      <Box sx={{width: '120px',height: '150px',}}>
        <Skeleton width={'120px'} height={'100%'} variant='rectangular' sx={{borderRadius: '7px'}} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-around'
        }}
      >
        {/* title */}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '0.5rem'
          }}
        >
          <Skeleton height={'100%'} width={'50%'} />
          {!hideIMDB ? <Skeleton width={'20px'} height={'20px'} /> : ''}
        </Box>
        {/*  */}
        <List sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '.2rem', md: '.5rem' } }}>
          {[1, 2, 3].map((sub) => (
            <ListItem disablePadding key={sub.id}>
              <Skeleton
                width={'80%'}
                height={'100%'}
                sx={{
                  // width: '100%',
                  textDecoration: 'none',
                  bgcolor: darkmode.value ? '#0082842b' : '#005d8414',
                  borderRadius: '5px',
                  p: '5px',
                  color: darkmode.value ? 'white' : '#21495F'
                }}
              />
            </ListItem>
          ))}
        </List>
        {/*  */}
        <Skeleton
          width={'50%'}
          sx={{
            fontFamily: 'Medium-Family',
            fontSize: '0.8rem',
            display: 'flex',
            alignItems: 'center',
            color: darkmode.value ? 'white' : 'initial'
          }}
        />
      </Box>
    </Paper>
  )
}
