import React from 'react'
import { Box, List, ListItem, Tooltip } from '@mui/material'
import { badge_definition } from '../../data/user'

export default function UserBadgeList({ badges, darkmode, disablePadding, smaller, center }) {
  return (
    <List
      sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '.25rem', justifyContent: center ? 'center' : '' }}
      disablePadding={disablePadding}
    >
      {badges.map((b) => {
        let badge = badge_definition.find((i) => i.badge === b)
        return (
          <ListItem key={b} disablePadding sx={{ width: 'auto' }}>
            <Tooltip
              PopperProps={{ sx: { whiteSpace: 'break-spaces', textAlign: 'center' } }}
              title={badge.description}
              arrow
              placement="top"
              classes={{ tooltip: 'badgeTooltip', arrow: 'badgeTooltip' }}
            >
              <Box
                className={`button-24 ${darkmode && 'dark-mode'}`}
                sx={{
                  '&:hover': { transform: smaller ? '' : 'scale(1.2)', zIndex: '100' },
                  width: smaller ? '30px' : '40px',
                  height: smaller ? '30px' : '40px',
                  m: smaller ? '0px' : '4px'
                }}
              >
                <img
                  alt={badge.description}
                  src={badge.image}
                  width={b === 'admin' ? (smaller ? '10px' : '20px') : smaller ? '20px' : '30px'}
                />
              </Box>
            </Tooltip>
          </ListItem>
        )
      })}
    </List>
  )
}
