import { Dialog } from '@mui/material'
import React from 'react'

export default function BadgeInfoDialog({ show, toggleShow }) {
  return (
    <Dialog
      open={show}
      onClose={toggleShow}
      sx={{ fontFamily: 'Medium-Family', marginBottom: '1rem' }}
      PaperProps={{ sx: { position: 'relative',flexDirection: 'row' } }}
    >
      <img
        src="/badges/ranks.jpg"
        alt="Ranking System"
        style={{ objectFit: 'contain', width: '100%', display: 'flex' }}
      />
    </Dialog>
  )
}
