import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { getMergeuUser, submitMergeUser } from '../../api/adminApi'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';

function Merge() {
  const [subsourceId, setSubsourceId] = useState('')
  const [subsceneId, setSubsceneId] = useState('')
  const [ forbidden, setForbidden ] = useState( false )
  set_meta({
    is_landing: false
  })
  const { refetch: submit, isFetching } = useQuery({
    queryKey: ['adminMergeSubmit'],
    queryFn: async () => {
      try {
        const _data = await submitMergeUser(subsceneId, subsourceId)
        if (_data.success) {
          dispachAlert('success', `Success`)
        } else {
          dispachAlert('error', `Error`)
        }
        return _data
      } catch (error) {
        dispachAlert('error', 'Error.')
      }
    },
    enabled: false
  })
  useQuery({
    queryKey: ['adminFixTitleGet'],
    queryFn: async () => {
      const _data = await getMergeuUser()
      if (!_data.success) {
        setForbidden(true)
      }
      return _data
    }
  })
  if (forbidden) {
    return (
      <>
        <Box>
          <Typography>Forbidden.</Typography>
        </Box>
      </>
    )
  }
  return (
    <Paper
      elevation={4}
      sx={{ borderRadius: '16px', width: '100%', maxWidth: { lg: '50%' }, mx: 'auto' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '24px', fontWeight: 'bold' }}>
          Merge Account
        </Typography>
        <TextField
          value={subsourceId}
          label={'Subsource ID'}
          onChange={(e) => setSubsourceId(e.target.value)}
        />
        <TextField
          value={subsceneId}
          label={'Subscene ID'}
          onChange={(e) => setSubsceneId(e.target.value)}
        />
        <Button
          variant="outlined"
          disabled={isFetching || !subsceneId || !subsourceId}
          onClick={(e) => {
            submit()
          }}
        >
          Submit
        </Button>
      </Box>
    </Paper>
  )
}

export default Merge
