import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { userBadges, } from '../../../api/adminApi';
import { dispachAlert } from '../../../utils/dispachers';
function Badges({ user, isLoading }) {
    const [badges, setBadges] = useState([]);
    const queryClient = useQueryClient();
    const { refetch: submitBadges, isFetching: isSubmitting } = useQuery({
        queryKey: ["adminBadges"],
        queryFn: async () => {
            try {
                const result = await userBadges(user.id, badges);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const handleClickRestrict = () => {
        submitBadges();
    }
    useEffect(() => {
        setBadges(user.badges)
    }, [user])
    const handleChange = (e) => {
        if (!badges.includes(e.target.value)) {
            setBadges(p => p.concat([e.target.value]))
        } else {
            setBadges(p => p.filter(x => x !== e.target.value))
        }
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '20px' }}>Badges </Typography>
        <Box sx={{ display: 'flex' }}>
          <FormGroup sx={{ display: 'flex', gap: '1rem', flexFlow: 'wrap', flex: 1 }}>
            <FormControlLabel
              value={'admin'}
              control={<Checkbox checked={badges.includes('admin')} onChange={handleChange} />}
              label="Admin"
            />
            <FormControlLabel
              value={'up1'}
              control={<Checkbox checked={badges.includes('up1')} onChange={handleChange} />}
              label="Up-1"
            />
            <FormControlLabel
              value={'up2'}
              control={<Checkbox checked={badges.includes('up2')} onChange={handleChange} />}
              label="Up-2"
            />
            <FormControlLabel
              value={'up3'}
              control={<Checkbox checked={badges.includes('up3')} onChange={handleChange} />}
              label="Up-3"
            />
            <FormControlLabel
              value={'tr1'}
              control={<Checkbox checked={badges.includes('tr1')} onChange={handleChange} />}
              label="Tr-1"
            />
            <FormControlLabel
              value={'tr2'}
              control={<Checkbox checked={badges.includes('tr2')} onChange={handleChange} />}
              label="Tr-2"
            />
            <FormControlLabel
              value={'tr3'}
              control={<Checkbox checked={badges.includes('tr3')} onChange={handleChange} />}
              label="Tr-3"
            />
            <FormControlLabel
              value={'do1'}
              control={<Checkbox checked={badges.includes('do1')} onChange={handleChange} />}
              label="Do-1"
            />
            <FormControlLabel
              value={'do2'}
              control={<Checkbox checked={badges.includes('do2')} onChange={handleChange} />}
              label="Do-2"
            />
            <FormControlLabel
              value={'do3'}
              control={<Checkbox checked={badges.includes('do3')} onChange={handleChange} />}
              label="Do-3"
            />
            <FormControlLabel
              value={'do4'}
              control={<Checkbox checked={badges.includes('do4')} onChange={handleChange} />}
              label="Do-4"
            />
            <FormControlLabel
              value={'vip'}
              control={<Checkbox checked={badges.includes('vip')} onChange={handleChange} />}
              label="VIP"
            />
          </FormGroup>
        </Box>
        <Button
          variant="outlined"
          disableRipple
          color="primary"
          disabled={isLoading || isSubmitting}
          sx={{ textTransform: 'none' }}
          onClick={handleClickRestrict}
        >
          Apply
        </Button>
      </Box>
    )
}

export default Badges