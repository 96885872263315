import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { changeSubsceneRatings } from '../../../../api/adminApi';
import { dispachAlert } from '../../../../utils/dispachers';
function SubsceneRatings({ user, isLoading }) {
    const [ratings, setRatings] = useState({ own: user.subscene_ratings || 0, given: user.subscene_ratings_given || 0 });
    const queryClient = useQueryClient();
    const { isFetching, refetch } = useQuery({
        queryKey: ['adminUpdateSubsceneStatus'],
        queryFn: async () => {
            try {
                const result = await changeSubsceneRatings(user.id, ratings);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    });
    useEffect(() => {
        setRatings({ own: user.subscene_ratings || 0, given: user.subscene_ratings_given || 0 })
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Box sx={{ display: 'flex', gap: "1rem" }}>
                <Box sx={{ flex: 1, gap: "1rem", display: "flex" }}>
                    <TextField type='number' size='small' value={ratings.given} label="Subscene Ratings Given" onChange={(e) => e.target.value >= 0 && setRatings(r => ({ ...r, given: e.target.value }))} />
                    <TextField type='number' size='small' value={ratings.own} label="Subscene Ratings" onChange={(e) => e.target.value >= 0 && setRatings(r => ({ ...r, own: e.target.value }))} />
                </Box>
                <Button sx={{ justifySelf: "flex-end" }} variant='outlined' disabled={(user.subscene_ratings === ratings.own && user.subscene_ratings_given === ratings.given) || isFetching || isLoading} onClick={() => { if (!isFetching) refetch() }}><SaveIcon /></Button>
            </Box>
        </Box>
    )
}

export default SubsceneRatings