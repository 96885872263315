import React from 'react'
import './SeriesSeasons.css'
import { Box, Link, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
function SeriesSeasons2({ seasons, handleSeasonClick }) {
    const darkmode = useDarkMode();
    const location = useLocation();
    return (
      <Box
        className={'scrollCustom'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start',
          flexFlow: 'wrap',
          justifyItems: 'center',
          px: {xs: '.5rem', md: '2rem'},
          justifyContent: 'center',
          height: {xs: '65vh', lg: '750px'},
          overflowX: 'hidden',
          overflowY: 'auto',
          flexGrow: 1,
          width: {md: '55%'}
        }}
      >
        {seasons.map((season, index) =>
          season.season === 0 ? (
            ''
          ) : (
            <Link
              href={location.pathname + '/season-' + season.season}
              sx={{ display: 'contents', textDecoration: 'none', color: 'black' }}
            >
              <Box
                onClick={(e) => {
                  if (!e.ctrlKey) {
                    e.preventDefault()
                    handleSeasonClick(season.season)
                  }
                }}
                key={index}
                sx={{
                  p: '0.5rem',
                  minWidth: '100%',
                  display: 'inline-flex',
                  marginBottom: '0.5rem',
                  height: 'max-content',
                  backgroundColor: darkmode.value ? '#44474a' : 'lavender',
                  borderRadius: '7px',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#deb88778' },
                  transition: '0.1s all ease-in-out'
                }}
              >
                <Box
                  component={'img'}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = `${process.env.REACT_APP_API_URL}/notfound.jpg`
                  }}
                  src={season.img}
                  alt={season.title + ' - Season ' + season.season}
                  width={'100px'}
                  height={'150px'}
                  sx={{ borderRadius: '7px', marginRight: '0.5rem' }}
                />
                <Box>
                  <Typography
                    variant="h5"
                    fontFamily={'Medium-Family'}
                    fontWeight={'bold'}
                    color={darkmode.value ? 'white' : 'initial'}
                  >
                    Season {season.season}
                  </Typography>
                  <Typography
                    variant="h7"
                    fontFamily={'Medium-Family'}
                    color={darkmode.value ? 'white' : 'initial'}
                  >
                    {season.subCount} Subtitles
                  </Typography>
                </Box>
              </Box>
            </Link>
          )
        )}
        {seasons
          .filter((x) => x.season === 0 && x.subCount > 0)
          .map((season, index) => (
            <Box
              onClick={(e) => {
                if (!e.ctrlKey) {
                  e.preventDefault()
                  handleSeasonClick(season.season)
                }
              }}
              key={index}
              sx={{
                p: '0.5rem',
                minWidth: '100%',
                display: 'inline-flex',
                marginBottom: '0.5rem',
                height: 'max-content',
                backgroundColor: darkmode.value ? '#44474a' : 'lavender',
                borderRadius: '7px',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#deb88778' },
                transition: '0.1s all ease-in-out'
              }}
            >
              <Box
                component={'img'}
                src={season.img}
                alt={season.title + ' - Season ' + season.season}
                width={'95px'}
                height={''}
                sx={{ borderRadius: '7px', marginRight: '0.5rem' }}
              />
              <Box>
                <Typography
                  variant="h5"
                  fontFamily={'Medium-Family'}
                  fontWeight={'bold'}
                  color={darkmode.value ? 'white' : 'initial'}
                >
                  Extras (Complete Series)
                </Typography>
                <Typography
                  variant="h7"
                  fontFamily={'Medium-Family'}
                  color={darkmode.value ? 'white' : 'initial'}
                >
                  {season.subCount} Subtitles
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    )
}

export default SeriesSeasons2