import React, { useContext, useEffect, useState } from 'react'
import MailIcon from '@mui/icons-material/Mail'
import { ThreeDots } from 'react-loading-icons'
import { Menu, Person, Subtitles, Login, AssignmentInd, Dashboard } from '@mui/icons-material'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import HeaderSearch from './Search'
import DarkModeToggleBtn from '../DarkModeToggleBtn'
import LogoutIcon from '@mui/icons-material/Logout'
import Box from '@mui/material/Box'
import {
  Badge,
  Button,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUserContext } from '../../Contexts/userContext'
import useDarkMode from 'use-dark-mode'
import { CurrentMovieContext } from '../../Contexts/currentMovieContext'
import { useSmallWidth } from '../../hooks/useSmallWidth'
import { History, FileUpload } from '@mui/icons-material'
import { dispachAlert } from '../../utils/dispachers'

/**
 * um : user unread messages count
 * @param {*} param0
 * @returns
 */
export default function UserMenu({ userNotifs, handleClickOpen, handleClickOpenSignUp }) {
  //
  const darkmode = useDarkMode(false)
  const { currentMovieCtx } = useContext(CurrentMovieContext)
  const navigate = useNavigate()
  const user = useUserContext()
  const location = useLocation()
  //
  const isSmall = useSmallWidth()
  const [open, setOpen] = React.useState(false)
  const navItems = [
    {
      name: 'Recent Subtitles',
      link: '/recent',
      icon: <History fontSize="small" sx={{ color: darkmode.value ? 'white' : 'black' }} />
    },
    {
      name: 'Upload',
      link: '/upload',
      icon: <FileUpload fontSize="small" sx={{ color: darkmode.value ? 'white' : 'black' }} />
    }
  ]
  //
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }
  const getUrlHrefs = (link) => {
    if (link === '/upload') {
      if (user) {
        const currentLoc = location.pathname
        if (currentLoc.startsWith('/subtitle') && currentMovieCtx) {
          return link + '/' + currentMovieCtx || ''
        } else if (currentLoc.startsWith('/subtitles') && currentMovieCtx) {
          return link + '/' + currentMovieCtx || ''
        } else {
          return link
        }
      } else {
        return '/'
      }
    } else {
      return link
    }
  }
  const handleNavigation = (link) => {
    if (link === '/upload') {
      if (user) {
        const currentLoc = location.pathname
        if (currentLoc.startsWith('/subtitle') && currentMovieCtx) {
          navigate(link + '/' + currentMovieCtx || '')
        } else if (currentLoc.startsWith('/subtitles') && currentMovieCtx) {
          navigate(link + '/' + currentMovieCtx || '')
        } else {
          navigate(link)
        }
      } else {
        dispachAlert('error', 'You need to be logged in.')
      }
    } else {
      navigate(link)
    }
  }
  //
  useEffect(() => {
    setOpen(false)
  }, [isSmall])
  return (
    <>
      {/* user menu desktop */}
      {user && !isSmall ? (
        <>
          <Button
            href={'/u/' + user.id}
            sx={{
              '&:hover': { backgroundColor: 'inherit' },
              color: darkmode.value ? 'white' : 'black',
              fontWeight: '600',
              transition: '0ms all ease-in-out',
              textTransform: 'none',
              fontSize: '1rem'
            }}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/u/' + user.id)
              }
            }}
          >
            {user.username}
          </Button>
          <Link
            href="/dashboard/mysubsource"
            sx={{ display: 'contents' }}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/dashboard/mysubsource')
              }
            }}
          >
            <Chip
              label={`My Subtitles ${userNotifs?.subtitles ?? ''}`}
              sx={{
                '&:active': {
                  boxShadow:
                    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                },
                '& .MuiChip-label': {
                  fontWeight: '600',
                  fontSize: '1rem',
                  color: darkmode.value ? 'white' : 'black'
                },
                boxShadow:
                  'inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#0080808f',
                '&:hover': { backgroundColor: '#0080808f' }
              }}
            ></Chip>
          </Link>
          <IconButton
            href={'/dashboard/messages'}
            sx={{ '&:hover': { background: 'none' } }}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/dashboard/messages')
              }
            }}
          >
            <Badge badgeContent={userNotifs?.unread_messages || 0} color="primary">
              <MailIcon color="action" sx={{ color: darkmode.value ? 'white' : 'initial' }} />
            </Badge>
          </IconButton>
          <IconButton
            href={'/dashboard/tickets'}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/dashboard/tickets')
              }
            }}
            sx={{ '&:hover': { background: 'none' } }}
          >
            <Badge badgeContent={userNotifs?.new_tickets || 0} color="primary">
              <HeadsetMicOutlinedIcon
                color="action"
                sx={{ color: darkmode.value ? 'white' : 'initial' }}
              />
            </Badge>
          </IconButton>
          <IconButton
            sx={{ '&:hover': { background: 'none' } }}
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_API_URL}/auth/logout`
            }}
          >
            <LogoutIcon sx={{ color: '#DF2D2D' }} />
          </IconButton>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{
              mx: '.5rem',
              borderColor: darkmode.value ? 'white' : 'initial',
              opacity: '0.6'
            }}
          />
        </>
      ) : (
        ''
      )}
      {/* guest menu desktop */}
      {!user ? (
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: '.15rem' }}>
          {/* login */}
          <Button
            key={'Login'}
            sx={{
              '&:hover': { backgroundColor: 'inherit' },
              color: darkmode.value ? 'white' : 'black',
              fontWeight: '600',
              transition: '0ms all ease-in-out',
              fontFamily: 'Regular-Family',
              textTransform: 'none',
              fontSize: '1rem',
              p: 0
            }}
            onClick={handleClickOpen}
          >
            {'Login'}
          </Button>
          <Button
            key={'Register'}
            sx={{
              '&:hover': { backgroundColor: 'inherit' },
              color: darkmode.value ? 'white' : 'black',
              fontWeight: '600',
              transition: '0ms all ease-in-out',
              fontFamily: 'Regular-Family',
              textTransform: 'none',
              fontSize: '1rem',
              p: 0
            }}
            onClick={handleClickOpenSignUp}
          >
            {'Register'}
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{
              mx: '.5rem',
              height: '30px',
              borderColor: darkmode.value ? 'white' : 'initial',
              opacity: '0.6'
            }}
          />
        </Box>
      ) : (
        ''
      )}
      <HeaderSearch darkmode={darkmode.value} />
      {/* links */}
      <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
        {navItems.map((item) => (
          <Button
            href={getUrlHrefs(item.link)}
            key={item.name}
            sx={{
              '&:hover': { backgroundColor: 'inherit' },
              color: darkmode.value ? 'white' : 'black',
              whiteSpace: 'nowrap',
              fontWeight: '600',
              transition: '0ms all ease-in-out',
              fontFamily: 'Regular-Family',
              textTransform: 'none',
              fontSize: '1rem'
            }}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                handleNavigation(item.link)
              }
            }}
          >
            {item.name}
          </Button>
        ))}
      </Box>
      <DarkModeToggleBtn />

      {isSmall ? (
        <IconButton
          sx={{ display: { sm: user ? '' : 'none' } }}
          aria-label="user-menu"
          onClick={toggleDrawer(!open)}
        >
          <Menu />
        </IconButton>
      ) : (
        ''
      )}
      <Drawer anchor={'top'} open={open} onClose={toggleDrawer(false)} sx={{ zIndex: 1199 }}>
        <Paper sx={{ pt: '60px' }}>
          <MenuList>
            {user ? (
              <>
                {/* profile */}
                <MenuItem
                  divider
                  onClick={(e) => {
                    if (!e.ctrlKey) {
                      setOpen(false)
                      e.preventDefault()
                      navigate('/u/' + user.id)
                    }
                  }}
                >
                  <ListItemIcon>
                    <Person fontSize="small" sx={{ color: darkmode.value ? 'white' : 'black' }} />
                  </ListItemIcon>
                  <ListItemText>User Profile ({user.username})</ListItemText>
                </MenuItem>
                {/* My Subsource */}
                <MenuItem
                  divider
                  onClick={(e) => {
                    if (!e.ctrlKey) {
                      setOpen(false)
                      e.preventDefault()
                      navigate('/dashboard/mysubsource')
                    }
                  }}
                >
                  <ListItemIcon>
                    <Subtitles
                      fontSize="small"
                      sx={{ color: darkmode.value ? 'white' : 'black' }}
                    />
                  </ListItemIcon>
                  <ListItemText>My Subsource</ListItemText>
                </MenuItem>
                {/* messages */}
                <MenuItem
                  divider
                  onClick={(e) => {
                    if (!e.ctrlKey) {
                      setOpen(false)
                      e.preventDefault()
                      navigate('/dashboard/messages')
                    }
                  }}
                >
                  <ListItemIcon>
                    {/* <MailIcon color="action" sx={{ color: darkmode.value ? 'white' : 'black' }} /> */}
                    <Badge badgeContent={userNotifs?.unread_messages || 0} color="primary">
                      <MailIcon
                        color="action"
                        sx={{ color: darkmode.value ? 'white' : 'initial' }}
                      />
                    </Badge>
                    {/* <Chat fontSize="small" /> */}
                  </ListItemIcon>
                  <ListItemText>Messages</ListItemText>
                </MenuItem>
                {/* tickets */}
                <MenuItem
                  divider
                  onClick={(e) => {
                    if (!e.ctrlKey) {
                      setOpen(false)
                      e.preventDefault()
                      navigate('/dashboard/tickets')
                    }
                  }}
                >
                  <ListItemIcon>
                    <Badge badgeContent={userNotifs?.new_tickets || 0} color="primary">
                      <HeadsetMicOutlinedIcon
                        color="action"
                        sx={{ color: darkmode.value ? 'white' : 'initial' }}
                      />
                    </Badge>
                    {/* <Headphones fontSize="small" /> */}
                  </ListItemIcon>
                  <ListItemText>Tickets</ListItemText>
                </MenuItem>
                {/* admin */}
                {user.privileges.length ? (
                  <MenuItem
                    divider
                    onClick={(e) => {
                      if (!e.ctrlKey) {
                        setOpen(false)
                        e.preventDefault()
                        navigate('/admin')
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Dashboard
                        color="action"
                        sx={{ color: darkmode.value ? 'white' : 'initial' }}
                      />
                      {/* <Headphones fontSize="small" /> */}
                    </ListItemIcon>
                    <ListItemText>Admin Panel</ListItemText>
                  </MenuItem>
                ) : (
                  ''
                )}
              </>
            ) : undefined}
            {/* nav items */}
            {navItems.map((item, index) => (
              <>
                <MenuItem
                  divider
                  href={getUrlHrefs(item.link)}
                  key={item.name}
                  sx={{ display: { sm: 'none' } }}
                  onClick={(e) => {
                    if (!e.ctrlKey) {
                      setOpen(false)
                      e.preventDefault()
                      handleNavigation(item.link)
                    }
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.name}</ListItemText>
                </MenuItem>
              </>
            ))}
            {user ? (
              <>
                {/* logout */}
                <MenuItem
                  onClick={() => {
                    setOpen(false)
                    window.location.href = `${process.env.REACT_APP_API_URL}/auth/logout`
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: '#DF2D2D' }} />
                    {/* <Logout fontSize="small" /> */}
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  divider
                  sx={{ display: { sm: 'none' } }}
                  onClick={(e) => {
                    setOpen(false)
                    handleClickOpen(e)
                  }}
                >
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Login</ListItemText>
                </MenuItem>
                <MenuItem
                  sx={{ display: { sm: 'none' } }}
                  onClick={(e) => {
                    setOpen(false)
                    handleClickOpenSignUp(e)
                  }}
                >
                  <ListItemIcon>
                    <AssignmentInd fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Register</ListItemText>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Paper>
      </Drawer>
    </>
  )
}
