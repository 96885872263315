import React, { useEffect } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import { Container } from '@mui/material'
import { set_meta } from '../utils/seo'

function Dashboard({ currentTab }) {
  const [currTab, setCurrTab] = React.useState(currentTab)
  const location = useLocation()
  const navigate = useNavigate()
  const darkmode = useDarkMode()
  set_meta({
    is_landing: false
  })
  const handleChangeTab = (event, newValue) => {
    const tabs = ['tickets', 'mysubsource', 'settings', 'messages', 'downloads']
    setCurrTab(newValue)
    navigate('/dashboard/' + tabs[newValue])
  }
  useEffect(() => {
    const tabs = ['tickets', 'mysubsource', 'settings', 'messages', 'downloads']
    location.pathname.split('/dashboard/')[1] !== undefined &&
      setCurrTab(tabs.indexOf(location.pathname.split('/dashboard/')[1]))
  }, [location])

  const tabStyle = {
    textTransform: 'none',
    fontFamily: 'Bold-Family',
    fontSize: { xs: '.7rem', sm: '.9rem', lg: '1.15rem' },
    whiteSpace: 'nowrap',
    px: { xs: '.25rem', sm: '.5rem', md: '1rem' },
    minWidth: { xs: 'auto', lg: '20%' }
  }
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: darkmode.value ? 'rgb(18,18,18)' : 'white',
          paddingTop: '1rem',
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0)'
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currTab} onChange={handleChangeTab} centered>
            <Tab label="My SubSource" value={1} sx={tabStyle} />
            <Tab label="Messages" value={3} sx={tabStyle} />
            <Tab label="Tickets" value={0} sx={tabStyle} />
            <Tab label="My Downloads" value={4} sx={tabStyle} />
            <Tab label="User Settings" value={2} sx={tabStyle} />
          </Tabs>
        </Box>
        <Box
          sx={{
            background: darkmode.value
              ? 'linear-gradient(180deg, rgb(18 18 18) 0%, rgba(18, 18, 18, 1) 14%, rgb(6 47 47) 100%)'
              : 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 14%, rgba(152,200,200,1) 100%)'
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </>
  )
}

export default Dashboard
