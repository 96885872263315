import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ReactTimeAgo from 'react-time-ago'
import './AdminPanel.css'
import { set_meta } from '../../utils/seo'
import { dispachAlert } from '../../utils/dispachers'
import { subjects } from '../../data/tickets'
import useDarkMode from 'use-dark-mode'
const languages = ['All', 'Farsi/Persian', 'Arabic', 'Bengali', 'Others']
const formattedLanguages = {
  All: 'All',
  'Farsi/Persian': 'farsi_persian',
  Arabic: 'arabic',
  Bengali: 'bengali',
  Others: 'others'
}
const statuses = ['All', 'Open', 'Close']
const formatted_statuses = {
  All: 'All',
  Open: 0,
  Close: 1
}
function Tickets() {
  const darkmode = useDarkMode()
  const [tickets, setTickets] = useState([])
  const [selected, setSelected] = useState(undefined)
  const [forbidden, setForbidden] = useState(false)
  const [subjectFilter, setSubjectFilter] = useState(0)
  const [languageFilter, setLanguageFilter] = useState(0)
  const [statusFilter, setStatusFilter] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const replyText = useRef()
  const tnRef = useRef()
  const uiRef = useRef()
  set_meta({
    is_landing: false
  })
  const onSubjectFilterChange = (e) => {
    setSubjectFilter(e.target.value)
  }
  const onLanguageFilterChange = (e) => {
    setLanguageFilter(e.target.value)
  }
  const onStatusFilterChange = (e) => {
    setStatusFilter(e.target.value)
  }
  const onTicketClick = (id) => {
    if (!selected) {
      if (!submitting) {
        setSubmitting(true)
        axios
          .post(
            process.env.REACT_APP_API_URL + '/api/admin/tickets/select',
            { id: id },
            { withCredentials: true }
          )
          .then((res) => {
            if (res.data.success) {
              setSelected( tickets.find( ( x ) => x.ticketNumber === id ) )
              let n = tickets.map((ticket) => {
                let res = { ...ticket }
                if (ticket.ticketNumber === id) {
                  res.ticketMessages = res.ticketMessages.map((message) => {
                    if (message.from.toLowerCase() === 'target')
                      return { ...message, isNew: false }
                    return message
                  })
                }

                return res
              })
              setTickets(n)
            } else {
              dispachAlert('error', res.data.errMsg)
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              dispachAlert('error', 'Forbidden.')
            } else {
              dispachAlert('error', 'Server Error.')
            }
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    } else {
      dispachAlert('error', 'You are already processing a ticket.')
    }
  }
  const handleSubmitReply = (id) => {
    if (replyText.current.value.length > 0) {
      if (!selected) {
        return
      }
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/tickets/update',
          { id: id, message: replyText.current.value },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            replyText.current.value = ''
            handleFilter()
          } else {
            dispachAlert('error', res.data.errMsg)
          }
        })
        .catch((err) => {
          dispachAlert('error', 'Error.')
          setSubmitting(false)
        })
    } else {
      dispachAlert('error', 'Reply cannot be empty')
    }
  }
  const handleFilter = () => {
    setSubmitting(true)
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/tickets/get', {
        params: {
          subject: ['All'].concat(subjects)[subjectFilter],
          language: formattedLanguages[languages[languageFilter]],
          status: formatted_statuses[statuses[statusFilter]],
          tn: tnRef.current.value,
          ui: uiRef.current.value
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          setSelected(res.data.currentlyResolving)
          setTickets(res.data.tickets)
          setSubmitting(false)
        }
      })
      .catch((err) => {
        console.log(err.response.status)
        setSubmitting(false)
        setForbidden(err.response.status === 403)
      })
  }
  const handleDeselectTicket = (id) => {
    if (!selected) {
      return
    }
    setSubmitting(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/admin/tickets/deselect',
        { id: id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          replyText.current.value = ''
          setSelected(undefined)
          setSubmitting(false)
        }
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
        setSubmitting(false)
      })
  }
  const handleCloseTicket = (id) => {
    if (!selected) {
      return
    }
    setSubmitting(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/admin/tickets/close',
        { id: id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          handleFilter()
        } else {
          dispachAlert('error', res.data.errMsg)
        }
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
        setSubmitting(false)
      })
  }
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/tickets/get', {
        params: {
          subject: ['All'].concat(subjects)[subjectFilter],
          language: formattedLanguages[languages[languageFilter]],
          status: statuses[statusFilter]
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          setTickets(res.data.tickets)
          setSelected(res.data.currentlyResolving)
        }
      })
      .catch((err) => {
        console.log(err.response.status)
        setForbidden(err.response.status === 403)
      })
  }, [])

  return forbidden ? (
    <>Forbidden</>
  ) : (
    <Paper elevation={4} sx={{ borderRadius: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        {/* filters */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            <Box sx={{ width: '250px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Subject/Issue</Typography>
              <Select
                value={subjectFilter}
                onChange={onSubjectFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                {['All'].concat(subjects).map((item, index) => (
                  <MenuItem value={index}>{item}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Language</Typography>
              <Select
                value={languageFilter}
                onChange={onLanguageFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Farsi/Persian</MenuItem>
                <MenuItem value={2}>Arabic</MenuItem>
                <MenuItem value={3}>Bengali</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '100px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Status</Typography>
              <Select
                value={statusFilter}
                onChange={onStatusFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Open</MenuItem>
                <MenuItem value={2}>Closed</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Ticket Number</Typography>
              <TextField fullWidth size="small" disabled={submitting} inputRef={tnRef}></TextField>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>User ID</Typography>
              <TextField fullWidth size="small" disabled={submitting} inputRef={uiRef}></TextField>
            </Box>
          </Box>
          <Box sx={{ alignSelf: 'center', width: '200px' }}>
            <Button
              sx={{ textTransform: 'none' }}
              variant="outlined"
              fullWidth
              onClick={handleFilter}
              disabled={submitting}
            >
              Filter
            </Button>
          </Box>
        </Box>
        {/* content */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: '1rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              maxWidth: '400px',
              flexDirection: 'column'
            }}
          >
            {tickets.length === 0 ? (
              <Box sx={{ mt: '250px' }}>
                <Typography fontSize={'25px'} fontWeight="bold" textAlign={'center'}>
                  No Tickets Found
                </Typography>
              </Box>
            ) : (
              <List sx={{ maxHeight: '600px', overflow: 'auto' }}>
                {tickets
                  ?.sort((i) => (i.ticketStatus === 0 ? -1 : 1))
                  .map((ticket) => (
                    <ListItem key={ticket.ticketNumber} disablePadding>
                      <Paper
                        onClick={() => {
                          onTicketClick(ticket.ticketNumber)
                        }}
                        sx={{
                          m: '0.5rem',
                          flexGrow: 1,
                          borderRadius: '8px',
                          display: 'flex',
                          padding: '15px 15px',
                          alignItems: 'center',
                          gap: '7px',
                          cursor: 'pointer',
                          bgcolor:
                            selected?.ticketNumber === ticket.ticketNumber ? 'lightgrey' : 'white'
                        }}
                        elevation={3}
                      >
                        <Box>
                          {ticket.ticketStatus === 0 ? (
                            <HourglassFullIcon sx={{ color: '#ffd233' }} />
                          ) : (
                            <CheckCircleRoundedIcon sx={{ color: 'darkgreen' }} />
                          )}
                        </Box>
                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 'Bold',
                              color: 'black'
                            }}
                          >
                            {ticket.ticketSubject}
                          </Typography>
                          <Typography sx={{ fontSize: '14px', color: 'rgb(0,0,0,0.65)' }}>
                            #{ticket.ticketNumber}
                          </Typography>
                          <Typography sx={{ fontSize: '14px', color: 'rgb(0,0,0,0.65)' }}>
                            Lang: {ticket.ticketLanguage || 'N/A'}
                          </Typography>
                        </Box>
                        <Badge
                          badgeContent={
                            ticket?.ticketMessages?.filter(
                              (i) => i.from?.toLowerCase() === 'target' && i?.isNew
                            )?.length
                          }
                          color="primary"
                        ></Badge>
                      </Paper>
                    </ListItem>
                  ))}
              </List>
            )}
          </Box>
          <Divider orientation="vertical" sx={{ borderRight: '1px solid #0000002b' }} flexItem />
          <Box sx={{ minHeight: '600px', width: '100%', display: 'flex' }}>
            {!selected ? (
              <>
                <Typography fontSize={'30px'} sx={{ alignSelf: 'start', fontWeight: 'Bold' }}>
                  Select a ticket.
                </Typography>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Box sx={{ mb: '2rem', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography fontSize={'25px'} fontWeight={'bold'}>
                      {selected.ticketSubject} #{selected.ticketNumber}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                      <Button
                        disabled={selected.ticketStatus !== 0 || submitting}
                        variant="outlined"
                        color="error"
                        disableRipple
                        onClick={() => {
                          handleCloseTicket(selected.ticketNumber)
                        }}
                      >
                        Close Ticket
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        disableRipple
                        disabled={submitting}
                        onClick={() => {
                          handleDeselectTicket(selected.ticketNumber)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    {selected.ticketMessages.map((msg) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '0.5rem',
                            backgroundColor: 'rgb(0 128 128 / 15%)',
                            padding: '0.35rem'
                          }}
                        >
                          <Avatar src={msg.avatar} sx={{ width: '35px', height: '35px' }}>
                            {msg.fromUsername.slice(0, 1)}
                          </Avatar>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Link
                              href={'/u/' + selected.ticketFromId}
                              target="_blank"
                              sx={{ textDecoration: 'none' }}
                            >
                              <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                                {msg.fromUsername}
                              </Typography>
                            </Link>
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '11px',
                                color: 'rgb(0,0,0,0.65)'
                              }}
                            >
                              <ReactTimeAgo date={new Date(msg.date)} />
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ mx: '3rem', py: '1rem' }}>
                          <Typography
                            sx={{ whiteSpace: 'pre-line', fontFamily: 'Regular-Family' }}
                            dir="auto"
                          >
                            {msg.text}
                          </Typography>
                        </Box>
                      </>
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '25px' }}>
                      Reply
                    </Typography>
                    <TextField
                      placeholder="Type your message here."
                      inputProps={{ dir: 'auto' }}
                      multiline
                      InputProps={{
                        sx: { fontFamily: 'Regular-Family' },
                        rows: 4,
                        inputComponent: 'textarea'
                      }}
                      inputRef={replyText}
                      sx={{ height: '100%' }}
                    ></TextField>
                    <Button
                      variant="contained"
                      color="success"
                      disableRipple
                      sx={{ textTransform: 'none' }}
                      onClick={() => {
                        handleSubmitReply(selected.ticketNumber)
                      }}
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Tickets
