import { Box, Button, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ReleaseInputField from './ReleaseInputField'
import AddBoxIcon from '@mui/icons-material/AddBox';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import useDarkMode from 'use-dark-mode';
function ReleaseInput({ rts, setRts, disabled }) {
    const darkmode = useDarkMode(false);
    const onBtnClick = () => {
        console.log(rts.length);
        if (rts.filter(x => x.display).length < 10) {
            setRts(p => p.concat([{ id: Math.max(...rts.map(x => x.id)) + 1, value: "", display: true }]))
        }
    }

    return (
        <Box sx={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0.5rem", }}>
                <Typography sx={{ fontWeight: "bold", color: darkmode.value ? "white" : "initial" }}>Release Info</Typography>
                <Tooltip title='Copy 2 or more releases and easily paste them here!' placement='top' arrow>
                    <ErrorOutlineSharpIcon sx={{ width: "20px", height: "20px", ml: "0.4rem", color: darkmode.value ? "white" : "initial" }} />
                </Tooltip>
            </Box>


            <Box sx={{ minWidth: 120, display: "flex", flexDirection: "column" }}>
                {rts.map((rt, index) => (
                    <ReleaseInputField disabled={disabled} key={index} setRts={setRts} val={rt.value} rts={rts} id={rt.id} display={rt.display} />
                ))}
                <Button disabled={disabled} variant='text' disableRipple sx={{ alignSelf: "end", textTransform: "none", "&:hover": { backgroundColor: "initial" }, display: 'flex', justifyContent: "flex-end" }} onClick={onBtnClick}><AddBoxIcon sx={{ color: darkmode.value ? "white" : "rgba(0, 0, 0, 0.87)" }} /></Button>
            </Box>
        </Box>
    )
}

export default ReleaseInput