import { Routes, Route, Navigate } from 'react-router-dom'
import headerImage from './imgs/Headerrrrr.webp'
import footerImage from './imgs/Footerrrrr.webp'
import headerImageDark from './imgs/Headerrrrr_dark.png'
import footerImageDark from './imgs/Footerrrrr_dark.png'
import { Suspense, lazy, useState } from 'react'
import { HeaderContext } from './Contexts/userContext'
import { Box, ThemeProvider, createTheme } from '@mui/material'
import { CurrentMovieContext } from './Contexts/currentMovieContext'
import { UserApi } from './api/userApi'
import { useQuery } from '@tanstack/react-query'
import useDarkMode from 'use-dark-mode'
//
import MainLayout from './layouts/MainLayout'
import UserDashboard from './layouts/UserDashboardLayout'
import AdminLayout from './layouts/AdminLayout'
// 
import MoviePage from './Pages/MoviePage2'
import SubtitlePage from './Pages/SubtitlePage'
import RatingsPage from './Pages/RatingsPage'
import UploadPage from './Pages/UploadPage'
import UploadPage2 from './Pages/UploadPage2'
import AddMoviePage from './Pages/AddMoviePage'
import BadUrl from './Pages/BadUrl'
import EditSubtitlePage from './Pages/EditSubtitlePage'
//import ResetPassword from './Pages/ResetPassword';
import AboutPage from './Pages/AboutPage'
import ContactPage from './Pages/ContactPage'
import TermsPage from './Pages/TermsPage'
import DmcaPage from './Pages/DmcaPage'
import HomePage from './Pages/HomePage'
// 
import UserProfilePage from './Pages/u/UserProfilePage'
import AllSubsPage from './Pages/u/AllSubsPage'
import UserRatings from './Pages/u/UserRatings'
import UserRatingsGiven from './Pages/u/UserRatingsGiven'
//
import AdminDashboard from './Pages/admin/Dashboard'
import AdminNews from './Pages/admin/News'
import AdminHomePage from './Pages/admin/HomePage'
import AdminUsers from './Pages/admin/Users'
import AdminReports from './Pages/admin/Reports'
import AdminTickets from './Pages/admin/Tickets'
import AdminLatestUploads from './Pages/admin/LatestUploads'
import AdminMovies from './Pages/admin/Movies/Movies'
import AdminFixSub from './Pages/admin/FixSub'
import AdminMerge from './Pages/admin/Merge'
//
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage'
import { Grid } from 'react-loading-icons'
import LatestsPage from './Pages/LatestsPage2'
import EditSubtitleAdminPage from './Pages/EditSubtitleAdminPage'
import FullSearch from './Pages/FullSearch'
import NotFound from './Pages/NotFound'
import DashboardTickets from './Pages/dashboard/Tickets/page'
import DashboardProfile from './Pages/dashboard/Profile/page'
import DashboardMySubscene from './Pages/dashboard/MySubsource/page'
import DashboardMessages from './Pages/dashboard/Messages/page'
import DashboardDownloads from './Pages/dashboard/Downloads/page'
// import Maintenance from "./Pages/Maintenance";
const ResetPassword = lazy( () => import( './Pages/ResetPassword' ) )

function App() {
  const darkmode = useDarkMode(false, {
    element: document.getElementById('root')
  })
  const [pageLoading, setPageLoading] = useState(true)
  const [currentMovieCtx, setCurrentMovieCtx] = useState(null)
  if (localStorage.getItem('darkMode') == null) {
    darkmode.disable()
  }
  const { data: user } = useQuery({
    queryKey: ['getUser'],
    gcTime: 0,
    queryFn: async () => {
      try {
        const _user = await UserApi.get()
        setPageLoading(false)
        return _user
      } catch (error) {
        /* if (error) {
          setPageLoading(false);
          if (error.code === "ERR_NETWORK") {
            setMaintenance(true);
          }
        } */
      }
    }
  })
  const darkTheme = createTheme({
    typography: {
      fontFamily: 'Regular-Family',
      h1: {
        fontFamily: 'Bold-Family',
        fontSize: '28px',
        '@media (min-width:600px)': {
          fontSize: '29px'
        },
        '@media (min-width:1200px)': {
          fontSize: '30px'
        }
      },
      h2: {
        fontFamily: 'Medium-Family',
        fontSize: '24px',
        '@media (min-width:600px)': {
          fontSize: '26px'
        },
        '@media (min-width:1200px)': {
          fontSize: '27px'
        },
        '@media (min-width:1700px)': {
          fontSize: '30px'
        },
        '@media (min-width:1900px)': {
          fontSize: '32px'
        },
        color: darkmode.value ? 'white' : '#21495F'
      },
      h3: {
        fontFamily: 'Medium-Family',
        fontSize: '18px',
        '@media (min-width:600px)': {
          fontSize: '23px'
        },
        '@media (min-width:1200px)': {
          fontSize: '24px'
        }
      },
      body1: {
        fontFamily: 'Regular-Family',
        fontSize: '14px'
      }
    },
    palette: {
      mode: darkmode.value ? 'dark' : 'light'
    }
  })

  if (pageLoading) {
    return <></>
  }
  /* if (maintenance) {
    return (
      <Maintenance />
    )
  } */

  if (darkmode.value) {
    document.body.style.backgroundColor = '#121212'
    let style = document.createElement('style')
    style.innerHTML = `
      #root{
        background-image: url(${headerImageDark}), url(${footerImageDark});
        background-size: contain;
        background-repeat: no-repeat, no-repeat, repeat-x;
        background-position: center 50px, center bottom, 0 0;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      }`
    document.head.appendChild(style)
  } else {
    document.body.style.backgroundColor = 'white'
    let style = document.createElement('style')
    style.innerHTML = `
      #root {
        background-image: url(${headerImage}), url(${footerImage});
        background-size: contain;
        background-repeat: no-repeat, no-repeat, repeat-x;
        background-position: center 50px, center bottom, 0 0;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      }`
    document.head.appendChild(style)
  }
  return (
    <CurrentMovieContext.Provider value={{ currentMovieCtx, setCurrentMovieCtx }}>
      <HeaderContext.Provider value={user}>
        <ThemeProvider theme={darkTheme}>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/dmca" element={<DmcaPage />} />
              <Route path="/policy" element={<PrivacyPolicyPage />} />
              <Route path="/contactus" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/addMovie" element={user ? <AddMoviePage /> : <HomePage />} />
              <Route path="/recent" element={<LatestsPage />} />
              <Route path="ratings/:id" element={<RatingsPage />} />
              <Route path="subtitles">
                <Route index element={<NotFound />} />
                <Route path=":movieName" element={<MoviePage />}>
                  <Route path=":secondParam" element={<MoviePage />}>
                    <Route path=":thirdParam" element={<MoviePage />} />
                  </Route>
                </Route>
              </Route>
              <Route path="subtitle">
                <Route index element={<NotFound />} />
                <Route path=":movie/:lang/:id" element={<SubtitlePage />} />
              </Route>
              <Route path="search/:query" element={<FullSearch />} />
              <Route path="/upload">
                <Route index element={<UploadPage />} />
                <Route path=":id" element={<UploadPage2 />} />
              </Route>
              <Route path="/u/:id">
                <Route index element={<UserProfilePage />} />
                <Route path="subtitles" element={<AllSubsPage />}></Route>
                <Route path="ratings" element={<UserRatings />}></Route>
                <Route path="ratings_given" element={<UserRatingsGiven />}></Route>
              </Route>
              <Route
                path="/reset_password/:token"
                element={
                  <Suspense
                    fallback={
                      <Box
                        sx={{
                          height: '800px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'black'
                        }}
                      >
                        <Grid fill={darkmode.value ? 'white' : 'black'} />
                      </Box>
                    }
                  >
                    <ResetPassword />
                  </Suspense>
                }
              />
              <Route
                path="edit/:id"
                element={user ? <EditSubtitlePage /> : <Navigate to={'/'} />}
              />
              <Route
                path="editAdmin/:id"
                element={user ? <EditSubtitleAdminPage /> : <Navigate to={'/'} />}
              />
              {/* user panel */}
              <Route path="/dashboard" element={<UserDashboard />}>
                <Route index element={user ? <DashboardMySubscene /> : <Navigate to={'/'} />} />
                <Route
                  path="mysubsource"
                  element={user ? <DashboardMySubscene /> : <Navigate to={'/'} />}
                />
                <Route
                  path="settings"
                  element={user ? <DashboardProfile /> : <Navigate to={'/'} />}
                />
                <Route
                  path="messages"
                  element={user ? <DashboardMessages /> : <Navigate to={'/'} />}
                />
                <Route
                  path="downloads"
                  element={user ? <DashboardDownloads /> : <Navigate to={'/'} />}
                />
                <Route
                  path="tickets"
                  element={user ? <DashboardTickets /> : <Navigate to={'/'} />}
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
            {/* admin panel */}
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route path="home" element={<AdminHomePage />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="movies" element={<AdminMovies />} />
              <Route path="news" element={<AdminNews />} />
              <Route path="reports" element={<AdminReports />} />
              <Route path="tickets" element={<AdminTickets />} />
              <Route path="latest" element={<AdminLatestUploads />} />
              <Route path="fix-subs" element={<AdminFixSub />} />
              <Route path="merge" element={<AdminMerge />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </HeaderContext.Provider>
    </CurrentMovieContext.Provider>
  )
}

export default App
