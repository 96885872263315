import { Box, Link, Typography } from '@mui/material'
import React from 'react'

function Information({ selected }) {
  const getSubSourceLink = ( movie ) =>
  {
    let origin= window.location.origin
    if (movie.type === 'Movie') {
      return `${origin}/subtitles/${movie.linkName}`
    } else {
      return `${origin}/subtitles/${movie.linkName}/season-${movie.season}`
    }
  }
  return (
    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
        <Typography>Type: {selected.type}</Typography>
        <Typography>Season: {selected.season !== null ? selected.season : 'N/A'} </Typography>
        <Typography>
          Year: {selected.releaseYear !== null ? selected.releaseYear : 'N/A'}{' '}
        </Typography>
        <Typography>Source: {selected.source !== null ? selected.source : 'N/A'} </Typography>
        <Typography>
          SourceLink: {selected.extra.sourceLink !== undefined ? selected.extra.sourceLink : 'N/A'}{' '}
        </Typography>
        <Typography>
          User Created:{' '}
          {selected?.extra?.createdBy !== null && selected?.extra?.createdBy !== undefined ? (
            <Link
              href={'/u/' + selected.extra.createdBy}
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              {selected.extra.createdBy}
            </Link>
          ) : (
            'N/A'
          )}{' '}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
        <Typography>All Subs: {selected.allSubs} </Typography>
        <Typography>Deleted Subs: {selected.deletedSubs} </Typography>
        <Typography>Available Subs: {selected.availableSubs} </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
        <Typography>
          IMDb:{' '}
          <Link
            sx={{ textDecoration: 'none' }}
            href={selected.imdbLink ? 'https://www.imdb.com/title/' + selected.imdbLink : ''}
            target="_blank"
          >
            {selected.imdbLink || 'N/A'}
          </Link>
        </Typography>
        <Typography>
          TMDb:{' '}
          {selected.tmdbLink ? (
            <Link
              sx={{ textDecoration: 'none' }}
              href={selected.tmdbLink ? selected.tmdbLink : ''}
              target="_blank"
            >
              {selected.tmdb_id || 'N/A'}
            </Link>
          ) : (
            'N/A'
          )}
        </Typography>
        <Typography>
          <Link sx={{ textDecoration: 'none' }} href={getSubSourceLink(selected)} target="_blank">
            SubSource Link
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default Information
