import { Box, Button, TextField, styled } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import '../AdminPanel.css'
import { dispachAlert } from '../../../utils/dispachers';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})
function ChangePoster({ selected, setSelected, handleLoad }) {
  const [selectedFile, setSelectedFile] = useState()
  const [posterLink, setPosterLink] = useState()
  const [loadedPosterLink, setLoadedPosterLink] = useState(false)
  const [preview, setPreview] = useState()
  const getImageSrc = () => {
    if (loadedPosterLink) {
      return posterLink
    }
    if (preview) {
      return preview
    }
    if (selected.poster) {
      return selected.poster
    }
    return ''
  }
  const imageLinkRef = useRef()
  const fileRef = useRef()
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    if (e.target.files[0].size > 5000000) {
      return dispachAlert('error', 'File too big.')
    }
    if (
      e.target.files[0].name.toLowerCase().endsWith('png') ||
      e.target.files[0].name.toLowerCase().endsWith('jpeg') ||
      e.target.files[0].name.toLowerCase().endsWith('jpg')
    ) {
      setSelectedFile(e.target.files[0])
      console.log(e.target.files[0])
    } else {
      return dispachAlert('error', 'Unsupported Format.')
    }
  }
  const handleResetPoster = () => {
    setSelectedFile(undefined)
    setPreview(undefined)
    fileRef.current.value = ''
  }
  const handleResetPosterLink = () => {
    setPosterLink('')
    setLoadedPosterLink(false)
  }
  const handleSubmitPoster = () => {
    if (loadedPosterLink) {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/movies/changePoster/change',
          { movieId: selected?.id, link: imageLinkRef.current.value },
          { withCredentials: true }
        )
        .then((res2) => {
          if (res2.data.success) {
            setSelected((p) => ({ ...p, poster: imageLinkRef.current.value }))
            setSelectedFile(undefined)
            setPreview(undefined)
            setPosterLink('')
            fileRef.current.value = ''
            setLoadedPosterLink(false)
            handleLoad()
          } else {
            dispachAlert('error', 'Error.')
          }
        })
        .catch((err2) => {
          if (err2?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
          }
          console.log({ err2 })
        })
    } else if (selectedFile) {
      let formData = new FormData()
      formData.append('file', selectedFile)
      axios
        .post(process.env.REACT_APP_API_URL + '/api/admin/movies/changePoster/upload', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (res.data.success) {
            axios
              .post(
                process.env.REACT_APP_API_URL + '/api/admin/movies/changePoster/change',
                { movieId: selected?.id, filePath: res.data.poster },
                { withCredentials: true }
              )
              .then((res2) => {
                if (res2.data.success) {
                  setSelected((p) => ({ ...p, poster: res.data.poster }))
                  setSelectedFile(undefined)
                  setPreview(undefined)
                  setPosterLink('')
                  fileRef.current.value = ''
                  setLoadedPosterLink(false)
                  handleLoad()
                } else {
                  dispachAlert('error', 'Error.')
                }
              })
              .catch((err2) => {
                if (err2?.response?.status === 403) {
                  dispachAlert('error', 'Forbidden.')
                }
                console.log({ err2 })
              })
          } else {
            dispachAlert('error', 'Error uploading files to server.')
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
          }
          //setSaving(false);
          console.log(err)
          dispachAlert('error', 'Error uploading files to server.')
        })
    }
  }
  return (
    <Box sx={{ display: { md: 'flex' } }}>
      <Box sx={{ height: '225px', width: '150px' }}>
        <img
          src={getImageSrc()}
          style={{ borderRadius: '10px', height: '225px', width: '150px' }}
          alt="poster"
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ padding: '0.5rem', display: 'flex', gap: '1rem' }}>
          <Button
            component="label"
            role="undefined"
            variant="outlined"
            color="primary"
            sx={{ textTransform: 'none' }}
            disableRipple
            disabled={loadedPosterLink}
          >
            Upload New Avatar
            <VisuallyHiddenInput
              type="file"
              onChange={onSelectFile}
              ref={fileRef}
              accept="image/png, image/jpeg, image/jpg"
            />
          </Button>
          <Button
            component="label"
            role="undefined"
            variant="outlined"
            color="error"
            sx={{ textTransform: 'none' }}
            disableRipple
            onClick={handleResetPoster}
            disabled={loadedPosterLink}
          >
            Reset
          </Button>
        </Box>
        <Box sx={{ padding: '0.5rem', display: 'flex', gap: '1rem' }}>
          <TextField
            value={posterLink}
            onChange={(e) => setPosterLink(e.target.value)}
            inputRef={imageLinkRef}
            size="small"
            placeholder="Place image link here."
            label="Image Link"
            fullWidth
            disabled={selectedFile !== undefined}
          />
          <Button
            variant="outlined"
            size="small"
            disabled={selectedFile !== undefined}
            disableRipple
            onClick={() => {
              setLoadedPosterLink(true)
            }}
          >
            Load
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            disabled={selectedFile !== undefined}
            disableRipple
            onClick={handleResetPosterLink}
          >
            Reset
          </Button>
        </Box>
        <Box sx={{ alignSelf: 'flex-end', marginTop: 'auto' }}>
          <Button
            variant="contained"
            disabled={!loadedPosterLink && !selectedFile}
            onClick={handleSubmitPoster}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ChangePoster
