import { Box, Divider, List, ListItem, Skeleton, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode'
import { HorizontalMovieCard } from '../../ui/HorizontalMovieCard'
import { HorizontalMovieCardSkeleton } from '../../ui/HorizontalMovieCardSkeleton'
function Movies({ data, isLoading, type }) {
  const darkmode = useDarkMode()

  if (isLoading)
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: '2rem', gap: '1rem' }}>
          <Typography
            fontFamily={'Medium-Family'}
            sx={{ fontSize: { xs: '1.5rem', md: '1.7rem', lg: '2rem', fontWeight: 'bold' } }}
            color={darkmode.value ? 'white' : '#21495F'}
          >
            {type === 'Series' ? 'Popular TV Subtitles' : 'Popular Movie Subtitles'}
          </Typography>
          <Divider
            sx={{
              borderColor: darkmode.value ? 'white' : 'black',
              borderBottomWidth: '2px'
            }}
          />
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.75rem',
              mb: '1rem'
            }}
          >
            {[1, 2, 3].map((x) => (
              <ListItem key={x} disablePadding>
                <HorizontalMovieCardSkeleton
                  posterHeight={'100%'}
                  posterAspectRatio="auto"
                  hideIMDB
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </>
    )
  return (
    <Box
      sx={{
        display: data?.length > 0 ? 'flex' : 'none',
        flexDirection: 'column',
        mb: '2rem'
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: 'bold', mb: '.5rem' }}>
        {type === 'Series' ? 'Popular TV Subtitles' : 'Popular Movie Subtitles'}
      </Typography>
      <Divider
        sx={{
          borderColor: darkmode.value ? 'white' : 'black',
          borderBottomWidth: '2px',
          mb: '1.25rem'
        }}
      />
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
          mb: '1rem'
        }}
      >
        {data?.map((x, i) => (
          <ListItem key={x.imdb + i} disablePadding>
            <HorizontalMovieCard
              movie={x}
              posterHeight={'100%'}
              posterAspectRatio="3/4"
              hideIMDB
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default Movies
