import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  ToggleButton,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../css/LatestsPage.css'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import { Grid } from 'react-loading-icons'
import NotFound from './NotFound'
import useDarkMode from 'use-dark-mode'
import { langs } from '../data/langs'
import DataGridLatestSubs from '../Components/DataGrid/LatestSubs'
import ReactPaginate from 'react-paginate';
import { set_meta } from '../utils/seo';
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}
function LatestsPage2() {
  const darkmode = useDarkMode()
  const [searchQuery, setSearchQuery] = useSearchParams()
  const [latests, setLatests] = useState([])
  const [langFilter, setLangFilter] = useState([])

  const [showEmpty, setShowEmpty] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [popularSort, setPopularSort] = useState(false)
  const [ typeFilter, setTypeFilter ] = useState( 'all' )
  const [open, setOpen] = React.useState(false)
  const pageCount = 20
  set_meta({
    is_landing: false,
    title: `Latest subtitles in SubSource.`
  })
  const filterButtonStyles = {
    fontSize: '14px',
    fontWeight: 'bold',
    textWrap: 'nowrap',
    '&.Mui-selected': {
      backgroundColor: '#0080808f',
      boxShadow:
        'inset 0px 2px 4px -1px rgba(0,0,0,0.2), inset 0px 4px 5px 0px rgba(0,0,0,0.14), inset 0px 1px 10px 0px rgba(0,0,0,0.12)',
      '&:hover': { backgroundColor: '#0080808f' }
    },
    textTransform: 'none',
    '&:hover': { backgroundColor: 'initial' },
    py: '0.5rem',
    px: '0.75rem'
  }
  // 
  const handleChangeLanguage = (event) => {
    const {
      target: { value }
    } = event
    setLangFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }
  const handleSaveFilters = () => {
    setLoading(true)
    setSearchQuery({
      page: 1
    })
    try {
      window.localStorage.setItem('langs', JSON.stringify(langFilter))
    } catch (error) {
      console.log({ error })
    }
    axios
      .get(process.env.REACT_APP_API_URL + '/api/latestsSubs', {
        params: {
          typeFilter: window.localStorage.getItem('type_filter') || 'all',
          langs: window.localStorage.getItem('langs'),
          page: searchQuery.get('page') || 1,
          sort_pop: window.localStorage.getItem('sort_popular') === 'true'
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          var subs =
            window.localStorage.getItem('sort_popular') === 'true'
              ? res.data.latests.sort((a, b) => b.downloads - a.downloads)
              : res.data.latests.sort(
                  (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
                )
          setLatests(subs)
          setLoading(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setNotFound(true)
        }
        setLoading(false)
        console.log(err)
      })
  }
  const handleSortChange = () => {
    setDataLoading(true)
    const sortByDownload = window.localStorage.getItem('sort_popular') === 'true'
    setSearchQuery({
      page: searchQuery.get('page') || 1,
      sort_pop: sortByDownload,
      type: window.localStorage.getItem('type_filter') || 'all'
    })
  }
  const handlePageClick = (event) => {
    if (!dataLoading) {
      setDataLoading(true)
      setSearchQuery({
        page: parseInt(event.selected || 0) + 1,
        sort_pop: window.localStorage.getItem('sort_popular') === 'true',
        type: window.localStorage.getItem('type_filter') || 'all'
      })
    }
  }
  const handleTypeFilterChange = () => {
    setDataLoading(true)
    const sortByDownload = window.localStorage.getItem('sort_popular') === 'true'
    setSearchQuery({
      page: searchQuery.get('page') || 1,
      sort_pop: sortByDownload,
      type: window.localStorage.getItem('type_filter') || 'all'
    })
  }

  //
  useEffect(() => {
    window.scrollTo(0, 0)
    try {
      setLangFilter(JSON.parse(window.localStorage.getItem('langs')) || [])
      setPopularSort(window.localStorage.getItem('sort_popular') === 'true')
      setTypeFilter(window.localStorage.getItem('type_filter') || 'all')
    } catch (error) {
      console.log({ error })
    }
    axios
      .get(process.env.REACT_APP_API_URL + '/api/latestsSubs', {
        params: {
          typeFilter: window.localStorage.getItem('type_filter') || 'all',
          page: searchQuery.get('page') || 1,
          langs: window.localStorage.getItem('langs'),
          sort_pop: window.localStorage.getItem('sort_popular') === 'true'
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          var subs =
            window.localStorage.getItem('sort_popular') === 'true'
              ? res.data.latests.sort((a, b) => b.downloads - a.downloads)
              : res.data.latests.sort(
                  (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
                )
          setLatests(subs)
          setLoading(false)
          setDataLoading(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setNotFound(true)
        }
        console.log(err)
      })
  }, [ searchQuery ] )
  
  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        setOpen(false) // Close the select menu when scrolling
      }
    }

    if (open) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }

    return () => window.removeEventListener('scroll', handleScroll)
  }, [ open ] )
  
  if (notFound) {
    return <NotFound />
  }
  if (loading)
    return (
      <>
        <Box
          sx={{
            height: '800px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'black'
          }}
        >
          <Grid fill={darkmode.value ? 'white' : 'black'} />
        </Box>
      </>
    )
  return (
    <Container maxWidth="xl" sx={{ mt: { xs: '2rem' } }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '1rem' }}>
        <Typography
          fontSize={'35px'}
          color={darkmode.value ? 'white' : '#21495F'}
          fontWeight={'700'}
          sx={{ flexGrow: { xs: 1 }, mr: '1rem' }}
        >
          Browse Subtitles
        </Typography>
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          <Paper>
            <ToggleButton
              disabled={dataLoading}
              value={!popularSort}
              selected={!popularSort}
              onClick={(e) => {
                if (popularSort) {
                  setPopularSort(false)
                  window.localStorage.setItem('sort_popular', 'false')
                  handleSortChange()
                }
              }}
              disableRipple
              sx={filterButtonStyles}
            >
              Latest
            </ToggleButton>
          </Paper>
          <Paper>
            <ToggleButton
              disabled={dataLoading}
              value={popularSort}
              selected={popularSort}
              onClick={(e) => {
                if (!popularSort) {
                  setPopularSort(true)
                  window.localStorage.setItem('sort_popular', 'true')
                  handleSortChange()
                }
              }}
              disableRipple
              sx={filterButtonStyles}
            >
              Popular
            </ToggleButton>
          </Paper>
        </Box>
      </Box>
      <Divider sx={{ mb: '1.5rem', mt: '0.5rem', borderColor: 'black' }} />
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        {/* language filter */}
        <FormGroup
          sx={{
            alignItems: { lg: 'flex-end' },
            // justifyContent: { lg: 'flex-end' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: 'wrap',
            // mb: '1rem',
            gap: '1rem',
            flexGrow: { md: 1 }
          }}
        >
          {/* Language */}
          <FormControl
            focused
            sx={{ flexGrow: { xs: 1 }, width: { md: '100%' }, maxWidth: { sm: 250 } }}
          >
            <InputLabel htmlFor="selected-language" shrink>
              Language
            </InputLabel>
            <Select
              open={open}
              onOpen={() =>
              {
                setShowEmpty( true );
                setOpen(true)
              }}
              onClose={() => setOpen(false)}
              onBlur={() => setShowEmpty(false)}
              value={langFilter ?? []}
              onChange={handleChangeLanguage}
              inputProps={{
                name: 'language',
                id: 'selected-language'
              }}
              size="small"
              multiple
              input={<OutlinedInput label="Language" />}
              renderValue={(selected) => {
                return selected.length === 0 ? 'All Languages' : selected.join(', ')
              }}
              displayEmpty={showEmpty}
              MenuProps={MenuProps}
            >
              {['Select Language'].concat(langs).map((lang, index) => (
                <MenuItem key={lang} value={lang} disabled={index === 0}>
                  <Checkbox checked={langFilter?.indexOf(lang) > -1} />
                  <ListItemText primary={lang} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* filter */}
          <Button type="submit" onClick={handleSaveFilters} variant="contained" color="success">
            Filter
          </Button>
        </FormGroup>
        {/* all/movie/tv */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Paper>
            <ToggleButton
              disabled={dataLoading}
              selected={typeFilter === 'all'}
              onClick={(e) => {
                if (typeFilter !== 'all') {
                  setTypeFilter('all')
                  window.localStorage.setItem('type_filter', 'all')
                  handleTypeFilterChange()
                }
              }}
              disableRipple
              sx={filterButtonStyles}
            >
              All
            </ToggleButton>
          </Paper>
          <Paper>
            <ToggleButton
              disabled={dataLoading}
              selected={typeFilter === 'movie'}
              onClick={(e) => {
                if (typeFilter !== 'movie') {
                  setTypeFilter('movie')
                  window.localStorage.setItem('type_filter', 'movie')
                  handleTypeFilterChange()
                }
              }}
              disableRipple
              sx={filterButtonStyles}
            >
              Movies
            </ToggleButton>
          </Paper>
          <Paper>
            <ToggleButton
              disabled={dataLoading}
              selected={typeFilter === 'tv'}
              onClick={(e) => {
                if (typeFilter !== 'tv') {
                  setTypeFilter('tv')
                  window.localStorage.setItem('type_filter', 'tv')
                  handleTypeFilterChange()
                }
              }}
              disableRipple
              sx={filterButtonStyles}
            >
              TV-Series
            </ToggleButton>
          </Paper>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', mt: '0.5rem' }}>
        {dataLoading ? (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#00000036',
              width: '100%',
              height: '100%'
            }}
          >
            <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
          </Box>
        ) : (
          <></>
        )}
        <DataGridLatestSubs
          latest={latests}
          dataLoading={dataLoading}
          setDataLoading={setDataLoading}
        />
        <ReactPaginate
          containerClassName={`allsubs-pagination ${darkmode.value && 'dark-mode'}`}
          breakLabel="..."
          breakLinkClassName="breaklink"
          nextLabel="»"
          nextClassName="nextpage"
          previousClassName="prevpage"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          forcePage={(searchQuery.get('page') || 1) - 1}
          previousLabel="«"
          renderOnZeroPageCount={null}
          pageClassName="page"
          pageLinkClassName="pagelink"
          activeClassName="active"
        />
      </Box>
    </Container>
  )
}

export default LatestsPage2
