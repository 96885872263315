import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import { Outlet, useNavigate } from 'react-router-dom'
// icons
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import HomeIcon from '@mui/icons-material/Home'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import MovieRoundedIcon from '@mui/icons-material/MovieRounded'
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import BackupRoundedIcon from '@mui/icons-material/BackupRounded'
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined'
import BuildIcon from '@mui/icons-material/Build'
import CallMergeIcon from '@mui/icons-material/CallMerge'
//
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Header from '../Components/Header/Header'
import { useEffect } from 'react'
import axios from 'axios'
import { useSmallWidth } from '../hooks/useSmallWidth'
import { Alert, Snackbar } from '@mui/material'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
      }
    }
  ]
}))

export default function AdminLayout() {
  /**
   * open
   * severity
   * message
   */
  const [alerts, setAlerts] = React.useState([])

  const handleClose = (index) => {
    setAlerts((prevAlerts) => prevAlerts.filter((i, j) => j !== index))
  }

  useEffect(() => {
    const addListener = (e) => {
      let detail = e?.detail ? JSON.parse(e.detail) : null
      if (detail) {
        // Use a callback function to ensure the latest state
        setAlerts((prevAlerts) => {
          const updatedAlerts = [...prevAlerts, { open: true, ...detail }]
          return updatedAlerts
        })
      }
    }

    window.addEventListener('SnackError', addListener)

    return () => {
      window.removeEventListener('SnackError', addListener)
    }
  }, [])
  //
  const navigate = useNavigate()
  //
  const isSmall = useSmallWidth()
  const [open, setOpen] = React.useState(false)
  const [isAdmin, setIsAdmin] = React.useState(false)
  //
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/getAdmin', { withCredentials: true })
      .then((res) => {
        if (!res.data.success) {
          window.location.href = '/'
        } else {
          setIsAdmin(true)
        }
      })
      .catch((err) => {
        window.location.href = '/'
        console.log(err)
      })
  }, [])
  //
  const genAsideMenuItem = (text, url, icon) => ({ text, url, icon })
  const handleDrawerToggle = () => {
    setOpen(!open)
  }
  //
  const asideMenuTopOptions = [
    genAsideMenuItem('Dashboard', '/admin', <DashboardRoundedIcon className="icon" />),
    genAsideMenuItem('Home Page', '/admin/home', <HomeIcon className="icon" />),
    genAsideMenuItem('Users', '/admin/users', <GroupRoundedIcon className="icon" />),
    genAsideMenuItem('Movies', '/admin/movies', <MovieRoundedIcon className="icon" />),
    genAsideMenuItem('Latest Uploads', '/admin/latest', <BackupRoundedIcon className="icon" />),
    genAsideMenuItem('News', '/admin/news', <NewspaperOutlinedIcon className="icon" />)
  ]
  const asideMenuBottomOptions = [
    genAsideMenuItem('Reports', '/admin/reports', <FlagCircleRoundedIcon className="icon" />),
    genAsideMenuItem('Tickets', '/admin/tickets', <SupportAgentRoundedIcon className="icon" />),
    genAsideMenuItem('Fix Subtitles', '/admin/fix-subs', <BuildIcon className="icon" />),
    genAsideMenuItem('Merge User', '/admin/merge', <CallMergeIcon className="icon" />)
  ]

  useEffect(() => {
    setOpen(false)
  }, [isSmall])

  return isAdmin ? (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header appBarPosition="fixed">
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
          >
            <DashboardRoundedIcon />
          </IconButton>
        </Box>
      </Header>
      <Drawer
        sx={{
          width: drawerWidth,
          minHeight: '100vh',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        PaperProps={{
          sx: {
            pt: '90px'
          }
        }}
        onClose={() => setOpen(false)}
        variant={!isSmall ? 'persistent' : ''}
        anchor="left"
        open={open}
      >
        <Typography sx={{ fontSize: '2rem', textAlign: 'center', fontWeight: 'bold' }}>
          SubSource
        </Typography>
        <Divider />
        <List>
          {asideMenuTopOptions.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                href={item.url}
                selected={document.location.pathname === item.url}
                onClick={(e) => {
                  if (isSmall) setOpen(false)
                  e.preventDefault()
                  navigate(item.url)
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {asideMenuBottomOptions.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                href={item.url}
                selected={document.location.pathname === item.url}
                onClick={(e) => {
                  if (isSmall) setOpen(false)
                  e.preventDefault()
                  navigate(item.url)
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={!isSmall ? open : true} sx={{ pt: { xs: '90px', md: '120px' } }}>
        <Outlet />
        {alerts.map((item, index) => (
          <Snackbar
            key={`alert-${index}`}
            open={item.open}
            autoHideDuration={6000}
            onClose={() => handleClose(index)}
          >
            <Alert
              onClose={() => handleClose(index)}
              severity={item.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {item.message}
            </Alert>
          </Snackbar>
        ))}
      </Main>
    </Box>
  ) : (
    <></>
  )
}
