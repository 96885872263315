import React, { useState } from 'react'
import { Box, ToggleButton, Typography } from '@mui/material'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo';
function PrivacyPolicyPage() {
  const darkmode = useDarkMode(false)
  const [ language, setLanguage ] = useState( 'en' )
  set_meta({
    is_landing: false
  })

  const items = [
    {
      dir: 'ltr',
      code: 'en',
      lang: 'English',
      title: 'Privacy Policy of SubSource',
      content: `<strong>How does SubSource keep your information safe?</strong>
        <br />
        <br />
        At SubSource, we understand the importance of your privacy. In today's data-driven world, we
        want to assure you that we prioritize the security and confidentiality of your information.
        This policy outlines how we collect, use, and protect your data.
        <br />
        <br />
        <br />
        <strong>What information does SubSource collect from you?</strong>
        <br />
        <br />
        SubSource collects two types of information from users: Public Profile Information: This
        includes details you choose to make publicly visible on your profile, such as your username
        and social media links. Account Information: This data is essential for account creation and
        using our services. It includes your email address and IP address.
        <br />
        <br />
        <br />
        <strong>How is your information protected?</strong>
        <br />
        <br />
        Your data is protected by industry-standard security measures. This includes secure storage
        with encryption at rest on our servers protected by robust firewalls. We utilize HTTPS
        encryption to ensure your information is transmitted securely during signup, and we store
        your data only for as long as necessary to provide you with our services.
        <br />
        <br />
        <br />
        <strong>How does SubSource Uses your information?</strong>
        <br />
        <br />
        We are legally and ethically obligated to retain your data, but it is always stored in
        encrypted format. We respect your privacy and will never send you unsolicited commercial
        emails.
        <br />
        <br />
        <br />
        <strong>Who does access your information?</strong>
        <br />
        <br />
        Only our high-level admins access some of your information. This access is strictly limited
        to providing you with the best possible support experience. We are committed to protecting
        your privacy and have robust safeguards in place to prevent unauthorized access to your
        information.
        <br />
        <br />
        <br />
        <strong>How much is SubSource allowed to change your account?</strong>
        <br />
        <br />
        To maintain a positive and secure environment for all users, SubSource reserves the right to
        remove content that violates our terms of service. Users who violate these terms may have
        their access to the site restricted or terminated.`
    },
    {
      dir: 'rtl',
      code: 'fa',
      lang: 'فارسی',
      title: 'سیاست حفظ حریم خصوصی ساب‌سورس',
      content: `<strong>ساب‌سورس چگونه از اطلاعات شما حراست می‌کند؟</strong>
        <br />
        <br />
        امروزه با گسترش جریان اطلاعات و استفاده روزمره کاربران از اینترنت و وسایل ارتباطی، حریم
        خصوصی کاربران به یکی از جدی‌ترین دغدغه‌ها تبدیل شده ‌است. نگرانی بسیاری از کاربران، محفوظ
        ماندن اطلاعات شخصی و ایمیل است که برای ثبت نام و لاگین شدن به ما می‌سپارند. باید گفت ما نیز
        اهمیت این موضوع را درک می‌کنیم و حریم خصوصی کاربران برای ما اهمیت بسیاری دارد. در ذیل سیاست
        حفظ حریم خصوصی و محرمانگی اطلاعات کاربران را ملاحظه خواهید کرد.
        <br />
        <br />
        <br />
        <strong>چه اطلاعاتی از شما دریافت می‌شود؟</strong>
        <br />
        <br />
        ساب‌سورس دو نوع اطلاعات از کاربران خود دریافت می‌کند:<br></br> 1- اطلاعاتی که خود کاربر به
        طور عمومی در حساب کاربری خود قرار می‌دهد (از قبیل نام کاربری و حساب‌های رسانه‌های احتماعی)
        <br></br> 2- اطلاعاتی که برای ورود و استفاده از سرویس‌های سایت لازم است. این اطلاعات شامل
        آی‌پی و آدرس ایمیل کاربر می‌شود.
        <br />
        <br />
        <br />
        <strong>چگونه از اطلاعات شما محافظت می‌شود؟</strong>
        <br />
        <br />
        با وجود این‌که امنیت به صورت نسبی تعریف شده است اما در طراحی وب‌سایت ساب‌سورس سعی شده که از
        بالاترین سطوح امنیتی سخت‌افزاری و نرم‌افزاری در نظر گرفته شود. در زمان ثبت نام، تمامی
        اطلاعات و داده‌ها بر روی سرورهای ایمن ما ذخیره و توسط فایروال‌های مستحکم محافظت خواهند شد.
        اطلاعات شخصی شما نیز به صورت رمزنگاری‌شده در بستر امن HTTPS به وب‌سایت ما منتقل و برای
        تعاملات بعدی نگهداری خواهند شد.
        <br />
        <br />
        <br />
        <strong>چه استفاده‌ای اطلاعات شما خواهد شد؟</strong>
        <br />
        <br />
        چه از نظر قانونی، چه از نظر اخلاقی، ما موظف به صیانت از اطلاعات شما هستیم. اطلاعات شخصی شما
        نیز صرفاً جهت تعاملات بعدی به صورت کدگذاری‌شده نگهداری می‌شوند. ما هم از اسپم متنفریم و
        ایمیل‌ها و پیام‌های تبلیغاتی را برای کاربران‌مان ارسال نخواهیم کرد.
        <br />
        <br />
        <br />
        <strong>چه کسانی به اطلاعات شما دسترسی دارند؟</strong>
        <br />
        <br />
        فقط و فقط ادمین‌های رده بالا به برخی از اطلاعات شما دسترسی دارند، آن هم برای ارائه پشتیبانی
        بهتر و با کیفیت‌تر مورد استفاده قرار می‌گیرد. تمامی اطلاعات شخصی شما نزد ما محفوظ خواهد ماند
        و در اختیار افراد یا سازمان‌های دیگری قرار نخواهد گرفت. همچین ساب‌سورس به رمز عبور حساب شما
        دسترسی ندارد و تمامی پسوردها به صورت هش و رمزگذاری شده ذخیره می‌شوند.
        <br />
        <br />
        <br />
        <strong>ساب‌سورس تا چه حد مجاز است در حساب شما تغییرات اعمال کند؟</strong>
        <br />
        <br />
        ساب‌سورس این حق را برای خود محفوظ می‌دارد که محتوای حساب‌ها را در جهت هماهنگی با قوانین سایت
        تغییر دهد و یا حذف کند. لذا در صورت تخطی از قوانین سایت، ساب‌سورس این حق را دارد که با
        صلاحدید خود و بدون هیچگونه اطلاع‌رسانی حساب کاربر خاطی را مسدود سازد.`
    },
    {
      dir: 'rtl',
      code: 'ar',
      lang: 'العربیة',
      title: 'سياسة الخصوصية لتطبيق SubSource',
      content: `<strong>كيف يحافظ SubSource على سلامة معلوماتك؟</strong>
        <br />
        <br />
        يدرك القائمون على SubSource أهمية خصوصيتك، وفي عالمنا اليوم المعتمد على البيانات نؤكد لك أن أمان وسرية بياناتك هي أولوية لنا.
        توضح هذه السياسة كيف نجمع ونستخدم ونحمي بياناتك.
        <br />
        <br />
        <br />
        <strong>أي معلومات يجمعها SubSource؟</strong>
        <br />
        <br />
        يجمع SubSource من المستخدمين نوعين من المعلومات:
        <br><br> 
        1- معلومات ملفك الشخصي العام: بيانات تختار جعلها مرئية على ملفك الشخصي، مثل اسم المستخدم وروابط وسائل التواصل الاجتماعي.
        <br><br> 
        2- معلومات الحساب: بيانات ضرورية لإنشاء الحساب والاستفادة من خدماتنا، وتشمل عنوان البريد الإلكتروني (E-Mail) وعنوان بروتوكول الإنترنت (IP Address).
        <br />
        <br />
        <br />
        <strong>كيف نحمي معلوماتك؟</strong>
        <br />
        <br />
        نحمي بياناتك بواسطة الإجراءات الأمنية المتّبعة في المجال، التي تشمل تشفير البيانات في أثناء تخزينها على خوادمنا المحمية ببرامج حماية Firewalls قوية.
        نستخدم تشفير بروتوكول نقل النص التشعبي الآمن (HTTPS) لضمان نقل معلوماتك بشكل آمن في أثناء عملية التسجيل، ولا نخزّن بياناتك إلا للمدة اللازمة لتزويدك بخدماتنا.
        <br />
        <br />
        <br />
        <strong>كيف يستخدم SubSource معلوماتك؟</strong>
        <br />
        <br />
        الموقع مُلزم قانونيًا وأخلاقيًا بحفظ بياناتك، ودائمًا ما تُخزّن عليه مشفّرة.
        نحترم خصوصيتك ولن نرسل لك رسائل إلكترونية تجارية غير مرغوب فيها.
        <br />
        <br />
        <br />
        <strong>من يمكنه الاطّلاع على معلوماتك؟</strong>
        <br />
        <br />
        وحدهم الإداريون رفيعو المستوى يمكنهم الاطّلاع على بعض بياناتك، وهذه الإمكانية مقيّدة تقييدًا صارمًا لتقديم أفضل تجربة دعم ممكنة.
        إننا ملتزمون بحماية خصوصيتك ولدينا إجراءات وقائية صارمة لمنع الوصول غير المصرح به إلى معلوماتك.
        <br />
        <br />
        <br />
        <strong>إلى أي مدى يحق لـ SubSource التغيير في حسابك؟</strong>
        <br />
        <br />
        للحفاظ على بيئة إيجابية وآمنة لجميع المستخدمين، يحتفظ موقع SubSource بالحق في إزالة أي محتوى ينتهك شروط وأحكام خدماتنا. والمستخدمون الذين ينتهكون هذه الشروط والأحكام، فولوجهم إلى الموقع قد يُقيّد أو يُنهى.`
    },
    {
      dir: 'ltr',
      code: 'bn',
      lang: 'বাংলা',
      title: 'SubSource-এর গোপনীয়তা নীতি',
      content: `<strong>সাবসোর্স আপনার তথ্য কীভাবে নিরাপদ রাখে?</strong>
        <br />
        <br />
        সাবসোর্সে, আমরা আপনার গোপনীয়তার গুরুত্ব বুঝি। বর্তমানে তথ্য-প্রযুক্তির যুগে, আপনাদের আস্বস্ত করতে চাই যে আমরা আপনাদের তথ্যের নিরাপত্তা এবং গোপনীয়তাকে অগ্রাধিকার দিব।
        <br />
        <br />
        <br />
        <strong>সাবসোর্স আপনার থেকে কী তথ্য সংগ্রহ করে?</strong>
        <br />
        <br />
        সাবসোর্স ইউজারদের থেকে ২ ধরনের তথ্য সংগ্রহ করে থাকে।
        <br><br>
        1- পাবলিক প্রোফাইল ইনফরমেশন: এক্ষেত্রে আপনাদের প্রোফাইল তৈরি করতে যেসব তথ্য ব্যবহার করে থাকেন যেমন ইউজার নেম, সোস্যাল মিডিয়া লিংক।
        <br><br>
        2- একাউন্ট ইনফরমেশন: আইডি তৈরি করতে এবং আমাদের সেবা উপভোগ করতে এই তথ্য অত্যাবশ্যক। এক্ষেত্রে ইমেইল অ্যাড্রেস এবং আইপি অ্যাড্রেস ব্যবহার হয়ে থাকে।
        <br />
        <br />
        <br />
        <strong>আপনার তথ্য নিরাপদ রাখা হয় কীভাবে?</strong>
        <br />
        <br />
        সাইবার মানদন্ড বিবেচনা করে নিরাপত্তা ব্যবস্থার সাহায্যে আপনাদের তথ্যের নিরাপত্তা নিশ্চিত করা হয়। যেমন শক্তিশালী ফায়ারওয়াল দ্বারা সুরক্ষিত আমাদের সার্ভারে এনক্রিপ্টেড সিকিউর স্টোরেজ আছে। সাইন-আপের সময় আপনাদের তথ্য যাতে নিরাপদে ট্রান্সমিট হয় তা নিশ্চিত করতে HTTPS এনক্রিপশন ব্যবহার করা হয়। এবং আপনাদের শুধু সেবা দেওয়ার প্রয়োজনেই তথ্য সংরক্ষণ করা হয়।
        <br />
        <br />
        <br />
        <strong>সাবসোর্স আপনার তথ্য কীভাবে ব্যবহার করে?</strong>
        <br />
        <br />
        আমরা আইনানুযায়ী নীতিগতভাবে আপনার তথ্য নিতে বাধ্য, তবে এটা সর্বদাই এনক্রিপটেড ফরম্যাটে থাকে। আমরা আপনার গোপনীয়তাকে সন্মান করি এবং কখনোই আপনাকে অপ্রয়োজনীয় বাণিজ্যিক ইমেইল পাঠাবো না।
        <br />
        <br />
        <br />
        <strong>আপনার তথ্য কারা দেখতে পারবে?</strong>
        <br />
        <br />
        শুধুমাত্র আমাদের উচ্চপর্যায়ের কিছু এডমিনরাই আপনার তথ্য দেখতে পারবে। আপনাকে সেরা অভিজ্ঞতা দেওয়ার জন্যই এই তথ্য কঠোরভাবে নিয়ন্ত্রিত। আমরা আপনার গোপনীয়তা সংরক্ষণে প্রতিজ্ঞাবদ্ধ এবং আপনার তথ্য অননুমোদিতভাবে হাসিল ঠেকাতে জোরালো নিরাপত্তামূলক পদক্ষেপ নিয়েছি।
        <br />
        <br />
        <br />
        <strong>সাবসোর্স আপনার একাউন্টে কতটা পরিবর্তন আনতে পারবে?</strong>
        <br />
        <br />
        সকলের জন্য পজেটিভ এবং নিরাপদ পরিবেশের নিমিত্তে সাবসোর্স তাদের নীতিমালা লঙ্ঘনকারী যে-কোনো কন্টেন্ট অপসারণের অধিকার রাখে। নীতিমালা লঙ্ঘনকারী ইউজারদের ওয়েবসাইটে প্রবেশ সীমাবদ্ধ অথবা বন্ধ করে দেওয়া হবে।
        `
    },
    {
      dir: 'ltr',
      code: 'id',
      lang: 'Bahasa Indonesia',
      title: 'Kebijakan Privasi SubSource',
      content: `<strong>Bagaimana SubSource menjaga keamanan informasi Anda?</strong>
<br />
<br />
Di SubSource, kami memahami pentingnya privasi Anda. Di dunia yang kini bergantung kepada data, kami ingin meyakinkan Anda bahwa kami memprioritaskan keamanan dan kerahasiaan informasi Anda. Kebijakan ini menjabarkan cara kami mengumpulkan, menggunakan, dan melindungi data Anda.
<br />
<br />
<br />
<strong>Informasi apa yang dikumpulkan SubSource dari Anda?</strong>
<br />
<br />
SubSource mengumpulkan dua jenis informasi dari pengguna:
<br><br>
1- Informasi Profil Publik: Ini termasuk informasi-informasi yang Anda pilih untuk ditampilkan kepada publik di profil Anda, seperti nama pengguna dan tautan media sosial.
<br><br>
2- Informasi Akun: Data ini penting untuk pembuatan akun dan penggunaan layanan kami. Data ini mencakup alamat email dan alamat IP Anda.
<br />
<br />
<br />
<strong>Bagaimana informasi Anda dilindungi?</strong>
<br />
<br />
Data Anda dilindungi oleh langkah-langkah keamanan standar industri. Ini termasuk penyimpanan yang aman dengan enkripsi di server kami yang dilindungi oleh firewall yang kuat. Kami menggunakan enkripsi HTTPS untuk memastikan informasi Anda dikirimkan dengan aman selama pendaftaran, dan kami menyimpan data Anda hanya selama diperlukan untuk menyediakan layanan kami kepada Anda.
<br />
<br />
<br />
<strong>Bagaimana SubSource Menggunakan informasi Anda?</strong>
<br />
<br />
Kami secara hukum dan etika berkewajiban untuk menyimpan data Anda, tetapi data tersebut selalu disimpan dalam format terenkripsi. Kami menghormati privasi Anda dan tidak akan pernah mengirimi Anda email komersial yang tidak diminta.
<br />
<br />
<br />
<strong>Siapa saja yang dapat mengakses informasi Anda?</strong>
<br />
<br />
Hanya admin tingkat tinggi kami yang dapat mengakses beberapa informasi Anda. Akses ini sangat terbatas untuk memberikan Anda pengalaman dukungan terbaik. Kami berkomitmen untuk melindungi privasi Anda dan memiliki perlindungan yang kuat untuk mencegah akses yang tidak sah ke informasi Anda.
<br />
<br />
<br />
<strong>Seberapa banyak SubSource diizinkan untuk mengubah akun Anda?</strong>
<br />
<br />
Untuk menjaga lingkungan yang positif dan aman bagi semua pengguna, SubSource berhak untuk menghapus konten yang melanggar ketentuan layanan kami. Pengguna yang melanggar persyaratan ini dapat dibatasi atau dihentikan aksesnya ke situs.
`
    }
  ]

  const item = items.find((i) => i.code === language)

  const handleChange = (event) => {
    setLanguage(event.target.value)
  }

  return (
    <Box
      sx={{
        margin: '0 auto',
        mt: '80px',
        maxWidth: '1100px',
        color: darkmode.value ? 'white' : 'initial'
      }}
    >
      {item ? (
        <Typography
          variant="h1"
          sx={{
            fontSize: '30px',
            fontWeight: '900',
            color: darkmode.value ? 'white' : '#205e60',
            fontFamily: 'Medium-Family',
            textAlign: 'center'
          }}
        >
          {item.title}
        </Typography>
      ) : (
        ''
      )}

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: {xs: '.5rem', lg: '1rem'}, justifyContent: 'center', mt: '1rem' }}>
        {items.map((i) => (
          <ToggleButton
            disableRipple
            onClick={handleChange}
            selected={language === i.code}
            size="small"
            value={i.code}
            key={i.code}
            sx={{ textTransform: 'none', fontFamily: 'Regular-Family' }}
          >
            {i.lang}
          </ToggleButton>
        ))}
      </Box>
      <Box
        sx={{
          mt: '80px',
          px: '2rem',
          fontFamily: 'Medium-Family',
          lineHeight: '2rem',
          fontSize: '18px',
          direction: item.dir
        }}
      >
        <div dangerouslySetInnerHTML={{__html: item.content}} />
      </Box>
    </Box>
  )
}

export default PrivacyPolicyPage
