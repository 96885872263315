import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled'
import moment from 'moment'
import { useSmallWidth } from '../../hooks/useSmallWidth'
import useDarkMode from 'use-dark-mode'
import ReactTimeAgo from 'react-time-ago'
import collabIcon from './../../icons/contribIcon.png'
import { Search } from '@mui/icons-material'

export default function DataGridUserAllSubs({ subs, dataLoading }) {
  const navigate = useNavigate()
  const isSmall = useSmallWidth()
  const darkmode = useDarkMode()
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  const columns = [
    {
      field: 'lang',
      headerName: isSmall ? 'Lang' : 'Language',
      width: isSmall ? 100 : 150,
      valueGetter: (val, row) => val
    },
    {
      field: 'title',
      headerName: 'Title',
      width: isSmall ? 250 : 400,
      bold: true,
      valueGetter: (val, row) => val
    },
    {
      field: 'isCollab',
      headerName: 'Collab',
      width: 100,
      align: 'center',
      valueGetter: (val, row) =>
        row.isCollab ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt="collab"
              src={collabIcon}
              width={20}
              style={{ filter: darkmode.value ? 'invert(1)' : 'none' }}
            />
          </Box>
        ) : (
          ''
        )
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      align: 'center',
      valueGetter: (val, row) => val
    },
    {
      field: 'cms',
      headerName: 'Comments',
      width: 100,
      align: 'center',
      valueGetter: (val, row) => val
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      align: 'center',
      valueGetter: (val, row) =>
        moment().diff(row.date, 'days') > 30 ? (
          moment(row.date).format('DD/MM/YYYY')
        ) : (
          <ReactTimeAgo date={new Date(row.date)} />
        )
    },
    {
      field: 'dc',
      headerName: 'Downloads',
      width: 100,
      align: 'center',
      valueGetter: (val, row) => val
    },
    {
      field: 'hi',
      headerName: 'H.I',
      width: 50,
      align: 'center',
      valueGetter: (val, row) =>
        row.hi ? <HearingDisabledIcon sx={{ color: 'darkgray', fontSize: '16px' }} /> : ''
    }
  ]

  const [scrollDirection, setScrollDirection] = useState(null) // Track scroll direction
  const initialX = useRef(null) // Initialize refs for X and Y
  const initialY = useRef(null)
  // Handle initial touch to determine scroll direction
  const handleTouchStart = (e) => {
    if (!isSmall) return
    const touch = e.touches[0]
    setScrollDirection(null)
    // Store initial touch coordinates
    initialX.current = touch.clientX
    initialY.current = touch.clientY
  }

  // Detect direction on touch move and set scroll constraint
  const handleTouchMove = (e) => {
    if (scrollDirection || !isSmall) return // Only set once
    const touch = e.touches[0]
    const diffX = Math.abs(touch.clientX - initialX.current)
    const diffY = Math.abs(touch.clientY - initialY.current)
    let dir = diffX > diffY ? 'horizontal' : 'vertical'

    let el = document.querySelector('#table-wrapper')
    if (el) {
      el.style.overflowX = dir === 'vertical' ? 'hidden' : 'auto'
      el.style.overflowY = dir === 'horizontal' ? 'hidden' : 'auto'
    }
    setScrollDirection(dir)
  }

  useEffect(() => {
    const resetScrollDirection = () => {
      let el = document.querySelector('#table-wrapper')
      if (el) {
        el.style.overflowX = 'auto'
        el.style.overflowY = 'auto'
      }
      setScrollDirection(null)
    }
    document.addEventListener('touchend', resetScrollDirection)
    return () => {
      document.removeEventListener('touchend', resetScrollDirection)
    }
  }, [])

  return (
    <Paper
      sx={{ display: 'flex', flexDirection: 'column', height: { xs: '95vh', lg: 'auto' } }}
      onTouchStart={isSmall ? handleTouchStart : undefined}
      onTouchMove={isSmall ? handleTouchMove : undefined}
    >
      <Box
        sx={{
          p: { xs: 1, lg: 1.5 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '.5rem', md: '1rem' }
        }}
      >
        <FormControl variant="standard" sx={{ width: '100%', maxWidth: '500px' }}>
          <Input
            placeholder="Search ..."
            onChange={handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <TableContainer
        sx={{
          overflowX: isSmall && scrollDirection === 'vertical' ? 'hidden' : 'auto',
          overflowY: isSmall && scrollDirection === 'horizontal' ? 'hidden' : 'auto'
        }}
        id="table-wrapper"
      >
        <Table sx={{ minWidth: 650 }} aria-label="user subtitles table">
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1100,
              bgcolor: '#0b7162',
              height: '50px'
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{
                    color: 'white',
                    whiteSpace: 'nowrap',
                    maxWidth: column.width,
                    textAlign: column.align || 'left',
                    fontWeight: column.bold ? 'bold' : 'normal'
                  }}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subs
              .filter((row) =>
                columns.some((column) => {
                  const cellValue = row[column.field]
                  return cellValue && cellValue.toString().toLowerCase().includes(searchTerm)
                })
              )
              .map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  sx={{
                    height: '40px',
                    borderTop: 'none',
                    borderBottom: 'none'
                  }}
                  onClick={() => navigate(row.fullLink)}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={`${row.id}-${column.field}`}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        border: 'none',
                        height: 'inherit',
                        p: '0',
                        maxWidth: column.width,
                        textAlign: column.align || 'left',
                        fontWeight: column.bold ? 'bold' : 'normal'
                      }}
                    >
                      <Link
                        to={row.fullLink}
                        style={{ justifyContent: column.align ?? 'flex-start' }}
                      >
                        {column.valueGetter
                          ? column.valueGetter(row[column.field], row)
                          : row[column.field]}
                      </Link>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
