export const badge_definition = [
  {
    badge: 'admin',
    description: 'Administrator',
    image: '/badges/Admin.png'
  },
  {
    badge: 'vip',
    description: 'VIP SubSourcer\nLoves Subtitles, Loves SubSource!',
    image: '/badges/vip.webp'
  },
  {
    badge: 'up1',
    description: 'Verified Uploader\nMore than 200 subtitles uploaded!',
    image: '/badges/up1.webp'
  },
  {
    badge: 'up2',
    description: 'Persistent Uploader\nMore than 1000 subtitles uploaded!',
    image: '/badges/up2.webp'
  },
  {
    badge: 'up3',
    description: 'Legendary Uploader\nMore than 5000 subtitles uploaded!',
    image: '/badges/up3.webp'
  },
  {
    badge: 'tr1',
    description: 'Verified Translator\nMore than 75 translations & 1 year of practice!',
    image: '/badges/tr1.webp'
  },
  {
    badge: 'tr2',
    description: 'Distinguished Translator\nMore than 400 translations & 4 years of practice!',
    image: '/badges/tr2.webp'
  },
  {
    badge: 'tr3',
    description: 'The Ancient One\nMore than 1000 translations & 10 years of practice!',
    image: '/badges/tr3.webp'
  },
  {
    badge: 'do1',
    description: 'Bronze Donator',
    image: '/badges/do1.webp'
  },
  {
    badge: 'do2',
    description: 'Silver Donator',
    image: '/badges/do2.webp'
  },
  {
    badge: 'do3',
    description: 'Gold Donator',
    image: '/badges/do3.webp'
  },
  {
    badge: 'do4',
    description: 'The Generous One',
    image: '/badges/do4.webp'
  },
  {
    badge: 'rt1',
    description: 'Subtitle Supporter\nMore than 1000 rates given!',
    image: '/badges/rt1.webp'
  },
  {
    badge: 'rt2',
    description: 'Subtitle Enthusiast\nMore than 10,000 rates given!',
    image: '/badges/rt2.webp'
  }
]