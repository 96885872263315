import { Box, Link, List, ListItem, Paper, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import Poster from './Poster'

export function HorizontalMovieCard({ movie, posterHeight, posterAspectRatio = '3/4', hideIMDB }) {
  const navigate = useNavigate()
  const darkmode = useDarkMode()
  const onLinkClicked = (e) => {
    if (!e.ctrlKey) {
      e.preventDefault()
      navigate(movie.fullLink)
    }
  }
  return (
    <Paper
      elevation={4}
      sx={{
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        gap: { xs: '0.5rem', lg: '1rem' },
        borderRadius: '10px',
        padding: { xs: '5px', md: '15px' }
      }}
      className="movie-card"
    >
      <Box sx={{}}>
        <Link
          onClick={onLinkClicked}
          href={movie.fullLink}
          sx={{
            display: 'block',
            width: { xs: '120px' },
            height: '100%'
          }}
        >
          <Poster
            src={movie.poster}
            alt={movie.title}
            aspectRatio={posterAspectRatio}
            height={posterHeight}
          />
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          flexDirection: 'column',
          justifyContent: 'space-around'
        }}
      >
        {/* title */}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '0.5rem'
          }}
        >
          <Link
            onClick={onLinkClicked}
            href={movie.fullLink}
            sx={{
              textDecoration: 'none',
              overflow: 'hidden',
              flexGrow: 1,
              color: darkmode.value ? 'white' : '#21495F'
            }}
          >
            <Typography
              variant="h3"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {movie.title}
            </Typography>
          </Link>
          {!hideIMDB ? (
            <Link
              sx={{ color: '#21495F', display: { xs: 'none', sm: 'inline-block' } }}
              href={
                movie?.imdb?.startsWith('tt')
                  ? 'https://www.imdb.com/title/' + movie.imdb
                  : movie.imdb
              }
              target="_blank"
            >
              <img
                fetchPriority="high"
                alt={'imdb'}
                src={'/icons/imdb.png'}
                width={'25px'}
                style={{ borderRadius: '50%' }}
              />
            </Link>
          ) : (
            ''
          )}
        </Box>
        {/*  */}
        <List sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '.2rem', md: '.5rem' } }}>
          {movie.subs.map((sub) => (
            <ListItem disablePadding key={sub.id}>
              <Link
                onClick={(e) => {
                  if (!e.ctrlKey) {
                    e.preventDefault()
                    navigate('/subtitle/' + sub.linkName + '/' + sub.language + '/' + sub.id)
                  }
                }}
                className="image-hover"
                href={'/subtitle/' + sub.linkName + '/' + sub.language + '/' + sub.id}
                sx={{
                  // width: '100%',
                  textDecoration: 'none',
                  bgcolor: darkmode.value ? '#0082842b' : '#005d8414',
                  borderRadius: '5px',
                  p: '5px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: darkmode.value ? 'white' : '#21495F'
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {/* <SubtitleIcon
                  style={{
                    fontSize: '1rem',
                    color: 'green',
                    mr: '0.15rem'
                  }}
                /> */}
                  {sub.languageFormatted} Subtitle By {sub.uploadedByUsername}
                </Typography>
              </Link>
            </ListItem>
          ))}
        </List>
        {/*  */}
        <Typography
          sx={{
            fontFamily: 'Medium-Family',
            fontSize: '0.8rem',
            display: 'flex',
            alignItems: 'center',
            color: darkmode.value ? 'white' : 'initial'
          }}
        >
          {movie.totalDownloads} downloads of {movie.totalSubs} subtitles
        </Typography>
      </Box>
    </Paper>
  )
}
