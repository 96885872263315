import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import SendToMobileIcon from '@mui/icons-material/SendToMobile'
import Countdown from 'react-countdown'
import { ThreeDots } from 'react-loading-icons'
import { Box, TextField, Typography } from '@mui/material'
import SubsceneLogo from '../../../imgs/ss.png'
import axios from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { dispachAlert } from '../../../utils/dispachers';
const RegularFont = 'Regular-Family'
const BoldFont = 'Bold-Family'

function DashboardImportProfileModal({ show, toggleShow }) {
  const [id, setId] = useState('')
  const [code, setCode] = useState('')
  const [onCountdown, setOnCountdown] = useState(false)
  const [codeSending, setCodeSending] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [codeChecking, setCodeChecking] = useState(false)
  const [timeLeft, setTimeLeft] = useState(120000)
  const [idError, setIdError] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [idErrorText, setIdErrorText] = useState('')
  const [codeErrorText, setCodeErrorText] = useState('')
  const queryClient = useQueryClient()
  const startDate = React.useRef(Date.now())
  const handleIdChange = (e) => {
    if (!isNaN(e.target.value)) {
      setId(e.target.value.trim())
    }
  }
  const CountdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return toggleCountDown()
    } else {
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )
    }
  }
  const onClose = () => {
    setOnCountdown(false)
    toggleShow()
  }
  const handleSendCode = () => {
    setCodeSending(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/user/sendSubsceneOtp',
        { subsceneId: id },
        { withCredentials: true }
      )
      .then((res) => {
        const resultData = res?.data
        if (resultData) {
          if (resultData.success) {
            setCodeSent(true)
            setOnCountdown(true)
            setTimeLeft(120000)
            setIdError(false)
            setIdErrorText('')
            startDate.current = Date.now()
          } else {
            if (
              resultData.errCode === 1 ||
              resultData.errCode === 2 ||
              resultData.errCode === 3 ||
              resultData.errCode === 4
            ) {
              setIdError(true)
              setIdErrorText(resultData.errMsg)
            } else if (resultData.errCode === 5) {
              setOnCountdown(true)
              setTimeLeft(parseInt(resultData.timeRemaining) * 1000)
              startDate.current = Date.now()
              setCodeSent(true)
            } else if (resultData.errCode === 10) {
              dispachAlert(
                'error',
                'Your Username was not found in subscene search, please search this user (SubSource) in subscene messages and send a message to us. (message text is not important)\nThen try again.'
              )
              setIdError(true)
              setIdErrorText(
                'Your Username was not found in subscene search, please search this user (SubSource) in subscene messages and send a message to us. (message text is not important)\nThen try again.'
              )
            } else {
              setIdError(true)
              setIdErrorText(resultData.errMsg)
            }
          }
        } else {
          setIdError(true)
          setIdErrorText('Error from server.')
        }
        setCodeSending(false)
      })
      .catch((err) => {
        console.log(err)
        setCodeSending(false)
        setIdError(true)
        setIdErrorText('Error from server.')
      })
  }
  const handleCodeChange = (e) => {
    if (!isNaN(e.target.value)) {
      setCode(e.target.value)
      if (e.target.value.length === 6) {
        handleCodeSubmit(e.target.value)
      } else if (e.target.value.length > 6) {
        setCode(e.target.value.slice(0, 6))
        handleCodeSubmit(e.target.value)
      }
    }
  }
  const handleCodeSubmit = (code) => {
    setCodeChecking(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/user/verifySubsceneOtp',
        { code: code },
        { withCredentials: true }
      )
      .then((res) => {
        const resultData = res?.data
        if (resultData) {
          if (resultData.success) {
            window.location.reload()
            queryClient.invalidateQueries({
              queryKey: ['getUser']
            })
            setCodeChecking(false)
            toggleShow()
          } else {
            if (resultData.errCode === 1) {
              setCodeError(true)
              setCodeErrorText(resultData.errMsg)
            } else {
              setCodeError(true)
              setCodeErrorText(resultData.errMsg)
            }
          }
        } else {
          setCodeError(true)
          setCodeErrorText('Error from server.')
        }
        setCodeChecking(false)
      })
      .catch((err) => {
        setCodeError(true)
        setCodeErrorText('Error from server.')
        setCodeChecking(false)
      })
    setCodeChecking(true)
    setTimeout(() => {}, 3000)
  }
  const toggleCountDown = () => setOnCountdown((p) => !p)
  return (
    <>
      <Dialog open={show} onClose={onClose} aria-labelledby={'Subscene ID'}>
        <DialogTitle
          sx={{
            display: 'flex',
            w: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            paddingTop: '3rem'
          }}
        >
          <img src={SubsceneLogo} alt="Subscene" style={{ width: '80px', borderRadius: '50%' }} />
        </DialogTitle>
        <DialogContent>
          <Box
            fontFamily={BoldFont}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              p: '1.5rem',
              paddingTop: '0.5rem'
            }}
          >
            <Typography
              sx={{
                fontFamily: RegularFont,
                fontSize: '1.4rem',
                color: 'black',
                textAlign: 'center',
                maxWidth: '25rem',
                alignSelf: 'center',
                mb: '1rem'
              }}
            >
              {'Enter your Subscene user ID, then check your Subscene messages for '}
              <u>confirmation code.</u>
            </Typography>
            <Typography
              sx={{
                fontFamily: RegularFont,
                fontSize: '1rem',
                textAlign: 'center',
                maxWidth: '25rem',
                marginTop: '0.5rem',
                alignSelf: 'center'
              }}
            >
              {'User ID Example:'}
              <br></br>subscene.com/u/<b style={{ color: 'red' }}>123456</b>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '3rem'
              }}
            >
              <TextField
                error={idError}
                helperText={idErrorText}
                disabled={onCountdown}
                id="Subscene_id"
                value={id}
                onChange={handleIdChange}
                variant="standard"
                placeholder="Subscene ID"
                sx={{
                  textAlign: 'center',
                  py: '0.5rem',
                  'input:focus::placeholder': { color: 'transparent' },
                  alignSelf: 'center',
                  mr: '5.5rem',
                  '& .MuiFormHelperText-root': {
                    textWrap: 'balance',
                    maxWidth: '200px'
                  }
                }}
                inputProps={{
                  sx: {
                    textAlign: 'center',
                    fontFamily: RegularFont,
                    '-webkit-user-select': 'text !important' /* Chrome, Opera, Safari */,
                    '-moz-user-select': 'text !important' /* Firefox 2+ */,
                    '-ms-user-select': 'text !important' /* IE 10+ */,
                    'user-select': 'text !important' /* Standard syntax */
                  }
                }}
              />
              <Button
                disabled={onCountdown || codeSending}
                disableRipple
                variant="contained"
                size="small"
                sx={{
                  lineHeight: 'normal',
                  py: '0.35rem',
                  fontFamily: RegularFont,
                  textTransform: 'none',
                  marginLeft: '0.75rem',
                  height: '2rem',
                  maxHeight: '2rem',
                  width: '5rem',
                  minWidth: '5rem',
                  position: 'fixed',
                  marginRight: '-14rem'
                }}
                onClick={handleSendCode}
              >
                {onCountdown ? (
                  <Countdown date={startDate.current + timeLeft} renderer={CountdownRenderer} />
                ) : codeSending ? (
                  <>
                    <ThreeDots width={'2rem'} height={'2rem'} />
                  </>
                ) : (
                  <>Send{<SendToMobileIcon sx={{ fontSize: '1.2rem' }} />}</>
                )}
              </Button>
            </Box>
            <Box
              sx={{
                justifyContent: 'center',
                transition: '0.3s all ease-in-out',
                transform: !codeSent ? 'translateY(-48px)' : 'translateY(0)',
                maxWidth: 'fit-content',
                alignSelf: 'center',
                opacity: !codeSent ? '0%' : '100%',
                display: !codeSent ? 'none' : 'flex'
              }}
            >
              <TextField
                disabled={codeChecking}
                error={codeError}
                helperText={codeErrorText}
                id="code"
                value={code}
                onChange={handleCodeChange}
                variant="standard"
                placeholder="Code"
                sx={{
                  textAlign: 'center',
                  py: '0.5rem',
                  'input:focus::placeholder': { color: 'transparent' },
                  alignSelf: 'center'
                }}
                inputProps={{ sx: { textAlign: 'center', fontFamily: RegularFont } }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ fontFamily: BoldFont, px: '1rem' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DashboardImportProfileModal
