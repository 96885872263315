import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { userPermissions, } from '../../../api/adminApi';
import { dispachAlert } from '../../../utils/dispachers';

function Permissions({ user, isLoading }) {
    const [permissions, setPermissions] = useState([]);
    const queryClient = useQueryClient();
    const { refetch: submitPermissions, isFetching: isSubmitting } = useQuery({
        queryKey: ["adminPermissions"],
        queryFn: async () => {
            try {
                const result = await userPermissions(user.id, permissions);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const handleClickRestrict = () => {
        submitPermissions();
    }
    useEffect(() => {
        setPermissions(user.privileges)
    }, [user])
    const handleChange = (e) => {
        if (!permissions.includes(e.target.value)) {
            setPermissions(p => p.concat([e.target.value]))
        } else {
            setPermissions(p => p.filter(x => x !== e.target.value))
        }
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '20px' }}>Permissions </Typography>
        <Box sx={{ display: 'flex' }}>
          <FormGroup sx={{ display: 'flex', gap: '1rem', flexFlow: 'wrap', flex: 1 }}>
            <FormControlLabel
              value={'1'}
              control={<Checkbox checked={permissions.includes('1')} onChange={handleChange} />}
              label="1"
            />
            <FormControlLabel
              value={'2'}
              control={<Checkbox checked={permissions.includes('2')} onChange={handleChange} />}
              label="2"
            />
            <FormControlLabel
              value={'3'}
              control={<Checkbox checked={permissions.includes('3')} onChange={handleChange} />}
              label="3"
            />
            <FormControlLabel
              value={'4'}
              control={<Checkbox checked={permissions.includes('4')} onChange={handleChange} />}
              label="4"
            />
            <FormControlLabel
              value={'5'}
              control={<Checkbox checked={permissions.includes('5')} onChange={handleChange} />}
              label="5"
            />
            <FormControlLabel
              value={'6'}
              control={<Checkbox checked={permissions.includes('6')} onChange={handleChange} />}
              label="6"
            />
            <FormControlLabel
              value={'7'}
              control={<Checkbox checked={permissions.includes('7')} onChange={handleChange} />}
              label="7"
            />
            <FormControlLabel
              value={'8'}
              control={<Checkbox checked={permissions.includes('8')} onChange={handleChange} />}
              label="8"
            />
            <FormControlLabel
              value={'9'}
              control={<Checkbox checked={permissions.includes('9')} onChange={handleChange} />}
              label="9"
            />
            <FormControlLabel
              value={'10'}
              control={<Checkbox checked={permissions.includes('10')} onChange={handleChange} />}
              label="10"
            />
            <FormControlLabel
              value={'10-01'}
              control={<Checkbox checked={permissions.includes('10-01')} onChange={handleChange} />}
              label="10-01"
            />
            <FormControlLabel
              value={'10-02'}
              control={<Checkbox checked={permissions.includes('10-02')} onChange={handleChange} />}
              label="10-02"
            />
            <FormControlLabel
              value={'10-03'}
              control={<Checkbox checked={permissions.includes('10-03')} onChange={handleChange} />}
              label="10-03"
            />
            <FormControlLabel
              value={'11'}
              control={<Checkbox checked={permissions.includes('11')} onChange={handleChange} />}
              label="11"
            />
            <FormControlLabel
              value={'12'}
              control={<Checkbox checked={permissions.includes('12')} onChange={handleChange} />}
              label="12"
            />
            <FormControlLabel
              value={'13'}
              control={<Checkbox checked={permissions.includes('13')} onChange={handleChange} />}
              label="13"
            />
            <FormControlLabel
              value={'14'}
              control={<Checkbox checked={permissions.includes('14')} onChange={handleChange} />}
              label="14"
            />
            <FormControlLabel
              value={'15'}
              control={<Checkbox checked={permissions.includes('15')} onChange={handleChange} />}
              label="15"
            />
            <FormControlLabel
              value={'16'}
              control={<Checkbox checked={permissions.includes('16')} onChange={handleChange} />}
              label="16"
            />
            <FormControlLabel
              value={'17'}
              control={<Checkbox checked={permissions.includes('17')} onChange={handleChange} />}
              label="17"
            />
            <FormControlLabel
              value={'18'}
              control={<Checkbox checked={permissions.includes('18')} onChange={handleChange} />}
              label="18"
            />
            <FormControlLabel
              value={'19'}
              control={<Checkbox checked={permissions.includes('19')} onChange={handleChange} />}
              label="19"
            />
            <FormControlLabel
              value={'20'}
              control={<Checkbox checked={permissions.includes('20')} onChange={handleChange} />}
              label="20"
            />
          </FormGroup>
        </Box>
        <Button
          variant="outlined"
          disableRipple
          color="primary"
          disabled={isLoading || isSubmitting}
          sx={{ textTransform: 'none' }}
          onClick={handleClickRestrict}
        >
          Apply
        </Button>
      </Box>
    )
}

export default Permissions