import { Box, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ContribIcon from '../../icons/contribIcon.png'
import Poster from './Poster'

export default function VerticalMovieCard({ movie, darkmode, hideDownloads }) {
  return (
    <Paper
      elevation={4}
      sx={{
        padding: '0.5rem',
        borderRadius: '10px'
      }}
      className="movie-card"
    >
      {/* image */}
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: movie?.isContrib ? 'flex' : 'none',
            position: 'absolute',
            flexDirection: 'column',
            right: '0',
            zIndex: 2,
            borderRadius: '100%',
            p: '.3rem',
            bgcolor: 'black',
            alignItems: 'center'
          }}
        >
          <Tooltip placement="top" title="Collaborated">
            <img
              src={ContribIcon}
              alt="Contributed"
              style={{
                width: '20px',
                height: '20px',
                objectFit: 'contain',
                filter: darkmode ? 'invert(1)' : 'none'
              }}
            />
          </Tooltip>
        </Box>
        <Poster src={movie.poster} alt={movie.title} />
      </Box>
      {/* title */}
      <Box sx={{ width: '100%', py: { xs: '0.5rem' } }}>
        <Typography
          sx={{
            fontFamily: 'Medium-Family',
            fontWeight: 'bold',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {movie?.title || movie?.subMovieName}
        </Typography>
        {hideDownloads ? (
          ''
        ) : (
          <Typography sx={{ textAlign: 'center' }}>{`${movie.downloads} Downloads`}</Typography>
        )}
      </Box>
    </Paper>
  )
}
