import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode';
const rates = [
    "N/A",
    "23.976",
    "24.00",
    "25.00",
    "29.97",
    "30.00",
    "50.00",
    "60.00"
]
function FrameRateInput({ rate, setRate, disabled }) {
    const darkmode = useDarkMode(false);
    const onChnage = (e) => {
        setRate(e.target.value)
    }
    return (
        <Box sx={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold", color: darkmode.value ? "white" : "initial" }}>Frame Rate (FPS)</Typography>
            <Box sx={{ minWidth: 120 }}>
                <FormControl>
                    <Select
                        disabled={disabled}
                        variant='standard'
                        labelId="Type"
                        id="Type"
                        value={rate}
                        label="Type"
                        onChange={onChnage}
                    >
                        {rates.map((t, index) => (
                            <MenuItem key={index} value={t}>{t}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default FrameRateInput