import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { getHomePage, setHomePage } from '../../api/adminApi'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';

function HomePage() {
  const queryClient = useQueryClient()
  const [ homepageData, setHomepageData ] = useState( { movies: [], series: [] } )
  set_meta({
    is_landing: false
  })
  useQuery({
    queryKey: ['adminGetHomepage'],
    queryFn: async () => {
      const _data = await getHomePage()
      setHomepageData({ movies: _data.current.movies, series: _data.current.series })
      return _data
    }
  })
  const { refetch: submitData, isFetching: isSubmitting } = useQuery({
    queryKey: ['adminSetHomePage'],
    queryFn: async () => {
      const _data = await setHomePage(homepageData)
      if (_data.success) {
        queryClient.invalidateQueries({
          queryKey: ['adminGetHomepage']
        })
        dispachAlert('success', 'success')
      }
      return _data
    },
    enabled: false
  })
  const handleEdit = (type, index, key, value) => {
    if (type === 'movie') {
      var array = homepageData.movies
      array[index] = { ...array[index], [key]: value }
      setHomepageData((p) => ({ ...p, movies: array }))
    } else {
      var _array = homepageData.series
      _array[index] = { ..._array[index], [key]: value }
      setHomepageData((p) => ({ ...p, series: _array }))
    }
  }
  const handleEditSub = (type, movieIndex, subIndex, value) => {
    if (type === 'movie') {
      var array = homepageData.movies
      var subs = homepageData?.movies[movieIndex]?.subs || []
      subs[subIndex] = value
      array[movieIndex] = { ...array[movieIndex], subs: subs }
      setHomepageData((p) => ({ ...p, movies: array }))
    } else {
      var _array = homepageData.series
      var _subs = homepageData?.series[movieIndex]?.subs || []
      _subs[subIndex] = value
      _array[movieIndex] = { ..._array[movieIndex], subs: _subs }
      setHomepageData((p) => ({ ...p, series: _array }))
    }
  }
  return (
    <Paper elevation={4} sx={{ borderRadius: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '24px', fontWeight: 'bold' }}>
          Movies
        </Typography>
        {[0, 1, 2, 3, 4].map((x) => (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <TextField
                  value={homepageData?.movies[x]?.id || ''}
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  label="Movie ID"
                  sx={{ flex: 1 }}
                  onChange={(e) => handleEdit('movie', x, 'id', e.target.value)}
                />
                <TextField
                  value={homepageData?.movies[x]?.p || ''}
                  disabled={isSubmitting}
                  size="small"
                  label="Priority"
                  onChange={(e) => handleEdit('movie', x, 'p', e.target.value)}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                {[0, 1, 2].map((y) => (
                  <>
                    <TextField
                      value={homepageData?.movies[x]?.subs[y] || ''}
                      disabled={isSubmitting}
                      size="small"
                      label="Sub ID"
                      fullWidth
                      onChange={(e) => handleEditSub('movie', x, y, e.target.value)}
                    />
                  </>
                ))}
              </Box>
            </Box>
            <Divider flexItem />
          </>
        ))}
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '24px', fontWeight: 'bold' }}>
          Series
        </Typography>
        {[0, 1, 2, 3, 4].map((x) => (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <TextField
                  value={homepageData?.series[x]?.id || ''}
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  label="Movie ID"
                  sx={{ flex: 1 }}
                  onChange={(e) => handleEdit('series', x, 'id', e.target.value)}
                />
                <TextField
                  value={homepageData?.series[x]?.p || ''}
                  disabled={isSubmitting}
                  size="small"
                  label="Priority"
                  onChange={(e) => handleEdit('series', x, 'p', e.target.value)}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                {[0, 1, 2].map((y) => (
                  <>
                    <TextField
                      value={homepageData?.series[x]?.subs[y] || ''}
                      disabled={isSubmitting}
                      size="small"
                      label="Sub ID"
                      fullWidth
                      onChange={(e) => handleEditSub('series', x, y, e.target.value)}
                    />
                  </>
                ))}
              </Box>
            </Box>
            <Divider flexItem />
          </>
        ))}
        <Button
          variant="outlined"
          disabled={isSubmitting}
          onClick={(e) => {
            submitData()
          }}
        >
          Submit
        </Button>
      </Box>
    </Paper>
  )
}

export default HomePage
