import { Box, Button, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { deleteSocialMedia } from '../../../../api/adminApi'
import { dispachAlert } from '../../../../utils/dispachers';

function SocialMedia({ user, isLoading }) {
    const [social, setSocial] = useState(undefined);
    const queryClient = useQueryClient();
    const { isFetching } = useQuery({
        queryKey: ['adminDeleteSocial', social],
        queryFn: async () => {
            try {
                const result = await deleteSocialMedia(user.id, social);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: social !== undefined
    })
    const handleClick = (sm) => {
        const answer = window.confirm("Confirm Delete?");
        if (answer) {
            setSocial(sm)
        }
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '20px' }}>Social</Typography>
        <Box sx={{ display: 'flex', gap: '1rem', flexFlow: 'wrap' }}>
          <Button
            variant="outlined"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('insta')}
          >
            Delete Instagram
          </Button>
          <Button
            variant="outlined"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('facebook')}
          >
            Delete Facebook
          </Button>
          <Button
            variant="outlined"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('twitter')}
          >
            Delete Twitter
          </Button>
          <Button
            variant="outlined"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('telegram')}
          >
            Delete Telegram
          </Button>
          <Button
            variant="outlined"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('bio')}
          >
            Delete Bio
          </Button>
          <Button
            variant="outlined"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('avatar')}
          >
            Delete Avatar
          </Button>
          <Button
            variant="contained"
            color="error"
            disableRipple
            sx={{ textTransform: 'none' }}
            disabled={isFetching || isLoading}
            onClick={() => handleClick('all')}
          >
            Delete All
          </Button>
        </Box>
      </Box>
    )
}

export default SocialMedia