import { Box, Typography } from '@mui/material'
import React from 'react'
import a1 from '../imgs/a1.jpeg'
import a2 from '../imgs/a2.jpeg'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo';

function SectionItem({item, index, darkmode}) {
  return (
    <Box
      component={'section'}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: index % 2 === 0 ? 'row-reverse' : 'row' },
        gap: { xs: '20px', lg: '85px' },
        justifyContent: 'space-between',
        mt: { xs: '50px', lg: '100px' }
      }}
    >
      <Box sx={{ flexGrow: 1, width: '100%', maxWidth: { lg: '1000px' } }}>
        <Typography
          variant="h2"
          sx={{
            color: darkmode ? 'white' : '#21495F',
            fontSize: '28px',
            fontWeight: '700',
            mb: {xs: '1rem' , md: '2rem'}
          }}
        >
          {item.heading}
        </Typography>
        <Typography
          sx={{
            color: darkmode ? 'white' : '#21495F',
            fontSize: '20px',
            fontWeight: '500'
          }}
        >
          {item.text}
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: { lg: '500px' },
          width: {lg:'100%'},
          flexGrow: 1,
          backgroundColor: item.imgBackgroundColor,
          border: {
            xs: item.imgBackgroundColor ? undefined : `1px solid ${darkmode ? '#eee' : 'black'}`,
            lg: 'none'
          },
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '40px',
          p: '20px',
          overflow: 'hidden'
        }}
      >
        <img
          src={item.img}
          alt={item.imgAlt}
          width={350}
          height={350}
          style={{ width: '100%', objectFit: 'contain' }}
        />
      </Box>
    </Box>
  )
}
function AboutPage() {
  //
  const darkmode = useDarkMode()
  set_meta({
    is_landing: false
  })
  //
  const sections = [
    {
      heading: 'Foundation',
      text: `SubSource was launched on May 2nd, 2024, in response to Subscene’s announcement of
              shutting down. Initially, our mission was to preserve and continue the legacy of
              Subscene by hosting their Persian and English subtitle databases. However, the
              overwhelming demand from speakers and translators of other languages inspired us to
              broaden our horizons.`,
      img: a1,
      imgAlt: 'Foundation',
      imgBackgroundColor: '#80B9C2'
    },
    {
      heading: 'Vision',
      text: `Our vision now extends beyond Farsi subtitles. We’ve expanded our database and
              infrastructure to include contributors from diverse linguistic backgrounds across the
              globe. Our hope is to foster a collaborative community where passionate individuals
              can come together to enhance the accessibility of subtitles for movies, TV shows, and
              other media. By bridging language gaps, we aim to make entertainment more inclusive
              and enjoyable for everyone.`,
      img: a2,
      imgAlt: 'Foundation',
      imgBackgroundColor: ''
    }
  ]
  return (
    <Box sx={{ margin: '0 auto', mt: '80px', width: '90%' }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: '30px',
          fontWeight: '900',
          color: darkmode.value ? 'white' : '#205e60',
          fontFamily: 'Medium-Family',
          textAlign: 'center'
        }}
      >
        About Us
      </Typography>

      {sections.map((item, index) => (
        <SectionItem item={item} index={index} key={index} darkmode={darkmode.value} />
      ))}
    </Box>
  )
}

export default AboutPage
