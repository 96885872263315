import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react'
import { banUser, unbanUser } from '../../../api/adminApi';
import moment from 'moment';
import { dispachAlert } from '../../../utils/dispachers';
function Bans({ user, isLoading }) {
    const [type, setType] = useState(0);
    const queryClient = useQueryClient();
    const timeRef = useRef();
    const { refetch: _banUser, isFetching: isBanning } = useQuery({
        queryKey: ["adminBan"],
        queryFn: async () => {
            try {
                const result = await banUser(user.id, type, parseInt(timeRef.current.value));
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const { refetch: _unbanUser, isFetching: isUnbanning } = useQuery({
        queryKey: ["adminUnban"],
        queryFn: async () => {
            try {

                const result = await unbanUser(user.id);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const handleClickBan = () => {
        const answer = window.confirm("Confirm banning user?");
        if (answer) {
            _banUser();
        }
    }
    const handleClickUnban = () => {
        const answer = window.confirm("Confirm un-banning user?");
        if (answer) {
            _unbanUser();
        }
    }
    useEffect(() => {
        setType(0)
        timeRef.current.value = ""
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Typography>Current Ban: {user.ban ? `Type ${user.ban.type}, Expires in: ${user.ban.type === 1 ? moment(user.ban.expiresIn).format("LLL") : "Permanent"}` : 'Not Banned.'}</Typography>
            <Box sx={{ display: 'flex', gap: "1rem", flexFlow: "wrap" }}>
                <FormControl sx={{ minWidth: "230px" }}>
                    <InputLabel id="banType" size='small'>Type</InputLabel>
                    <Select
                        labelId="banType"
                        id="banTypeSelect"
                        value={type}
                        size='small'
                        label="Age"
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem value={0} disabled>Select Ban Type</MenuItem>
                        <MenuItem value={1}>Temporary</MenuItem>
                        <MenuItem value={2}>Permanent</MenuItem>
                        <MenuItem value={3}>Permanent (Full Delete)</MenuItem>
                    </Select>
                </FormControl>
                <TextField size='small' type='number' label={"Time (Hours)"} disabled={isBanning || isUnbanning || isLoading || type !== 1} inputRef={timeRef} />
                <Button variant='contained' disableRipple color='error' disabled={isBanning || isUnbanning || isLoading} sx={{ textTransform: "none" }} onClick={handleClickBan}>Ban User</Button>
                <Button variant='outlined' disableRipple color='error' disabled={isBanning || isUnbanning || isLoading} sx={{ textTransform: "none" }} onClick={handleClickUnban}>Un-ban User</Button>
            </Box>
        </Box>

    )
}

export default Bans