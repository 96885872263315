import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useQuery } from '@tanstack/react-query'
import { UserApi } from '../../api/userApi'
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
const RegularFont = 'Regular-Family'
function ForgetPasswordDialog({ handleClose, isOpen, openLogIn }) {
  const [email, setEmail] = useState('')
  const [submiting, setSubmiting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [captchaSolved, setCaptchaSolved] = useState(false)
  const [helperText, setHelperText] = useState({ color: 'green', text: '' })
  const captchaRef = useRef()
  const { refetch } = useQuery({
    queryKey: ['forgetPassword'],
    queryFn: async () => {
      try {
        const forgetPasswordData = await UserApi.forgetPassword(
          email,
          captchaRef.current.getValue()
        )
        if (forgetPasswordData) {
          if (forgetPasswordData.success) {
            setHelperText({ text: 'Password recovery link sent to your Email.', color: 'green' })
            setSubmiting(false)
            captchaRef.current.reset()
          } else {
            console.log(forgetPasswordData)
            if (forgetPasswordData.errCode === 1) {
              setHelperText((p) => ({ ...p, text: 'Email Not Found.', color: 'red' }))
            } else if (forgetPasswordData.errCode === 2) {
              setHelperText((p) => ({ ...p, text: 'Sending email too frequently.', color: 'red' }))
            } else {
              setHelperText((p) => ({ ...p, text: forgetPasswordData?.err, color: 'red' }))
            }
            setSubmiting(false)
            captchaRef.current.reset()
          }
        }
        return { success: true }
      } catch (err) {
        setHelperText((p) => ({ ...p, text: 'Server Error.', color: 'red' }))
        setSubmiting(false)
        captchaRef.current.reset()
        console.log(err)
      }
    },
    enabled: false
  })
  const handleEmailChange = (e) => {
    setIsValid(validateEmail(e.target.value))
    setEmail(e.target.value)
  }
  const handleForgetPassword = () => {
    if (validateEmail(email) && !submiting && isValid) {
      setSubmiting(true)
      setHelperText((p) => ({ ...p, text: '' }))
      setCaptchaSolved(false)
      refetch()
      /* axios.post(process.env.REACT_APP_API_URL + "/auth/forgetpassword", { email: email, c: captchaRef.current.getValue() }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setHelperText({ text: "Password recovery link sent to your Email.", color: "green" });
                    setSubmiting(false);
                    captchaRef.current.reset();
                } else {
                    console.log(res.data)
                    if (res.data.errCode === 1) {
                        setHelperText(p => ({ ...p, text: 'Email Not Found.', color: "red" }));
                    } else if (res.data.errCode === 2) {
                        setHelperText(p => ({ ...p, text: 'Sending email too frequently.', color: "red" }));
                    } else {
                        setHelperText(p => ({ ...p, text: res?.data?.err, color: "red" }));
                    }
                    setSubmiting(false);
                    captchaRef.current.reset();
                }
            }).catch(err => {
                setHelperText(p => ({ ...p, text: 'Server Error.', color: "red" }));
                setSubmiting(false);
                captchaRef.current.reset();
                console.log(err)
            }) */
    }
  }
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        sx: { maxHeight: 'unset !important', maxWidth: '400px' }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'right', p: '0.5rem' }}>
        <IconButton
          children={<CloseIcon />}
          sx={{ borderRadius: '0px' }}
          disableRipple
          onClick={() => {
            handleClose()
          }}
        />
      </Box>
      <DialogTitle
        sx={{
          fontFamily: 'Bold-Family',
          alignSelf: 'center',
          fontWeight: 'bold',
          pt: '0',
          pb: '1rem',
          fontSize: { xs: '1.5rem', md: '1.75rem', lg: '2.25rem' }
        }}
      >
        Forget Password
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: '0.5rem'
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          sx={{
            mb: '1rem',
            '.MuiInputBase-root': { borderRadius: '10px', fontFamily: RegularFont },
            '.MuiFormLabel-root': { fontFamily: RegularFont }
          }}
          variant="outlined"
          onChange={handleEmailChange}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={(e) => {
            setCaptchaSolved(true)
          }}
          onExpired={() => {
            setCaptchaSolved(false)
          }}
          ref={captchaRef}
        />
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', px: '24px' }}
      >
        {helperText.text !== '' ? (
          <>
            <Typography
              sx={{
                display: helperText.text !== '' ? 'flex' : 'none',
                top: '-10px',
                paddingBottom: '1rem'
              }}
              color={helperText.color}
              fontFamily={RegularFont}
            >
              {helperText.text}
            </Typography>
          </>
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          disabled={!isValid || !captchaSolved}
          children={
            <Typography fontFamily={RegularFont} fontSize={'1.2rem'}>
              Send Email
            </Typography>
          }
          fullWidth
          sx={{
            marginBottom: '3rem',
            textTransform: 'none',
            borderRadius: '10px'
          }}
          disableElevation
          disableRipple
          onClick={handleForgetPassword}
        />
        <Button
          variant=""
          children={
            <Typography fontFamily={RegularFont} fontSize={'0.9rem'}>
              Back to Login
            </Typography>
          }
          sx={{
            width: '66%',
            marginBottom: '2rem',
            textTransform: 'none',
            borderRadius: '10px',
            '&:hover': { backgroundColor: 'inherit' },
            color: 'blue'
          }}
          disableElevation
          disableRipple
          onClick={() => {
            handleClose()
            openLogIn()
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ForgetPasswordDialog
