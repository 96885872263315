import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Link,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Grid from 'react-loading-icons/dist/esm/components/grid'
import { useParams } from 'react-router-dom'
import LanguageInput from '../Components/inputs/LanguageInput'
import ReleaseInput from '../Components/EditSub/ReleaseInput'
import ReleaseTypeInput from '../Components/EditSub/ReleaseTypeInput'
import ProductionTypeInput from '../Components/EditSub/ProductionTypeInput'
import CommentInput from '../Components/EditSub/CommentInput'
import axios from 'axios'
import moment from 'moment'
import FrameRateInput from '../Components/EditSub/FrameRateInput'
import useDarkMode from 'use-dark-mode'
import { formatted_langs, langs } from '../data/langs'
import { set_meta } from '../utils/seo';
import { dispachAlert } from '../utils/dispachers';

function EditSubtitlePage() {
  const darkmode = useDarkMode()
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [movie, setMovie] = useState(undefined)
  const [sub, setSub] = useState(undefined)
  const [lang, setLang] = useState('Select Language')
  const [hi, setHi] = useState(false)
  const [fp, setFp] = useState(false)
  const [rate, setRate] = useState('N/A')
  const [files, setFiles] = useState([])
  const [rts, setRts] = useState([
    {
      id: 0,
      value: '',
      display: true
    }
  ])
  const [rt, setRt] = useState("Don't Know")
  const [type, setType] = useState('Translated a Subtitle')
  const [text, setText] = useState('')
  const validateLang = (lang) => {
    if (!lang || lang === 'Select Language') {
      return false
    }
    if (langs.includes(lang)) {
      return true
    }
    return false
  }
  const validateRt = (rt) => {
    const rtypes = [
      "Don't Know",
      'WEB',
      'BLURAY',
      'HDTV',
      'CAM/HDTS/HDRIP',
      'UNOFFICIAL/LEAKED',
      'DVDRip',
      'Other'
    ]
    if (!rt) {
      return false
    }
    if (rtypes.includes(rt)) {
      return true
    }
    return false
  }
  const validateRts = (rts) => {
    var validRts = rts.filter((x) => x.value.trim() !== '' && x.display === true)
    if (validRts.length > 0) {
      return true
    }
    return false
  }
  const validatePt = (type) => {
    const types = [
      'Translated a Subtitle',
      'Transcript (By Listening)',
      'From Retail',
      'Machine Translated',
      'Forced/Foreign Lines Only',
      'Improved a Subtitle'
    ]
    if (!rt) {
      return false
    }
    if (types.includes(type)) {
      return true
    }
    return false
  }
  const validateRtsCharacters = (rts) => {
    var expr = /^[\p{L}\p{N}\p{Zs}?()[/|\]'":!&+@,._ -]+$/mu
    var validRts = rts.filter((x) => x.value.trim() !== '' && x.display === true)
    var validRtsCharacters = validRts.filter((x) => !expr.test(x.value))
    if (validRtsCharacters.length > 0) {
      return false
    }
    return true
  }
  const handleSave = () => {
    if (rts.filter((x) => x.display === true && x.value !== '').length > 10) {
      return dispachAlert('error', 'Can only have maximum of 10 release infos')
    }
    if (!validateLang(lang)) {
      return dispachAlert('error', 'Select a language.')
    }
    if (!validateRt(rt)) {
      return dispachAlert('error', 'Release type not valid.')
    }
    if (!validateRts(rts)) {
      return dispachAlert('error', "Release info can't be empty.")
    }
    if (!validatePt(type)) {
      return dispachAlert('error', 'Production type not valid.')
    }
    if (!validateRtsCharacters(rts)) {
      return dispachAlert('error', 'Unallowed Characters in release name')
    }
    setSaving(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/user/subs/edit',
        {
          subId: sub.id,
          hi: hi,
          comment: text,
          rt: rt,
          fp,
          rate,
          rts: rts.filter((x) => x.display === true && x.value !== '').map((x) => x.value),
          lang: lang,
          type: type
        },
        { withCredentials: true }
      )
      .then((res) => {
        console.log()
        if (res.data.success) {
          return (window.location.href = `/subtitle/${movie.full_linkName}/${formatted_langs[lang]}/${sub.id}`)
        } else {
          dispachAlert('error', 'Error.')
          console.log(res.data.err || 'Error.')
        }
        setSaving(false)
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
        console.log({ err })
        setSaving(false)
      })
  }
  const handleDelete = (id) => {
    var answer = window.confirm('Confirm Deleting Sub?')
    if (answer) {
      setSaving(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/user/subs/delete',
          { subId: id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            return (window.location.href = '/dashboard/mysubsource')
          } else {
            console.log(res.data.err || 'Error.')
          }
          setSaving(false)
        })
        .catch((err) => {
          dispachAlert('error', 'Error.')
          console.log({ err })
          setSaving(false)
        })
    }
  }
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/user/subs/getOne',
        { subId: params.id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          set_meta({
            is_landing: false,
            title: `Editing ${ res?.data?.movie?.title }`
          })
          setSub(res.data.sub)
          setMovie(res.data.movie)
          setLang(res.data.sub.language)
          setRt(res.data.sub.releaseType)
          setRts(
            res.data.sub.releaseInfo.map((x, index) => ({ id: index, display: true, value: x }))
          )
          setType(res.data.sub.productionType)
          setHi(res.data.sub.hearingImpaired)
          setFp(res.data.sub.foreignParts == 1 ? true : false)
          setRate(res.data.sub.framerate || 'N/A')
          setFiles(res.data.sub.fileContents)
          setText(res.data.sub.commentary)
          setLoading(false)
        } else {
          dispachAlert('error', 'Error.')
        }
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
      })
  }, [params.id])
  return loading ? (
    <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
        <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
      </Box>
    </Box>
  ) : (
    <Container
      maxWidth="xl"
      sx={{
        pt: { xs: '1rem', lg: '4rem' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          gap: { xs: '1rem', lg: '3rem' }
        }}
      >
        <Box sx={{ display: 'flex', width: { lg: '75%' }, flexDirection: 'column' }}>
          <Typography variant="h1" color={darkmode.value ? 'white' : '#21495F'}>
            Edit Subtitle
          </Typography>
          <Divider sx={{ borderColor: 'black', borderBottomWidth: '2px' }} />
          <Box>
            <LanguageInput
              disabled={saving}
              lang={lang}
              setLang={setLang}
            />
            <br></br>
            <ReleaseInput disabled={saving} rts={rts} setRts={setRts} />
            <ReleaseTypeInput disabled={saving} rt={rt} setRt={setRt} />
            <ProductionTypeInput disabled={saving} type={type} setType={setType} />
            <FrameRateInput disabled={saving} rate={rate} setRate={setRate} />
            <Box sx={{ display: 'flex', mt: '1rem', color: darkmode.value ? 'white' : 'initial' }}>
              <FormControlLabel
                sx={{ ml: '0px' }}
                control={
                  <Checkbox
                    disabled={saving}
                    checked={hi}
                    onChange={(e, value) => {
                      setHi(value)
                    }}
                    sx={{ p: 0, py: '0.5rem', paddingRight: '0.5rem' }}
                  />
                }
                label="Hearing Impaired"
              />
            </Box>
            <Box sx={{ display: 'flex', mt: '1rem', color: darkmode.value ? 'white' : 'initial' }}>
              <FormControlLabel
                sx={{ ml: '0px' }}
                control={
                  <Checkbox
                    disabled={saving}
                    checked={fp}
                    onChange={(e, value) => {
                      setFp(value)
                    }}
                    sx={{ p: 0, py: '0.5rem', paddingRight: '0.5rem' }}
                  />
                }
                label="Foreign Parts"
              />
            </Box>
            <CommentInput disabled={saving} text={text} setText={setText} />
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Button
                disabled={saving}
                variant="contained"
                onClick={handleSave}
                sx={{
                  textTransform: 'none',
                  my: '1rem',
                  bgcolor: 'teal',
                  '&:hover': { backgroundColor: '#008080E5' }
                }}
                disableRipple
              >
                Save Subtitle
              </Button>
              <Button
                disabled={saving}
                variant="contained"
                onClick={() => handleDelete(sub.id)}
                sx={{
                  textTransform: 'none',
                  my: '1rem',
                  bgcolor: '#DF2D2D',
                  '&:hover': { backgroundColor: '#df2d2dd1' }
                }}
                disableRipple
              >
                Delete Subtitle
              </Button>
            </Box>
          </Box>
          <Divider sx={{ borderColor: 'black', borderBottomWidth: '2px', my: '20px' }} />
          <Typography
            fontSize={'35px'}
            color={darkmode.value ? '#ffffff80' : '#00000080'}
            fontWeight={'bold'}
            fontFamily={'Medium-Family'}
          >
            Files
          </Typography>
          {files.map((file) => (
            <Typography
              fontSize={'12px'}
              color={darkmode.value ? '#ffffff80' : '#00000080'}
              fontWeight={'bold'}
              fontFamily={'Medium-Family'}
            >
              {file}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            height: 'auto',
            width: { lg: '25%' },
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{ display: { xs: 'hidden', lg: 'block' }, visibility: 'hidden' }}
            variant="h1"
            component={'p'}
            noWrap
          >
            Edit Subtitle
          </Typography>
          <Divider sx={{ mb: '0.25rem', borderColor: 'black', borderBottomWidth: '2px' }} />
          <Link
            href={`/subtitles/${
              movie?.type === 'Movie'
                ? movie?.fullLinkName
                : movie.linkName + '/season-' + movie?.season
            }`}
            target="_blank"
            sx={{ fontSize: '30px', wordWrap: 'break-word', fontFamily: 'Medium-Family' }}
          >
            {movie?.title} ({movie?.releaseYear})<br></br>
            {movie.type === 'TVSeries'
              ? movie?.season !== 0
                ? `Season ${movie?.season}`
                : `Others`
              : ''}
          </Link>
          <Typography
            fontSize={'20px'}
            fontFamily={'Medium-Family'}
            sx={{ my: '10px', color: darkmode.value ? 'white' : 'initial' }}
          >
            {movie.subCount} Subtitles Available
          </Typography>
          <img
            src={movie?.poster}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = `${process.env.REACT_APP_API_URL}/notfound.jpg`
            }}
            alt={movie?.title}
            style={{ borderRadius: '15px' }}
          />
          <Typography
            sx={{
              fontSize: '25px',
              fontFamily: 'Medium-Family',
              mt: '1rem',
              color: darkmode.value ? 'white' : '#21495F'
            }}
          >
            Statistics
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'Medium-Family',
              lineHeight: '30px',
              color: darkmode.value ? 'white' : '#21495F'
            }}
          >
            Date Created: {moment(sub.uploadDate).format('YYYY/MM/DD HH:mm:ss')}
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'Medium-Family',
              lineHeight: '30px',
              color: darkmode.value ? 'white' : '#21495F'
            }}
          >
            Downloads: {sub.downloads}
          </Typography>
          <Link
            sx={{
              fontSize: '15px',
              fontFamily: 'Medium-Family',
              lineHeight: '30px',
              color: darkmode.value ? 'white' : '#21495F'
            }}
          >
            View Ratings
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default EditSubtitlePage
