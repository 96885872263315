import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import 'react-dropzone-uploader/dist/styles.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../css/UploadPage.css'
import LanguageInput from '../Components/inputs/LanguageInput'
import ReleaseInput from '../Components/Upload/ReleaseInput'
import ReleaseTypeInput from '../Components/Upload/ReleaseTypeInput'
import ProductionTypeInput from '../Components/Upload/ProductionTypeInput'
import CommentInput from '../Components/Upload/CommentInput'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Grid } from 'react-loading-icons'
import { useParams } from 'react-router-dom'
import { useUserContext } from '../Contexts/userContext'
import FrameRateInput from '../Components/Upload/FrameRateInput'
import NotFound from './NotFound'
import useDarkMode from 'use-dark-mode'
import { useQueryClient } from '@tanstack/react-query'
import { langs } from '../data/langs'
import Poster from '../Components/ui/Poster'
import { set_meta } from '../utils/seo'
import { dispachAlert } from '../utils/dispachers'
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        color={props.value === 100 ? 'success' : 'primary'}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" fontSize={'0.7rem'}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}
function UploadPage2() {
  const darkmode = useDarkMode(false)
  const controllerRef = useRef()
  const user = useUserContext()
  const [loading, setLoading] = useState(true)
  const [movie, setMovie] = useState(undefined)
  const [lang, setLang] = useState('Select Language')
  const [hi, setHi] = useState(false)
  const queryClient = useQueryClient()
  const [fp, setFp] = useState(false)
  const [rts, setRts] = useState([
    {
      id: 0,
      value: '',
      display: true
    }
  ])
  const navigate = useNavigate()
  const params = useParams()
  const [rt, setRt] = useState('WEB')
  const [type, setType] = useState('Translated a Subtitle')
  const [rate, setRate] = useState('N/A')
  const [text, setText] = useState('')
  const [currentFiles, setCurrentFiles] = useState([])
  const [searchedUsers, setSearchedUsers] = useState([])
  const [searchingUsers, setSearchingUsers] = useState(false)
  const [contribs, setContribs] = useState([])
  const [hasContribs, setHasContribs] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    if (!user) {
      navigate('/')
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/getMovieById',
        { movieId: params.id },
        { withCredentials: true }
      )
      .then((res) => {
        set_meta({
          is_landing: false,
          title: `Upload a new subtitle for ${res?.data?.movie?.title}`
        })
        setMovie(res.data.movie)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setNotFound(true)
        }
        console.log({ err })
      })
  }, [params.id, user, navigate])
  const onDrop = useCallback((acceptedFiles) => {
    var finalArr = []
    acceptedFiles.forEach((file) => {
      finalArr.push({
        file: file,
        name: file.name,
        size: file.size,
        type: file.type,
        path: file.path,
        uploaded: false,
        progress: 0,
        uploading: false
      })
    })
    if (acceptedFiles.length > 0) {
      const fileName = new RegExp('(.+?)(.[^.]*$|$)').exec(acceptedFiles[0]?.name)
      handleFillRtsDrop(fileName)
    }
    setCurrentFiles((p) => [...p, ...finalArr])
  }, [])
  const handleFillRtsDrop = (fileName) => {
    setRts((p) => {
      if (p.filter((x) => x.display === true && x.value === '').length === 1) {
        return [
          {
            id: 0,
            value: fileName[1],
            display: true
          }
        ]
      } else {
        return p
      }
    })
  }

  const handleUpload = (files) => {
    let formData = new FormData()
    files.forEach((f) => {
      formData.append('files', f.file)
    })
    axios
      .post(process.env.REACT_APP_API_URL + '/api/upload', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (data) => {
          setUploadProgress(data.progress)
        }
      })
      .then((res) => {
        if (res.data.success) {
          handleSubtitleUpload(res.data.files)
        } else if (res.data.err === 'Merge Error') {
          setUploading(false)
          dispachAlert('error', 'You Cannot Upload Until You Merge Your Account.')
        } else if (res.data.err) {
          setUploading(false)
          dispachAlert('error', res.data.err)
        } else {
          dispachAlert('error', 'Error uploading files to server.')
        }
      })
      .catch((err) => {
        setUploading(false)
        dispachAlert('error', 'Error uploading files to server.')
      })
  }
  const handleSubtitleUpload = (files) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/uploadSub',
        {
          files,
          movieId: movie.id,
          rts: rts.filter((x) => x.display === true && x.value !== ''),
          rt,
          comment: text,
          type,
          hasContribs,
          contribs,
          hi,
          rate,
          fp,
          lang
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          queryClient.invalidateQueries({
            queryKey: ['getUser']
          })
          queryClient.invalidateQueries({
            queryKey: ['getUserNotifs']
          })
          navigate(res.data.link)
        } else {
          dispachAlert('error', res.data.err)
        }
      })
      .catch((err) => {
        console.log({ err })
      })
  }
  const validateLang = (lang) => {
    if (!lang || lang === 'Select Language') {
      return false
    }
    if (langs.includes(lang)) {
      return true
    }
    return false
  }
  const validateRt = (rt) => {
    const rtypes = [
      "Don't Know",
      'WEB',
      'BLURAY',
      'HDTV',
      'CAM/HDTS/HDRIP',
      'UNOFFICIAL/LEAKED',
      'DVDRip',
      'Other'
    ]
    if (!rt) {
      return false
    }
    if (rtypes.includes(rt)) {
      return true
    }
    return false
  }
  const validateRts = (rts) => {
    var validRts = rts.filter((x) => x.value.trim() !== '' && x.display === true)
    if (validRts.length > 0) {
      return true
    }
    return false
  }
  const validateRtsCharacters = (rts) => {
    var expr = /^[\p{L}\p{N}\p{Zs}?()[/|\]'":!&+@,._ -]+$/mu
    var validRts = rts.filter((x) => x.value.trim() !== '' && x.display === true)
    var validRtsCharacters = validRts.filter((x) => !expr.test(x.value))
    console.log(validRtsCharacters)
    if (validRtsCharacters.length > 0) {
      return false
    }
    return true
  }
  const validatePt = (type) => {
    const types = [
      'Translated a Subtitle',
      'Transcript (By Listening)',
      'From Retail',
      'Machine Translated',
      'Forced/Foreign Lines Only',
      'Improved a Subtitle'
    ]
    if (!rt) {
      return false
    }
    if (types.includes(type)) {
      return true
    }
    return false
  }
  const validateFr = (rate) => {
    const rates = ['N/A', '23.976', '24.00', '25.00', '29.97', '30.00', '50.00', '60.00']
    if (rates.includes(rate)) {
      return true
    }
    return false
  }
  const validateContribs = (contribs, hasContribs) => {
    if (hasContribs) {
      if (contribs.length > 0 && contribs.length <= 3) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  const handleUploadButton = () => {
    if (rts.filter((x) => x.display === true && x.value !== '').length > 10) {
      return dispachAlert('error', 'Can only have maximum of 10 release infos')
    }
    if (!validateLang(lang)) {
      return dispachAlert('error', 'Select a language.')
    }
    if (currentFiles.length === 0) {
      return dispachAlert('error', 'No Files Selected.')
    }
    if (!validateRt(rt)) {
      return dispachAlert('error', 'Release type not valid.')
    }
    if (!validateRts(rts)) {
      return dispachAlert('error', "Release info can't be empty.")
    }
    if (!validatePt(type)) {
      return dispachAlert('error', 'Production type not valid.')
    }
    if (!validateContribs(contribs, hasContribs)) {
      return dispachAlert('error', 'Select atleast one collaborator.')
    }
    if (!validateFr(rate)) {
      return dispachAlert('error', 'Frame Rate is not valid.')
    }
    if (!validateRtsCharacters(rts)) {
      return dispachAlert('error', 'Unallowed Characters in release name')
    }
    setUploading(true)
    handleUpload(currentFiles)
  }
  const handleSubDelete = (sub) => {
    setCurrentFiles((p) => p.filter((x) => x !== sub))
  }
  const handleContributersInputChange = (e) => {
    setSearchedUsers(null)
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
    controllerRef.current = new AbortController()
    const signal = controllerRef.current.signal
    setSearchingUsers(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/searchUsersContrib',
        { query: e.target.value },
        { signal: signal, withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setSearchingUsers(false)
          setSearchedUsers(res.data.found)
        }
      })
      .catch((err) => {
        if (err.name !== 'CanceledError') {
          setSearchingUsers(false)
        }
        setSearchedUsers([])
      })
  }
  const nameLengthValidator = (file) => {
    if (file?.name?.length > 260) {
      return {
        code: 'name-too-large',
        message: `Name is larger than ${260} characters`
      }
    }
    return null
  }
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 150,
    maxSize: 5000000,
    accept: {
      'text/html': ['.srt', '.txt', '.smi', '.ssa', '.sub', '.ass']
    },
    validator: nameLengthValidator,
    onDropRejected: (e) => dispachAlert('error', e[0].errors[0].message),
    disabled: uploading,
    noClick: true,
    noKeyboard: true
  })
  if (notFound) {
    return <NotFound />
  }
  return loading ? (
    <Box
      sx={{
        height: '800px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black'
      }}
    >
      <Grid fill={darkmode.value ? 'white' : 'black'} />
    </Box>
  ) : (
    <Container
      sx={{
        display: { lg: 'flex' },
        flexDirection: { lg: 'row-reverse' },
        mt: { xs: '1rem', lg: '4rem' },
        gap: { xs: '1rem', lg: '3rem' }
      }}
    >
      {/* movie info */}
      <Box sx={{ width: { lg: '30%' } }}>
        <Typography variant="h1" sx={{ visibility: { lg: 'hidden' } }}>
          Upload a Subtitle: Step 2
        </Typography>
        <Divider sx={{ borderColor: 'rgb(0,0,0,0.35)', marginBottom: '1rem' }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'start' },
            gap: '.5rem',
            flexDirection: { xs: 'row', lg: 'column-reverse' }
          }}
        >
          <Box sx={{ width: { xs: '120px', lg: '100%' } }}>
            <Poster src={movie.poster} alt={movie?.title} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Link
              href={movie.full_linkName}
              onClick={(e) => {
                if (!e.ctrlKey) {
                  e.preventDefault()
                  navigate(movie.full_linkName)
                }
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Medium-Family',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  marginBottom: '0.25rem'
                }}
              >
                {movie?.title} ({movie?.releaseYear})
              </Typography>
            </Link>
            {movie.type === 'TVSeries' && (
              <Typography
                sx={{
                  fontFamily: 'Medium-Family',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  marginBottom: '0.5rem',
                  color: darkmode.value ? 'white' : 'initial'
                }}
              >
                Season {movie.season === 0 ? ' - Others' : movie.season}
              </Typography>
            )}
            <Typography
              sx={{
                fontFamily: 'Medium-Family',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                marginBottom: '0.5rem',
                color: darkmode.value ? 'rgb(255,255,255,0.65)' : 'rgb(0,0,0,0.65)'
              }}
            >
              Subtitles: {movie.subCount} Available
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* Upload */}
      <Box sx={{ width: { xs: '100%', lg: '70%' } }}>
        <Typography
          variant="h1"
          sx={{
            color: darkmode.value ? 'white' : 'initial',
            visibility: { xs: 'hidden', lg: 'visible' }
          }}
        >
          Upload a Subtitle: Step 2
        </Typography>
        <Divider sx={{ borderColor: 'rgb(0,0,0,0.35)', marginBottom: '1rem' }} />
        {/* files */}
        <Box sx={{ minHeight: '100px', width: '100%', borderRadius: '10px', marginTop: '1rem' }}>
          <Button
            variant="contained"
            disabled={uploading}
            onClick={open}
            sx={{ mb: '1rem', bgcolor: 'teal' }}
          >
            <input {...getInputProps()} />
            Upload Files
          </Button>
          <Box
            {...getRootProps()}
            onClick={undefined}
            sx={{
              width: '100%',
              minHeight: '150px',
              backgroundColor: darkmode.value ? '#525252' : 'gainsboro',
              display: 'flex'
            }}
          >
            {currentFiles.length === 0 ? (
              <>
                <Box sx={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}>
                  <Typography variant="h5" sx={{ color: darkmode.value ? 'white' : 'initial' }}>
                    Drop Files Here.
                  </Typography>
                </Box>
              </>
            ) : (
              <Box sx={{ p: '0.5rem', width: '100%' }}>
                {currentFiles.map((x, index) => (
                  <Paper
                    key={index}
                    elevation={3}
                    sx={{
                      p: '0.5rem',
                      bgcolor: darkmode.value ? 'dimgray' : 'lavender',
                      mb: '0.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={{ color: darkmode.value ? 'white' : 'black' }}>
                      {x.name}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <IconButton onClick={() => handleSubDelete(x)}>
                        <HighlightOffIcon sx={{ color: 'red' }} />
                      </IconButton>
                      <Box sx={{ display: uploadProgress === 0 ? 'none' : 'flex' }}>
                        <CircularProgressWithLabel value={uploadProgress * 100} />
                      </Box>
                    </Box>
                  </Paper>
                ))}
              </Box>
            )}
          </Box>
        </Box>
        {/* language */}
        <LanguageInput
          disabled={uploading}
          lang={lang}
          setLang={setLang}
        />
        <br></br>
        <ReleaseInput disabled={uploading} rts={rts} setRts={setRts} />
        <ReleaseTypeInput disabled={uploading} rt={rt} setRt={setRt} />
        <ProductionTypeInput disabled={uploading} type={type} setType={setType} />
        <FrameRateInput disabled={uploading} rate={rate} setRate={setRate} />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem' }}>
          <Checkbox
            disabled={uploading}
            checked={hi}
            onChange={(e, value) => {
              setHi(value)
            }}
            sx={{ p: 0, py: '0.5rem', paddingRight: '0.5rem' }}
          />
          <Typography sx={{ color: darkmode.value ? 'white' : 'initial' }}>
            Hearing Impaired
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '0.25rem',
            color: darkmode.value ? 'white' : 'initial'
          }}
        >
          <Checkbox
            disabled={uploading}
            checked={fp}
            onChange={(e, value) => {
              setFp(value)
            }}
            sx={{ p: 0, py: '0.5rem', paddingRight: '0.5rem' }}
          />
          <Typography>Foreign Parts</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '0.25rem',
            color: darkmode.value ? 'white' : 'initial'
          }}
        >
          <Checkbox
            disabled={uploading}
            checked={hasContribs}
            onChange={(e, value) => {
              setHasContribs(value)
            }}
            sx={{ p: 0, py: '0.5rem', paddingRight: '0.5rem' }}
          />
          <Typography>Any Collaborators?</Typography>
        </Box>

        <Autocomplete
          disabled={!hasContribs || uploading}
          sx={{ display: hasContribs ? 'flex' : 'none' }}
          multiple
          blurOnSelect={false}
          freeSolo={contribs.length >= 3 ? false : true}
          getOptionDisabled={(options) => (contribs.length >= 3 ? true : false)}
          clearOnBlur={false}
          id="Contributors"
          options={searchedUsers || []}
          onInputChange={handleContributersInputChange}
          getOptionLabel={(option) => option.userName}
          defaultValue={[]}
          isOptionEqualToValue={(option, value) => {
            return option.userId === value.userId
          }}
          loading={searchingUsers}
          noOptionsText="No Users Found."
          loadingText="Searching..."
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Collaborators"
              placeholder={
                contribs.length < 3
                  ? `You can add up to ${3 - contribs.length} more collaborators.`
                  : "You can't add any more collaborators."
              }
            />
          )}
          onChange={(e, value) => {
            setContribs(value)
          }}
        />
        <CommentInput disabled={uploading} text={text} setText={setText} />
        <Button
          disabled={uploading}
          variant="contained"
          onClick={handleUploadButton}
          sx={{ textTransform: 'none', my: '1rem', bgcolor: 'teal', color: 'white' }}
        >
          Submit Subtitle
        </Button>
      </Box>
    </Container>
  )
}

export default UploadPage2
