import { Box, Container, Divider, Link, List, ListItem, Paper, Rating, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import NotFound from './../NotFound'
import { Grid } from 'react-loading-icons'
import useDarkMode from 'use-dark-mode'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp'
import RatingCard from '../../Components/Ratings/Card';
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';

function UserRatings() {
  window.scrollTo(0, 0)
  const darkmode = useDarkMode()
  const params = useParams()
  const navigate = useNavigate()
  const [notFound, setNotFound] = useState(false)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const handleBackToProfile = () => {}
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/getRatings',
        { userId: params.id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          set_meta({
            is_landing: false,
            title: `SubSource: ${ res?.data?.user?.username }'s Profile.`
          })
          setData(res.data)
          setLoading(false)
        } else {
          dispachAlert('error', 'Error.')
          window.location.href = '/'
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setNotFound(true)
        }
        console.log(err)
      })
  }, [params.id])
  if (notFound) {
    return <NotFound />
  }
  if (loading) {
    return (
      <>
        <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
            <Grid fill={darkmode.value ? 'white' : 'black'} fontSize={'0.5rem'} />
          </Box>
        </Box>
      </>
    )
  }
  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: '1rem',
        display: { lg: 'flex' },
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '2rem'
      }}
    >
      <Box sx={{ width: { lg: '60%' } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'fit-content',
            mb: '10px'
          }}
          onClick={handleBackToProfile}
        >
          <Link
            href={'/u/' + params.id}
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/u/' + params.id)
              }
            }}
            sx={{ display: 'contents', color: darkmode.value ? 'white' : 'black' }}
          >
            <Paper
              sx={{
                width: 'fit-content',
                py: '0.25rem',
                px: '0.25rem',
                m: '0.25rem',
                backgroundColor: 'rgb(255,255,255,0.6)',
                transform: 'scale(0.75)'
              }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: '0.75rem', color: 'black' }} />
            </Paper>
            <Typography
              fontFamily={'Medium-Family'}
              fontSize={'15px'}
              sx={{ lineHeight: 'unset', color: darkmode.value ? 'white' : 'initial' }}
            >
              {' '}
              {'Back To user profile'}
            </Typography>
          </Link>
        </Box>
        <Divider
          sx={{
            borderColor: darkmode.value ? 'white' : 'black',
            borderBottomWidth: '2px',
            mb: '20px'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: '0.5rem',
            mb: '1rem'
          }}
        >
          <Typography
            sx={{ display: 'flex', gap: '0.5rem' }}
            variant="h1"
            color={darkmode.value ? 'white' : '#21495F'}
          >
            <Tooltip title="Only SubSource Ratings" placement="top" arrow>
              <ErrorOutlineSharpIcon
                sx={{
                  width: '20px',
                  height: '20px',
                  ml: '0.4rem',
                  color: darkmode.value ? 'white' : 'initial',
                  alignSelf: 'center'
                }}
              />
            </Tooltip>
            {data.totalRates} Ratings received
          </Typography>
          <Rating
            value={data.user.rate}
            max={5}
            precision={0.5}
            readOnly
            sx={{
              display: data.user.rate === -1 ? 'none' : 'flex',
              alignSelf: 'center',
              '& .MuiRating-decimal': { marginLeft: '-2px', color: 'teal' }
            }}
            size="medium"
          ></Rating>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: '1rem',
            mt: '0.25rem'
          }}
        >
          <List sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem', pt: '1rem' }}>
            {data.rates
              .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
              .map( ( x, index ) =>
              {
                let rating = { ...x, subLink: x.fullLink, subMovieName: x.movieName, fromName : x.from}
                return (
                  <ListItem key={index} disablePadding>
                    <RatingCard rating={rating} />
                  </ListItem>
                )
              })}
          </List>
          <Paper
            sx={{
              display: data.ratesNotShown > 0 ? 'flex' : 'none',
              justifyContent: 'space-between',
              py: '10px',
              px: '5px'
            }}
            elevation={3}
          >
            <Typography sx={{ fontSize: '16px' }}>
              Additional {data.ratesNotShown} ratings not shown.{' '}
            </Typography>
          </Paper>
        </Box>
      </Box>
      <Box
        sx={{
          height: 'auto',
          width: { lg: '40%' },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Divider
          sx={{
            mb: '0.25rem',
            mt: '44.25px',
            borderColor: darkmode.value ? 'white' : 'black',
            borderBottomWidth: '2px'
          }}
        />
        <Box sx={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', mt: '0.5rem' }}>
          <Typography
            fontSize={'25px'}
            fontWeight={'bold'}
            sx={{ color: darkmode.value ? 'white' : '#21495F' }}
          >
            {data.user.username}
          </Typography>
          <Typography fontSize={'18px'} sx={{ color: darkmode.value ? 'white' : '#21495F' }}>
            {data.user.totalSubs} Subtitles
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default UserRatings
