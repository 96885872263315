import { Avatar, Box, Button, Divider, InputBase, Link, Paper, Typography } from '@mui/material'
import React, { useRef, useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import axios from 'axios'
import './Messages.css'
import Bubble from './Bubble'
import ContribBubble from './ContribBubble'
import { useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import { dispachAlert } from '../../../utils/dispachers';
const BoldFont = 'Bold-Family'
function Messages({ chat, setChats, chats, setVisibleChats }) {
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  const messagesRef = useRef()
  const textRef = useRef()
  const [isSending, setIsSending] = useState(false)
  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTo({
        top: messagesRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [chat])
  const handleContribStateChange = (subId, state) => {
    var oldChats = JSON.parse(JSON.stringify(chats))
    oldChats
      .filter((x) => x.chatId === chat.chatId)[0]
      .messages.filter((x) => x.isContrib === true && x.subId === subId)[0].state = state
    setChats(oldChats)
    setVisibleChats(oldChats)
  }
  const handleHideContrib = (subId) => {
    var oldChats = JSON.parse(JSON.stringify(chats))
    oldChats = oldChats.map((x) => {
      if (x.chatId === chat.chatId) {
        x.messages = x.messages.filter((x) => x.subId !== subId)
      }
      return x
    })
    setChats(oldChats)
    setVisibleChats(oldChats)
  }
  const handleSend = () => {
    if (textRef.current.value.length > 0) {
      setIsSending(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/sendMessage',
          { msg: textRef.current.value, chatid: chat.chatId },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            const newMessages = [
              { from: 'me', text: textRef.current.value, time: new Date() },
              ...chat.messages
            ]
            var oldChats = JSON.parse(JSON.stringify(chats))
            oldChats.filter((x) => x.chatId === chat.chatId)[0].messages = newMessages
            setChats(oldChats)
            setVisibleChats(oldChats)
            setIsSending(false)
            textRef.current.value = ''
          } else {
            dispachAlert('error', res.data.errMsg || 'Error.')
            setIsSending(false)
          }
        })
        .catch((err) => {
          setIsSending(false)
          textRef.current.value = ''
        })
    }
  }
  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          height: '10%',
          width: '100%',
          backgroundColor: darkmode.value ? 'rgb(18, 18, 18)' : '#1c232e',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{ backgroundColor: 'mediumpurple', width: '3.5rem', height: '3.5rem', mx: '1rem' }}
          src={chat?.userAvatar ? process.env.REACT_APP_API_URL + chat?.userAvatar : ''}
        >
          {chat?.targetName.slice(0, 1)}
        </Avatar>
        <Link
          href={'/u/' + chat?.userId}
          onClick={(e) => {
            if (!e.ctrlKey) {
              e.preventDefault()
              navigate('/u/' + chat.userId)
            }
          }}
          sx={{ display: 'contents' }}
        >
          <Typography variant="h4" fontFamily={BoldFont} color={'aliceblue'}>
            {chat?.targetName}
          </Typography>
        </Link>
      </Box>
      <Divider sx={{ borderWidth: '1px', borderColor: 'rgb(255,255,255,0.1)' }} />
      <Box
        ref={messagesRef}
        sx={{
          display: 'flex',
          height: '80%',
          minHeight: '250px',
          width: '100%',
          backgroundColor: darkmode.value ? '#2a2a2a' : '#1c232e',
          flexDirection: 'column-reverse',
          overflow: 'auto'
        }}
        className="scrollCustom"
      >
        {chat?.messages
          .sort((a, b) => new Date(b.time) - new Date(a.time))
          .filter((x) => x.isContrib)
          .map((contrib, index) => (
            <ContribBubble
              key={index}
              userId={contrib.userId}
              time={contrib.time}
              me={contrib.me}
              subId={contrib.subId}
              userName={contrib.username}
              subLink={contrib.subLink}
              state={contrib.state}
              handleContribStateChange={handleContribStateChange}
              handleHideContrib={handleHideContrib}
            />
          ))}
        {chat?.messages
          .filter((x) => !x.isContrib)
          .map((msg, index) => (
            <Bubble
              text={msg.text}
              key={index}
              time={msg.time}
              me={msg.from === 'me' ? true : false}
            ></Bubble>
          ))}
      </Box>
      <Box sx={{ display: 'flex', height: 'min-content' }}>
        <Paper
          variant="elevation"
          elevation={1}
          sx={{
            width: '100%',
            backgroundColor: darkmode.value ? 'rgb(18, 18, 18)' : '#1c232e8a',
            p: '1rem',
            display: 'flex'
          }}
        >
          <InputBase
            sx={{
              fontSize: '1.5rem',
              fontFamily: 'Regular-Family',
              color: 'cornsilk',
              width: '100%',
              mr: '1rem'
            }}
            placeholder="Message..."
            onChange={null}
            inputRef={textRef}
            inputProps={{ dir: 'auto', maxLength: 512 }}
            multiline={true}
          ></InputBase>
          <Button
            disabled={isSending}
            onClick={() => {
              handleSend()
            }}
            disableRipple
            variant="contained"
            endIcon={<SendIcon />}
            sx={{
              fontFamily: BoldFont,
              textTransform: 'none',
              fontSize: '1.2rem',
              height: 'max-content'
            }}
          >
            Send
          </Button>
        </Paper>
      </Box>
    </Box>
  )
}

export default Messages
