import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import '../AdminPanel.css'
import ChangePoster from './ChangePoster'
import ChangeTitle from './ChangeTitle'
import Information from './Information'
import ChangeLinkname from './ChangeLinkname'
import MoveAll from './MoveAll'
import ChangeBigPoster from './ChangeBigPoster'
import ChangeSourcelink from './ChangeSourcelink'
import Poster from '../../../Components/ui/Poster'
import { set_meta } from '../../../utils/seo'
import { dispachAlert } from '../../../utils/dispachers';
const types = ['All', 'TVSeries', 'Movie']
const sources = ['All', 'IMDb', 'TMDb', 'MyDramaList', 'Custom']
function Movies() {
  const [movies, setMovies] = useState([])
  const [selected, setSelected] = useState(undefined)
  const [forbidden, setForbidden] = useState(false)
  const [typeFilter, setTypeFilter] = useState(0)
  const [sourceFilter, setSourceFilter] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  set_meta({
    is_landing: false
  })
  const movieIdRef = useRef()
  const imdbLinkRef = useRef()
  const createdbyRef = useRef()
  const movieNameRef = useRef()

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line
  }, [])

  const onTypeFilterChange = (e) => {
    setTypeFilter(e.target.value)
  }
  const onSourceFilterChange = (e) => {
    setSourceFilter(e.target.value)
  }
  const handleLoad = () => {
    setSubmitting(true)
    var filters = {
      type: types[typeFilter],
      source: sources[sourceFilter],
      movieId: movieIdRef.current.value,
      imdbLink: imdbLinkRef.current.value?.includes('imdb.com/title/')
        ? imdbLinkRef.current.value.split('imdb.com/title/')[1].split('?')[0]?.replaceAll('/', '')
        : imdbLinkRef.current.value.replaceAll('/', ''),
      movieName: movieNameRef.current.value,
      createdBy: createdbyRef.current.value
    }
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/movies/get', {
        params: { filters },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          setMovies(res.data.movies)
          setSubmitting(false)
        }
      })
      .catch((err) => {
        console.log(err.response.status)
        setSubmitting(false)
        setForbidden(err.response.status === 403)
      })
  }
  const handleMovieClick = (movie) => {
    setSelected(movie)
  }
  const handleMovieDelete = () => {
    if (selected && selected.availableSubs === 0) {
      var answer = window.confirm(
        "After page deletion, the subtitles and the page can't be recovered. Proceed?"
      )
      if (answer) {
        setSubmitting(true)
        axios
          .post(
            process.env.REACT_APP_API_URL + '/api/admin/movies/delete',
            { movieId: selected.id },
            { withCredentials: true }
          )
          .then((res) => {
            if (res.data.success) {
              handleLoad()
              setSelected(undefined)
            } else {
              dispachAlert('error', res.data.errMsg)
            }
            setSubmitting(false)
          })
          .catch((err) => {
            if (err?.response?.status === 403) {
              dispachAlert('error', 'Forbidden.')
            } else {
              dispachAlert('error', 'server Error.')
            }
            setSubmitting(false)
          })
      }
    }
  }
  return forbidden ? (
    <>Forbidden</>
  ) : (
    <Paper elevation={4} sx={{ borderRadius: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        {/* filters */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Box sx={{ width: '250px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Type</Typography>
              <Select
                value={typeFilter}
                onChange={onTypeFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                {types.map((type, index) => (
                  <MenuItem value={index}>{type}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ width: '250px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Source</Typography>
              <Select
                value={sourceFilter}
                onChange={onSourceFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                {sources.map((source, index) => (
                  <MenuItem value={index}>{source}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Movie Name</Typography>
              <TextField
                fullWidth
                size="small"
                disabled={submitting}
                inputRef={movieNameRef}
              ></TextField>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Movie ID</Typography>
              <TextField
                fullWidth
                size="small"
                disabled={submitting}
                inputRef={movieIdRef}
              ></TextField>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>IMDb Link</Typography>
              <TextField
                fullWidth
                size="small"
                disabled={submitting}
                inputRef={imdbLinkRef}
              ></TextField>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Created By</Typography>
              <TextField
                fullWidth
                size="small"
                disabled={submitting}
                inputRef={createdbyRef}
              ></TextField>
            </Box>
          </Box>
          <Box sx={{ alignSelf: 'center', width: '200px' }}>
            <Button
              sx={{ textTransform: 'none' }}
              variant="outlined"
              fullWidth
              onClick={handleLoad}
              disabled={submitting}
            >
              Filter
            </Button>
          </Box>
        </Box>
        {/* content */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: '1rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              maxWidth: '400px',
              flexDirection: 'column'
            }}
          >
            {movies.length === 0 ? (
              <Box sx={{ mt: '250px' }}>
                <Typography fontSize={'25px'} fontWeight="bold" textAlign={'center'}>
                  No Movies Found
                </Typography>
              </Box>
            ) : (
              <List sx={{ maxHeight: '600px', overflow: 'auto' }}>
                {movies.concat().map((movie) => (
                  <ListItem key={movie.id} disablePadding>
                    <Paper
                      onClick={() => {
                        handleMovieClick(movie)
                      }}
                      sx={{
                        m: '0.5rem',
                        flexGrow: 1,
                        borderRadius: '8px',
                        display: 'flex',
                        padding: '15px 15px',
                        alignItems: 'center',
                        gap: '7px',
                        cursor: 'pointer',
                        bgcolor: selected?.id === movie.id ? 'lightgrey' : 'white'
                      }}
                      elevation={3}
                    >
                      <Box sx={{ maxWidth: '90px' }}>
                        <Poster src={movie.poster} alt="poster" />
                      </Box>
                      {/* <Box sx={{ display: 'flex', minHeight: '105px' }}>
                        <img
                          src={movie.poster}
                          width={'70'}
                          style={{ borderRadius: '8px' }}
                          alt="poster"
                        />
                      </Box> */}
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '14px'
                          }}
                        >
                          {movie.title}
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          Season: {movie.season !== null ? movie.season : 'N/A'}
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          Year: {movie.releaseYear || 'N/A'}
                        </Typography>
                        <Typography sx={{ fontSize: '14px' }}>
                          Type: {movie.type || 'N/A'}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: 'rgb(0,0,0,0.65)' }}>
                          #{movie.id}
                        </Typography>
                      </Box>
                    </Paper>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Divider orientation="vertical" sx={{ borderRight: '1px solid #0000002b' }} flexItem />
          <Box sx={{ minHeight: '600px', width: '100%', display: 'flex', flexDirection: 'column' }}>
            {!selected ? (
              <>
                <Typography fontSize={'30px'} sx={{ alignSelf: 'start', fontWeight: 'Bold' }}>
                  Select a Movie.
                </Typography>
              </>
            ) : (
              <Box>
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <Information selected={selected} />
                </Box>
                <Divider sx={{ my: '0.5rem' }} />
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <ChangePoster
                    selected={selected}
                    setSelected={setSelected}
                    handleLoad={handleLoad}
                  />
                </Box>
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <ChangeBigPoster
                    selected={selected}
                    setSelected={setSelected}
                    handleLoad={handleLoad}
                  />
                </Box>
                <Divider sx={{ my: '0.5rem' }} />
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <ChangeTitle
                    selected={selected}
                    setSelected={setSelected}
                    handleLoad={handleLoad}
                  />
                </Box>
                <Divider sx={{ my: '0.5rem' }} />
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <ChangeLinkname
                    selected={selected}
                    setSelected={setSelected}
                    handleLoad={handleLoad}
                  />
                </Box>
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <ChangeSourcelink
                    selected={selected}
                    setSelected={setSelected}
                    handleLoad={handleLoad}
                  />
                </Box>
                <Divider sx={{ my: '0.5rem' }} />
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <MoveAll selected={selected} setSelected={setSelected} handleLoad={handleLoad} />
                </Box>
                <Divider sx={{ my: '0.5rem' }} />
                <Box sx={{ display: 'flex', padding: '10px', gap: '1rem' }}>
                  <Button
                    onClick={handleMovieDelete}
                    fullWidth
                    variant="contained"
                    size="small"
                    sx={{ bgcolor: 'darkred', '&:hover': { bgcolor: '#8b0000c9' } }}
                    disableRipple
                    disabled={parseInt(selected.availableSubs) > 0}
                  >
                    Delete Page
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Movies
