import { Box, Drawer, IconButton, InputAdornment } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { ThreeDots } from 'react-loading-icons'
import SearchMovies from './SearchMovies'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useSmallWidth } from '../../hooks/useSmallWidth'
import { Close } from '@mui/icons-material';

function Search({ darkmode }) {
  const navigate = useNavigate()
  //
  const [searchFocused, setSearchFocused] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [foundMovies, setFoundMovies] = useState([])
  const isSmall = useSmallWidth()
  //
  const controllerRef = useRef()
  //
  const handleCloseSearch = () => {
    setSearchFocused( false )
  }
  const handleFocusSearch = () => {
    setSearchFocused( true )
  }
  const handleOpenSearch = () => {
    setSearchFocused(true)
  }
  const handleSearchInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleCloseSearch()
      navigate('/search/' + searchText)
    }
  }
  const handleSearchTextChange = (e) => {
    setSearching(true)
    setSearchText(e.target.value)
    setSearchFocused(true)
    if (e.target.value === '') {
      setSearching(false)
      setFoundMovies([])
    }
    
  }
  //
  const Overlay = () => (
    <Box
      sx={{
        visibility: searchFocused ? 'visible' : 'hidden',
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        transition: 'background-color 1000ms linear',
        backgroundColor: searchFocused ? 'rgb(0,0,0,0.6)' : 'rgb(0,0,0,0)',
        zIndex: '1'
      }}
      onClick={handleCloseSearch}
    ></Box>
  )
  const Loading = () => (
    <Box
      sx={{
        margin: '0 auto',
        my: '2rem',
        display: searchText.length > 0 ? 'flex' : 'none'
      }}
    >
      <ThreeDots fill={darkmode ? 'white' : 'black'} width={'100px'}></ThreeDots>
    </Box>
  )
  const SearchInput = () => (
    <InputBase
      fullWidth
      value={searchText}
      onInput={handleSearchTextChange}
      onKeyUp={handleSearchInputKeyUp}
      onFocus={handleOpenSearch}
      placeholder="Search title or IMDB link..."
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      inputProps={{
        'aria-label': 'search',
        color: darkmode ? 'white' : 'black',
        autoFocus: searchFocused
      }}
      sx={{
        // bgcolor: darkmode ? 'rgb(18,18,18)' : 'white',
        'input::placeholder': {
          color: darkmode ? 'white' : 'black'
        },
        backgroundImage: darkmode
          ? 'linear-gradient(rgb(84, 84, 84), rgb(62, 62, 62))'
          : 'linear-gradient(rgb(233, 230, 230), rgb(233, 230, 230))',
        p: '.25rem',
        zIndex: 1,
        borderRadius: '10px'
        // width: { md: 200 }
      }}
    />
  )
  //
  useEffect(() => {
    if (searchText.length >= 1) {
      const delayDebounceFn = setTimeout(() => {
        if (controllerRef.current) {
          controllerRef.current.abort()
        }
        controllerRef.current = new AbortController()
        const signal = controllerRef.current.signal
        axios
          .post(
            process.env.REACT_APP_API_URL + '/api/searchMovie',
            { query: searchText },
            { signal: signal, withCredentials: true }
          )
          .then((res) => {
            if (res.data.success) {
              setSearching(false)
              setFoundMovies(res.data.found)
            }
          })
          .catch((err) => {
            if (err.name !== 'CanceledError') {
              setSearching(false)
            }
            setFoundMovies([])
          })
      }, 600)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchText])

  return (
    <>
      {Overlay()}
      {isSmall ? (
        <IconButton onClick={handleFocusSearch} aria-label="search">
          <SearchIcon />
        </IconButton>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: 1, maxWidth: '400px' }}>
          {SearchInput()}
          {/* search popup */}
          <Box
            sx={{
              display:
                searchFocused === false
                  ? 'none'
                  : foundMovies.length >= 0 || searching
                  ? 'flex'
                  : 'none',
              zIndex: '1',
              overflow: 'hidden',
              borderRadius: '10px',
              height: 'auto',
              width: '400px',
              position: 'absolute',
              bgcolor: darkmode ? 'rgb(18,18,18)' : 'white',
              top: '3rem',
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
            }}
          >
            {searching ? Loading() : (
              <SearchMovies
                movies={foundMovies}
                handleClose={handleCloseSearch}
                setSearchText={setSearchText}
                setFoundMovies={setFoundMovies}
                setSearching={setSearching}
                searchText={searchText}
              />
            )}
          </Box>
        </Box>
      )}
      {/* search popup */}

      {isSmall && (
        <Drawer
          anchor={'top'}
          open={searchFocused}
          onClose={handleCloseSearch}
          sx={{ zIndex: 15000 }}
        >
          <Box
            sx={{
              flexGrow: 1,
              py: '10px',
              px: '.25rem',
              borderRadius: '10px',
              height: 'max-content',
              bgcolor: darkmode ? 'rgb(18,18,18)' : 'white'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {SearchInput()}
              <IconButton onClick={handleCloseSearch} aria-label="delete">
                <Close />
              </IconButton>
            </Box>
            {/* result box */}
            <Box
              sx={{
                display:
                  searchFocused === false
                    ? 'none'
                    : foundMovies.length >= 0 || searching
                    ? 'flex'
                    : 'none',
                zIndex: '1',
                overflow: 'hidden',
                borderRadius: '10px',
                height: 'auto',
                width: '100%'
              }}
            >
              {searching ? (
                <Loading />
              ) : (
                <SearchMovies
                  movies={foundMovies}
                  handleClose={handleCloseSearch}
                  setSearchText={setSearchText}
                  setFoundMovies={setFoundMovies}
                  setSearching={setSearching}
                  searchText={searchText}
                />
              )}
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  )
}

export default Search
