import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { changeUsernameChanged } from '../../../../api/adminApi';
import { dispachAlert } from '../../../../utils/dispachers';

function UsernameChangedTimes({ user, isLoading }) {
    const [changed, setChanged] = useState(user.username_changed || 0);
    const queryClient = useQueryClient();
    const { isFetching, refetch } = useQuery({
        queryKey: ['adminUpdateSubsceneStatus'],
        queryFn: async () => {
            try {
                const result = await changeUsernameChanged(user.id, changed);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    });
    useEffect(() => {
        setChanged(user.username_changed || 0)
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Box sx={{ display: 'flex', gap: "1rem" }}>
                <Box sx={{ flex: 1, gap: "1rem", display: "flex" }}>
                    <TextField type='number' size='small' value={changed} label="Usernames Changed" onChange={(e) => e.target.value >= 0 && setChanged(e.target.value)} />
                </Box>
                <Button sx={{ justifySelf: "flex-end" }} variant='outlined' disabled={(user.username_changed === changed) || isFetching || isLoading} onClick={() => { if (!isFetching) refetch() }}><SaveIcon /></Button>
            </Box>
        </Box>
    )
}

export default UsernameChangedTimes