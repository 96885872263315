import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { changeUsername } from '../../../../api/adminApi'
import { dispachAlert } from '../../../../utils/dispachers'
function Username({ user, isLoading }) {
  const [username, setUsername] = useState(user.username || '')
  const queryClient = useQueryClient()
  const { isFetching, refetch } = useQuery({
    queryKey: ['adminUpdateUsername'],
    queryFn: async () => {
      try {
        const result = await changeUsername(user.id, username)
        if (result.success) {
          dispachAlert('success', 'Success.')
          queryClient.invalidateQueries({
            queryKey: ['adminGetUsers']
          })
        } else {
          dispachAlert('error', result?.errMsg || 'Server Error.')
        }
        return result
      } catch (error) {
        if (error?.response?.status === 403) {
          dispachAlert('error', 'Forbidden.')
        } else if (error?.response?.status === 404) {
          dispachAlert('error', 'Not Found.')
        } else {
          dispachAlert('error', 'Server Error.')
        }
        return { success: false }
      }
    },
    enabled: false
  })
  useEffect(() => {
    setUsername(user.username)
  }, [user])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <TextField
          title="Username"
          label="Username"
          disabled={isFetching || isLoading}
          size="small"
          value={username}
          sx={{ flex: 1 }}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button
          variant="outlined"
          disabled={user.username === username || isFetching || isLoading}
          onClick={() => {
            if (!isFetching) refetch()
          }}
        >
          <SaveIcon />
        </Button>
      </Box>
    </Box>
  )
}

export default Username
