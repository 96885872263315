import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactTimeAgo from 'react-time-ago'
function NewsCard({ data, index }) {
    const darkmode = useDarkMode();
    return (
      <Accordion key={index} id={'homepage-newscard'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            fontSize: '15px',
            fontWeight: 'bold',
            fontStyle: 'italic',
            fontFamily: 'Medium-Family',
            color: darkmode.value ? 'white' : 'initial'
          }}
        >
          {data.title}
        </AccordionSummary>
        <AccordionDetails>
          <Box
            className={`newsTextBox ${darkmode.value && 'dark-mode'}`}
            dangerouslySetInnerHTML={{ __html: data.desc }}
          ></Box>
          <Typography sx={{ mt: '1rem' }}>
            Added By:{' '}
            <Link sx={{ textDecoration: 'none' }} href={'/u/' + data.addedBy} target={'_blank'}>
              {data.uploaderName}
            </Link>{' '}
            <span style={{ fontSize: '12px' }}>
              (<ReactTimeAgo date={new Date(data.date)} locale="en-US" />)
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>
    )
}

export default NewsCard