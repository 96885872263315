import React from 'react'
import useDarkMode from 'use-dark-mode'
import { DarkModeToggle } from 'react-dark-mode-toggle-2'

const DarkModeToggleBtn = () => {
  const darkMode = useDarkMode(false)

  return (
    <DarkModeToggle
      onChange={darkMode.toggle}
      isDarkMode={darkMode.value}
      size={'2.5rem'}
      speed={2}
    />
  )
}

export default DarkModeToggleBtn
