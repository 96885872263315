import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode';
const types = [
    "Translated a Subtitle",
    "Transcript (By Listening)",
    "From Retail",
    "Machine Translated",
    "Forced/Foreign Lines Only",
    "Improved a Subtitle",
]
function ProductionTypeInput({ type, setType, disabled }) {
    const darkmode = useDarkMode(false);
    const onChnage = (e) => {
        setType(e.target.value)
    }
    return (
        <Box sx={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold", color: darkmode.value ? "white" : "initial" }}>Production Type</Typography>
            <Box sx={{ minWidth: 120 }}>
                <FormControl>
                    <Select
                        disabled={disabled}
                        variant='standard'
                        labelId="Type"
                        id="Type"
                        value={type}
                        label="Type"
                        onChange={onChnage}
                    >
                        {types.map((t, index) => (
                            <MenuItem key={index} value={t}>{t}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default ProductionTypeInput