import { Box, Button } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react'
import { deleteOthers, restoreOthers } from '../../../../api/adminApi';
import { dispachAlert } from '../../../../utils/dispachers';

function Comments({ user, isLoading }) {
    const queryClient = useQueryClient();
    const { refetch: fetchDeleteCms, isFetching: isFetchingDelete } = useQuery({
        queryKey: ["adminDeleteAllCms"],
        queryFn: async () => {
            try {
                const result = await deleteOthers(user.id, 'comments');
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const { refetch: fetchRestoreCms, isFetching: isFetchingRestore } = useQuery({
        queryKey: ["adminRestoreAllCms"],
        queryFn: async () => {
            try {

                const result = await restoreOthers(user.id, 'comments');
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const handleClickDelete = () => {
        const answer = window.confirm("Confirm Deleting All Comments?");
        if (answer) {
            fetchDeleteCms();
        }
    }
    const handleClickRestore = () => {
        const answer = window.confirm("Confirm Restore All Comments?");
        if (answer) {
            fetchRestoreCms();
        }
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Button variant='outlined' disableRipple color='error' disabled={isFetchingRestore || isFetchingDelete || isLoading} size='small' sx={{ textTransform: "none" }} onClick={handleClickDelete}>Delete Comments ({user.cmCount})</Button>
            <Button variant='outlined' disableRipple color='error' disabled={isFetchingRestore || isFetchingDelete || isLoading} size='small' sx={{ textTransform: "none" }} onClick={handleClickRestore}>Restore Comments ({user.cmCountBanDeleted})</Button>
        </Box>

    )
}

export default Comments