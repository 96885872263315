import { InputBase } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useDarkMode from 'use-dark-mode';
import CloseIcon from '@mui/icons-material/Close';
function ReleaseInputField({ val, setRts, rts, id, display, disabled }) {
    const darkmode = useDarkMode(false);
    const [value, setValue] = useState(val || "")
    const [invalid, setInvalid] = useState(false)
    useEffect(() => {
        var expr = /^[\p{L}\p{N}\p{Zs}?()[/|\]'":!&+@,._ -]+$/mu
        if (!expr.test(value) && value !== "") {
            setInvalid(true)
        } else {
            setInvalid(false)
        }
    }, [value])
    const handleChange = (e) => {
        setValue(e.target.value);
        setRts(p => p.map(x => { if (x.id === id) { x.value = e.target.value }; return x; }))
    }
    const handlePaste = (e) => {
        if (e.target.value === "") {
            e.preventDefault();
            var data = e.clipboardData.getData('Text').split(/\r?\n|\r|\n/g).map(x => x.trim());
            var finalData = [];
            data.forEach((line, index) => {
                if (line !== "")
                    if (finalData.length + rts.filter(x => x.display && !x.value === "").length < 10) {
                        finalData.push({ id: Math.max(...rts.map(x => x.id)) + index + 1, value: line, display: true })
                    } else {
                        console.log(false)
                    }
            })
            setRts(p => p.map(x => {
                if (x.display) {
                    if (x.value === "") {
                        x.display = false;
                    }
                }
                return x;
            }).concat(finalData))
        }
    }
    const handleRemove = () => {
        if (!disabled) {
            if (rts.filter(x => x.display === true).length > 1) {
                setRts(p => p.map(rt => {
                    if (rt.id === id) { rt.display = false };
                    return rt;
                }))
            } else {
                setValue("");
                setRts(p => p.map(x => {
                    if (x.id === id) { x.value = "" }; return x;
                }))
            }
        }
    }
    return (
        <InputBase
            disabled={disabled}
            sx={{ py: "0.25rem", color: darkmode.value ? "white" : "initial", bgcolor: darkmode.value ? "#525252" : "white", borderRadius: "5px", marginBottom: "0.5rem", px: "0.5rem", display: display ? 'flex' : 'none', border: invalid ? "1px solid red" : "1px solid #00000080" }}
            fullWidth
            value={value}
            onChange={handleChange}
            placeholder='Write / Paste here.'
            onPaste={handlePaste}
            endAdornment={<CloseIcon sx={{ cursor: "pointer", color: darkmode.value ? "white" : "initial" }} onClick={handleRemove} />}
        >
        </InputBase>
    )
}

export default ReleaseInputField