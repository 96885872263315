import 'react-dropzone-uploader/dist/styles.css'
import '../css/UploadPage.css'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  InputBase,
  Link,
  Paper,
  Grid,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import useDarkMode from 'use-dark-mode'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { MainApi } from '../api/mainApi'
import { ThreeDots } from 'react-loading-icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VerticalMovieCard from '../Components/ui/VerticalMovieCard'
import { set_meta } from '../utils/seo';

function UploadPage() {
  //
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  set_meta({
    is_landing: false
  })
  //
  const [searchText, setSearchText] = useState('')
  //
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value)
  }
  const { data: trendingTitles, isLoading } = useQuery({
    queryKey: ['getTrendingUploads'],
    queryFn: async () => {
      return await MainApi.getTrendingUploads()
    }
  })
  const {
    data: searchResult,
    isFetching: searchResultIsLoading,
    refetch: doSearch
  } = useQuery({
    queryKey: ['searchMoviesUpload'],
    queryFn: async () => {
      return await MainApi.searchMoviesUpload(searchText)
    },
    enabled: false
  })
  //
  if (isLoading) {
    return (
      <Box
        sx={{
          height: '800px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black'
        }}
      >
        <Grid fill={darkmode.value ? 'white' : 'black'} />
      </Box>
    )
  }
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          mt: { xs: '2rem' }
        }}
      >
        {/* header */}
        <Typography
          variant="h1"
          sx={{
            color: darkmode.value ? 'white' : '#21495F'
          }}
        >
          Upload a Subtitle: Step 1
        </Typography>
        <Divider
          flexItem
          sx={{ borderColor: darkmode.value ? 'white' : 'black', borderBottomWidth: '2px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
          {/* search */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
              width: '100%',
              maxWidth: {lg: '50%'},
              mt: '1rem'
            }}
          >
            <Typography
              sx={{
                fontSize: '15px',
                color: darkmode.value ? 'white' : '#21495F',
                textAlign: 'center'
              }}
            >
              Find any Media Title (Movies, TV-Series, etc.)
            </Typography>
            <InputBase
              value={searchText}
              onChange={handleSearchTextChange}
              fullWidth
              onKeyDown={(e) => {
                if (e.key === 'Enter' && searchText.length > 1 && !searchResultIsLoading) doSearch()
              }}
              sx={{
                border: darkmode.value ? '1px solid white' : '1px solid black',
                borderRadius: '10px',
                px: '0.25rem',
                minHeight: '45px'
              }}
              inputProps={{
                sx: {
                  px: '0.25rem',
                  fontSize: '20px'
                }
              }}
              placeholder="write down here..."
              endAdornment={
                <IconButton
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  onClick={() => {
                    if (searchText.length > 1 && !searchResultIsLoading) doSearch()
                  }}
                >
                  <SearchIcon sx={{ fontSize: '30px' }} />
                </IconButton>
              }
            />
          </Box>
          {/* search result */}
          <Box
            sx={{
              width: { lg: '50%' },
              placeSelf: 'center',
              display: searchResult?.found !== undefined || searchResultIsLoading ? 'flex' : 'none',
              flexDirection: 'column',
              background: darkmode.value ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.6)',
              borderRadius: '16px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(6.9px)',
              WebkitBackdropFilter: 'blur(6.9px)',
              padding: '25px',
              mt: '1rem'
            }}
          >
            <Box
              sx={{
                display: searchResultIsLoading ? 'flex' : 'none',
                placeSelf: 'center',
                my: '3rem'
              }}
            >
              <ThreeDots fill={darkmode.value ? 'white' : 'black'} width={'150px'} />
            </Box>
            <Box
              className="scrollCustom"
              sx={{
                display: searchResultIsLoading ? 'none' : 'flex',
                flexDirection: 'column',
                maxHeight: '50vh',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {searchResult?.found !== undefined && searchResult?.found?.length === 0 ? (
                <Typography
                  sx={{
                    fontFamily: 'Medium-Family',
                    fontSize: '35px',
                    textAlign: 'center',
                    my: '1rem',
                    color: darkmode.value ? 'white' : 'initial'
                  }}
                >
                  No Results...
                </Typography>
              ) : (
                searchResult?.found
                  .sort((a, b) => b.fs - a.fs)
                  .map((found) => (
                    <Link
                      sx={{ display: 'contents' }}
                      onClick={(e) => {
                        if (found.type === 'Movie') {
                          e.stopPropagation()
                          e.preventDefault()
                          navigate('/upload/' + found.id)
                        }
                      }}
                      href={found.type === 'Movie' && '/upload/' + found.id}
                    >
                      <Accordion
                        key={found.id}
                        disableGutters
                        sx={{
                          cursor: 'pointer',
                          my: '0.25rem',
                          boxShadow: 'none',
                          background: 'transparent !important',
                          mr: '1rem'
                        }}
                        onClick={(e) => {
                          found.type === 'Movie' && console.log('navigate')
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{ display: found.type === 'Movie' ? 'none' : 'flex' }}
                            />
                          }
                          sx={{
                            minHeight: '100px',
                            pointerEvents: found.type === 'Movie' ? 'none' : 'initial',
                            p: '0px'
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Box sx={{ minHeight: '75px', minWidth: '50px' }}>
                              <img
                                src={found.poster}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null // prevents looping
                                  currentTarget.src = `${process.env.REACT_APP_API_URL}/notfound.jpg`
                                }}
                                alt={found.title}
                                height={'75px'}
                                width={'50px'}
                                style={{ objectFit: 'cover' }}
                              />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                              <Typography
                                sx={{
                                  fontFamily: 'Medium-Family',
                                  color: darkmode.value ? 'white' : '#21495F',
                                  fontSize: '20px'
                                }}
                              >
                                {found.title} ({found.releaseYear})
                              </Typography>
                              <Typography sx={{ fontSize: '14px' }}>Type: {found.type}</Typography>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                        >
                          {found?.seasons
                            ?.map((x) => {
                              if (x.number === 0) {
                                x.number = Infinity
                              }
                              return x
                            })
                            .sort((a, b) => a.number - b.number)
                            .map((season) => (
                              <Link
                                sx={{ display: 'contents' }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  navigate('/upload/' + season.id)
                                }}
                                href={'/upload/' + season.id}
                              >
                                <Paper sx={{ padding: '0.25rem' }} elevation={2}>
                                  <Typography
                                    sx={{ fontFamily: 'Medium-Family', fontSize: '18px' }}
                                  >
                                    {season.number !== Infinity
                                      ? 'Season ' + season.number
                                      : 'Extras (Complete Series)'}
                                  </Typography>
                                </Paper>
                              </Link>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    </Link>
                  ))
              )}
            </Box>
          </Box>
          {/* Trending */}
          <Box
            sx={{
              display:
                searchResult?.found === undefined && !searchResultIsLoading ? 'flex' : 'none',
              alignItems: 'center',
              placeSelf: 'center',
              flexDirection: 'column',
              gap: '1rem',
              width: '100%',
              maxWidth: '1000px'
            }}
          >
            <Typography
              sx={{
                fontSize: '35px',
                color: darkmode.value ? 'white' : '#21495F',
                fontWeight: 'bold'
              }}
            >
              Trending now
            </Typography>
            <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 10 }}>
              {trendingTitles.map((trend) => (
                <Grid key={trend.id} item xs={6} sm={4} md={3} lg={2} sx={{ p: '.15rem' }}>
                  <Link
                    href={'/upload/' + trend.id}
                    sx={{ display: 'inline-block', width: '100%',textDecoration: 'none' }}
                    onClick={(e) => {
                      if (!e.ctrlKey) {
                        e.preventDefault()
                        navigate('/upload/' + trend.id)
                      }
                    }}
                  >
                    <VerticalMovieCard movie={trend} hideDownloads/>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* add */}
          <Button
            disableElevation
            variant="contained"
            size="small"
            sx={{
              fontWeight: 'bold',
              fontSize: '13px',
              display: 'block',
              mx: 'auto',
              alignSelf: 'center',
              mt: '2rem',
              py: '.5rem',
              backgroundColor: '#008281',
              color: 'white',
              textTransform: 'none',
              '&:hover': { backgroundColor: '#016767' }
            }}
            onClick={() => {
              navigate('/addMovie')
            }}
          >
            Can't Find? Click Here to Add a New Film or TV-Series Title
          </Button>
        </Box>
      </Container>
    </>
  )
}

export default UploadPage
