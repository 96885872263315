import { Box, Divider } from '@mui/material'
import React from 'react'
import Email from './Information/Email'
import SignupDate from './Information/SignupDate'
import SignupDateSubscene from './Information/SignupDateSubscene'
import SubsceneStatus from './Information/SubsceneStatus'
import SubsceneRatings from './Information/SubsceneRatings'
import UsernameChangedTimes from './Information/UsernameChangedTimes'
import Username from './Information/Username'

function Information({ user, isLoading }) {
    return (
        <Box sx={{ display: 'flex', gap: "1rem", flexDirection: "column" }}>
            <Username user={user} isLoading={isLoading} />
            <Divider flexItem />
            <Email user={user} isLoading={isLoading} />
            <Divider flexItem />
            <SignupDate user={user} isLoading={isLoading} />
            <Divider flexItem />
            <SignupDateSubscene user={user} isLoading={isLoading} />
            <Divider flexItem />
            <SubsceneStatus user={user} isLoading={isLoading} />
            <Divider flexItem />
            <SubsceneRatings user={user} isLoading={isLoading} />
            <Divider flexItem />
            <UsernameChangedTimes user={user} isLoading={isLoading} />
        </Box>
    )
}

export default Information