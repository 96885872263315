import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import axios from 'axios'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';
function News() {
  const { quill, quillRef } = useQuill()
  const [selected, setSelected] = useState(undefined)
  const [title, setTitle] = useState('')
  const [priority, setPriority] = useState('')
  const [desc, setDesc] = useState('')
  const [ submitting, setSubmitting ] = useState( false )
  set_meta({
    is_landing: false
  })
  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(selected?.desc || '')
      setTitle(selected?.title || '')
      setPriority(selected?.priority === undefined ? '' : selected?.priority)
      quill.on('text-change', (delta, oldDelta, source) => {
        console.log('Text change!', quillRef)
        setDesc(quillRef.current.firstChild.innerHTML)
      })
    }
  }, [quill, selected])
  const [news, setNews] = useState([])
  const [forbidden, setForbidden] = useState(false)
  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }
  const handlePriorityChange = (e) => {
    setPriority(e.target.value)
  }
  const handleReload = () => {
    setSubmitting(true)
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/news/get', { withCredentials: true })
      .then((res) => {
        if (res.data.success) {
          setNews(res.data.news)
          setSubmitting(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispachAlert('error', 'Forbidden.')
          setSubmitting(false)
        }
      })
  }
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/news/get', { withCredentials: true })
      .then((res) => {
        if (res.data.success) {
          setNews(res.data.news)
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setForbidden(true)
        }
      })
  }, [])
  const handleSubmit = () => {
    if (isNaN(priority)) {
      return dispachAlert('error', 'priority must be number')
    }
    if (selected) {
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/news/update',
          { title, desc, priority: parseInt(priority), id: selected.id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            dispachAlert('error', 'success')
            handleReload()
            setSubmitting(false)
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
            setSubmitting(false)
          }
        })
    } else {
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/news/add',
          { title, desc, priority: parseInt(priority) },
          { withCredentials: true }
        )
        .then((res) => {
          dispachAlert('error', 'success')
          handleReload()
          setSubmitting(false)
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
            setSubmitting(false)
          }
        })
    }
  }
  const handleDelete = () => {
    if (selected) {
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/news/delete',
          { id: selected.id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            dispachAlert('error', 'success')
            handleReload()
            setSubmitting(false)
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
            setSubmitting(false)
          }
        })
    }
  }
  if (forbidden) {
    return (
      <>
        <Box>
          <Typography>Forbidden.</Typography>
        </Box>
      </>
    )
  }
  return (
    <Paper elevation={4} sx={{ borderRadius: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: { md: '50%' }
          }}
        >
          <Typography variant="h4">News</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setSelected({})
            }}
            disabled={submitting}
          >
            Add news
          </Button>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {news.length == 0 ? (
              <Typography sx={{ mt: '5rem', textAlign: 'center' }}>No News Found.</Typography>
            ) : (
              news.map((n) => (
                <Paper
                  sx={{
                    display: 'flex',
                    p: '1rem',
                    alignItems: 'center',
                    gap: '1rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (!submitting) {
                      setSelected(n)
                    }
                  }}
                >
                  <Box sx={{ mx: '0.5rem' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                      {n.priority}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '0.9rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {n.title}
                    </Typography>
                    <Box
                      sx={{
                        fontSize: '0.75rem'
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: n.desc }} />
                    </Box>
                  </Box>
                </Paper>
              ))
            )}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: { md: '50%' }
          }}
        >
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          </Box> */}
          <TextField
            label="Title"
            value={title}
            fullWidth
            onChange={handleTitleChange}
            disabled={submitting}
          ></TextField>
          <TextField
            value={priority}
            onChange={handlePriorityChange}
            fullWidth
            label="Priority"
            disabled={submitting}
          />
          <Box sx={{overflow: 'hidden',borderBottom: '1px solid #ccc'}}>
            <div ref={quillRef} disabled={submitting} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', placeSelf: 'flex-end', gap: '1rem' }}>
              <Button variant="outlined" color="error" onClick={handleDelete} disabled={submitting}>
                Delete
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                disabled={submitting}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default News
