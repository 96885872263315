import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { changeEmail } from '../../../../api/adminApi';
import { dispachAlert } from '../../../../utils/dispachers';
function Email({ user, isLoading }) {
    const [email, setEmail] = useState(user.email || "");
    const queryClient = useQueryClient();
    const { isFetching, refetch } = useQuery({
        queryKey: ['adminUpdateEmail'],
        queryFn: async () => {
            try {
                const result = await changeEmail(user.id, email);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    });
    useEffect(() => {
        setEmail(user.email)
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Box sx={{ display: 'flex', gap: "1rem" }}>
                <TextField
                    label="Email"
                    title="Email"
                    disabled={isFetching || isLoading}
                    size='small'
                    value={email}
                    sx={{ flex: 1 }}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant='outlined' disabled={user.email === email || isFetching || isLoading} onClick={() => { if (!isFetching) refetch() }}><SaveIcon /></Button>
            </Box>
        </Box>
    )
}

export default Email