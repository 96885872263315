import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check';
import { ThreeDots, } from 'react-loading-icons';
import { Box, TextField, Typography } from '@mui/material'
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '../../../icons/facebookColored2.svg';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { UserApi } from '../../../api/userApi'
import { dispachAlert } from '../../../utils/dispachers';
const RegularFont = 'Regular-Family'
const BoldFont = 'Bold-Family'
const socialMedias = [
    'Instagram',
    'Twitter',
    'Telegram',
    'Facebook'
]
const SocialMediaIcons = [
    <InstagramIcon color='primary' sx={{ p: "1.5rem", backgroundColor: "#000aff08", borderRadius: "50%", fontSize: "3rem" }} />,
    <TwitterIcon color='primary' sx={{ p: "1.5rem", backgroundColor: "#000aff08", borderRadius: "50%", fontSize: "3rem" }} />,
    <TelegramIcon color='primary' sx={{ p: "1.5rem", backgroundColor: "#000aff08", borderRadius: "50%", fontSize: "3rem" }} />,
    <img src={FacebookIcon} alt="Facebook" width={'50px'} style={{ padding: "1.5rem", backgroundColor: "#000aff08", borderRadius: "50%", fontSize: "3rem" }} height={'50px'} />
]
function DashboardSocialMediaModal({ show, toggleShow, socialMedia, currentSocialMedia }) {
    const [id, setId] = useState('')
    const handleIdChange = (e) => {
        setId(e.target.value)
    }
    const queryClient = useQueryClient();
    const { refetch, isFetching } = useQuery({
        queryKey: ["changeSocial"],
        staleTime: 0,
        gcTime: 0,
        enabled: false,
        queryFn: async () => {
            try {
                const result = await UserApi.changeSocial(socialMedias[socialMedia], id);
                if (result) {
                    if (result?.success === true) {
                        queryClient.invalidateQueries({
                            queryKey: ['getUser'],
                        });
                        toggleShow();
                    }
                }
                return;
            } catch (error) {
                toggleShow()
                dispachAlert('error', 'Server error.')
                console.log({ error })
            }
        },
    })
    useEffect(() => {
        setId(currentSocialMedia)
    }, [currentSocialMedia, show])

    const handleSubmit = () => {
        refetch();
    }
    const onClose = () => {
        toggleShow();
    }
    return (
        <Dialog open={show} onClose={toggleShow} aria-labelledby={'Subscene ID'} >
            <DialogTitle sx={{ display: "flex", minWidth: "400px", justifyContent: "center", alignContent: "center", paddingTop: "3rem" }}>
                {SocialMediaIcons[socialMedia]}
            </DialogTitle>
            <DialogContent >
                <DialogContentText fontFamily={BoldFont} sx={{ display: "flex", justifyContent: "center", flexDirection: "column", p: "1.5rem", paddingTop: "0.5rem" }}>
                    <Typography sx={{ fontFamily: RegularFont, fontSize: "1.4rem", color: "black", textAlign: "center", maxWidth: "20rem", alignSelf: "center" }}>{`Enter your ${socialMedias[socialMedia]} username`}</Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center", marginTop: "1rem" }}>
                        <TextField
                            disabled={isFetching}
                            id="id"
                            value={id}
                            onChange={handleIdChange}
                            variant='standard'
                            placeholder={socialMedias[socialMedia] + ' ID'}
                            sx={{ textAlign: "center", py: "0.5rem", "input:focus::placeholder": { color: "transparent" }, alignSelf: "center", mr: "1.5rem" }}
                            inputProps={{ sx: { textAlign: "center", fontFamily: RegularFont } }}
                        />
                        <Button
                            disabled={isFetching}
                            disableRipple
                            variant='contained'
                            size='small'
                            sx={{
                                lineHeight: "normal",
                                py: "0.35rem",
                                fontFamily: RegularFont,
                                textTransform: "none",
                                marginLeft: "0.75rem",
                                height: "2rem",
                                maxHeight: "2rem",
                                width: "5rem",
                                minWidth: "5rem",
                                position: "fixed",
                                marginRight: "-17rem"

                            }} onClick={handleSubmit}>
                            {isFetching
                                ?
                                <>
                                    <ThreeDots width={'2rem'} height={'2rem'} />
                                </>
                                :
                                <>Submit {<CheckIcon sx={{ fontSize: "1.2rem" }} />}</>}

                        </Button>

                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    sx={{ fontFamily: BoldFont, px: "1rem" }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DashboardSocialMediaModal