import { Box } from '@mui/material'
import React from 'react'

export default function Poster({ src, alt, aspectRatio = '3/4', height }) {
  return (
    <Box sx={{ borderRadius: '10px', height, display: 'flex' }}>
      <img
        src={src}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = `${process.env.REACT_APP_API_URL}/notfound.jpg`
        }}
        alt={alt}
        className="poster"
        height={'225'}
        width={'120'}
        style={{
          aspectRatio: aspectRatio,
          borderRadius: '10px',
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }}
      />
    </Box>
  )
}
