
import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const UserApi = {
  get: async function (cancel = false) {
    const response = await api.request({
      url: `/api/user/get`,
      method: 'GET',
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response?.data?.user || null
  },
  login: async function (username, password, captcha, cancel = false) {
    const response = await api.request({
      url: `/auth/login`,
      method: 'post',
      data: {
        username: username,
        password: password,
        c: captcha
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  signup: async function (email, username, password, isSubscene, captcha, cancel = false) {
    const response = await api.request({
      url: `/auth/register`,
      method: 'post',
      data: {
        email: email,
        username: username,
        password: password,
        isSubscene: isSubscene,
        c: captcha
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  forgetPassword: async function (email, captcha, cancel = false) {
    const response = await api.request({
      url: `/auth/forgetpassword`,
      method: 'post',
      data: {
        email: email,
        c: captcha
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  changeSocial: async function (smn, smid, cancel = false) {
    const response = await api.request({
      url: `/api/user/changeSocial`,
      method: 'post',
      data: {
        smn,
        smid
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  editProfile: async function (avatarDeleted, bio, username, cancel = false) {
    const response = await api.request({
      url: `/api/user/editProfile`,
      method: 'post',
      data: {
        avatarDeleted: avatarDeleted,
        bio: bio,
        username: username
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  editProfileWAvatar: async function (selectedFile, avatarDeleted, bio, username, cancel = false) {
    let formData = new FormData()
    formData.append('file', selectedFile)
    const uploadFile = await api.request({
      url: `/api/user/avatar/upload`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    if (uploadFile.data.success) {
      const response = await api.request({
        url: `/api/user/editProfile`,
        method: 'post',
        data: {
          avatarDeleted: avatarDeleted,
          avatar: uploadFile.data.avatar,
          bio: bio,
          username: username
        },
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined
      })
      return response.data
    } else {
      throw new Error('Server Error.')
    }
  },
  changeCountry: async function (country, cancel = false) {
    const response = await api.request({
      url: `/api/user/changeCountry`,
      method: 'post',
      data: {
        country
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  changeLastSeenStatus: async function (lastSeen, cancel = false) {
    const response = await api.request({
      url: `/api/user/changeLastSeenStatus`,
      method: 'post',
      data: {
        lastSeen
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  getDownloads: async function (page = 1, cancel = false) {
    const response = await api.request({
      url: `/api/user/mySubsource/getDownloads`,
      method: 'get',
      params: {
        page
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  getUnreadMessages: async function (cancel = false) {
    const response = await api.request({
      url: `/api/user/getUnreadMessages`,
      method: 'get',
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  getUserNotifs: async function (cancel = false) {
    const response = await api.request({
      url: `/api/user/getUserNotifs`,
      method: 'get',
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  },
  report: async function (
    type,
    reason = null,
    summary = null,
    id = null,
    rate = null,
    comment = null,
    cancel = false
  ) {
    const response = await api.request({
      url: `/api/createReport`,
      method: 'POST',
      data: {
        type,
        reason,
        summary,
        id,
        rate,
        comment
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
    })
    return response.data
  }
}

const cancelApiObject = defineCancelApiObject(UserApi)