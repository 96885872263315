import { Box, Divider, Link, List, ListItem, Skeleton, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode'
import { useNavigate } from 'react-router-dom'
import './RecentSubs.css'
import RecentSub from './RecentSub'
function RecentSubs({ isLoading, subs }) {
  const navigate = useNavigate()
  const darkmode = useDarkMode()

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            mt: '1rem',
            mb: '0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link
              onClick={(e) => {
                if (!e.ctrlKey) {
                  e.preventDefault()
                  navigate('/recent')
                }
              }}
              href="/recent"
              sx={{
                textDecoration: 'none',
                fontSize: '30px',
                fontFamily: 'Medium-Family',
                fontWeight: 'bold',
                color: darkmode.value ? 'white' : '#21495F'
              }}
            >
              Recent Subtitles
            </Link>
            <span
              style={{
                fontSize: '12px',
                color: darkmode.value ? 'white' : 'black',
                opacity: '0.5',
                marginLeft: '5px',
                marginTop: '5px',
                fontFamily: 'Medium-Family',
                fontWeight: 'bold'
              }}
            >
              (updates every minute)
            </span>
          </Box>
          <Typography
            sx={{
              fontSize: '30px',
              fontFamily: 'Medium-Family',
              verticalAlign: '',
              fontWeight: 'bold',
              display: {xs: 'none',sm: 'inline-block'},
              color: darkmode.value ? 'white' : '#21495F'
            }}
          >
            Owner
          </Typography>
        </Box>
        <Divider
          sx={{
            borderColor: darkmode.value ? 'white' : 'black',
            borderBottomWidth: '2px',
            mb: '1.25rem'
          }}
        />
        <Box sx={{ padding: '5px' }}>
          {[1, 2, 3, 4, 5, 6].map((rec) => (
            <Box
              key={rec}
              sx={{
                display: 'flex',
                mb: '5px',
                gap: '2rem',
                justifyContent: 'space-between',
                borderBottom: '1px solid transparent'
              }}
            >
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ fontSize: '20px', width: '100%' }}
              />
            </Box>
          ))}
        </Box>
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          mt: '1rem',
          mb: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/recent')
              }
            }}
            href="/recent"
            sx={{
              textDecoration: 'none'
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
              Recent Subtitles
            </Typography>
          </Link>
          <span
            style={{
              fontSize: '12px',
              color: darkmode.value ? 'white' : 'black',
              opacity: '0.5',
              marginLeft: '5px',
              marginTop: '5px',
              fontFamily: 'Medium-Family',
              fontWeight: 'bold'
            }}
          >
            (updates every minute)
          </span>
        </Box>
        <Typography
          component={'p'}
          variant="h2"
          sx={{
            fontWeight: 'bold',
            display: { xs: 'none', sm: 'inline-block' },
            color: darkmode.value ? 'white' : '#21495F'
          }}
        >
          Owner
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: darkmode.value ? 'white' : 'black',
          borderBottomWidth: '2px',
          mb: '1.25rem'
        }}
      />
      <List sx={{ padding: '5px', mb: '2rem' }}>
        {subs?.map((rec) => (
          <ListItem key={rec.id} disablePadding>
            <RecentSub rec={rec} />
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default RecentSubs
