import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ReactTimeAgo from 'react-time-ago'
import './AdminPanel.css'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';
const types = ['All', 'sub', 'comment', 'rate', 'user', 'movie']
const languages = ['All', 'Farsi/Persian', 'Arabic', 'Bengali', 'Others']
const formattedLanguages = {
  All: 'All',
  'Farsi/Persian': 'farsi_persian',
  Arabic: 'arabic',
  Bengali: 'bengali',
  Others: 'others'
}
const statuses = ['All', 'Open', 'Close']
const formatted_statuses = {
  All: 'All',
  Open: 0,
  Close: 1
}
const subReasons = [
  '',
  'Is not a subtitle (spam, fake or incomplete.)',
  'Wrong Release info.',
  'Stolen (Exact copy of another subtitle. Please provide a link to the original subtitle.)',
  'Other Reason.'
]
const cmReasons = ['', 'inappropriate Language', 'Spam / Advertisement']
const rateReasons = ['', 'Spam or Fake', 'Other Reason']
const userReasons = ['', 'Fake user', 'Other Reason']
const movieReasons = ['', 'Poster', 'Title', 'Other Reason']
function Reports() {
  const [reports, setReports] = useState([])
  const [selected, setSelected] = useState(undefined)
  const [forbidden, setForbidden] = useState(false)
  const [typeFilter, setTypeFilter] = useState(0)
  const [languageFilter, setLanguageFilter] = useState(0)
  const [statusFilter, setStatusFilter] = useState(0)
  const [ submitting, setSubmitting ] = useState( false )
  set_meta({
    is_landing: false
  })
  const rnRef = useRef()
  const uiRef = useRef()
  const onTypeFilterChange = (e) => {
    setTypeFilter(e.target.value)
  }
  const onLanguageFilterChange = (e) => {
    setLanguageFilter(e.target.value)
  }
  const onStatusFilterChange = (e) => {
    setStatusFilter(e.target.value)
  }
  const onReportClick = (id) => {
    if (!selected) {
      if (!submitting) {
        setSubmitting(true)
        axios
          .post(
            process.env.REACT_APP_API_URL + '/api/admin/reports/select',
            { id: id },
            { withCredentials: true }
          )
          .then((res) => {
            if (res.data.success) {
              setSelected(reports.filter((x) => x.id === id)[0])
              setSubmitting(false)
            } else {
              dispachAlert('error', res.data.errMsg)
              setSubmitting(false)
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              dispachAlert('error', 'Forbidden.')
            } else {
              dispachAlert('error', 'Server Error.')
            }
            setSubmitting(false)
          })
      }
    } else {
      dispachAlert('error', 'You are already processing a report.')
    }
  }
  const handleFilter = () => {
    setSubmitting(true)
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/reports/get', {
        params: {
          type: types[typeFilter],
          language: formattedLanguages[languages[languageFilter]],
          status: formatted_statuses[statuses[statusFilter]],
          tn: rnRef.current.value,
          ui: uiRef.current.value
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          setSelected(res.data.currentlyResolving)
          setReports(res.data.reports)
          setSubmitting(false)
        }
      })
      .catch((err) => {
        console.log(err.response.status)
        setSubmitting(false)
        setForbidden(err.response.status === 403)
      })
  }
  const handleDeselectReport = (id) => {
    if (!selected) {
      return
    }
    setSubmitting(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/admin/reports/deselect',
        { id: id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setSelected(undefined)
          setSubmitting(false)
        }
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
        setSubmitting(false)
      })
  }
  const handleCloseReport = (id) => {
    if (!selected) {
      return
    }
    setSubmitting(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/admin/reports/close',
        { id: id },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          handleFilter()
        } else {
          dispachAlert('error', res.data.errMsg)
        }
      })
      .catch((err) => {
        dispachAlert('error', 'Error.')
        setSubmitting(false)
      })
  }
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/api/admin/reports/get', {
        params: {
          type: types[typeFilter],
          language: formattedLanguages[languages[languageFilter]],
          status: statuses[statusFilter]
        },
        withCredentials: true
      })
      .then((res) => {
        if (res.data.success) {
          setReports(res.data.reports)
          setSelected(res.data.currentlyResolving)
        }
      })
      .catch((err) => {
        console.log(err.response.status)
        setForbidden(err.response.status === 403)
      })
    // eslint-disable-next-line
  }, [])
  console.log({ reports })
  return forbidden ? (
    <>Forbidden</>
  ) : (
    <Paper elevation={4} sx={{ borderRadius: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            <Box sx={{ width: '250px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Type</Typography>
              <Select
                value={typeFilter}
                onChange={onTypeFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Subtitle</MenuItem>
                <MenuItem value={2}>Comment</MenuItem>
                <MenuItem value={3}>Rate</MenuItem>
                <MenuItem value={4}>User</MenuItem>
                <MenuItem value={5}>Movie</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Language</Typography>
              <Select
                value={languageFilter}
                onChange={onLanguageFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Farsi/Persian</MenuItem>
                <MenuItem value={2}>Arabic</MenuItem>
                <MenuItem value={3}>Bengali</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '100px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Status</Typography>
              <Select
                value={statusFilter}
                onChange={onStatusFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                size="small"
                disabled={submitting}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Open</MenuItem>
                <MenuItem value={2}>Closed</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>Report Number</Typography>
              <TextField fullWidth size="small" disabled={submitting} inputRef={rnRef}></TextField>
            </Box>
            <Box sx={{ width: '200px' }}>
              <Typography sx={{ mb: '0.25rem' }}>User ID</Typography>
              <TextField fullWidth size="small" disabled={submitting} inputRef={uiRef}></TextField>
            </Box>
          </Box>
          <Box sx={{ alignSelf: 'center', width: '200px' }}>
            <Button
              sx={{ textTransform: 'none' }}
              variant="outlined"
              fullWidth
              onClick={handleFilter}
              disabled={submitting}
            >
              Filter
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: '1rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              maxWidth: '400px',
              flexDirection: 'column'
            }}
          >
            {reports.length === 0 ? (
              <Box sx={{ mt: '250px' }}>
                <Typography fontSize={'25px'} fontWeight="bold" textAlign={'center'}>
                  No Reports Found
                </Typography>
              </Box>
            ) : (
              <List sx={{ maxHeight: '600px', overflow: 'auto' }}>
                {reports.concat().map((report) => (
                  <ListItem key={report.id} disablePadding>
                    <Paper
                      onClick={() => {
                        onReportClick(report.id)
                      }}
                      sx={{
                        m: '0.5rem',
                        flexGrow: 1,
                        borderRadius: '8px',
                        display: 'flex',
                        padding: '15px 15px',
                        alignItems: 'center',
                        gap: '7px',
                        cursor: 'pointer',
                        bgcolor: selected?.id === report.id ? 'lightgrey' : 'white'
                      }}
                      elevation={3}
                    >
                      <Box>
                        {report.status === 0 ? (
                          <HourglassFullIcon sx={{ color: '#ffd233' }} />
                        ) : (
                          <CheckCircleRoundedIcon sx={{ color: 'darkgreen' }} />
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: '14px' }}>
                          {report.type} #{report.id}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: 'rgb(0,0,0,0.65)' }}>
                          <ReactTimeAgo date={new Date(report.date)} />
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: 'rgb(0,0,0,0.65)' }}>
                          Lang: {report.language || 'N/A'}
                        </Typography>
                      </Box>
                    </Paper>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Divider orientation="vertical" sx={{ borderRight: '1px solid #0000002b' }} flexItem />
          <Box sx={{ minHeight: '600px', width: '100%', display: 'flex' }}>
            {!selected ? (
              <>
                <Typography fontSize={'30px'} sx={{ alignSelf: 'start', fontWeight: 'Bold' }}>
                  Select a report.
                </Typography>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Box sx={{ mb: '2rem', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography fontSize={'25px'} fontWeight={'bold'}>
                      {'Report '} #{selected.id}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                      <Button
                        disabled={selected.status !== 0 || submitting}
                        variant="outlined"
                        color="error"
                        disableRipple
                        onClick={() => {
                          handleCloseReport(selected.id)
                        }}
                      >
                        Mark as Resolved.
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        disableRipple
                        disabled={submitting}
                        onClick={() => {
                          handleDeselectReport(selected.id)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <Typography>Report Information: </Typography>
                    {selected.type === 'Subtitle' && (
                      <>
                        <Typography>
                          Subtitle ID:{' '}
                          <Link href={selected.info.subLink} target="_blank">
                            {selected.info.subId}
                          </Link>
                        </Typography>
                        <Typography>Report Reason: {subReasons[selected.reason]}</Typography>
                        <Typography>Summary: {selected.summary || 'N/A'}</Typography>
                        <Typography>
                          From:{' '}
                          <Link href={'/u/' + selected?.from} target="_blank">
                            {selected?.from || 'N/A'}
                          </Link>
                        </Typography>
                      </>
                    )}
                    {selected.type === 'Comment' && (
                      <>
                        <Typography>
                          Subtitle ID:{' '}
                          <Link href={selected.info.subLink} target="_blank">
                            {selected.info.subId}
                          </Link>
                        </Typography>
                        <Typography>Report Reason: {cmReasons[selected.reason]}</Typography>
                        <Typography>Summary: {selected.summary || 'N/A'}</Typography>
                        <Typography>Comment Text: {selected.info.commentText || 'N/A'}</Typography>
                        <Typography>
                          From:{' '}
                          <Link href={'/u/' + selected?.from} target="_blank">
                            {selected?.from || 'N/A'}
                          </Link>
                        </Typography>
                      </>
                    )}
                    {selected.type === 'Rate' && (
                      <>
                        <Typography>
                          Subtitle ID:{' '}
                          <Link href={selected?.info?.subLink} target="_blank">
                            {selected?.info?.subId}
                          </Link>
                        </Typography>
                        <Typography>Report Reason: {rateReasons[selected?.reason]}</Typography>
                        <Typography>Summary: {selected?.summary || 'N/A'}</Typography>
                        <Typography>Rated From: {selected?.info?.ratedFrom || 'N/A'}</Typography>
                        <Typography>
                          Rated From Username:{' '}
                          <Link href={'/u/' + selected?.info?.ratedFrom} target="_blank">
                            {selected?.info?.ratedFromUsername || 'N/A'}
                          </Link>
                        </Typography>
                        <Typography>
                          From:{' '}
                          <Link href={'/u/' + selected?.from} target="_blank">
                            {selected?.from || 'N/A'}
                          </Link>
                        </Typography>
                      </>
                    )}
                    {selected.type === 'User' && (
                      <>
                        <Typography>
                          User ID:{' '}
                          <Link href={'/u/' + selected?.reportedId} target="_blank">
                            {selected?.reportedId || 'N/A'}
                          </Link>
                        </Typography>
                        <Typography>Report Reason: {userReasons[selected.reason]}</Typography>
                        <Typography>Summary: {selected.summary || 'N/A'}</Typography>
                        <Typography>
                          From:{' '}
                          <Link href={'/u/' + selected?.fromId} target="_blank">
                            {selected?.from || 'N/A'}
                          </Link>
                        </Typography>
                      </>
                    )}
                    {selected.type === 'Movie' && (
                      <>
                        <Typography>
                          Movie ID:{' '}
                          <Link href={selected?.info?.movieLink} target="_blank">
                            {selected?.reportedId}
                          </Link>
                        </Typography>
                        <Typography>Report Reason: {movieReasons[selected.reason]}</Typography>
                        <Typography>Summary: {selected.summary || 'N/A'}</Typography>
                        <Typography>
                          From:{' '}
                          <Link href={'/u/' + selected?.fromId} target="_blank">
                            {selected?.from || 'N/A'}
                          </Link>
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Reports
