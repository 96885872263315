import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import SocialMedia from './Actions/SocialMedia'
import Subs from './Actions/Subs'
import Comments from './Actions/Comments'
import Rates from './Actions/Rates'
import Messages from './Actions/Messages'
import Bans from './Bans'
import Restrictions from './Restrictions'
import Badges from './Badges'
import Permissions from './Permissions'

function Actions({ user, isLoading }) {
    return (
      <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
        <SocialMedia user={user} isLoading={isLoading} />
        <Divider flexItem />
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '20px' }}>
          Other Actions
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexFlow: 'wrap' }}>
          <Subs user={user} isLoading={isLoading} />
          <Comments user={user} isLoading={isLoading} />
          <Rates user={user} isLoading={isLoading} />
          <Messages user={user} isLoading={isLoading} />
        </Box>
        <Divider flexItem />
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '20px' }}>Bans</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexFlow: 'wrap' }}>
          <Bans user={user} isLoading={isLoading} />
        </Box>
        <Divider flexItem />
        <Restrictions user={user} isLoading={isLoading} />
        <Divider flexItem />
        <Badges user={user} isLoading={isLoading} />
        <Divider flexItem />
        <Permissions user={user} isLoading={isLoading} />
      </Box>
    )
}

export default Actions