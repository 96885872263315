import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainApi } from '../api/mainApi'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  Paper,
  Typography
} from '@mui/material'
import Grid from 'react-loading-icons/dist/esm/components/grid'
import useDarkMode from 'use-dark-mode'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { set_meta } from '../utils/seo';

function SearchResultSection({ title, items }) {
  const navigate = useNavigate()
  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        flex: 1
      }}
    >
      {/* title */}
      <Typography variant="h2">{title}</Typography>
      {/* not found */}
      {(!items || items?.length === 0) && (
        <Typography sx={{ fontSize: '24px' }}>No Results</Typography>
      )}
      {/* result list */}
      <List sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
        {items.map((found) => (
          <ListItem flexDirection="column" divider>
            <Link
              sx={{ display: 'block', width: '100%', textDecoration: 'none' }}
              onClick={(e) => {
                if (found.type === 'Movie') {
                  e.stopPropagation()
                  e.preventDefault()
                  navigate(found.fullLink)
                }
              }}
              href={found.type === 'Movie' && found.fullLink}
            >
              <Accordion
                key={found.id}
                disableGutters
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  boxShadow: 'none',
                  background: 'transparent !important'
                }}
                onClick={(e) => {
                  found.type === 'Movie' && console.log('navigate')
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ display: found.type === 'Movie' ? 'none' : 'flex' }} />
                  }
                  sx={{
                    pointerEvents: found.type === 'Movie' ? 'none' : 'initial',
                    p: '0px',
                    '& .MuiAccordionSummary-content': { m: '0px' }
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }}>
                    <img
                      src={found.poster}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = `${process.env.REACT_APP_API_URL}/notfound.jpg`
                      }}
                      width={'80px'}
                      height={'120px'}
                      alt="poster"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography fontWeight={'bold'} fontSize={{xs: '14px', lg: '18px'}}>
                        {found.title} ({found.releaseYear})
                      </Typography>
                      <Box>
                        <Typography>Type: Movie</Typography>
                        <Typography>Available Subs: {found.subCount}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  {found?.seasons
                    ?.map((x) => {
                      if (x.number === 0) {
                        x.number = Infinity
                      }
                      return x
                    })
                    .sort((a, b) => a.number - b.number)
                    .map((season) => (
                      <Link
                        sx={{ display: 'contents' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          navigate(season.fullLink)
                        }}
                        href={season.fullLink}
                      >
                        <Paper sx={{ padding: '0.25rem' }} elevation={2}>
                          <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '18px' }}>
                            {season.number !== Infinity
                              ? 'Season ' + season.number
                              : 'Extras (Complete Series)'}
                          </Typography>
                        </Paper>
                      </Link>
                    ))}
                </AccordionDetails>
              </Accordion>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

function FullSearch() {
  //
  const params = useParams()
  const darkmode = useDarkMode()
  set_meta( {
    is_landing: false,
  })
  //
  const { data: searchResult, isFetching: searchResultIsLoading } = useQuery({
    queryKey: ['searchMoviesUpload', params.query],
    queryFn: async () => {
      return await MainApi.searchMoviesFull(params.query)
    }
  })
  //

  //
  if (searchResultIsLoading) {
    return (
      <Box
        sx={{
          height: '800px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black'
        }}
      >
        <Grid fill={darkmode.value ? 'white' : 'black'} />
      </Box>
    )
  }
  return (
    <Container
      sx={{
        mt: { xs: '2rem', lg: '5rem' },
        gap: '0.5rem'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: 'bold',
          color: darkmode.value ? 'white' : '#21495F'
        }}
      >
        Search Results
      </Typography>
      <Divider
        flexItem
        sx={{
          borderColor: darkmode.value ? 'white' : 'black',
          borderBottomWidth: '2px',
          mb: '2rem'
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          width: '100%',
          gap: '3rem',
          color: darkmode.value ? 'white' : 'initial'
        }}
      >
        {/* Movies */}
        <SearchResultSection
          title={`Movies (#${searchResult.found.filter((x) => x.type === 'Movie').length})`}
          items={searchResult.found.filter((x) => x.type === 'Movie').slice(0, 20)}
        />
        {/* TV-Series */}
        <SearchResultSection
          title={`TV-Series (#${searchResult.found.filter((x) => x.type === 'TVSeries').length})`}
          items={searchResult.found.filter((x) => x.type === 'TVSeries').slice(0, 20)}
        />
      </Box>
    </Container>
  )
}

export default FullSearch
