import { Box, Button, ButtonGroup, Divider, Link, Paper, Typography } from '@mui/material'
import { PRIVILAGES } from '../../data/privileges'
import axios from 'axios'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import { useWindowSize } from 'usehooks-ts'
import { dispachAlert } from '../../utils/dispachers';
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
function SubtitleDetails({
  rated,
  details,
  togglePreview,
  toggleReport,
  isOwned,
  subId,
  privs,
  toggleMoveSub
}) {
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  const isSmall = useWindowSize()
  const isRated = rated !== undefined
  const good = rated === 'good' ? 1 : 0
  const bad = rated === 'bad' ? 1 : 0
  const totalRates = parseInt(details?.rating?.rated) + (isRated ? 1 : 0)
  const goodRates = parseInt(details?.rating?.goodRate) + good
  const badRates = parseInt(details?.rating?.badRate) + bad
  const handleEditClick = () => {
    if (privs?.includes(PRIVILAGES.edit_subtitle)) navigate('/editadmin/' + details.id)
    else if (isOwned) navigate(`/edit/${details.id}`)
  }
  const handleDeleteSub = () => {
    var answer = window.confirm('Confirm Deleting Sub?')
    if (answer) {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/subtitle/delete',
          { subId: subId },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            window.location.reload()
          } else {
            dispachAlert('error', res?.data?.errMsg || 'Error')
          }
        })
        .catch((err) => {
          dispachAlert('error', 'Error')
          console.log(err)
        })
    }
  }
  return (
    <Paper
      elevation={6}
      sx={{
        backgroundColor: darkmode.value ? 'unset' : 'white',
        '& .MuiTypography-root': { fontFamily: 'Regular-Family,Arial' }
      }}
    >
      <Box sx={{ p: '1rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mb: '1rem',
            gap: '1rem',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Medium-Family',
              fontWeight: 'bold',
              fontSize: '1.15rem',
              mb: '.5rem'
            }}
          >
            Subtitle Details
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ButtonGroup
              variant="contained"
              orientation={isSmall ? 'horizontal' : 'vertical'}
              aria-label="subinfo"
              sx={{ width: 'fit-content', '& .MuiButton-root': { borderColor: '#fff' } }}
              disableRipple
            >
              {/* preview */}
              <Button
                sx={{
                  color: darkmode.value ? 'white' : 'white',
                  '&:hover': { backgroundColor: '#0b9f9f' },
                  backgroundColor: '#4b8686',
                  textTransform: 'none',
                  fontSize: '0.7rem',
                  py: '0.25rem',
                  px: '0.5rem',
                  fontFamily: 'Medium-Family'
                }}
                onClick={togglePreview}
              >
                Preview
              </Button>
              {/* report */}
              <Button
                sx={{
                  color: darkmode.value ? 'white' : 'white',
                  '&:hover': { backgroundColor: '#0b9f9f' },
                  backgroundColor: '#4b8686',
                  textTransform: 'none',
                  fontSize: '0.7rem',
                  py: '0.25rem',
                  px: '0.5rem',
                  fontFamily: 'Medium-Family'
                }}
                onClick={toggleReport}
              >
                Report
              </Button>
              {/* move */}
              {privs?.includes(PRIVILAGES.move_subtitle) && (
                <Button
                  sx={{
                    color: darkmode.value ? 'white' : 'white',
                    '&:hover': { backgroundColor: '#0b9f9f' },
                    backgroundColor: '#4b8686',
                    textTransform: 'none',
                    fontSize: '0.7rem',
                    py: '0.25rem',
                    px: '0.5rem',
                    fontFamily: 'Medium-Family'
                  }}
                  onClick={toggleMoveSub}
                >
                  Move
                </Button>
              )}
              {isOwned || privs?.includes(PRIVILAGES.edit_subtitle) ? (
                <Button
                  sx={{
                    color: darkmode.value ? 'white' : 'white',
                    '&:hover': { backgroundColor: '#0b9f9f' },
                    backgroundColor: '#4b8686',
                    textTransform: 'none',
                    fontSize: '0.7rem',
                    py: '0.25rem',
                    px: '0.5rem',
                    fontFamily: 'Medium-Family'
                  }}
                  disableTouchRipple
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              ) : (
                ''
              )}
              {/* delete */}
              {privs?.includes(PRIVILAGES.delete_subtitle) && (
                <Button
                  sx={{
                    color: darkmode.value ? 'white' : 'white',
                    '&:hover': { backgroundColor: '#0b9f9f' },
                    backgroundColor: '#4b8686',
                    textTransform: 'none',
                    fontSize: '0.7rem',
                    py: '0.25rem',
                    px: '0.5rem',
                    fontFamily: 'Medium-Family'
                  }}
                  onClick={handleDeleteSub}
                >
                  Delete
                </Button>
              )}
            </ButtonGroup>
          </Box>
        </Box>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Uploaded: </strong>
          {moment(details?.uploadDate).format('lll')}&nbsp;&nbsp;(
          {moment(details.uploadDate).fromNow()})
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Hearing Impaired: </strong>
          {details?.hi !== undefined ? (details?.hi ? 'Yes' : 'No') : 'N/A'}
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Foriegn Parts: </strong>
          {details?.fp !== undefined ? (details?.fp ? 'Yes' : 'No') : 'N/A'}
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Files: </strong>
          {details?.files || 'N/A'}&nbsp;(
          {details?.size ? numberWithCommas(details.size) + ' Bytes' : 'N/A'})
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Production Type: </strong>
          {details?.pt || 'N/A'}
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Frame Rate: </strong>
          {details?.fr || 'N/A'}
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Release Type: </strong>
          {details?.rt || 'N/A'}
        </Typography>
        <Divider sx={{ borderColor: 'rgb(0,0,0,0.25)', my: '0.5rem' }} />
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Rated: </strong>
          {totalRates > 0 ? Math.round((goodRates / totalRates) * 10) + '/10' : 'N/A'} From{' '}
          {totalRates > 0 ? (
            <Link
              underline={'none'}
              href={'/ratings/' + subId}
              onClick={(e) => {
                if (!e.ctrlKey) {
                  e.preventDefault()
                  navigate('/ratings/' + subId)
                }
              }}
            >
              {totalRates} Users
            </Link>
          ) : (
            `${totalRates} Users`
          )}
        </Typography>
        {goodRates > 0 ? (
          <>
            <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
              <strong>Voted as Good By: </strong>
              {goodRates || 'N/A'}
            </Typography>
          </>
        ) : (
          <></>
        )}
        {badRates > 0 ? (
          <>
            <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
              <strong>Voted as Bad By: </strong>
              {badRates || 'N/A'}
            </Typography>
          </>
        ) : (
          <></>
        )}
        <Typography sx={{ fontSize: '0.9rem', fontFamily: 'Medium-Family' }}>
          <strong>Downloads: </strong>
          {details?.downloads || 'N/A'}
        </Typography>
      </Box>
    </Paper>
  )
}

export default SubtitleDetails
