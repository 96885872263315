import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import useDarkMode from 'use-dark-mode';
const rts = [
    "WEB",
    "BLURAY",
    "HDTV",
    "CAM/HDTS/HDRIP",
    "UNOFFICIAL/LEAKED",
    "DVDRip",
    "Other",
]
function ReleaseTypeInput({ rt, setRt, disabled }) {
    const darkmode = useDarkMode(false);
    const onChnage = (e) => {
        setRt(e.target.value)
    }
    return (
        <Box sx={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "0.5rem", fontWeight: "bold", color: darkmode.value ? "white" : "initial" }}>Release Type</Typography>
            <Box sx={{ minWidth: 120 }}>
                <FormControl>
                    <Select
                        disabled={disabled}
                        variant='standard'
                        labelId="Release Type"
                        id="rt"
                        value={rt}
                        label="Release Type"
                        onChange={onChnage}
                    >
                        {rts.map((rt, index) => (
                            <MenuItem key={index} value={rt} >{rt}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default ReleaseTypeInput