import { Box, Divider, Skeleton } from '@mui/material';
import React from 'react'
import useDarkMode from 'use-dark-mode';

function DownloadsSkeleton() {
    const darkmode = useDarkMode();
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
      <>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50px',
            gap: '3rem',
            fontFamily: 'Medium-Family'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              wordBreak: 'break-word',
              width: '65%'
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ height: '30px', width: '100%' }}
            ></Skeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textWrap: 'nowrap',
              width: '20%',
              gap: '5px'
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ height: '20px', width: '150px' }}
            ></Skeleton>
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ height: '20px', width: '150px' }}
            ></Skeleton>
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ height: '20px', width: '150px' }}
            ></Skeleton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              gap: '0.5rem',
              width: '25%'
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ height: '50px', width: '120px' }}
            ></Skeleton>
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={{ height: '50px', width: '120px' }}
            ></Skeleton>
          </Box>
        </Box>
        <Divider
          sx={{ borderColor: darkmode.value ? 'rgb(255,255,255,0.35)' : 'rgb(0,0,0,0.35)' }}
          flexItem
        />
      </>
    ))
}

export default DownloadsSkeleton