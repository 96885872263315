import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React, { useRef, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import axios from 'axios'
import { dispachAlert } from '../../../utils/dispachers';
const mainFont = 'Medium-Family'
const BoldFont = 'Bold-Family'

function NewChatModal({ open, handleClose, setChats, setCurrentChat, setVisibleChats }) {
  const controllerRef = useRef()
  const msgRef = useRef()
  const [loading, setLoading] = useState(false)
  const [found, setFound] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)

  const handleOnChangeText = (e) => {
    if (e.target.value.length >= 3) {
      setSelectedUser(null)
      if (controllerRef.current) {
        controllerRef.current.abort()
      }
      controllerRef.current = new AbortController()
      const signal = controllerRef.current.signal
      setLoading(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/searchUsers',
          { query: e.target.value },
          { signal: signal, withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setLoading(false)
            setFound(res.data.found)
          }
        })
        .catch((err) => {
          if (err.name !== 'CanceledError') {
            setLoading(false)
          }
          setFound([])
        })
    }
  }
  const handleClickUser = (user) => {
    setSelectedUser(user)
  }
  const handleCloseHere = () => {
    setLoading(false)
    setFound([])
    setSelectedUser(null)
    setShowMessage(false)
    handleClose()
  }
  const handleSend = () => {
    if (msgRef.current.value !== '') {
      setSendingMessage(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/createChat',
          { userid: selectedUser.userId, msg: msgRef.current.value },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setChats((p) => [
              {
                chatId: res.data.chatId,
                targetName: res.data.userName,
                userId: selectedUser.userId,
                messages: [
                  {
                    from: 'me',
                    time: new Date(),
                    text: msgRef.current.value
                  }
                ],
                userAvatar: null
              },
              ...p
            ])
            setVisibleChats((p) => [
              {
                chatId: res.data.chatId,
                targetName: res.data.userName,
                userId: selectedUser.userId,
                messages: [
                  {
                    from: 'me',
                    time: new Date(),
                    text: msgRef.current.value
                  }
                ],
                userAvatar: null
              },
              ...p
            ])
            setCurrentChat({ chatId: res.data.chatId, userId: selectedUser.userId })
            handleCloseHere()
            setSendingMessage(false)
          } else {
            dispachAlert('error', res.data.errMsg || 'Error.')
            setSendingMessage(false)
          }
        })
        .catch((err) => {
          setSendingMessage(false)
          setFound([])
        })
    }
  }
  const handleClickHasChat = ( user ) =>
  {
    console.log('user', user);
    
    setCurrentChat({ chatId: user.chatId.toString(), userId: user.userId })
    handleCloseHere()
  }
  return (
    <>
      <Dialog open={open} onClose={null}>
        <DialogTitle fontFamily={mainFont} sx={{ fontWeight: 'Bold', minWidth: '550px' }}>
          New Chat
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ width: { lg: '50%' } }}>
            <Box>
              <DialogContentText>Type a username to search</DialogContentText>
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                name="Username"
                label="Username"
                type="text"
                variant="standard"
                onChange={handleOnChangeText}
              />
            </Box>
            <Box sx={{ display: selectedUser ? 'flex' : 'none', mt: '1rem' }}>
              <TextField
                inputRef={msgRef}
                autoFocus
                margin="dense"
                id="Message"
                name="Message"
                label="Message"
                type="text"
                variant="outlined"
                multiline
                fullWidth
                maxRows={5}
                inputProps={{ dir: 'auto' }}
              />
            </Box>
          </Box>

          {loading ? (
            <>
              <Box
                sx={{
                  height: '70px',
                  width: { lg: '45%' },
                  bgcolor: '',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ThreeDots fill="black" width={'6rem'} />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: { lg: '45%' },
                  bgcolor: '',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                {found.length > 0 ? (
                  found.map((user) => (
                    <Paper
                      onClick={() => !user.hasChat ? handleClickUser(user) : handleClickHasChat(user)}
                      key={user.userId}
                      variant="elevation"
                      elevation={2}
                      sx={{
                        bgcolor: selectedUser == user ? '#00808091' : 'rgb(0,0,0,0.18)',
                        my: '0.25rem',
                        display: 'flex',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        width: '250px'
                      }}
                    >
                      <Box
                        sx={{ width: '100%', display: 'flex', alignItems: 'center', p: '0.5rem' }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: 'mediumpurple',
                            width: '1.5rem',
                            height: '1.5rem',
                            fontSize: '.9rem'
                          }}
                          src={user?.userAvatar}
                        >
                          {user.userName.slice(0, 1).toUpperCase()}
                        </Avatar>
                        <Box
                          sx={{
                            height: '100%',
                            p: '0.5rem',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            fontFamily={BoldFont}
                            sx={{ marginBottom: '0rem', color: 'rgb(0,0,0,0.9)', fontSize: '1rem' }}
                          >
                            {user.userName}{' '}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  ))
                ) : (
                  <>
                    <Typography>No User Found</Typography>
                  </>
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button disableRipple onClick={handleCloseHere} sx={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <Button
            disableRipple
            variant="contained"
            onClick={handleSend}
            sx={{ textTransform: 'none' }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default NewChatModal
