import { Box, Button, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { dispachAlert } from '../../../utils/dispachers';

function ChangeLinkname({ selected, setSelected, handleLoad }) {
  const [linkname, setLinkname] = useState(selected.linkName)
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    setLinkname(selected.linkName)
  }, [selected])
  const handleSubmit = () => {
    if (linkname !== '') {
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/movies/updateLinkname',
          { movieId: selected.id, newLinkname: linkname },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setSelected((p) => ({ ...p, linkname: linkname }))
            dispachAlert('success', 'Success.')
            handleLoad()
          } else {
            dispachAlert('error', res.data.errMsg || 'Error.')
          }
          setSubmitting(false)
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
          }
          setSubmitting(false)
          console.log({ err })
        })
    }
  }
  return (
    <Box>
      <TextField
        fullWidth
        value={linkname}
        onChange={(e) => setLinkname(e.target.value)}
        placeholder="Enter Linkname Here ..."
        label="LinkName"
        size="small"
      />
      <Button
        variant="contained"
        disabled={linkname === selected.linkName || submitting || linkname === ''}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Box>
  )
}

export default ChangeLinkname
