import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { changeSignupDateSubscene } from '../../../../api/adminApi';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { dispachAlert } from '../../../../utils/dispachers';
function SignupDateSubscene({ user, isLoading }) {
    const [date, setDate] = useState(user.signupDateSubscene || "");
    const queryClient = useQueryClient();
    const { isFetching, refetch } = useQuery({
        queryKey: ['adminUpdateSignupSubscene'],
        queryFn: async () => {
            try {
                const result = await changeSignupDateSubscene(user.id, date);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    });
    const { isFetching: isFetchingDelete, refetch: deleteSignupSubscene } = useQuery({
        queryKey: ['adminDeleteSignupSubscene'],
        queryFn: async () => {
            try {
                const result = await changeSignupDateSubscene(user.id, null);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    });
    useEffect(() => {
        setDate(user?.signupDateSubscene || null)
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Box sx={{ display: 'flex', gap: "1rem", }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker label="Subscene Register Date" value={moment(date)} disabled={isFetching || isLoading || isFetchingDelete}
                        onChange={(newValue) => setDate(newValue)}
                        slotProps={{ textField: { fullWidth: true, error: false } }}
                    />
                </LocalizationProvider>
                <Button sx={{ display: "flex" }} variant='outlined' disabled={user.signupDateSubscene === date || isFetching || isLoading} onClick={() => { if (!isFetching) refetch() }}><SaveIcon /></Button>
                <Button sx={{ display: "flex" }} color='error' variant='outlined' disabled={user.signupDateSubscene === null || isFetching || isLoading || isFetchingDelete} onClick={() => { if (!isFetching) return deleteSignupSubscene(); }}><DeleteForeverIcon /></Button>
            </Box>
        </Box>
    )
}

export default SignupDateSubscene
