import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import './AdminPanel.css'
import { useQuery } from '@tanstack/react-query'
import { getUsers } from '../../api/adminApi'
import Information from './Users/Information'
import Actions from './Users/Actions'
import { set_meta } from '../../utils/seo';

function Users() {
  const [filters, setFilters] = useState({})
  const [ selected, setSelected ] = useState( undefined )
  set_meta({
    is_landing: false
  })
  const {
    data: userData,
    refetch: reloadUserData,
    isFetching: isFetchingUsers
  } = useQuery({
    queryKey: ['adminGetUsers'],
    queryFn: async () => {
      try {
        const result = await getUsers(
          filters.id || undefined,
          filters.username || undefined,
          filters.verified !== undefined ? filters.verified : true
        )
        if (selected) {
          const newSelected = result?.users?.filter((x) => x.id === selected.id)[0]
          if (newSelected) {
            setSelected(newSelected)
          }
        }
        return result
      } catch (error) {
        if (error?.response?.status === 403) {
          return { forbidden: true }
        }
        return error
      }
    }
  })
  if (userData?.forbidden) {
    return <>Forbidden</>
  }
  return (
    <Paper elevation={4} sx={{ borderRadius: '16px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: '1rem',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Typography sx={{ textWrap: 'nowrap' }}>User ID</Typography>
            <TextField
              fullWidth
              size="small"
              disabled={isFetchingUsers}
              value={filters.id || ''}
              onChange={(e) => setFilters((p) => ({ ...p, id: e.target.value }))}
            ></TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Typography sx={{ textWrap: 'nowrap' }}>Username</Typography>
            <TextField
              fullWidth
              size="small"
              disabled={isFetchingUsers}
              value={filters.username || ''}
              onChange={(e) => setFilters((p) => ({ ...p, username: e.target.value }))}
            ></TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Verified</Typography>
            <Checkbox
              disabled={isFetchingUsers}
              defaultChecked
              onChange={(e, v) => setFilters((p) => ({ ...p, verified: v }))}
            />
          </Box>
        </Box>
        <Box sx={{ alignSelf: 'center' }}>
          <Button variant="outlined" disabled={isFetchingUsers} onClick={reloadUserData}>
            Filter
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            minHeight: '80vh',
            gap: '1rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%',
              maxWidth: '400px'
            }}
          >
            <Typography sx={{ fontFamily: 'Bold-Family', fontSize: '32px', my: '1rem' }}>
              Users
            </Typography>
            {userData?.users?.map((user) => (
              <Paper
                elevation={4}
                key={user.id}
                sx={{ width: '100%', p: '1rem', display: 'flex', gap: '0.5rem', cursor: 'pointer' }}
                onClick={() => setSelected(user)}
              >
                <Avatar
                  src={process.env.REACT_APP_API_URL + user.avatar}
                  sx={{ height: '50px', width: '50px' }}
                ></Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontFamily: 'Medium-Family', fontWeight: 'bold' }}>
                    {user.username}
                  </Typography>
                  <Typography sx={{ fontFamily: 'Medium-Family', opacity: '0.6' }}>
                    #{user.id}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
          <Divider orientation="vertical" flexItem sx={{ borderColor: 'black' }} />
          <Box sx={{ flex: 1 }}>
            {!selected ? (
              <Typography variant="h4" fontFamily={'Medium-Family'} fontWeight={'bold'}>
                Select a user...
              </Typography>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Link href={'/u/' + selected.id} target="_blank" sx={{ display: 'contents' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Bold-Family',
                        fontSize: '32px',
                        my: '1rem',
                        textAlign: 'center'
                      }}
                    >
                      {selected.username}
                    </Typography>
                  </Link>
                </Box>
                <Information user={selected} isLoading={isFetchingUsers} />
                <Divider flexItem />
                <Actions user={selected} isLoading={isFetchingUsers} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Users
