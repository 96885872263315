/**
 * Permissions:
 */
export const PRIVILAGES = {
  /**
   * move subtitle - all & move all subs
   */
  move_subtitle: '1',
  /**
   * delete subtitle - all
   */
  delete_subtitle: '2',
  /**
   * delete rate - all
   */
  delete_rate: '3',
  /**
   * delete comment - all
   */
  delete_comment: '4',
  /**
   * edit subtitle - all
   */
  edit_subtitle: '5',
  /**
   * edit account status
   */
  edit_account_status: '6',
  /**
   * edit user + give badges + Merge User
   */
  edit_user_give_badges_merge_user: '7',
  /**
   * delete movie page
   */
  delete_movie_page: '8',
  /**
   * ban user + restrictions
   */
  ban_user: '9',
  /**
   * tickets - all
   */
  all_tickets: '10',
  /**
   * tickets - arabic
   */
  arabic_tickets: '10-01',
  /**
   * tickets - bengal
   */
  bengal_tickets: '10-02',
  /**
   * tickets - farsi/persian
   */
  persian_tickets: '10-03',
  /**
   * edit movie poster
   */
  edit_movie_poster: '11',
  /**
   * add news
   */
  add_news: '12',
  /**
   * edit news
   */
  edit_news: '13',
  /**
   * reports access
   */
  reports_access: '14',
  /**
   * give permissions + fix errors panel
   */
  give_permissions_fix_errors_panel: '15',
  /**
   * delete news
   */
  delete_news: '16',
  /**
   * change source link for custom
   */
  change_source_link_for_custom: '17',
  /**
   * change movie title
   */
  change_movie_title: '18',
  /**
   * change movie linkname
   */
  change_movie_linkname: '19',
  /**
   * change homepage titles
   */
  change_homepage_titles: '20'
}

/**
 * Permissions:
 */
export const privilage_list = [
  /**
   * move subtitle - all & move all subs
   */
  { value: 1, title: 'move subtitle - all & move all subs' },
  /**
   * delete subtitle - all
   */
  { value: 2, title: 'delete subtitle - all' },
  /**
   * delete rate - all
   */
  { value: 3, title: 'delete rate - all' },
  /**
   * delete comment - all
   */
  { value: 4, title: 'delete comment - all' },
  /**
   * edit subtitle - all
   */
  { value: 5, title: 'edit subtitle - all' },
  /**
   * edit account status
   */
  { value: 6, title: 'edit account status' },
  /**
   * edit user + give badges + Merge User
   */
  { value: 7, title: 'edit user + give badges + Merge User' },
  /**
   * delete movie page
   */
  { value: 8, title: 'delete movie page' },
  /**
   * ban user + restrictions
   */
  { value: 9, title: 'ban user + restrictions' },
  /**
   * tickets - all
   */
  { value: 10, title: 'tickets - all' },
  /**
   * tickets - arabic
   */
  { value: '10-01', title: 'tickets - arabic' },
  /**
   * tickets - bengal
   */
  { value: '10-02', title: 'tickets - bengal' },
  /**
   * tickets - farsi/persian
   */
  { value: '10-03', title: 'tickets - farsi/persian' },
  /**
   * edit movie poster
   */
  { value: 11, title: 'edit movie poster' },
  /**
   * add news
   */
  { value: 12, title: 'add news' },
  /**
   * edit news
   */
  { value: 13, title: 'edit news' },
  /**
   * reports access
   */
  { value: 14, title: 'reports access' },
  /**
   * give permissions + fix errors panel
   */
  { value: 15, title: 'give permissions + fix errors panel' },
  /**
   * delete news
   */
  { value: 16, title: 'delete news' },
  /**
   * change source link for custom
   */
  { value: 17, title: 'change source link for custom' },
  /**
   * change movie title
   */
  { value: 18, title: 'change movie title' },
  /**
   * change movie linkname
   */
  { value: 19, title: 'change movie linkname' },
  /**
   * change homepage titles
   */
  { value: 20, title: 'change homepage titles' }
]
