import React, { useEffect, useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import { Button, IconButton, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { UserApi } from '../../api/userApi'
const validateUsername = (username) => {
  return username.length > 2
}
const validatePassword = (password) => {
  return password.length > 7
}
const RegularFont = 'Regular-Family'
const BoldFont = 'Bold-Family'
function LoginDialog({ handleClose, isOpen, handleRegisterOpen, openForgetPassword }) {
  const [creds, setCreds] = useState({ username: '', password: '' })
  const [submiting, setSubmiting] = useState(false)
  const [submitDisable, setSubmitDisable] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [emailResendProps, setEmailResendProps] = useState({
    color: 'red',
    text: '',
    disabled: false
  })
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const [error, setError] = useState(undefined)
  const [captchaSolved, setCaptchaSolved] = useState(false)
  const [showBanned, setShowBanned] = useState(false)
  const [banMessage, setBanMessage] = useState('')
  const captchaRef = useRef()
  const { refetch } = useQuery({
    queryKey: ['login'],
    queryFn: async () => {
      try {
        const loginData = await UserApi.login(
          creds.username,
          creds.password,
          captchaRef.current.getValue()
        )
        if (loginData) {
          if (loginData.success) {
            queryClient.invalidateQueries({
              queryKey: ['getUser']
            })
            handleClose()
          } else {
            if (loginData.errCode === 1) {
              setError('User Not Found.')
            } else if (loginData.errCode === 2) {
              setError('Email Not verified yet.')
              setEmailResendProps({
                ...emailResendProps,
                text: 'Email Not verified yet. Click to resend Email.'
              })
            } else if (loginData.errCode === 5) {
              setError('Incorrect Password.')
            } else if (loginData.errCode === 7) {
              setError('Captcha Verification Failed.')
            } else if (loginData.errCode === 8) {
              setBanMessage(loginData.msg)
              setShowBanned(true)
            } else {
              setError('Error from server.')
            }
            setSubmiting(false)
            setSubmitDisable(false)
            captchaRef.current.reset()
          }
        }
        return { success: true }
      } catch (error) {
        setSubmiting(false)
        setSubmitDisable(false)
        setError('Server error.')
      }
    },
    enabled: false
  })
  const queryClient = useQueryClient()

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleCredsChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (isOpen === false) {
      setCreds({ email: '', username: '', password: '' })
      setError(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    if (validatePassword(creds.password) && validateUsername(creds.username)) {
      setSubmitDisable(false)
    } else {
      setSubmitDisable(true)
    }
  }, [creds])
  const handleLogin = () => {
    if (validateUsername(creds.username) && !submiting) {
      setSubmiting(true)
      setSubmitDisable(true)
      setError(undefined)
      setCaptchaSolved(false)
      refetch()
    }
  }
  const handleResendEmailClick = (e) => {
    setEmailResendProps({
      ...emailResendProps,
      text: 'Sending ...',
      color: 'darkgoldenrod',
      disabled: true
    })
    axios
      .post(
        process.env.REACT_APP_API_URL + '/auth/resendEmail',
        { username: creds.username },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setEmailResendProps({ ...emailResendProps, text: 'Email sent.', color: 'green' })
        } else {
          if (res.data.errCode === 2) {
            setEmailResendProps({
              ...emailResendProps,
              text: 'Please wait a few minutes and try again...',
              color: 'red',
              disabled: false
            })
          }
          if (res.data.errCode === 6) {
            setEmailResendProps({
              ...emailResendProps,
              text: 'Your Account is already Verified. Proceed to login.',
              color: 'green',
              disabled: true
            })
          }
          if (res.data.errCode === 4) {
            setEmailResendProps({
              ...emailResendProps,
              text: 'Error from server',
              color: 'red',
              disabled: false
            })
          }
          if (res.data.errCode === 1) {
            setEmailResendProps({
              ...emailResendProps,
              text: 'User Not Found.',
              color: 'red',
              disabled: false
            })
          }
          if (res.data.errCode === 3) {
            setEmailResendProps({
              ...emailResendProps,
              text: 'Error from Email Sender',
              color: 'red',
              disabled: false
            })
          }
          if (res.data.errCode === 5) {
            setEmailResendProps({
              ...emailResendProps,
              text: 'User Not Found.',
              color: 'red',
              disabled: false
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        sx: {
          maxWidth: '400px'
        }
      }}
    >
      <Dialog open={showBanned} onClose={() => setShowBanned(false)}>
        <DialogContent>
          <Typography fontFamily={'Medium-Family'}>{banMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowBanned(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex', justifyContent: 'right', p: '0.5rem' }}>
        <IconButton
          children={<CloseIcon />}
          sx={{ borderRadius: '0px' }}
          disableRipple
          onClick={() => {
            handleClose()
          }}
        />
      </Box>
      <DialogTitle
        sx={{
          alignSelf: 'center',
          fontWeight: 'bold',
          fontFamily: BoldFont,
          pt: '0',
          pb: '1rem',
          fontSize: { xs: '1.5rem', md: '1.75rem', lg: '2.25rem' }
        }}
      >
        Login via Email
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '0.5rem'
        }}
      >
        {/* <DialogContentText sx={{ fontFamily: "Regular-Family", marginBottom: "2.5rem" }}>
                    To Login to this website, please enter your email address and password.
                </DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="username"
          label="Email Address"
          type="email"
          fullWidth
          sx={{
            '.MuiInputBase-root': { borderRadius: '10px', fontFamily: RegularFont },
            '.MuiFormLabel-root': { fontFamily: RegularFont }
          }}
          variant="outlined"
          onChange={handleCredsChange}
        />
        <TextField
          onChange={handleCredsChange}
          margin="dense"
          id="name"
          name="password"
          label="password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          sx={{
            '.MuiInputBase-root': { borderRadius: '10px', fontFamily: RegularFont },
            '.MuiFormLabel-root': { fontFamily: RegularFont },
            mb: '1rem'
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={(e) => {
            setCaptchaSolved(true)
          }}
          onExpired={() => {
            setCaptchaSolved(false)
          }}
          ref={captchaRef}
        />
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', px: '24px' }}
      >
        {error === 'Email Not verified yet.' ? (
          <>
            <Button
              disabled={emailResendProps.disabled}
              onClick={handleResendEmailClick}
              disableRipple
              disableTouchRipple
              sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'inherit' } }}
            >
              <Typography
                sx={{ display: error ? 'flex' : 'none', top: '-10px', paddingBottom: '1rem' }}
                color={emailResendProps.color}
                fontFamily={RegularFont}
              >
                {emailResendProps.text}
              </Typography>
            </Button>
          </>
        ) : (
          <>
            <Typography
              sx={{ display: error ? 'flex' : 'none', top: '-10px', paddingBottom: '1rem' }}
              color={'red'}
              fontFamily={RegularFont}
            >
              {error}
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          disabled={submitDisable || !captchaSolved}
          children={
            <Typography fontFamily={RegularFont} fontSize={'1.2rem'}>
              Login
            </Typography>
          }
          fullWidth
          sx={{
            // marginBottom: '3rem',
            textTransform: 'none',
            borderRadius: '10px'
          }}
          disableElevation
          disableRipple
          onClick={handleLogin}
        />
        <Button
          variant=""
          children={
            <Typography fontFamily={RegularFont} fontSize={'0.9rem'}>
              New user? Click to Register
            </Typography>
          }
          fullWidth
          sx={{
            textTransform: 'none',
            borderRadius: '10px',
            '&:hover': { backgroundColor: 'inherit' },
            color: 'blue'
          }}
          disableElevation
          disableRipple
          onClick={() => {
            handleRegisterOpen()
            handleClose()
          }}
        />
        <Button
          variant=""
          children={
            <Typography fontFamily={RegularFont} fontSize={'0.9rem'}>
              Forgot Password?
            </Typography>
          }
          fullWidth
          sx={{
            mb: { md: '2rem' },
            textTransform: 'none',
            borderRadius: '10px',
            '&:hover': { backgroundColor: 'inherit' },
            color: 'blue'
          }}
          disableElevation
          disableRipple
          onClick={() => {
            openForgetPassword()
            handleClose()
          }}
        />
      </DialogActions>
    </Dialog>
  )
}
export default LoginDialog
