import { Box, Link, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import SubtitleIcon from '../../Reusables/SubtitleIcon';

function RecentSub({ rec }) {
    const navigate = useNavigate();
    const darkmode = useDarkMode();
    return (
      <Box
        key={rec.id}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          mb: '5px',
          justifyContent: 'space-between',
          borderBottom: darkmode.value ? '1px solid rgb(255 255 255 / 50%)' : '1px solid #00000066'
        }}
      >
        <Tooltip title={rec.title} placement="top">
          <Link
            onClick={(e) => {
              if (!e.ctrlKey) {
                e.preventDefault()
                navigate('/subtitle/' + rec.linkName + '/' + rec.language + '/' + rec.id)
              }
            }}
            href={'/subtitle/' + rec.linkName + '/' + rec.language + '/' + rec.id}
            sx={{
              textDecoration: 'none',
              color: darkmode.value ? 'white' : '#21495F',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              textWrap: 'nowrap',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <SubtitleIcon style={{ fontSize: '20px', color: 'green', mr: '0.15rem' }} />
            <Typography
              sx={{
                fontFamily: 'Medium-Family',
                fontSize: '18px',
                display: 'block',
                alignItems: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textWrap: 'nowrap',
                color: darkmode.value ? 'white' : 'initial'
              }}
            >
              {rec.languageFormatted} {rec.title}
            </Typography>
          </Link>
        </Tooltip>
        <Link
          href={'/u/' + rec.uploadedBy}
          onClick={(e) => {
            if (!e.ctrlKey) {
              e.preventDefault()
              navigate('/u/' + rec.uploadedBy)
            }
          }}
          sx={{
            whiteSpace: 'nowrap',
            textDecoration: 'none',
            fontSize: '18px',
            color: darkmode.value ? 'white' : '#21495F',
            fontFamily: 'Medium-Family'
          }}
        >
          {rec.uploadedByUsername}
        </Link>
      </Box>
    )
}

export default RecentSub