import { Box, Button, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { dispachAlert } from '../../../utils/dispachers';

function ChangeSourcelink({ selected, setSelected, handleLoad }) {
  const [newSourcelink, setNewSourcelink] = useState(selected?.extra?.sourceLink || '')
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    setNewSourcelink(selected?.extra?.sourceLink || '')
  }, [selected])
  const handleSubmit = () => {
    if (newSourcelink !== '') {
      setSubmitting(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/admin/movies/updateSourcelink',
          { movieId: selected.id, newSourcelink: newSourcelink },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setSelected((p) => ({ ...p, extra: { sourceLink: newSourcelink } }))
            dispachAlert('success', 'Success.')
            handleLoad()
          } else {
            dispachAlert('error', res.data.errMsg || 'Error.')
          }
          setSubmitting(false)
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            dispachAlert('error', 'Forbidden.')
          }
          setSubmitting(false)
          console.log({ err })
        })
    }
  }
  return (
    <Box>
      <TextField
        disabled={selected.source !== 'custom'}
        fullWidth
        value={newSourcelink}
        onChange={(e) => setNewSourcelink(e.target.value)}
        placeholder="Enter Source Link Here ..."
        label="Source Link"
        size="small"
      />
      <Button
        variant="contained"
        disabled={
          newSourcelink === selected?.extra?.sourceLink || submitting || newSourcelink === ''
        }
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Box>
  )
}

export default ChangeSourcelink
