const TEMPLATES = {
  title: `SubSource: Download subtitles for your movies and Tv shows!`,
  'home:description':
    'Download subtitles for your favorite movies, entire seasons of TV Shows, and more—all from our diverse source. Best of all? It is free!',
  'movie:description': 'Download Subtitle Of [ Movie Name ], [ movie brief ].',
  'twitter:description': 'Download Subtitle Of [ Movie Name ], [ movie brief ].'
}


/**
 * Sets metadata for the current webpage, including title, description, and social media tags.
 * This function dynamically updates the HTML document's head section to customize metadata
 * based on provided parameters, enhancing SEO and social media sharing.

 * @param {Object} params - The metadata parameters.
 * @param {string} params.title - The page title to display in the browser tab and metadata.
 * @param {string} params.movie_name - The name of the movie (used in description metadata).
 * @param {string} params.movie_brief - A brief description of the movie.
 * @param {string} params.poster - A URL to the movie's poster image for use in social media tags.
 * @param {boolean} params.is_landing - Indicates if the page is a landing page, affecting the `og:type` property.

 * Internally, the function:
 * - Sets the document's title or a default title if none is provided.
 * - Adds or updates `meta` tags in the HTML `<head>` with Open Graph (og), Twitter, and generic meta tags.
 * - Truncates descriptions over 155 characters to fit typical social media length requirements.
 */

export function set_meta({ title, movie_name, movie_brief, poster, is_landing }) {
  // Helper to truncate long text to 155 characters, followed by '...'
  const getMax = (str) => (str.length <= 155 ? str : str.slice(0, 155) + '...');

  // Finds an existing meta tag by its `name` or `property` attribute
  const findMeta = ({ name, property }) =>
    name
      ? document.head.querySelector(`meta[name="${name}"]`)
      : document.head.querySelector(`meta[property="${property}"]`);

  // Adds or updates a meta tag's content or removes it if no content is provided
  const setMeta = ({ name, property, content }) => {
    let m = findMeta({ name, property });
    if (!content && m) {
      document.head.removeChild(m); // Removes the meta tag if content is empty
      return;
    }
    if (content) {
      if (!m) {
        // Creates the meta tag if it doesn't exist
        m = document.createElement('meta');
        if (name) m.setAttribute('name', name);
        if (property) m.setAttribute('property', property);
        document.head.appendChild(m);
      }
      m.content = getMax(content); // Sets the meta tag's content with a 155-character limit
    }
  };

  // Set document title or default from templates
  document.title = title ?? TEMPLATES['title'];

  // Set Open Graph and Twitter title meta tags
  setMeta({ property: 'og:title', content: title ?? TEMPLATES['title'] })
  setMeta({ property: 'twitter:title', content: title ?? TEMPLATES['title'] })

  // Set the Open Graph type depending on whether it's a landing page
  setMeta({
    property: 'og:type',
    content: is_landing ? 'website' : 'Article'
  });

  // Construct the description based on movie details, or use a default
  const desc = movie_name || movie_brief
    ? TEMPLATES['movie:description']
        .replace('[ Movie Name ]', movie_name)
        .replace('[ movie brief ]', movie_brief)
    : TEMPLATES['home:description'];

  // Set description, Open Graph, and Twitter description meta tags
  setMeta({ name: 'description', content: desc });
  setMeta({ property: 'og:description', content: desc });
  setMeta({ name: 'twitter:description', content: desc });

  // Set Open Graph and Twitter image meta tags with the poster URL
  setMeta({ property: 'og:image', content: poster });
  setMeta({ name: 'twitter:image', content: poster });
}

