import {
  Avatar,
  Box,
  Divider,
  Rating,
  Typography,
  Button,
  Paper,
  Tooltip,
  IconButton,
  Link,
  Container,
  Stack,
  Skeleton,
  ButtonGroup,
  List,
  ListItem
} from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../css/SubtitlePage.css'
import { decode } from 'html-entities'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import DownloadIcon from '@mui/icons-material/Download'
import SubtitleComments from '../Components/Subtitle/SubtitleComments'
import SubtitleDetails from '../Components/Subtitle/SubtitleDetails'
import SubtitlePreview from '../Components/Subtitle/SubtitlePreview'
import goodIcon from '../icons/good.png'
import badIcon from '../icons/bad.png'
import donateIcon from '../icons/Asset 17.png'
import { Grid } from 'react-loading-icons'
import { useUserContext } from '../Contexts/userContext'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import SubtitleMove from '../Components/Subtitle/SubtitleMove'
import useDarkMode from 'use-dark-mode'
import NotFound from './NotFound'
import { CurrentMovieContext } from '../Contexts/currentMovieContext'
import SubtitleReport from '../Components/Reports/SubtitleReport'
import Poster from '../Components/ui/Poster'
import { set_meta } from '../utils/seo'
import { dispachAlert } from '../utils/dispachers'
import UserBadgeList from '../Components/User/BadgeList'

const mainFont = 'Medium-Family'
function SubtitlePage() {
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  const user = useUserContext()
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [downloaded, setDownloaded] = useState(false)
  const [data, setData] = useState({})
  const [err, setErr] = useState(false)
  const [previewShow, setPreviewShow] = useState(
    window.localStorage.getItem('preview') !== undefined
      ? window.localStorage.getItem('preview') === '1'
        ? true
        : false
      : false
  )
  const [reportShow, setReportShow] = useState(false)
  const [rated, setRated] = useState(undefined)
  const [comments, setComments] = useState([])
  const [isHovering, setIsHovering] = useState({})
  const [copied, setCopied] = useState({})
  const [rating, setRating] = useState(false)
  const [moveShow, setMoveShow] = useState(false)
  const [submittingVote, setSubmittingVote] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { setCurrentMovieCtx } = useContext(CurrentMovieContext)
  const addComment = (comment) => {
    setComments((p) => [...p, comment])
  }
  const togglePreviewShow = () => {
    window.localStorage.setItem('preview', previewShow === true ? '0' : '1')
    setPreviewShow((p) => !p)
  }
  const toggleReportShow = () => {
    setReportShow((p) => !p)
  }
  const handleRate = (rate) => {
    if (!rating) {
      setRating(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/rateSub',
          { id: params.id, rate: rate },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setRated(rate)
          } else if (res.data.errMsg) {
            dispachAlert('error', res.data.errMsg)
          }
          setRating(false)
        })
        .catch((err) => {
          console.log(err)
          setRating(false)
        })
    }
  }
  const handleRemoveRate = () => {
    if (!rating) {
      setRating(true)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/rateSub',
          { id: params.id, remove: true },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setRated(undefined)
          } else if (res.data.errMsg) {
            dispachAlert('error', res.data.errMsg)
          }
          setRating(false)
        })
        .catch((err) => {
          setRating(false)
        })
    }
  }
  /* const handleDownload = () => {
        axios.post(process.env.REACT_APP_API_URL + "/api/downloadSub", { id: params.id }, { responseType: "blob", withCredentials: true }).then(res => {
            if (res?.data && user?.id) {
                setDownloaded(true);
            }
            fileDownload(res.data, data.sub.fileName);
        }).catch(err => {
            console.log(err);
        })
    } */
  const handleDownload2 = (e) => {
    e.preventDefault()
    setDownloaded(true)
    window.location.href =
      process.env.REACT_APP_API_URL + '/api/downloadSub/' + data.sub.downloadToken
  }
  useEffect(() => {
    document.getElementById('preview')?.scrollIntoView()
  }, [previewShow])
  const voteComment = (id, vote) => {
    if (!user) {
      dispachAlert('error', 'You need to be logged in.')
      return
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/subCommentVote',
        { cmid: id, vote: comments.filter((x) => x.id === id)[0].vote === vote ? 0 : vote },
        { withCredentials: true }
      )
      .then((res) => {
        console.log({ id, vote })
        var _newComments = comments.map((cm) => {
          if (cm.id === id) {
            cm.vote === vote ? (cm.votes -= vote) : (cm.votes += vote - cm.vote)
            cm.vote = cm.vote === vote ? 0 : vote
          }
          return cm
        })
        setComments(_newComments)
        setSubmittingVote(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    const fetchSub = () => {
      window.scrollTo(0, 0)
      axios
        .post(
          process.env.REACT_APP_API_URL + '/api/getSub',
          { movie: params?.movie.toLowerCase(), lang: params?.lang?.toLowerCase(), id: params.id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.success) {
            setData(res.data)
            setDownloaded(res.data.downloaded || false)
            setComments(res.data.comments)
            setLoading(false)
            if (user) {
              setCurrentMovieCtx(res?.data?.movie?.id)
            }
            if (res?.data?.sub?.userRated !== undefined) {
              setRated(res?.data?.sub?.userRated === false ? 'bad' : 'good')
            }
            set_meta({
              is_landing: false,
              title: `Download ${res.data.sub.lang} Subtitle For ${res.data.movie.title}`,
              movie_name: res.data.movie.title,
              movie_brief: res.data.movie.brief ?? '',
              poster: res.data.movie?.poster ?? ''
            })
          } else {
            setLoading(false)
          }
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            setNotFound(true)
          }
          setLoading(false)
          setErr(true)
          console.log({ err })
        })
    }
    fetchSub()
    // eslint-disable-next-line
  }, [])
  const handleMouseEnterRI = (index) => {
    setIsHovering((p) => ({ ...p, [index]: true }))
  }
  const handleMouseLeaveRI = (index) => {
    setIsHovering((p) => ({ ...p, [index]: false }))
    setCopied((p) => ({ ...p, [index]: false }))
  }
  const handleClickedRI = (index) => {
    setCopied((p) => ({ ...p, [index]: true }))
    navigator.clipboard.writeText(data.sub.ri[index])
  }
  const handleCopyAll = () => {
    var text = ''
    data.sub.ri.forEach((info) => {
      text += `${info}\n`
    })
    navigator.clipboard.writeText(text)
  }
  const toggleMoveSub = () => {
    setMoveShow((p) => !p)
  }
  if (notFound) {
    return <NotFound />
  }
  return err ? (
    <>Error...</>
  ) : !loading ? (
    <Container maxWidth="xl" sx={{ marginTop: '1rem', overflow: 'auto' }}>
      {/* back to sub list */}
      <Link
        sx={{ display: 'contents' }}
        href={data.movie.movieLink}
        onClick={(e) => {
          if (!e.ctrlKey) {
            e.preventDefault()
            navigate(data.movie.movieLink)
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: 'fit-content'
          }}
        >
          <Paper
            sx={{
              width: 'fit-content',
              py: '0.25rem',
              px: '0.25rem',
              m: '0.25rem',
              backgroundColor: 'rgb(255,255,255,0.6)',
              transform: 'scale(0.75)'
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: '0.75rem', color: 'black' }} />
          </Paper>
          <Typography
            fontWeight={'bold'}
            fontSize={'1rem'}
            sx={{ lineHeight: 'unset', color: darkmode.value ? 'white' : 'initial' }}
          >
            {' '}
            {'Back To Subtitle List'}
          </Typography>
        </Box>
      </Link>
      <Divider sx={{ borderColor: 'black' }} />
      {/* content */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: { xs: '1.5rem' },
          py: { xs: '1.5rem' },
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: { xs: '1.5rem' }
          }}
        >
          {/* poster */}
          <Box sx={{ display: 'flex', gap: { xs: '.5rem' } }}>
            <Box sx={{ display: 'inline-flex' }}>
              <Link
                href={data.movie.movieLink}
                sx={{
                  display: 'inline-block',
                  width: { xs: '150px', md: '150px', lg: '200px', textDecoration: 'none' }
                }}
                onClick={(e) => {
                  if (!e.ctrlKey) {
                    e.preventDefault()
                    navigate(data.movie.movieLink)
                  }
                }}
              >
                {loading ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                      width: '100%',
                      height: '100%',
                      aspectRatio: '3/4',
                      borderRadius: '7px'
                    }}
                  />
                ) : (
                  <Poster src={data.movie.poster} alt={data.movie.title} />
                )}
              </Link>
            </Box>
            {/* title */}
            <Box
              sx={{
                display: { xs: 'flex', lg: 'none' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                gap: { xs: '.5rem' }
              }}
            >
              <Link
                sx={{ display: 'inline-block', textDecoration: 'none' }}
                href={data.movie.movieLink}
                onClick={(e) => {
                  if (!e.ctrlKey) {
                    e.preventDefault()
                    navigate(data.movie.movieLink)
                  }
                }}
              >
                <Typography
                  variant={'h1'}
                  sx={{
                    color: darkmode.value ? 'white' : 'initial',
                    fontSize: { xs: '1.25rem', md: '1.5rem', lg: '2rem' }
                  }}
                >
                  {data.movie.title}
                </Typography>
              </Link>
              <Box sx={{ display: 'flex' }}>
                <a
                  href={
                    data?.movie?.imdbLink?.startsWith('tt')
                      ? 'https://www.imdb.com/title/' + data?.movie?.imdbLink
                      : data?.movie?.imdbLink
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Avatar
                    children={
                      <img alt="IMDb" src={'/icons/imdb.png'} width={'40px'} height={'40px'} />
                    }
                    sx={{
                      backgroundColor: 'gold',
                      width: '40px',
                      height: '40px',
                      boxShadow:
                        '0px 3px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Box>
          {/* title/releases/commentors/download/detail/preview */}
          <Stack
            sx={{
              width: '100%',
              maxWidth: { lg: '600px' },
              gap: { xs: '1rem', md: '2rem' }
            }}
          >
            {/* title */}
            <Box
              sx={{
                display: { xs: 'none', lg: 'flex' },
                width: '100%',
                gap: { xs: '.5rem' },
                justifyContent: 'space-between'
              }}
            >
              <Link
                sx={{ display: 'inline-block', textDecoration: 'none' }}
                href={data.movie.movieLink}
                onClick={(e) => {
                  if (!e.ctrlKey) {
                    e.preventDefault()
                    navigate(data.movie.movieLink)
                  }
                }}
              >
                <Typography
                  variant={'h1'}
                  sx={{
                    color: darkmode.value ? 'white' : 'initial',
                    fontSize: { xs: '1.25rem', md: '1.5rem', lg: '2rem' }
                  }}
                >
                  {data.movie.title}
                </Typography>
              </Link>
              <Box>
                <a
                  href={
                    data?.movie?.imdbLink?.startsWith('tt')
                      ? 'https://www.imdb.com/title/' + data?.movie?.imdbLink
                      : data?.movie?.imdbLink
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Avatar
                    children={
                      <img alt="IMDb" src={'/icons/imdb.png'} width={'40px'} height={'40px'} />
                    }
                    sx={{
                      backgroundColor: 'gold',
                      width: '40px',
                      height: '40px',
                      boxShadow:
                        '0px 3px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }}
                  />
                </a>
              </Box>
            </Box>
            {/* Release Info */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  color="initial"
                  fontFamily={mainFont}
                  fontWeight={'bold'}
                  fontSize={'14px'}
                  sx={{ color: darkmode.value ? 'white' : 'initial' }}
                >
                  Release Info:
                </Typography>
                <Button
                  onClick={handleCopyAll}
                  sx={{
                    fontSize: '12px',
                    padding: '0px',
                    px: '0.25rem',
                    textTransform: 'none',
                    fontFamily: 'Medium-Family',
                    fontWeight: 'bold',
                    color: darkmode.value ? 'white' : 'initial'
                  }}
                  variant="outlined"
                  color="inherit"
                  endIcon={<CopyAllIcon />}
                >
                  Copy All
                </Button>
              </Box>
              {data.sub.ri.map((info, index) => (
                <Tooltip key={index} title={info} arrow placement="top-start">
                  <Paper
                    sx={{
                      py: '0.25rem',
                      my: '0.25rem',
                      px: '0.5rem',
                      backgroundColor: darkmode.value
                        ? 'rgb(255,255,255,0.2)'
                        : 'rgb(255,255,255,0.6)',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      textWrap: 'nowrap',
                      display: 'flex',
                      justifyContent: 'space-between',
                      cursor: 'pointer'
                    }}
                    onMouseEnter={() => {
                      handleMouseEnterRI(index)
                    }}
                    onMouseLeave={() => {
                      handleMouseLeaveRI(index)
                    }}
                    onClick={() => {
                      handleClickedRI(index)
                    }}
                  >
                    <Typography
                      color={darkmode.value ? 'white' : 'initial'}
                      fontFamily={mainFont}
                      fontSize={'14px'}
                      sx={{
                        lineHeight: '1rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '435px'
                      }}
                    >
                      {info}
                    </Typography>
                    {copied[index] === true ? (
                      <DoneIcon sx={{ fontSize: '0.75rem' }} />
                    ) : (
                      <ContentCopyIcon
                        sx={{
                          fontSize: '0.75rem',
                          visibility:
                            isHovering[index] !== undefined && isHovering[index]
                              ? 'visible'
                              : 'hidden'
                        }}
                      />
                    )}
                  </Paper>
                </Tooltip>
              ))}
            </Box>
            {/* Commentors */}
            <Box>
              <Typography
                fontFamily={mainFont}
                fontSize={'0.75rem'}
                sx={{ mt: '0.75rem', color: darkmode.value ? 'white' : 'initial' }}
              >
                A Commentary By:
              </Typography>
              <List>
                {/* original commentor */}
                <ListItem disablePadding divider sx={{ height: '50px' }}>
                  <Link
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '.5rem',
                      textDecoration: 'none',
                      '&:visited': { color: darkmode.value ? '#a561e3' : '#551A8B' }
                    }}
                    href={'/u/' + data.user.id}
                    onClick={(e) => {
                      if (!e.ctrlKey) {
                        e.preventDefault()
                        navigate('/u/' + data.user.id)
                      }
                    }}
                  >
                    <UserBadgeList badges={data.user.badges ?? []} disablePadding smaller />
                    <Tooltip title={data.user.username} placement="top">
                      <Typography
                        fontFamily={'Medium-Family'}
                        fontWeight={'bold'}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '16px'
                        }}
                      >
                        {data.user.username}
                      </Typography>
                    </Tooltip>
                    <Rating
                      value={data.user.rating}
                      max={5}
                      precision={0.5}
                      readOnly
                      sx={{
                        display: data.user.rating === -1 ? 'none' : 'flex',
                        height: '21px',
                        alignSelf: 'center',
                        '& .MuiRating-decimal': { marginLeft: '-2px', color: 'teal' }
                      }}
                      size="small"
                    ></Rating>
                  </Link>
                  <Tooltip title="Support Your Translator" placement="top" sx={{}}>
                    <IconButton
                      sx={{ marginLeft: '0.5rem', paddingTop: '0.25rem', display: 'none' }}
                      children={<img alt="donate" src={donateIcon} width={'17px'} />}
                    />
                  </Tooltip>
                </ListItem>
                {/* contributors */}
                {data.sub.contributors.map((c) => (
                  <ListItem key={c.id} disablePadding divider sx={{ height: '50px' }}>
                    <Link
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.5rem',
                        textDecoration: 'none',
                        '&:visited': { color: darkmode.value ? '#a561e3' : '#551A8B' }
                      }}
                      href={'/u/' + c.id}
                      onClick={(e) => {
                        if (!e.ctrlKey) {
                          e.preventDefault()
                          navigate('/u/' + c.id)
                        }
                      }}
                    >
                      <UserBadgeList badges={c.badges ?? []} disablePadding smaller />
                      <Tooltip title={c.username} placement="top">
                        <Typography
                          fontFamily={'Medium-Family'}
                          fontWeight={'bold'}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: '16px'
                          }}
                        >
                          {c.username}
                        </Typography>
                      </Tooltip>
                      <Rating
                        value={c.rating}
                        max={5}
                        precision={0.5}
                        readOnly
                        sx={{
                          display: c.rating === -1 ? 'none' : 'flex',
                          height: '21px',
                          alignSelf: 'center',
                          '& .MuiRating-decimal': { marginLeft: '-2px', color: 'teal' }
                        }}
                        size="small"
                      ></Rating>
                    </Link>
                    {/*  <Tooltip title="Support Your Translator" placement='top'>
                                                        <IconButton sx={{ marginLeft: "0.5rem", paddingTop: "0.25rem", display: "none" }} children={<img alt='donate' src={donateIcon} width={"17px"} />} />
                                                    </Tooltip> */}
                  </ListItem>
                ))}
              </List>
            </Box>
            {/* commentary */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  height: 'fit-content',
                  // width: '390px',
                  backgroundColor: darkmode.value ? '#434343' : '#f8f1df',
                  // transform: 'skew(-20deg)',
                  py: '1.5rem',
                  px: '1.5rem',
                  boxShadow: darkmode.value
                    ? 'inset 1px 1px 10px 5px #181818'
                    : 'inset 1px 1px 10px 5px #d9ceb4'
                }}
              >
                <Typography
                  sx={{
                    color: darkmode.value ? 'white' : 'black',
                    maxWidth: '450px',
                    whiteSpace: 'no-wrap',
                    maxHeight: '80px',
                    wordBreak: 'break-word',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '0.85rem',
                    // transform: 'skew(20deg)',
                    fontFamily: 'Regular-Family'
                  }}
                >
                  {decode(data.sub.commentary)}
                </Typography>
              </Box>
            </Box>
            {/* download & rate btn */}
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                gap: '.75rem',
                alignItems: 'center'
              }}
            >
              {/* download btn */}
              <Button
                href={process.env.REACT_APP_API_URL + '/api/downloadSub/' + data.sub.downloadToken}
                onClick={handleDownload2}
                variant="contained"
                color="success"
                size="small"
                sx={{
                  background: 'linear-gradient(#49bd4e,#2e7d32)',
                  minHeight: '45px',
                  width: { xs: '100%', sm: 'fit-content' },
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  padding: '0px',
                  py: '0.35rem',
                  textAlign: 'center',
                  paddingLeft: '1rem',
                  paddingRight: '1.2rem'
                }}
              >
                <DownloadIcon sx={{ marginRight: '0.35rem' }} />
                <Typography fontFamily={mainFont}> Download {data.sub.lang} Subtitle</Typography>
              </Button>

              {/* rate */}
              {downloaded && user ? (
                <ButtonGroup variant="outlined" aria-label="Subtitle rate button group">
                  {/* rate good */}
                  <Button
                    variant="contained"
                    color={rated === 'good' ? 'success' : 'inherit'}
                    disabled={rated === 'bad'}
                    size="small"
                    onClick={() => {
                      if (rated === undefined) {
                        handleRate('good')
                      } else {
                        handleRemoveRate()
                      }
                    }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: '4px',
                      py: '0.35rem',
                      color: darkmode.value ? 'white' : 'black',
                      fontSize: { xs: '.55rem', sm: '.8rem' },
                      minHeight: '45px',
                      borderTopLeftRadius: '15px',
                      borderBottomLeftRadius: '15px',
                      width: { xs: '50px', md: '100px' }
                    }}
                  >
                    <img alt="good" src={goodIcon} width={'15px'} />
                    <span>Good</span>
                  </Button>
                  {/* rate bad */}
                  <Button
                    variant="contained"
                    color={rated === 'bad' ? 'error' : 'inherit'}
                    disabled={rated === 'good'}
                    size="small"
                    onClick={() => {
                      if (rated === undefined) {
                        handleRate('bad')
                      } else {
                        handleRemoveRate()
                      }
                    }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: '4px',
                      py: '0.35rem',
                      fontSize: { xs: '.55rem', sm: '.8rem' },
                      minHeight: '45px',
                      color: darkmode.value ? 'white' : 'black',
                      borderTopRightRadius: '15px',
                      borderBottomRightRadius: '15px',
                      width: { xs: '50px', md: '100px' }
                    }}
                  >
                    <img alt="bad" src={badIcon} width={'15px'} />
                    <span>Bad</span>
                  </Button>
                </ButtonGroup>
              ) : (
                ''
              )}
            </Box>
            <SubtitleReport show={reportShow} toggleShow={toggleReportShow} subId={data.sub.id} />
            <SubtitleMove show={moveShow} toggleShow={toggleMoveSub} subId={data.sub.id} />
            <SubtitleDetails
              rated={rated}
              details={data.sub}
              togglePreview={togglePreviewShow}
              toggleReport={toggleReportShow}
              isOwned={user?.id === data.user.id}
              privs={user?.privileges}
              subId={data.sub.id}
              toggleMoveSub={toggleMoveSub}
            />
            <SubtitlePreview
              preview={data.sub.preview}
              show={previewShow}
              toggleShow={togglePreviewShow}
              isNew={data.sub.userUploaded}
            />
          </Stack>
        </Box>

        {/* comments */}
        <SubtitleComments
          comments={comments}
          addComment={addComment}
          voteComment={voteComment}
          subData={data}
          submittingVote={submittingVote}
          setSubmittingVote={setSubmittingVote}
        />
      </Box>
    </Container>
  ) : (
    <Box
      sx={{
        height: '800px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black'
      }}
    >
      <Grid fill={darkmode.value ? 'white' : 'black'} />
    </Box>
  )
}

export default SubtitlePage
