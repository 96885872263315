import {
  Box,
  Divider,
  Paper,
  Typography,
  Avatar,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Link
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import SubtitleCommentField from './SubtitleCommentField'
import moment from 'moment'
import ReplyIcon from '@mui/icons-material/Reply'
import FlagIcon from '@mui/icons-material/Flag'
import './Subtitle.css'
import { useNavigate } from 'react-router-dom'
import useDarkMode from 'use-dark-mode'
import { useQuery } from '@tanstack/react-query'
import { deleteSubCommentApi } from '../../api/adminApi'
import { useUserContext } from '../../Contexts/userContext'
import CommentReport from '../Reports/CommentReport'
import { dispachAlert } from '../../utils/dispachers';
function SubtitleComments({
  comments,
  addComment,
  voteComment,
  subData,
  submittingVote,
  setSubmittingVote,
  isAdmin
}) {
  const darkmode = useDarkMode()
  const navigate = useNavigate()
  const user = useUserContext()
  const [reply, setReply] = useState(null)
  const [commentText, setCommentText] = useState('')
  const [deleteCommentCreds, setDeleteCommentCreds] = useState(undefined)
  const [reportCommentShow, setReportCommentShow] = useState(false)
  const [reportCommentId, setReportCommentId] = useState(false)
  const { isFetching: isDeleting, refetch } = useQuery({
    queryKey: ['deleteComment', deleteCommentCreds],
    queryFn: async () => {
      try {
        await deleteSubCommentApi(
          deleteCommentCreds.id,
          deleteCommentCreds.subId,
          deleteCommentCreds.isReply,
          deleteCommentCreds.replyDate
        )
        window.location.reload()
      } catch (error) {
        dispachAlert('error', 'Server Error.')
      }
    },
    enabled: false
  })
  const toggleReportCommentShow = () => {
    setReportCommentShow((p) => !p)
  }
  const handleReply = (subId, second = null) => {
    setReply(comments.filter((x) => x.id === subId)[0])
    if (second) {
      if (!commentText.startsWith('@' + second)) {
        setCommentText((p) => '@' + second + ' ' + p)
      }
    }
  }
  const handleDeleteComment = (id, subId, isReply = false, replyDate = '') => {
    const answer = window.confirm('Confirm deleting comment?')
    if (answer) {
      setDeleteCommentCreds({
        id,
        subId,
        isReply,
        replyDate
      })
    }
  }
  useEffect(() => {
    if (deleteCommentCreds !== undefined) refetch()
  }, [deleteCommentCreds, refetch])
  return (
    <>
      <CommentReport
        show={reportCommentShow}
        toggleShow={toggleReportCommentShow}
        cmid={reportCommentId}
      />
      <Box
        sx={{
          maxHeight: 'max-content',
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: { lg: '550px' }
        }}
      >
        <Typography
          fontFamily={'Regular-Family'}
          fontWeight={'bold'}
          fontSize={'1.2rem'}
          sx={{ color: darkmode.value ? 'white' : 'initial' }}
        >
          Comments
        </Typography>
        <Divider sx={{ mb: '1rem' }} />
        {comments.length === 0 ? (
          <>
            <Paper
              variant="elevation"
              elevation={3}
              sx={{
                flexGrow: 1,
                mb: '1rem',
                p: '.5rem'
              }}
            >
              <Typography fontFamily={'Regular-Family'} fontWeight={'bold'} fontSize={'1.1rem'}>
                No Comments.
              </Typography>
            </Paper>
            <Box>
              <SubtitleCommentField
                addComment={addComment}
                subData={subData}
                reply={reply}
                commentText={commentText}
                setCommentText={setCommentText}
                setReply={setReply}
              />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ maxHeight: '70vh', overflowY: 'auto', px: '1rem' }} className="scrollCustom">
              {comments.map((comment, index) => (
                <Box key={index}>
                  <Paper
                    elevation={4}
                    sx={{
                      paddingLeft: '0.5rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      paddingRight: '0.75rem'
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ p: '0.5rem', display: 'flex', alignItems: 'center' }}>
                        <ToggleButtonGroup
                          disabled={submittingVote}
                          orientation="vertical"
                          value={comment.vote}
                          exclusive
                          onChange={() => {
                            setSubmittingVote(true)
                            voteComment(comment.id, 1)
                          }}
                          sx={{
                            width: '25px',
                            placeContent: 'center',
                            backgroundColor: darkmode.value ? 'dimgray' : '#f8f1df'
                          }}
                        >
                          <ToggleButton
                            value={1}
                            aria-label="list"
                            sx={{ padding: '0px', px: '10px', py: '0px', lineHeight: '19px' }}
                          >
                            +
                          </ToggleButton>
                          <ToggleButton
                            value="count"
                            aria-label="module"
                            sx={{
                              padding: '0px',
                              px: '10px',
                              py: '0px',
                              lineHeight: '19px',
                              '&.Mui-disabled': {
                                color:
                                  parseInt(comment.votes) > 0
                                    ? darkmode.value
                                      ? 'lightseagreen'
                                      : 'green'
                                    : parseInt(comment.votes) < 0
                                    ? darkmode.value
                                      ? 'palevioletred'
                                      : 'red'
                                    : darkmode.value
                                    ? 'rgb(255,255,255)'
                                    : 'rgb(0,0,0,0.5)'
                              },
                              fontSize: '0.8rem',
                              fontWeight: 'bold'
                            }}
                            disabled
                          >
                            {comment.votes}
                          </ToggleButton>
                          <ToggleButton
                            value={-1}
                            aria-label="quilt"
                            sx={{ padding: '0px', px: '10px', py: '0px', lineHeight: '19px' }}
                            onChange={() => {
                              setSubmittingVote(true)
                              voteComment(comment.id, -1)
                            }}
                          >
                            -
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '30px',
                            marginTop: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginBottom: '0.25rem'
                          }}
                        >
                          <Avatar
                            sx={{
                              marginLeft: '0.5rem',
                              width: '20px',
                              height: '20px',
                              bgcolor: 'purple',
                              fontSize: '0.7rem'
                            }}
                            src={
                              comment?.avatar ? process.env.REACT_APP_API_URL + comment?.avatar : ''
                            }
                            alt={comment.fromName}
                          >
                            {!comment.avatar ? comment.fromName.slice(0, 1) : ''}
                          </Avatar>
                          <Link
                            href={'/u/' + comment.from}
                            onClick={(e) => {
                              if (!e.ctrlKey) {
                                e.preventDefault()
                                navigate('/u/' + comment.from)
                              }
                            }}
                            sx={{ display: 'contents' }}
                          >
                            <Typography
                              fontWeight={'bold'}
                              sx={{
                                color: darkmode.value ? 'white' : 'black',
                                ml: '0.5rem',
                                fontSize: '0.8rem'
                              }}
                            >
                              {comment.fromName}
                            </Typography>
                          </Link>
                          <Typography
                            fontWeight={'bold'}
                            sx={{
                              color: darkmode.value ? '#b3b3b3' : '#595656',
                              ml: '0.5rem',
                              fontSize: '0.55rem'
                            }}
                          >
                            {moment(comment.date).format('MMM[.] D, YYYY HH:mm')}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: '0.25rem' }}>
                          <Box dir="auto">
                            <Typography
                              sx={{
                                fontFamily: 'Regular-Family',
                                ml: '0.5rem',
                                fontWeight: 'bold',
                                opacity: '0.85',
                                fontSize: '0.8rem',
                                wordBreak: 'break-word',
                                whiteSpace: 'break-spaces'
                              }}
                            >
                              <div dangerouslySetInnerHTML={{ __html: comment.text }} />
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              height: '20px',
                              marginTop: '0.35rem'
                            }}
                          >
                            <Button
                              onClick={() => handleReply(comment.id)}
                              variant="text"
                              color="primary"
                              disableRipple
                              sx={{
                                textTransform: 'none',
                                fontSize: '0.65rem',
                                fontWeight: 'bold'
                              }}
                            >
                              <ReplyIcon sx={{ fontSize: '1rem' }} /> Reply
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              disableRipple
                              sx={{
                                textTransform: 'none',
                                fontSize: '0.65rem',
                                fontWeight: 'bold'
                              }}
                              onClick={() => {
                                setReportCommentId(comment.id)
                                toggleReportCommentShow()
                              }}
                            >
                              <FlagIcon sx={{ fontSize: '1rem' }} /> Report
                            </Button>
                            {user?.privileges?.includes('4') && (
                              <Button
                                disabled={isDeleting}
                                variant="text"
                                color="error"
                                disableRipple
                                sx={{
                                  textTransform: 'none',
                                  fontSize: '0.65rem',
                                  fontWeight: 'bold'
                                }}
                                onClick={() => handleDeleteComment(comment.id, subData.sub.id)}
                              >
                                Delete
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                  {comment.replies.map((reply, index) => (
                    <Paper
                      key={index}
                      elevation={4}
                      sx={{
                        marginLeft: '3rem',
                        marginTop: '1rem',
                        paddingLeft: '0.5rem',
                        paddingRight: '0.75rem'
                      }}
                    >
                      <Box sx={{ width: '100%' }}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '30px',
                            marginTop: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginBottom: '0.25rem'
                          }}
                        >
                          <Avatar
                            sx={{
                              marginLeft: '0.5rem',
                              width: '20px',
                              height: '20px',
                              bgcolor: 'purple',
                              fontSize: '0.7rem'
                            }}
                            src={reply?.avatar ? process.env.REACT_APP_API_URL + reply?.avatar : ''}
                            alt={reply.fromName}
                          >
                            {!reply.avatar ? reply.fromName.slice(0, 1) : ''}
                          </Avatar>
                          <Link
                            href={'/u/' + reply.from}
                            onClick={(e) => {
                              if (!e.ctrlKey) {
                                e.preventDefault()
                                navigate('/u/' + reply.from)
                              }
                            }}
                            sx={{ display: 'contents' }}
                          >
                            <Typography
                              fontWeight={'bold'}
                              sx={{
                                color: darkmode.value ? 'white' : 'black',
                                ml: '0.5rem',
                                fontSize: '0.8rem'
                              }}
                            >
                              {reply.fromName}
                            </Typography>
                          </Link>
                          <Typography
                            fontWeight={'bold'}
                            sx={{ color: '#595656', ml: '0.5rem', fontSize: '0.55rem' }}
                          >
                            {moment(reply.date).format('MMM[.] D, YYYY HH:mm')}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: '0.25rem' }}>
                          <Box dir="auto">
                            <Typography
                              sx={{
                                fontFamily: 'Regular-Family',
                                ml: '0.5rem',
                                fontWeight: 'bold',
                                opacity: '0.85',
                                fontSize: '0.8rem'
                              }}
                            >
                              {reply.text}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              height: '20px',
                              marginTop: '0.35rem'
                            }}
                          >
                            <Button
                              onClick={() => handleReply(comment.id, reply.fromName)}
                              variant="text"
                              color="primary"
                              disableRipple
                              sx={{
                                textTransform: 'none',
                                fontSize: '0.65rem',
                                fontWeight: 'bold'
                              }}
                            >
                              <ReplyIcon sx={{ fontSize: '1rem' }} /> Reply
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              disableRipple
                              sx={{
                                textTransform: 'none',
                                fontSize: '0.65rem',
                                fontWeight: 'bold'
                              }}
                              onClick={() => {
                                setReportCommentId(comment.id)
                                toggleReportCommentShow()
                              }}
                            >
                              <FlagIcon sx={{ fontSize: '1rem' }} /> Report
                            </Button>
                            {user?.privileges?.includes('4') && (
                              <Button
                                disabled={isDeleting}
                                variant="text"
                                color="error"
                                disableRipple
                                sx={{
                                  textTransform: 'none',
                                  fontSize: '0.65rem',
                                  fontWeight: 'bold'
                                }}
                                onClick={() =>
                                  handleDeleteComment(comment.id, subData.sub.id, true, reply.date)
                                }
                              >
                                Delete
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  ))}
                  <Divider sx={{ my: '1rem' }} />
                </Box>
              ))}
            </Box>
            <Box sx={{ mt: '1rem' }}>
              {reply ? (
                <>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: '0.5rem',
                      color: darkmode.value ? 'white' : 'initial'
                    }}
                  >
                    <ReplyIcon sx={{ fontSize: '1rem' }} />
                    Replying to {reply?.fromName}
                  </Typography>
                </>
              ) : (
                <></>
              )}
              <Box>
                <SubtitleCommentField
                  addComment={addComment}
                  subData={subData}
                  reply={reply}
                  commentText={commentText}
                  setCommentText={setCommentText}
                  setReply={setReply}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default SubtitleComments
