
import { api } from "./configs/axiosConfig"

export const deleteRateApi = async (subId, ratedFrom) => {
    const response = await api.request({
        url: `/api/admin/rates/delete`,
        method: "POST",
        data: {
            subId,
            ratedFrom,
        },
    })
    return response?.data || null
}
export const deleteSubCommentApi = async (commentId, subId, isReply = false, replyDate = "") => {
    const response = await api.request({
        url: `/api/admin/comment/delete`,
        method: "POST",
        data: {
            subId,
            commentId,
            isReply,
            replyDate
        },
    })
    return response?.data || null
}
export const getUsers = async (id = undefined, username = undefined, verified) => {
    const response = await api.request({
        url: `/api/admin/users/get`,
        method: "POST",
        data: {
            id,
            username,
            verified
        },
    })
    return response?.data || null
}
export const changeUsername = async (id = undefined, username = undefined) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            username,
            update: "username"
        },
    })
    return response?.data || null
}
export const changeEmail = async (id = undefined, email = undefined) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            email,
            update: "email"
        },
    })
    return response?.data || null
}
export const changeSignupDate = async (id = undefined, register_date = undefined) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            register_date,
            update: "register_date"
        },
    })
    return response?.data || null
}
export const changeSignupDateSubscene = async (id = undefined, register_date_subscene = undefined) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            register_date_subscene,
            update: "register_date_subscene"
        },
    })
    return response?.data || null
}
export const changeSubsceneStatus = async (id = undefined, is_subscene) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            is_subscene,
            update: "is_subscene"
        },
    })
    return response?.data || null
}
export const changeSubsceneRatings = async (id = undefined, subscene_ratings) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            subscene_ratings,
            update: "subscene_ratings"
        },
    })
    return response?.data || null
}
export const changeUsernameChanged = async (id = undefined, username_changed) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            username_changed,
            update: "username_changed"
        },
    })
    return response?.data || null
}
export const deleteSocialMedia = async (id = undefined, social_media) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            social_media,
            update: "delete_social"
        },
    })
    return response?.data || null
}
export const deleteOthers = async (id = undefined, type) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            type,
            update: "delete_others"
        },
    })
    return response?.data || null
}
export const restoreOthers = async (id = undefined, type) => {
    const response = await api.request({
        url: `/api/admin/users/update`,
        method: "POST",
        data: {
            id,
            type,
            update: "restore_others"
        },
    })
    return response?.data || null
}
export const banUser = async (id = undefined, type, time = undefined) => {
    const response = await api.request({
        url: `/api/admin/users/ban`,
        method: "POST",
        data: {
            id,
            type,
            time,
        },
    })
    return response?.data || null
}
export const unbanUser = async (id = undefined, type) => {
    const response = await api.request({
        url: `/api/admin/users/unban`,
        method: "POST",
        data: {
            id,
            type,
        },
    })
    return response?.data || null
}
export const restrictUser = async (id = undefined, restrictions) => {
    const response = await api.request({
        url: `/api/admin/users/restrict`,
        method: "POST",
        data: {
            id,
            restrictions,
        },
    })
    return response?.data || null
}
export const userBadges = async (id = undefined, badges) => {
    const response = await api.request({
        url: `/api/admin/users/badges`,
        method: "POST",
        data: {
            id,
            badges,
        },
    })
    return response?.data || null
}
export const userPermissions = async (id = undefined, permissions) => {
    const response = await api.request({
        url: `/api/admin/users/permissions`,
        method: "POST",
        data: {
            id,
            permissions,
        },
    })
    return response?.data || null
}
export const getHomePage = async () => {
    const response = await api.request({
        url: `/api/admin/homepage/get`,
        method: "GET",
    })
    return response?.data || null
}
export const setHomePage = async (hp) => {
    const response = await api.request({
        url: `/api/admin/homepage/update`,
        method: "POST",
        data: {
            hp
        }
    })
    return response?.data || null
}
export const getFixSub = async () => {
    const response = await api.request({
        url: `/api/admin/fixSub/get`,
        method: "GET",
    })
    return response?.data || null
}
export const submitFixSub = async (sslink, ssid) => {
    const response = await api.request({
        url: `/api/admin/fixSub/fix`,
        method: "POST",
        data: {
            sslink,
            ssid
        }
    })
    return response?.data || null
}
export const getMergeuUser = async () => {
    const response = await api.request({
        url: `/api/admin/fixSub/get`,
        method: "GET",
    })
    return response?.data || null
}
export const submitMergeUser = async (subsceneId, subsourceId) => {
    const response = await api.request({
        url: `/api/admin/merge/merge`,
        method: "POST",
        data: {
            subsceneId,
            subsourceId
        }
    })
    return response?.data || null
}