import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Box, Container } from '@mui/material'
import SeriesTitle from '../Components/SeriesSeasons/SeriesTitle2'
import SeriesSeasons2 from '../Components/SeriesSeasons/SeriesSeasons2'
import Subtitles from '../Components/Subtitles/Subtitles2'
import { useUserContext } from '../Contexts/userContext'
import { Grid } from 'react-loading-icons'
import NotFound from './NotFound'
import { CurrentMovieContext } from '../Contexts/currentMovieContext'
import { formatted_langs } from '../data/langs'
import useDarkMode from 'use-dark-mode'
import { set_meta } from '../utils/seo';
const formattedLangToNormal = (lang) => {
  const swap = (json) => {
    var ret = {}
    for (var key in json) {
      ret[json[key]] = key
    }
    return ret
  }
  const swapped = swap(formatted_langs)
  return swapped[lang]
}
function MoviePage() {
  //
  const user = useUserContext()
  const darkmode = useDarkMode()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const { setCurrentMovieCtx } = useContext(CurrentMovieContext)
  //
  const [loading, setLoading] = useState(true)
  const [isMovie, setIsMovie] = useState(false)
  const [subs, setSubs] = useState([])
  const [movieData, setMovieData] = useState({})
  const [seasons, setSeasons] = useState(false)
  const [bigPoster, setBigPoster] = useState(undefined)
  const [langFilter, setLangFilter] = useState()
  const [typesFilter, setTypesFilter] = useState()
  const [subsLoading, setSubsLoading] = useState(false)
  const [urlFilter, setUrlFilter] = useState(undefined)
  const [totalSubs, setTotalSubs] = useState(undefined)
  const [notFound, setNotFound] = useState(false)
  //

  const setMovieType = (movie) => {
    if (movie) {
      setIsMovie(movie?.type === 'Movie')
    }
  }

  const setPageTitle = ( movie ) =>
  {
    if (movie && 'fullName' in movie) {
      set_meta({
        is_landing: false,
        title: `Download Subtitles For ${movie?.fullName} | SubSource`,
        movie_name: movie?.fullName ?? '',
        movie_brief: movie?.brief ?? '',
        poster: movie?.poster ?? ''
      })
    }
  }

  const handleSeasonClick = (seasonNumber) => {
    navigate(location.pathname + '/season-' + seasonNumber)
    setSeasons(false)
    setBigPoster(undefined)
    setIsMovie(false)
    setMovieData({})
    setLoading(true)
  }
  const handleReload = () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/getMovie',
        {
          movieName: params?.movieName,
          season: params?.secondParam?.toLowerCase()?.includes('season')
            ? params.secondParam.toLowerCase()
            : undefined
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res?.data?.success) {
          setPageTitle(res?.data?.movie)

          if (params.thirdParam && formattedLangToNormal(params.thirdParam) !== undefined) {
            setUrlFilter(formattedLangToNormal(params.thirdParam))
            setTotalSubs(res.data.subs.length)
            setSubs(
              res.data.subs.filter((x) => x.lang === formattedLangToNormal(params.thirdParam))
            )
            setMovieData(res.data.movie)
            if (user) {
              setCurrentMovieCtx(res?.data?.movie?.id)
            }
          } else {
            if (
              params.secondParam &&
              (params.secondParam.includes('season') ||
                formattedLangToNormal(params.secondParam) !== undefined)
            ) {
              if (params.secondParam.includes('season')) {
                if (langFilter.length > 0) {
                  setTotalSubs(res.data.subs.length)
                  typesFilter.length > 0
                    ? setSubs(
                        res.data.subs.filter(
                          (x) => langFilter.includes(x.lang) && typesFilter.includes(x.rt)
                        )
                      )
                    : setSubs(res.data.subs.filter((x) => langFilter.includes(x.lang)))
                  setMovieData(res.data.movie)
                  if (user) {
                    setCurrentMovieCtx(res?.data?.movie?.id)
                  }
                } else {
                  setTotalSubs(res.data.subs.length)
                  typesFilter.length > 0
                    ? setSubs(res.data.subs.filter((x) => typesFilter.includes(x.rt)))
                    : setSubs(res.data.subs)
                  setMovieData(res.data.movie)
                  if (user) {
                    setCurrentMovieCtx(res?.data?.movie?.id)
                  }
                }
              } else {
                setUrlFilter(formattedLangToNormal(params.secondParam))
                setTotalSubs(res.data.subs.length)
                setSubs(
                  res.data.subs.filter((x) => x.lang === formattedLangToNormal(params.secondParam))
                )
                setMovieData(res.data.movie)
                if (user) {
                  setCurrentMovieCtx(res?.data?.movie?.id)
                }
              }
            } else {
              if (res?.data?.seasons) {
                setIsMovie(false)
                setSeasons(res.data.seasons.sort((a, b) => a.season - b.season))
                setBigPoster(res?.data?.bigPoster)
                if (user) {
                  setCurrentMovieCtx(res?.data?.movie?.id)
                }
              } else {
                if (langFilter.length > 0) {
                  setIsMovie(false)
                  setMovieData(res.data.movie)
                  if (user) {
                    setCurrentMovieCtx(res?.data?.movie?.id)
                  }
                  setTotalSubs(res.data.subs.length)
                  typesFilter.length > 0
                    ? setSubs(
                        res.data.subs.filter(
                          (x) => langFilter.includes(x.lang) && typesFilter.includes(x.rt)
                        )
                      )
                    : setSubs(res.data.subs.filter((x) => langFilter.includes(x.lang)))
                } else {
                  setIsMovie(false)
                  setMovieData(res.data.movie)
                  if (user) {
                    setCurrentMovieCtx(res?.data?.movie?.id)
                  }
                  setTotalSubs(res.data.subs.length)
                  typesFilter.length > 0
                    ? setSubs(res.data.subs.filter((x) => typesFilter.includes(x.rt)))
                    : setSubs(res.data.subs)
                }
              }
            }
          }
          setSubsLoading(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setNotFound(true)
        }
        console.log({ err })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const getSubtitles = (langFilters, releaseTypeFilters) => {
    setMovieData({})
    axios
      .post(
        process.env.REACT_APP_API_URL + '/api/getMovie',
        {
          langs: langFilters,
          movieName: params?.movieName,
          season: params?.secondParam?.toLowerCase()?.includes('season')
            ? params.secondParam.toLowerCase()
            : undefined
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          setPageTitle(res?.data?.movie)

          setMovieData(res.data.movie ?? {})

          setMovieType(res?.data?.movie)

          setTotalSubs(res?.data?.subs?.length ?? 0)

          if (user) {
            setCurrentMovieCtx(res?.data?.movie?.id)
          }

          // third params logic
          if (params.thirdParam && formattedLangToNormal(params.thirdParam) !== undefined) {
            setUrlFilter(formattedLangToNormal(params.thirdParam))
            setSubs(
              res.data.subs.filter((x) => x.lang === formattedLangToNormal(params.thirdParam))
            )
          }
          // second params logic
          else if (
            params.secondParam &&
            (params.secondParam.includes('season') ||
              formattedLangToNormal(params.secondParam) !== undefined)
          ) {
            // if (params.secondParam.includes('season')) {
            if (langFilters.length > 0) {
              releaseTypeFilters.length > 0
                ? setSubs(
                    res.data.subs.filter(
                      (x) => langFilters.includes(x.lang) && releaseTypeFilters.includes(x.rt)
                    )
                  )
                : setSubs(res.data.subs.filter((x) => langFilters.includes(x.lang)))
            } else {
              releaseTypeFilters.length > 0
                ? setSubs(res.data.subs.filter((x) => releaseTypeFilters.includes(x.rt)))
                : setSubs(res.data.subs)
            }
            // }
            // else
            // {
            //   setUrlFilter(formattedLangToNormal(params.secondParam))
            //   setSubs(
            //     res.data.subs.filter((x) => x.lang === formattedLangToNormal(params.secondParam))
            //   )
            //   if (user) {
            //     setCurrentMovieCtx(res?.data?.movie?.id)
            //   }
            // }
          }
          // first params logic
          else {
            if (res?.data?.seasons) {
              setSeasons(res.data.seasons.sort((a, b) => a.season - b.season))
              setBigPoster(res?.data?.bigPoster)
            } else {
              if (langFilters.length > 0) {
                releaseTypeFilters.length > 0
                  ? setSubs(
                      res.data.subs.filter(
                        (x) => langFilters.includes(x.lang) && releaseTypeFilters.includes(x.rt)
                      )
                    )
                  : setSubs(res.data.subs.filter((x) => langFilters.includes(x.lang)))
              } else {
                releaseTypeFilters.length > 0
                  ? setSubs(res.data.subs.filter((x) => releaseTypeFilters.includes(x.rt)))
                  : setSubs(res.data.subs)
              }
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setNotFound(true)
        }
        console.log({ err })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  //
  useEffect( () =>
  {
    setNotFound( false );
    setLoading(true)
    setIsMovie(false)
    setSubs([])
    setSeasons(false)
    setBigPoster(undefined)
    // try finding filter langs
    let lf = [],
      rf = []
    try {
      lf = JSON.parse(window.localStorage.getItem('langs')) || []
      setLangFilter(() => lf)
    } catch (error) {
      console.log({ error })
    }
    // try finding filter release types
    try {
      rf = JSON.parse(window.localStorage.getItem('release_types')) || []
      setTypesFilter(() => rf)
    } catch (error) {
      console.log({ error })
    }
    //
    getSubtitles(lf, rf)

    // eslint-disable-next-line
  }, [location.pathname, user?.id])

  if ( notFound )
  {
    
    return <NotFound />
  }
  return !loading ? (
    <Container maxWidth="xl" sx={{ mt: '2rem' }}>
      {isMovie ? (
        <Subtitles
          backToSerie={false}
          typesFilter={typesFilter}
          setTypesFilter={setTypesFilter}
          totalSubs={totalSubs}
          subs={subs}
          movie={movieData}
          langFilter={langFilter}
          setLangFilter={setLangFilter}
          loading={subsLoading || loading}
          setLoading={setSubsLoading}
          urlFilter={urlFilter}
          reload={handleReload}
        />
      ) : Array.isArray(seasons) ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '2rem',
            backgroundColor: 'transparent',
          }}
        >
          <SeriesTitle seasons={seasons} bigPoster={bigPoster} />
          <SeriesSeasons2 seasons={seasons} handleSeasonClick={handleSeasonClick} />
        </Box>
      ) : (
        <>
          <Box>
            <Subtitles
              backToSerie={movieData?.type === 'TVSeries'}
              movieLink={params?.movieName}
              typesFilter={typesFilter}
              setTypesFilter={setTypesFilter}
              totalSubs={totalSubs}
              subs={subs}
              movie={movieData}
              langFilter={langFilter}
              setLangFilter={setLangFilter}
              loading={subsLoading || loading}
              setLoading={setSubsLoading}
              urlFilter={urlFilter}
              reload={handleReload}
            />
          </Box>
        </>
      )}
    </Container>
  ) : (
    <Box
      sx={{
        height: '800px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black'
      }}
    >
      <Grid fill={darkmode.value ? 'white' : 'black'} />
    </Box>
  )
}

export default MoviePage
