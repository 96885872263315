import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { restrictUser, } from '../../../api/adminApi';
import { dispachAlert } from '../../../utils/dispachers';

function Restrictions({ user, isLoading }) {
    const [restrictions, setRestrictions] = useState({
        upload: false,
        comment: false,
        message: false,
        rate: false
    });
    const queryClient = useQueryClient();
    const { refetch: _restrictUser, isFetching: isRestricting } = useQuery({
        queryKey: ["adminRestrict"],
        queryFn: async () => {
            try {
                const result = await restrictUser(user.id, restrictions);
                if (result.success) {
                    dispachAlert('success', "Success.");
                    queryClient.invalidateQueries({
                        queryKey: ['adminGetUsers']
                    })
                } else {
                    dispachAlert('error', result?.errMsg || "Server Error.");
                }
                return result;
            } catch (error) {
                if (error?.response?.status === 403) {
                    dispachAlert('error', "Forbidden.")
                } else if (error?.response?.status === 404) {
                    dispachAlert('error', "Not Found.")
                } else {
                    dispachAlert('error', "Server Error.")
                }
                return { success: false }
            }
        },
        enabled: false
    })
    const handleClickRestrict = () => {
        _restrictUser();
    }
    useEffect(() => {
        setRestrictions({
            upload: user.restrictions.upload,
            comment: user.restrictions.comment,
            rate: user.restrictions.rate,
            message: user.restrictions.message,
        })
    }, [user])

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '20px' }}>
          Restrictions{' '}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <FormGroup sx={{ display: 'flex', gap: '1rem', flexFlow: 'wrap', flex: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={restrictions.upload}
                  onChange={(e, v) => setRestrictions((p) => ({ ...p, upload: v }))}
                />
              }
              label="Upload"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={restrictions.comment}
                  onChange={(e, v) => setRestrictions((p) => ({ ...p, comment: v }))}
                />
              }
              label="Comment"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={restrictions.rate}
                  onChange={(e, v) => setRestrictions((p) => ({ ...p, rate: v }))}
                />
              }
              label="Rate"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={restrictions.message}
                  onChange={(e, v) => setRestrictions((p) => ({ ...p, message: v }))}
                />
              }
              label="Message"
            />
          </FormGroup>
          <Button
            variant="outlined"
            disableRipple
            color="primary"
            disabled={isLoading || isRestricting}
            sx={{ textTransform: 'none' }}
            onClick={handleClickRestrict}
          >
            Apply
          </Button>
        </Box>
      </Box>
    )
}

export default Restrictions