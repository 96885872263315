import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { getFixSub, submitFixSub } from '../../api/adminApi'
import { set_meta } from '../../utils/seo';
import { dispachAlert } from '../../utils/dispachers';

function FixSub() {
  const [sslink, setSslink] = useState('')
  const [ssid, setSsid] = useState('')
  const [ forbidden, setForbidden ] = useState( false )
  set_meta({
    is_landing: false
  })
  const { refetch: submit, isFetching } = useQuery({
    queryKey: ['adminFixSubSubmit'],
    queryFn: async () => {
      try {
        const _data = await submitFixSub(sslink, ssid)
        if (_data.success) {
          dispachAlert('success', `Fixed ${_data.count} Subtitles.`)
        }
        return _data
      } catch (error) {
        dispachAlert('error', 'Error.')
      }
    },
    enabled: false
  })
  useQuery({
    queryKey: ['adminFixTitleGet'],
    queryFn: async () => {
      const _data = await getFixSub()
      if (!_data.success) {
        setForbidden(true)
      }
      return _data
    }
  })
  if (forbidden) {
    return (
      <>
        <Box>
          <Typography>Forbidden.</Typography>
        </Box>
      </>
    )
  }
  return (
    <Paper elevation={4} sx={{ borderRadius: '16px', width: '100%', maxWidth: {lg: '50%'}, mx: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          p: { xs: '1rem', md: '3rem' }
        }}
      >
        <Typography sx={{ fontFamily: 'Medium-Family', fontSize: '24px', fontWeight: 'bold' }}>
          Fix Subtitle
        </Typography>
        <TextField
          value={sslink}
          label={'Subscene Link'}
          onChange={(e) => setSslink(e.target.value)}
        />
        <TextField value={ssid} label={'Subsource ID'} onChange={(e) => setSsid(e.target.value)} />
        <Button
          variant="outlined"
          disabled={isFetching || !ssid || !sslink}
          onClick={(e) => {
            submit()
          }}
        >
          Submit
        </Button>
      </Box>
    </Paper>
  )
}

export default FixSub
