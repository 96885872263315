import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Container, Link } from '@mui/material'
import LoginDialog from './LoginDialog'
import SignUpDialog from './SignUpDialog'
import { useUserContext } from '../../Contexts/userContext'
import ForgetPasswordDialog from './ForgetPasswordDialog'
import useDarkMode from 'use-dark-mode'
import { useQuery } from '@tanstack/react-query'
import { UserApi } from '../../api/userApi'
import Navigation from './Navigation'
import { useSmallWidth } from '../../hooks/useSmallWidth';
const RegularFont = 'Regular-Family'
function Header({ children, appBarPosition = 'sticky' }) {
  const darkmode = useDarkMode(false)
  const user = useUserContext()

  const isSmall = useSmallWidth()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogOpenSignUp, setDialogSignUpOpen] = useState(false)
  const [dialogOpenForgetPassword, setDialogOpenForgetPassword] = useState(false)
  // getUserNotifs
  const [userNotifs, setUserNotifs] = useState({
    subtitles: 0,
    unread_messages: 0,
    new_tickets: 0
  })
  // user unread messages count
  const { refetch: refetchUserNotifs } = useQuery({
    queryKey: ['getUserNotifs'],
    queryFn: async () => {
      try {
        const _res = await UserApi.getUserNotifs()
        setUserNotifs(_res)
        return _res
      } catch (error) {
        console.log(error)
      }
    },
    enabled: user !== undefined
  })
  const handleClickOpen = () => {
    setDialogOpen(true)
  }
  const handleForgetPasswordOpen = () => {
    setDialogOpenForgetPassword(true)
  }
  const handleCloseForgetPassword = () => {
    setDialogOpenForgetPassword(false)
  }
  const handleClickOpenSignUp = () => {
    setDialogSignUpOpen(true)
  }
  const handleClose = () => {
    setDialogOpen(false)
  }
  const handleCloseSignUp = () => {
    setDialogSignUpOpen(false)
  }

  useEffect(() => {
    let interv
    if ( user )
    {
      refetchUserNotifs()
      // refetch unread messages after each minute 
      interv = setInterval(refetchUserNotifs, 60000)
    }
    return () => clearInterval(interv)
    // eslint-disable-next-line
  }, [ user ] )
  return (
    <>
      <LoginDialog
        handleClose={handleClose}
        isOpen={dialogOpen}
        handleRegisterOpen={handleClickOpenSignUp}
        openForgetPassword={handleForgetPasswordOpen}
      />
      <SignUpDialog
        handleClose={handleCloseSignUp}
        isOpen={dialogOpenSignUp}
        openLogIn={handleClickOpen}
        openForgetPassword={handleForgetPasswordOpen}
      />
      <ForgetPasswordDialog
        handleClose={handleCloseForgetPassword}
        isOpen={dialogOpenForgetPassword}
        openLogIn={handleClickOpen}
      />
      <AppBar
        position={appBarPosition}
        color="inherit"
        sx={{
          backgroundColor: darkmode.value ? '#121212' : '#fff',
          px: '0',
          top: '0',
          zIndex: '1200'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              minHeight: '50px!important',
              position: 'relative',
              p: { xs: '4px', md: '7px', lg: '10px' },
              gap: '.15rem'
            }}
          >
            {/* logo */}
            <Box
              sx={{
                display: 'flex',
                flex: 'auto',
                alignItems: 'center',
                marginLeft: '-10px',
                filter: 'none'
              }}
            >
              <Link
                href="/"
                // onClick={(e) => {
                //   e.preventDefault()
                //   if (!e.ctrlKey) {
                //     navigate('/')
                //   }
                // }}
              >
                <img
                  src={darkmode.value ? '/icons/subsource-light.png' : '/icons/subsource-dark.png'}
                  alt="subsource"
                  width={isSmall ? '135px' : '160px'}
                />
              </Link>
              {user?.privileges?.length && !window.location.pathname.includes('admin') ? (
                <>
                  {!isSmall && (
                    <Link
                      href="/admin"
                      sx={{
                        '&:hover': { backgroundColor: 'inherit' },
                        color: darkmode.value ? 'white' : 'black',
                        whiteSpace: 'nowrap',
                        fontWeight: '600',
                        transition: '0ms all ease-in-out',
                        fontFamily: RegularFont,
                        textDecoration: 'none',
                        textTransform: 'none',
                        fontSize: '1rem'
                      }}
                    >
                      Admin Panel
                    </Link>
                  )}
                </>
              ) : (
                ''
              )}
              {children}
            </Box>
            {/* navigation */}
            <Navigation
              userNotifs={userNotifs}
              handleClickOpen={handleClickOpen}
              handleClickOpenSignUp={handleClickOpenSignUp}
            />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default Header
